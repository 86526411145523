import iconIdDafi from "../../../../assets/icons/id_DAFI.svg";
import iconComite from "../../../../assets/icons/Icono_comite_DAFI-07.svg";
import iconFideicomiso from "../../../../assets/icons/Icono_fideicomiso_DAFI-06.svg";
import iconUser from "../../../../assets/icons/Icono_usuario_blanco_DAFI-04.svg";
import DetalleCurso from "./DetalleCurso";
import st from "./userDetail.module.css";
import { useMemo } from "react";
import { parseUserCursos } from "../../../../helpers/reporter";

const UserDetail = ({ userData }) => {
    const userGem = useMemo(() => parseUserCursos(userData), [userData]);
    
    return (
        <div>
            <div className={st.user_detail_header}>
                <h4>{userGem.informacion_general.nombre} {userGem.informacion_general.apellido}</h4>
                <div className={st.user_detail_header_info}>
                    <span>{userGem.informacion_general.email}</span>
                    <div>
                        <img src={iconIdDafi} alt="id_icon" />
                        <span>
                            <strong>ID: </strong>{userGem.informacion_general.id}
                        </span>
                    </div>
                    <div>
                        <img src={iconFideicomiso} alt="fideicomiso" />
                        <span>
                            <strong>Fideicomiso: </strong>{userGem.informacion_general.fideicomiso}
                        </span>
                    </div>
                    <span>
                        <strong>Cargo: </strong>{userGem.informacion_general.cargo}
                    </span>
                    <div>
                        <img src={iconUser} alt="user" />
                        <span>
                            <strong>Usuario: </strong>{userGem.informacion_general.usuario}
                        </span>
                    </div>
                    <div>
                        <img src={iconComite} alt="comite" />
                        <span>
                            <strong>Comite: </strong>{userGem.informacion_general.comite}
                        </span>
                    </div>
                </div>
            </div>
            <div className={st.user_detail_info_general}>
                <h5>Indicadores generales</h5>
                <div className={st.user_detail_info_general_data}>
                    <span>Cursos finalizados</span>
                    <span>Porcentaje de avance</span>
                    <span>Promedio de calificaciones</span>
                    <span>Accesos totales</span>
                    <h6>{userGem.finalizados}</h6>
                    <h6>{userGem.avance_global}</h6>
                    <h6>{userGem.calificacion_global}</h6>
                    <h6>{userGem.accesos_plataforma.length ? userGem.accesos_plataforma[0].accesos : 0}</h6>
                </div>
            </div>
            <div className={st.user_detail_info_cursos}>
                <h5>Cursos</h5>
                <div className={st.faq__list}>
                    { userGem.cursos.length ? userGem.cursos.map((curso, index) => (
                        <DetalleCurso
                            key={index}
                            titulo={curso.title}
                            accesos={curso.accesos}
                            finalizado={curso.finalizado ? 'si' : 'no'}
                            califi={curso.calificacion ?? 0}
                            avance={curso.avance}
                            fechaInicio={curso.primer_acceso_fecha}
                            fechaFin={curso.ultimo_acceso_fecha}
                        />
                    )) : (
                        <span>El usuario no ha completado cursos en las fechas seleccionadas</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserDetail;
