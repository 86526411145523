import { rewards_constants } from '../constants/rewards';
import { rewards_service } from '../services/rewards';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const rewards_actions = {
    get_all_rewards,
    create_reward,
    edit_reward,
    delete_reward
};

function get_all_rewards() {
    return dispatch => {
        dispatch(request());

        rewards_service.get_all_rewards()
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.rewards));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: rewards_constants.REWARDS_REQUEST, payload: true } }
    function success(param) { return { type: rewards_constants.REWARDS_SUCCESS, payload: param } }
    function failure(error) { return { type: rewards_constants.REWARDS_FAILURE, payload: error } }
}


async function create_reward(reward) {
    await rewards_service.create_reward(reward)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Recompensa',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Recompensa',
                        `Fue creada con exito`,
                        'success',
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return Swal.fire(
                'Error',
                'Ocurrio un error, intenta más tarde',
                'error',
            )
        })

    function request() { return { type: rewards_constants.CREATE_REWARDS_REQUEST, payload: true } }
    function success(param) { return { type: rewards_constants.CREATE_REWARDS_SUCCESS, payload: param } }
    function failure(error) { return { type: rewards_constants.CREATE_REWARDS_FAILURE, payload: error } }
}

async function edit_reward(reward) {
    await rewards_service.edit_reward(reward)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {

                    return Swal.fire(
                        'Recompensa',
                        `${res.error.message}`,
                        'error',
                    )
                } else {

                    return Swal.fire(
                        'Recompensa',
                        `Fue actualizada con exito`,
                        'success',
                    )
                }
            },
            error => {

                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return Swal.fire(
                'Recompensa',
                `${e}`,
                'error',
            )
        })
}

async function delete_reward(reward) {

    await rewards_service.delete_reward(reward)
        .then(
            res => {
                if (res == 0) {
                    failure(res);
                    return Swal.fire(
                        'Recompensa',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Recompensa',
                        'Fue borrado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

        function request() { return { type: rewards_constants.DELETE_REWARDS_REQUEST, payload: true } }
        function success(param) { return { type: rewards_constants.DELETE_REWARDS_SUCCESS, payload: param } }
        function failure(error) { return { type: rewards_constants.DELETE_REWARDS_FAILURE, payload: error } }
}