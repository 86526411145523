import React, { useState } from 'react';
import Meetings from './Conferencias/Meetings';
import Header from './Header';



const Conferencias = ({ onClose }) => {
    const [active, setActive] = useState(true);
    return (
        <div className="bg-gray-100 w-1/4 rounded-xl text-dark">
            <div>
                <Meetings onClose={onClose} />
            </div>
        </div>

    );
}

export default Conferencias;