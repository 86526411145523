import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-dropdown-select';

import { tutors_actions } from '../../../../store/actions/tutors';



const AsignarCursos = ({ tutor, onCloseSubMenu }) => {
    const dispatch = useDispatch();

    const tutorId  = tutor.id;
    const [selected_courses, set_selected_courses] = useState([]);

    const courses = useSelector(state => state.courses);
    const courseTutor = useSelector(state => state.tutors.tutors);
    const tutorCourse = useSelector(state => state.tutors);

    useEffect(() => {
        /* const getCoursesTutor = () => dispatch(tutors_actions.get_tutor_courses(tutorId));
        getCoursesTutor(); */
        let idscourseActu = new Array();
        courseTutor.map((course)=>{
            idscourseActu.push(course.id);
        });//Obtiene el id de los cursos actuales que esta agregado el Tutor
        formik.setFieldValue('courseid', idscourseActu);

        courses_seleccionados();
    }, [tutorCourse.loading_tutors]);

    const options_courses = courses.courses.map((option) => ({
        ...option,
        label: option.fullname,
        value: option.id,
        courseid: option.id
    }));

    const opcionesCourse = []
    options_courses.map(course => {
        opcionesCourse.push(course);
    });


    const courses_seleccionados = () => {
        let selectes_courses = [];

        options_courses.map(array1 => {
            let band = false;
            courseTutor.map(array2 => {
                if (array2.id === array1.id) {
                    selectes_courses.push(array1);
                    band = true;
                }
                
            });
        });
        set_selected_courses(selectes_courses);
    }

    const validate = (values) => {
        //console.log('funValidate', values);

        let errors = {};
        if (values.courseid.length === 0) {
            errors.courseid = 'Debe de Elegir un Curso'
        }
        return errors;
    }

    const formik = useFormik({
        initialValues: {
            courseid: [],
            coursesdelete:[]
        },
        validate: validate,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async (coursesids) => {
        await tutors_actions.assing_tutor_courses(coursesids, tutorId);
        onCloseSubMenu(false); 
    }

    const onChange = async (values) => {
       
        let coursesids = formik.values.courseid;

        let coursesSelect = new Array();//Obtiene los cursos Seleccionados
        let idscourseActu = new Array();
        let idsValues = new Array();
        values.map((value)=>{
            idsValues.push(value.id);
        });//Obtiene el id de los cursos seleccionados

        courseTutor.map((course)=>{
            idscourseActu.push(course.id);
        });//Obtiene el id de los cursos actuales que esta agregado el Tutor

        let coursesid = idsValues.filter(value => { 
            return !coursesids.includes(value)
        });//Obtiene los cursos ha agregar

        let deleteC = new Array();
        deleteC = idscourseActu.filter((id)=> !idsValues.includes(id));

        coursesids.push(coursesid);
        formik.setFieldValue('courseid', idsValues);
        formik.setFieldValue('coursesdelete', deleteC);
        set_selected_courses(values);
    };
    //console.log('tutor-cursos', tutorCourse);

    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <div className="campo">
                <label className="mb-2" htmlFor="">Asignar cursos</label>

                <Select
                    multi
                    options={opcionesCourse}
                    values={ [...selected_courses] }
                    name="courseid"
                    onChange={ (value) => { onChange(value)} }
                />
                <p className="text-red-600 text-sm">{formik.errors.courseid ? <div>{formik.errors.courseid}</div> : null}</p>
            </div>
            
            <button className="mx-auto text-center rounded-full bg-iacblack bg-opacity-100 px-4 py-2 w-48 text-white
                            border border-blue-300 hover:text-blue-300 hover:bg-opacity-50 block">Guardar</button>
        </form>
    );
}

export default AsignarCursos;