
import { attempts_scorm_constants } from '../constants/attemptScorm';
import { attempts_scorm_service } from '../services/attemptScorm';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const attempts_scorm_actions = {
    get_all_attempts_scorm,
    delete_attempt_scorm
};

function get_all_attempts_scorm(idScorm) {

    return dispatch => {
        dispatch(request());

        attempts_scorm_service.get_all_attempts_scorm(idScorm)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.attempts_scorm));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: attempts_scorm_constants.ATTEMPTS_SCORM_REQUEST, payload: true } }
    function success(param) { return { type: attempts_scorm_constants.ATTEMPTS_SCORM_SUCCESS, payload: param } }
    function failure(error) { return { type: attempts_scorm_constants.ATTEMPTS_SCORM_FAILURE, payload: error } }
}

async function delete_attempt_scorm(row, idScorm) {

    await attempts_scorm_service.delete_attempt_scorm(row, idScorm)
        .then(
            res => {
                if (res == 0) {
                    failure(res);
                    return Swal.fire(
                        'Intento del scorm',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Intento del scorm',
                        'Intento borrado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: attempts_scorm_constants.ATTEMPTS_SCORM_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: attempts_scorm_constants.ATTEMPTS_SCORM_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: attempts_scorm_constants.ATTEMPTS_SCORM_DELETE_FAILURE, payload: error } }
}