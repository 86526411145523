
import { courses_constants } from '../constants/courses';
import { courses_service } from '../services/courses';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const courses_actions = {
    get_all_courses,
    courses_edit,
    courses_delete,
    courses_create,
    get_contents_course,
    delete_activity,
    add_activity_url_course,
    add_activity_resource_course,
    add_activity_scorm_course,
    get_method_enrol_course,
    delete_method_enrol_course,
    get_group_category_course,
    group_synchro,
    add_activity_exam_course,
    add_activity_dctres_course,
    add_activity_forum_course,
    add_activity_communication_course
};

function get_all_courses() {
    return dispatch => {
        dispatch(request());

        courses_service.get_all_courses()
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.courses));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: courses_constants.COURSES_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSES_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSES_FAILURE, payload: error } }
}

function courses_create(course, examen, urls, files, scorms) {
    console.log(files);
    return (dispatch) => {
        dispatch(request());

        Swal.fire({
            title: 'Agregando curso...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                let timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                            b.textContent = Swal.getTimerLeft()
                        }
                    }
                }, 100)
            },
        });

        courses_service.courses_create(course, examen, urls, files, scorms)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                        return Swal.fire(
                            'Curso',
                            `${res.error.message}`,
                            'error',
                        )
                    } else {
                        dispatch(success(res));
                        dispatch(get_all_courses());
                        return Swal.fire(
                            'Curso',
                            'Fue agregado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                    return Swal.fire(
                        'Curso',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: courses_constants.COURSE_CREATE_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_CREATE_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_CREATE_FAILURE, payload: error } }
}


function courses_edit(course) {
    return dispatch => {
        dispatch(request());
        Swal.fire({
            title: 'Actualizando curso...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                let timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                            b.textContent = Swal.getTimerLeft()
                        }
                    }
                }, 100)
            },
        });
        courses_service.courses_edit(course)
            .then(
                res => {
                    console.log(res);
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.warnings[0].message.toString()));
                        return Swal.fire(
                            'Curso',
                            'Ocurrio un error, intenta más tarde',
                            'error',
                        )
                    } else {
                        dispatch(success(res));
                        dispatch(get_all_courses());

                        return Swal.fire(
                            'Curso',
                            'Fue actualizado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    console.log(error);
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                    return Swal.fire(
                        'Curso Error',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: courses_constants.COURSE_EDIT_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_EDIT_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_EDIT_FAILURE, payload: error } }
}

function courses_delete(course) {
    return dispatch => {
        dispatch(request());
        Swal.fire({
            title: 'Eliminando curso...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                let timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                            b.textContent = Swal.getTimerLeft()
                        }
                    }
                }, 100)
            },
        });
        courses_service.courses_delete(course)
            .then(
                res => {
                    if (res === 0) {
                        dispatch(failure(res));
                        return Swal.fire(
                            'Curso',
                            'Ocurrio un error, intenta más tarde',
                            'error',
                        )
                    } else {
                        dispatch(success(res));
                        dispatch(get_all_courses());
                        return Swal.fire(
                            'Curso',
                            'Fue borrado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                    return Swal.fire(
                        'Curso',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: courses_constants.COURSE_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_DELETE_FAILURE, payload: error } }
}

function get_contents_course(courseid) {
    return dispatch => {
        dispatch(request());

        courses_service.get_contents_course(courseid)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.modulos));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: courses_constants.COURSE_CONTENTS_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_CONTENTS_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_CONTENTS_FAILURE, payload: error } }
}

async function delete_activity(actividad) {
    Swal.fire({
        title: 'Eliminando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.delete_activity(actividad)
        .then(
            res => {

                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Actividad',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                        (failure(res.error.message.toString()));
                } else {
                    return Swal.fire(
                        'Actividad',
                        'Fue borrado con exito',
                        'success'
                    );
                    success(res.courses);
                }
            },
            error => {
                return Swal.fire(
                    'Actividad',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
                    (failure(error.toString()));
                (alert_actions.error(error.toString()));
            })
        .catch(e => console.log(e))

    function request() { return { type: courses_constants.COURSE_CONTENTS_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_CONTENTS_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_CONTENTS_DELETE_FAILURE, payload: error } }
}

async function add_activity_url_course(resourse) {
    Swal.fire({
        title: 'Agregando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.add_activity_url_course(resourse)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    (failure(res.error.message.toString()));
                } else {
                    Swal.fire(
                        'URL',
                        'Fue agregado con exito',
                        'success'
                    )
                    //success(res.result);
                    return true;
                }
            },
            error => {
                console.log(error)
                (failure(error.toString()));
                (alert_actions.error(error.toString()));
            })
        .catch(e => {
            console.log(e)
            Swal.fire(
                'URL',
                'Ocurrio un error, intenta más tarde',
                'error'
            )
        })

    function request() { return { type: courses_constants.COURSE_CONTENTS_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_CONTENTS_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_CONTENTS_DELETE_FAILURE, payload: error } }
}

async function add_activity_resource_course(resource) {
    Swal.fire({
        title: 'Agregando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.add_activity_resource_course(resource)
        .then(
            res => {
                console.log('res', res);
                if (res.hasOwnProperty('error')) {
                    (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {
                    Swal.fire(
                        'Actividad',
                        'Fue agregado con exito',
                        'success'
                    )
                    return true;
                }
            },
            error => {
                (alert_actions.error(error.toString()));
            })
        .catch(e => {
            console.log(e)
            Swal.fire(
                'URL',
                'Ocurrio un error, intenta más tarde',
                'error'
            )
        })
}

async function add_activity_scorm_course(resource) {
    Swal.fire({
        title: 'Agregando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.add_activity_scorm_course(resource)
        .then(
            res => {
                console.log(res);
                if (res.hasOwnProperty('error')) {
                    Swal.fire(
                        'Scorm',
                        `${res.error.message}`,
                        'error'
                    )
                        (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {
                    Swal.fire(
                        'Actividad',
                        'Fue agregada con exito',
                        'success'
                    )
                    return true;
                }
            },
            error => {
                Swal.fire(
                    'Scorm',
                    `${error.toString()}`,
                    'error'
                )
                    (alert_actions.error(error.toString()));
            })
        .catch(e => {
            console.log(e)
            Swal.fire(
                'Scorm',
                'Ocurrio un error, intenta más tarde',
                'error'
            )
        })
}

function get_method_enrol_course(courseid) {
    return dispatch => {
        dispatch(request());

        courses_service.get_method_enrol_course(courseid)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.gruposAccess));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: courses_constants.COURSE_METHOD_EROL_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_METHOD_EROL_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_METHOD_EROL_FAILURE, payload: error } }
}

async function delete_method_enrol_course(courseid, enrolid) {
    await courses_service.delete_method_enrol_course(courseid, enrolid)
        .then(
            res => {

                if (res.hasOwnProperty('error')) {
                    (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {

                    return true;
                }
            },
            error => {
                (alert_actions.error(error.toString()));
            })
        .catch(e => console.log(e))
}

function get_group_category_course(contextid) {
    return dispatch => {
        dispatch(request());

        courses_service.get_group_category_course(contextid)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.group));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: courses_constants.COURSE_GROUP_REQUEST, payload: true } }
    function success(param) { return { type: courses_constants.COURSE_GROUP_SUCCESS, payload: param } }
    function failure(error) { return { type: courses_constants.COURSE_GROUP_FAILURE, payload: error } }
}

async function group_synchro(synchron) {
    await courses_service.group_synchro(synchron)
        .then(
            res => {

                if (res.hasOwnProperty('error')) {
                    Swal.fire(
                        'Acceso',
                        'Hubo un error al intentar crear el acceso',
                        'error'
                    )
                        (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {
                    Swal.fire(
                        'Acceso',
                        'Fue agregada con exito',
                        'success'
                    )
                    return true;
                }
            },
            error => {
                Swal.fire(
                    'Acceso',
                    'Hubo un error al intentar crear el acceso',
                    'error'
                )
                    (alert_actions.error(error.toString()));
            })
        .catch(e => console.log(e))
}

async function add_activity_exam_course(exam) {
    Swal.fire({
        title: 'Agregando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.add_activity_exam_course(exam)
        .then(
            res => {
                console.log(res);
                if (res.hasOwnProperty('error')) {
                    Swal.fire(
                        'Examen',
                        `${res.error.message}`,
                        'error'
                    )
                        (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {
                    Swal.fire(
                        'Examen',
                        'Fue agregada con exito',
                        'success'
                    )
                    return true;
                }
            },
            error => {
                Swal.fire(
                    'Examen',
                    `${error.toString()}`,
                    'error'
                )
                    (alert_actions.error(error.toString()));
            })
        .catch(e => {
            console.log(e)
            Swal.fire(
                'Examen',
                'Ocurrio un error, intenta más tarde',
                'error'
            )
        })
}

async function add_activity_dctres_course(dctres) {
    Swal.fire({
        title: 'Agregando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.add_activity_dctres_course(dctres)
        .then(
            res => {
                console.log(res);
                if (res.hasOwnProperty('error')) {
                    Swal.fire(
                        'Certificado Dctres',
                        `${res.error.message}`,
                        'error'
                    )
                        (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {
                    Swal.fire(
                        'Certificado dctres',
                        'Fue agregado con exito',
                        'success'
                    )
                    return true;
                }
            },
            error => {
                Swal.fire(
                    'Certificado dctres',
                    `${error.toString()}`,
                    'error'
                )
                    (alert_actions.error(error.toString()));
            })
        .catch(e => {
            console.log(e)
            Swal.fire(
                'Certificado DCTRES',
                'Ocurrio un error, intenta más tarde',
                'error'
            )
        })
}

async function add_activity_forum_course(forum) {
    Swal.fire({
        title: 'Agregando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.add_activity_forum_course(forum)
        .then(
            res => {
                console.log(res);
                if (res.hasOwnProperty('error')) {
                    Swal.fire(
                        'Foro',
                        `${res.error.message}`,
                        'error'
                    )
                        (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {
                    Swal.fire(
                        'Foro',
                        'Fue agregado con exito',
                        'success'
                    )
                    return true;
                }
            },
            error => {
                Swal.fire(
                    'Foro',
                    `${error.toString()}`,
                    'error'
                )
                    (alert_actions.error(error.toString()));
            })
        .catch(e => {
            console.log(e)
            Swal.fire(
                'Foro',
                'Ocurrio un error, intenta más tarde',
                'error'
            )
        })
}

async function add_activity_communication_course(channel) {
    Swal.fire({
        title: 'Agregando actividad...',
        timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
            let timerInterval = setInterval(() => {
                const content = Swal.getHtmlContainer()
                if (content) {
                    const b = content.querySelector('b')
                    if (b) {
                        b.textContent = Swal.getTimerLeft()
                    }
                }
            }, 100)
        },
    });
    await courses_service.add_activity_communication_course(channel)
        .then(
            res => {
                console.log(res);
                if (res.hasOwnProperty('error')) {
                    Swal.fire(
                        'Canal de comunicaciones',
                        `${res.error.message}`,
                        'error'
                    )
                        (alert_actions.error(res.hasOwnProperty('error').toString()));
                    return false;

                } else {
                    Swal.fire(
                        'Canal de comunicaciones',
                        'Fue agregado con exito',
                        'success'
                    )
                    return true;
                }
            },
            error => {
                Swal.fire(
                    'Canal de comunicaciones',
                    `${error.toString()}`,
                    'error'
                )
                    (alert_actions.error(error.toString()));
            })
        .catch(e => {
            console.log(e)
            Swal.fire(
                'Canal de comunicaciones',
                'Ocurrio un error, intenta más tarde',
                'error'
            )
        })
}