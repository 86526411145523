import Swal from 'sweetalert2'
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';



export const rewards_service = {
    get_all_rewards,
    create_reward,
    edit_reward,
    delete_reward
};


async function get_all_rewards() {

    const request_rewards = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=local_targettpoints_get_articles&moodlewsrestformat=json`);
    
    if (typeof request_rewards.data === 'object') {
        const rewards = request_rewards.data;
        return { rewards }
    }

    const error = request_rewards.data;
    return { error };

}


async function create_reward(reward) {

    console.log(reward);
    let formData = new FormData()
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'local_targettpoints_create_article');
    formData.append('moodlewsrestformat', 'json');
    formData.append('title', reward.title);
    formData.append('description', reward.description);
    formData.append('image', reward.image);
    formData.append('price', reward.price);
    formData.append('discount', reward.discount);
    formData.append('promotionend', reward.promotionend);

    const request_rewards = await api_iac.post(`webservice/rest/server.php`, formData);
    
    if (typeof request_rewards.data === 'object') {
        return 1;
    }

    const error = request_rewards.data;
    return { error };

}

async function edit_reward(reward) {

    let formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", 'local_targettpoints_update_article');
    formData.append("moodlewsrestformat", "json");
    formData.append("id", reward.id);
    formData.append("newtitle", reward.title);
    formData.append("newdescription", reward.description);
    formData.append('newimage', reward.image);
    formData.append('newgroupid', 0);
    formData.append('newprice', reward.baseprice);
    formData.append("newdiscount", reward.discount);
    formData.append('newpromotionend', reward.promotionend);
    formData.append('newidnumber', '');

    const request_rewards = await api_iac.post(`webservice/rest/server.php`, formData);

    if (typeof request_rewards.data === 'object') {
        return 1;
    }

    const error = request_rewards.data;
    return { error };

}

async function delete_reward(reward) {

    var formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", 'local_targettpoints_delete_article');
    formData.append("moodlewsrestformat", "json");
    formData.append("id", reward.id);

    const request = await api_iac.post(`webservice/rest/server.php`, formData);

    if (typeof request.data === 'object') {
        return 1;
    }

    const error = request.data;
    return { error };
}