import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import st from "../styles_kardex.module.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { downloadExcelReport, kardex_actions, resetKardexFideicomiso, resetKardexGeneral, resetKardexIndividual } from "../../../../store/actions/kardex";

//Services
import { kardex } from "../../../../store/services/kardex";

import { useDispatch, useSelector } from "react-redux";
import { TableFideicomiso, TableGeneral, TableIndividual } from "./Tablex";
import { numerosfideicomisos } from "../../../../store/constants/fideicomisosinverso";

import { getIdsFromFiglos, setStructure } from "../../../../helpers/estructura_curricular";

//import { user } from '../../../../config/user_token'
let user = JSON.parse(localStorage.getItem('user'));

const validate = (obj, typeKardex) => {
    const errors = {}

    if (typeKardex === 'individual') {
        if (!obj.username) {
            errors.username = 'El número de cuenta es requerido';
        }
    }

    if (typeKardex === 'fideicomiso') {
        if (!obj.fideicomiso && obj.fideicomiso === 0) {
            errors.fideicomiso = 'El fideicomiso es requerido';
        }
    }

    return errors;
}

const Kardex = ({onClose}) =>{

    const [typeKardex, setTypeKardex] = useState('');
    const [banderaIndividual, setBanderaIndividual] = useState(false)
    const [banderaGeneral, setBaneraGeneral] = useState(false)
    const [banderaFideicomiso, setBanderaFideicomiso] = useState(false)

    const [errors, setErrors] = useState({});
    const [data, setData] = useState({
        username: '',
        fideicomiso: 0
    });

    const dataFideicomiso = useSelector(state => state.kardex.kardex_data_fideicomiso);
    const dataIndividual = useSelector(state => state.kardex.kardex_data_inidividual);
    const dataGeneral  = useSelector(state => state.kardex.kardex_data_general);

    const categories = useSelector(state => state.categories.categories);

    const dispatch = useDispatch()

    useEffect(()=>{
        return () => {
            dispatch(resetKardexFideicomiso())
            dispatch(resetKardexGeneral())
            dispatch(resetKardexIndividual())
        }
    }, [dispatch])

    const handleConsult = () => {

        setTypeKardex('fideicomiso');


        setErrors(validate(data, typeKardex));

        setBanderaFideicomiso(true)
        setBanderaIndividual(false)
        setBaneraGeneral(false)
        //if (!Object.keys(validate(data, typeKardex)).length) {
            dispatch(kardex_actions.get_kardex_por_fideicomiso(data));
            dispatch(resetKardexGeneral())
            dispatch(resetKardexIndividual())
        
    }

    const handleConsultIndividual = () => {
        setTypeKardex('individual');

        setErrors(validate(data, typeKardex));

        setBanderaFideicomiso(false);
        setBaneraGeneral(false);
        
            dispatch(kardex_actions.get_kardex_individual(data));
            dispatch(resetKardexFideicomiso());
            dispatch(resetKardexGeneral());

            setBanderaIndividual(!!dataIndividual);

    }

    const handleConsultGeneral = () => {
        setTypeKardex('general')

        setErrors(validate(data, typeKardex))
        setBanderaFideicomiso(false)
        setBanderaIndividual(false)
        setBaneraGeneral(true)

        //if(!Object.keys(validate(data, typeKardex)).length){
            dispatch(kardex_actions.get_kardex_general(data));
            dispatch(resetKardexFideicomiso())
            dispatch(resetKardexIndividual())
        
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const xdata = useMemo(() =>{
        if (!dataFideicomiso) return [];

        //console.log(dataFideicomiso, 'dataFideicomiso');

        return Object.keys(dataFideicomiso);
    }, [dataFideicomiso])

    const xdataIndividual = useMemo(()=>{
        if(!dataIndividual) return [];

        return Object.keys(dataIndividual);
    })

    const xdataGeneral = useMemo(() =>{
        if (!dataGeneral) return [];

        return Object.keys(dataGeneral);
    })

    const onChangeFideicomiso = (e) => {
        setErrors({});
        setData({
            username: '',
            fideicomiso: e.target.value
        });
    }

    const onChangeUsername = (e) => {

        setErrors({});
        setData({
            fideicomiso: 0,
            username: e.target.value
        });
    }

    const ResultTables = useCallback(() => {
        //console.debug(data.fideicomiso);
        //console.debug(dataFideicomiso);
        //console.debug(dataFideicomiso['data']);
        //console.debug(dataFideicomiso['data'].length);
        //console.debug(xdata);
        if((data.fideicomiso > 0 && dataFideicomiso) || (data.fideicomiso === 'dafi' && dataFideicomiso && dataFideicomiso['data'] && dataFideicomiso['data'].length)) {
            return xdata.length ? xdata.map((item, index) => {
                console.debug(item);
                return (
                    <TableFideicomiso key={index} data={dataFideicomiso['data']} datados={dataFideicomiso} />
                )
            }
            ) : null
        }else{
            
            return null
        }
    }, [dataFideicomiso]);

    const ResultTableIndividual = useCallback(()=>{
        if (data.username.length > 0 && dataIndividual) {
            return xdataIndividual.length ? xdataIndividual.map((item, index)=>{
                return (
                    <TableIndividual key={index} data={dataIndividual['data']}/>
                )
            }):null
        } else {
            
            return null

        }
    }, [dataIndividual])

    const ResultTableGeneral = useCallback(() => {
        if ( dataGeneral) {
            return xdataGeneral.length ? xdataGeneral.map((item, index) => {
                return (
                    <TableGeneral key={index} data={dataGeneral['data']} />
                )
            }) : null
        } else {
            
            return null
        }
    }, [dataGeneral])

    const hanndleDownload = () =>{
        switch(typeKardex){
            case 'fideicomiso':
                downloadExcelReport(dataFideicomiso, 'Fideicomiso ' + numerosfideicomisos[data.fideicomiso], 'fideicomiso', 'export_fideicomiso', numerosfideicomisos[data.fideicomiso]);
                break;
            case 'individual':
                downloadExcelReport(dataIndividual, 'Cuenta ' + data.username, 'individual', 'export_individual');
                break;
            case 'general':
                downloadExcelReport(dataGeneral, 'General', 'general', 'export_general');
                break;
            default:
                break;
        }
    }

    const categoriasUser = useMemo(() => setStructure(categories, true), [categories])

    return(
        <div className={st.kardex}>
            <div className={st.kardex_header}>
                {<div className={st.seccion_reportes}>
                    {!user.ismanager && <h1 className={st.titulo_seccion}>General</h1>}
                    {(!user.ismanager) && <button onClick={handleConsultGeneral} className={st.button_generar_kardex}>
                        Generar kardex
                    </button>}
                </div>}
                <div className={st.seccion_reportes}>
                    <h1 className={st.titulo_seccion}>Por fideicomiso</h1>
                    <select onChange={onChangeFideicomiso} value={data.fideicomiso} className={st.select_fideicomiso}>
                        <option value={0} selected disabled>Fideicomisos</option>
                        {categoriasUser.length
                            ? categoriasUser.map(item =>
                                <option key={item.id} value={item.id}>{item.name}</option>
                            )
                            : null}
                        {
                            user.isadmin && <option value="dafi">DAFI</option>
                        }
                    </select>
                    <button onClick={handleConsult} className={st.button_generar_kardex}>
                        Generar kardex
                    </button>
                </div>
                <div className={st.seccion_reportes}>
                    {!user.ismanager && <><h1 className={st.titulo_seccion}>Individual</h1>
                    <input onChange={onChangeUsername} value={data.username} className={st.input_user} type="text" placeholder="Ingrese el número de usuario"/>
                    <button onClick={handleConsultIndividual} className={st.button_generar_kardex}>
                        Generar kardex
                    </button></>}
                </div>
            </div>
            <div className={st.cuerpo}>

                <div className={st.header_cuerpo}>
                    {(banderaGeneral || banderaFideicomiso || banderaIndividual) && <>
                    <div>

                    </div>
                    <div>
                        <h1 className={st.title_cuerpo}>Universidad DaFi</h1>
                        {
                            typeKardex === 'fideicomiso' ? <h1 className={st.title_cuerpo}>Fideicomiso {numerosfideicomisos[data.fideicomiso] ? numerosfideicomisos[data.fideicomiso] : "DAFI"}</h1> : null
                        }
                        {
                            typeKardex === 'individual' ? <h1 className={st.title_cuerpo}>Número de usuario {data.username}</h1> : null
                        }

                        {
                            typeKardex ? (<button onClick={hanndleDownload} className={st.button__generate_excel}>
                                Generar excel
                            </button>) : null
                        }
                    </div>
                    <div>

                    </div>
                    </>}
                </div>

                <div>
                    {banderaGeneral && <ResultTableGeneral />}
                    {banderaFideicomiso && <ResultTables />}
                    {banderaIndividual &&<ResultTableIndividual />}
                </div>

                <div className="text-right" onClick={() => onClose()}>
                    <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute mt-2" style={{ top: '-1px', marginLeft: '0.3rem' }} />
                </div>
            </div>
        </div>
    );
}

export default Kardex;