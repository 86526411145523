import { cohorts_constants } from '../constants/cohorts';


const initialState = {
    cohorts: [],
    loading_cohorts: null,
    error_cohorts: null,

    loading_cohorts_edit: null,
    cohorts_edit: null,
    error_cohorts_edit: null,

    loading_cohorts_create: null,
    cohorts_create: null,
    error_cohorts_create: null,

    loading_cohorts_delete: false,
    category_delete: false,
    error_category_delete: false
};

export function cohorts_reducers(state = initialState, action) {
  switch (action.type) {
    //------------GET cohorts--------------//
    case cohorts_constants.COHORTS_REQUEST:
        return {
            ...state,
            loading_cohorts: action.payload,
        };
    case cohorts_constants.COHORTS_SUCCESS:
        return {
            ...state,
            loading_cohorts: false,
            cohorts: action.payload,
            error_cohorts: null
        };
    case cohorts_constants.COHORTS_FAILURE:
        return {
            ...state,
            loading_cohorts: false,
            error_cohorts: action.payload
        };

       //-------------CREATE COHORTE-----------------
    case cohorts_constants.COHORTS_CREATE_REQUEST:
        return {
            ...state,
            loading_cohorts_create: action.payload,
        };
    case cohorts_constants.COHORTS_CREATE_SUCCESS:
        return {
            ...state,
            loading_cohorts_create: false,
            cohorts_create: action.payload,
            error_cohorts_create: null
        };
    case cohorts_constants.COHORTS_CREATE_FAILURE:
        return {
            ...state,
            loading_cohorts_create: false,
            error_cohorts_create: action.payload
        };

    // ------------- EDIT COHORTE ----------
    case cohorts_constants.COHORTS_EDIT_REQUEST:
        return {
            ...state,
            loading_cohorts_edit: action.payload,
        };
    case cohorts_constants.COHORTS_EDIT_SUCCESS:
        return {
            ...state,
            loading_cohorts_edit: false,
            cohorts_edit: action.payload,
            error_cohorts_edit: null
        };
    case cohorts_constants.COHORTS_EDIT_FAILURE:
        return {
            ...state,
            cohorts_edit: 0,
            loading_cohorts_edit: false,
            error_cohorts_edit: action.payload
        };

      //------------DELETE COHORTE--------------//
      case cohorts_constants.COHORTS_DELETE_REQUEST:
        return {
            ...state,
            loading_cohorts_delete: action.payload,
            category_delete: null,
            error_category_delete: null
        };
    case cohorts_constants.COHORTS_DELETE_SUCCESS:
        return {
            ...state,
            loading_cohorts_delete: false,
            category_delete: action.payload,
            error_category_delete: false
        };
    case cohorts_constants.COHORTS_DELETE_FAILURE:
        return {
            ...state,
            loading_cohorts_delete: false,
            category_delete: 0,
            error_category_delete: action.payload
        };
    default:
      return state
  }
}