import { reporter_constants } from '../constants/reporter';


const initialState = {
    reporter: [],
    loading_reporter: null,
    error_reporter: null,

    reporter_filter: [],
    loading_reporter_filter: null,
    error_reporter_filter: null,

    // cursos:
    global_data: null,
    global_data_request: {},
    course_gen: null,

    // user
    user_detail: null,
    user_detail_error: {}
};

export function reporter_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET REPORTER--------------//
        case reporter_constants.REPORTER_REQUEST:
            return {
                ...state,
                loading_reporter: action.payload,
            };
        case reporter_constants.REPORTER_SUCCESS:
            return {
                ...state,
                loading_reporter: false,
                reporter: action.payload,
                error_reporter: null
            };
        case reporter_constants.REPORTER_FAILURE:
            return {
                ...state,
                loading_reporter: false,
                error_reporter: action.payload
            };
            //------------GET REPORTER_FILTER--------------//
        case reporter_constants.REPORTER_FILTER_REQUEST:
            return {
                ...state,
                loading_reporter_filter: action.payload,
            };
        case reporter_constants.REPORTER_FILTER_SUCCESS:
            return {
                ...state,
                loading_reporter_filter: false,
                reporter_filter: action.payload,
                error_reporter_filter: null
            };
        case reporter_constants.REPORTER_FILTER_FAILURE:
            return {
                ...state,
                loading_reporter_filter: false,
                error_reporter_filter: action.payload
            };
        
        //------------GET REPORTER_COURSE_GEN--------------//
        case reporter_constants.REPORTE_FILTER_COURSE_GEN_REQUEST:
            return {
                ...state,
                course_gen: null,
                // filter_request: { loading: action.payload }
            }
        case reporter_constants.REPORTE_FILTER_COURSE_GEN_SUCCESS:
            return {
                ...state,
                // filter_request: { loading: false, success: true },
                course_gen: action.payload
            }
        case reporter_constants.REPORTE_FILTER_COURSE_GEN_FAILURE:
            return {
                ...state,
                // filter_request: { loading: false, success: false, msg: action.payload }
            }

        //------------ GET REPORTER_GLOBAL --------------//
        case reporter_constants.REPORTE_GLOBAL_REQUEST:
            return {
                ...state,
                global_data_request: { loading: true }
            }
        case reporter_constants.REPORTE_GLOBAL_SUCCESS:
            console.log('success divi')
            return {
                ...state,
                global_data: action.payload,
                global_data_request: { loading: false, success: true }
            }
        case reporter_constants.REPORTE_GLOBAL_FAILURE:
            return {
                ...state,
                global_data_request: { loading: false, success: false }
            }

        //------------ GET REPORTER_USER --------------//
        case reporter_constants.REPORTE_USER_SUCCESS:
            return {
                ...state,
                user_detail: action.payload,
            }
        
        case reporter_constants.REPORTE_USER_FAILURE:
            return {
                ...state,
                user_detail_error: action.payload
            }

        /* --------- RESET -------- */
        case reporter_constants.REPORTE_FILTER_COURSE_RESET:
            return {
                ...state,
                filter_request: {},
                course_gen: null,
            }

        case reporter_constants.REPORTE_GLOBAL_RESET:
            return {
                ...state,
                global_data_request: {},
                global_data: null,
            }
        
        case reporter_constants.REPORTE_USER_DETAIL_RESET:
            return {
                ...state,
                user_detail: null,
            }
        default:
            return state
    }
}