import { tutors_constants } from '../constants/tutors';


const initialState = {
    tutors: [],
    loading_tutors: null,
    error_tutors: null,
};

export function tutors_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET TUTORS--------------//
        case tutors_constants.TUTORS_REQUEST:
            return {
                ...state,
                loading_tutors: action.payload,
            };
        case tutors_constants.TUTORS_SUCCESS:
            return {
                ...state,
                loading_tutors: false,
                tutors: action.payload,
                error_tutors: null
            };
        case tutors_constants.TUTORS_FAILURE:
            return {
                ...state,
                loading_tutors: false,
                error_tutors: action.payload
            };
        default:
            return state
    }
}