export const reporter_constants = {
    REPORTER_REQUEST: 'REPORTER_REQUEST',
    REPORTER_SUCCESS: 'REPORTER_SUCCESS',
    REPORTER_FAILURE: 'REPORTER_FAILURE',

    REPORTER_FILTER_REQUEST: 'REPORTER_FILTER_REQUEST',
    REPORTER_FILTER_SUCCESS: 'REPORTER_FILTER_SUCCESS',
    REPORTER_FILTER_FAILURE: 'REPORTER_FILTER_FAILURE',

    // reporte de cursos generales
    REPORTE_FILTER_COURSE_GEN_REQUEST: 'REPORTE_FILTER_COURSE_GEN_REQUEST',
    REPORTE_FILTER_COURSE_GEN_SUCCESS: 'REPORTE_FILTER_COURSE_GEN_SUCCESS',
    REPORTE_FILTER_COURSE_GEN_FAILURE: 'REPORTE_FILTER_COURSE_GEN_FAILURE',

    // reporte global de las tablas
    REPORTE_GLOBAL_REQUEST: "REPORTE_GLOBAL_REQUEST",
    REPORTE_GLOBAL_SUCCESS: "REPORTE_GLOBAL_SUCCESS",
    REPORTE_GLOBAL_FAILURE: "REPORTE_GLOBAL_FAILURE",

    // buscador de usuario
    REPORTE_USER_SUCCESS: "REPORTE_USER_SUCCESS",
    REPORTE_USER_FAILURE: "REPORTE_USER_FAILURE",

    // reset
    REPORTE_FILTER_COURSE_RESET: "REPORTE_FILTER_COURSE_RESET",
    REPORTE_USER_DETAIL_RESET: "REPORTE_USER_DETAIL_RESET",
    REPORTE_GLOBAL_RESET: "REPORTE_GLOBAL_RESET",



}
