import React from 'react';
import RouterReact from './Route/RouterReact';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';


function App() {
  if (isMobile) {
    Swal.fire(
      'Aviso',
      'La plataforma solo es accesible desde una PC, Laptop o dispositivos donde la pantalla tenga la relación de aspecto similar.',
      'info',
    );
    return (
      <>
      </>
    )
  } else {
    return (
      <div className="App">
        <RouterReact></RouterReact>
      </div>
    );
  }
};



export default App;
