import Swal from 'sweetalert2'
import api_iac from '../../config/instance_axios';
import { user as usertoken } from '../../config/user_token';


export const rankings_service = {
    get_all_rankings,
    add_ranking_points,
    remove_ranking_points,
    ranking_get_exchanges,
    get_ranking_page,
};


async function get_all_rankings() {

    const request_rankings = await api_iac.get("webservice/rest/server.php?wstoken=" + usertoken.token + "&wsfunction=local_targettpoints_get_ranking&moodlewsrestformat=json&size=10");

    if(Array.isArray(request_rankings.data)  ){
        const rankings = request_rankings.data
        return { rankings };
    }

    const error = request_rankings.data;
    return { error };

}

async function add_ranking_points(user,points) {

    let formData = new FormData();
    
    formData.append('wstoken', usertoken.token);
    formData.append('wsfunction', 'local_targettpoints_add_points');
    formData.append('moodlewsrestformat', 'json');
    formData.append('userid', user.id);
    formData.append('points', points);

    const request_user_points = await api_iac.post(`webservice/rest/server.php`, formData);

    const error = request_user_points.data;
    return { error }
}

async function remove_ranking_points(user,points) {

    let formData = new FormData();
    
    formData.append('wstoken', usertoken.token);
    formData.append('wsfunction', 'local_targettpoints_remove_points');
    formData.append('moodlewsrestformat', 'json');
    formData.append('userid', user.id);
    formData.append('points', points);

    const request_user_points = await api_iac.post(`webservice/rest/server.php`, formData);

    const error = request_user_points.data;
    return { error }
}

async function ranking_get_exchanges(user,article) {

    let formData = new FormData();
    
    formData.append('wstoken', usertoken.token);
    formData.append('wsfunction', 'api_get_exchange');
    formData.append('moodlewsrestformat', 'json');
    formData.append('userid', user.id);
    if (article) {
        formData.append('articleid', article);
    }

    const request_user_points = await api_iac.post(`webservice/rest/server.php`, formData);

    const error = request_user_points.data.error;
    const exchange_list = request_user_points.data.exchanges;
    if (error) {
        return { error };
    } else {
        return { exchange_list };
    }
}

async function get_ranking_page(size, offset) {
    const request_rankings = await api_iac.get("webservice/rest/server.php?wstoken=" + usertoken.token + "&wsfunction=local_targettpoints_get_ranking&moodlewsrestformat=json&size=" + size + "&offset=" + offset);

    //if(Array.isArray(request_rankings.data)  ){
        //const rankings = request_rankings.data
        //return { rankings };
    //}

    //const error = request_rankings.data;
    //return { error };

    return { request_rankings };
}
