import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';

import { meetings_actions } from '../../../../../store/actions/meetings';
import SubMenuAddUserMeeting from '../../SubMenu/SubMenuAddUserMeeting';
import api_iac from '../../../../../config/instance_axios';
import { users_actions } from '../../../../../store/actions/users';


const AddusersMeeting = ({ meeting, onCloseSubMenu }) => {
    const dispatch = useDispatch();

    const [user_meetings_select, set_user_meetings_select] = useState([]);
    const [user_meetings_propect, set_user_meetings_prospect] = useState([]);
    const [user_meetings_add, set_user_meetings_add] = useState([]);
    const [user_meetings_remove, set_user_meetings_remove] = useState([]);
    const [users_search, set_users_search] = useState([]);

    useEffect(() => {
        meetings_members();
    }, []);

    //Obtiene los datos de los usuario dentro del grupo
    async function meetings_members() {
        let members_users = await meetings_actions.meetings_members_users(meeting.id);

        await set_user_meetings_select(members_users);
    }
    //Busca los usuarios
    async function searhcUser(user) {
        await buscaruser(user);
    }
    async function buscaruser(user) {
        let users = await users_actions.users_seach(user);
        let members_users = await meetings_actions.meetings_members_users(meeting.id);
        let tempProspect = [];
        users.users.filter(user => {
            let isUserAdded = members_users.find(usermeeting => {
                if (user.id === usermeeting.id) {
                    return true;
                }
                return false;
            })

            if (!!isUserAdded) {
                //temp.push(user);
            } else {
                tempProspect.push(user);
            }
        });
        set_user_meetings_prospect(tempProspect);
    }
    //Agregar usuarios al grupo
    const add_users_meeting = async values => {
        let values_option = []
        await Array.from(values.target.selectedOptions).map(option => {
            values_option.push(option.value);
        });
        set_user_meetings_add(values_option);
    };

    async function add_users_meeting_save() {
        await meetings_actions.add_members_meeting(meeting, user_meetings_add);
        await meetings_members();
        await searhcUser(document.querySelector("#searchuser").value);
        let select = document.querySelector('#addselectusers');
        Array.from(select.selectedOptions).map(item => {
            item.selected = false
            item.style.background = "white";
        })
    }

    //Remover usuarios de grupo
    const remove_users_meeting = async values => {
        let values_option_delete = []
        await Array.from(values.target.selectedOptions).map(option => {
            values_option_delete.push(option.value);

        });
        set_user_meetings_remove(values_option_delete);
    };

    const remover_users_meeting_save = async () => {
        await meetings_actions.remove_members_meeting(meeting, user_meetings_remove);
        await meetings_members();
        await searhcUser(document.querySelector("#searchuser").value);
        let select = document.querySelector('#delselectusers');
        Array.from(select.selectedOptions).map(item => {
            item.selected = false
            item.style.background = "white";
        })
    }

    return (
        <div>
            <div className="formularios">
                <div className="campo">
                    <h3 className="text-center">Usuarios Potenciales</h3>
                    <label className="mb-2" htmlFor="">Buscar Usuarios</label>
                    <input type="text" onChange={(value) => { searhcUser(value.target.value) }} id="searchuser" />
                    <select name="addselect[]" multiple="multiple" size="20"
                        className="form-control no-overflow w-full" style={{ color: "black", background: "white" }}
                        onChange={(values) => { add_users_meeting(values) }}
                        id="addselectusers">
                        <optgroup label="">
                            {
                                user_meetings_propect.map(user => (<option id={user.id} value={user.id}>{user.fullname} ({(((typeof (user.extrafields) !== 'undefined') && (user.extrafields.length)) ? (user.extrafields[0].value) : (''))})</option>))
                            }
                        </optgroup>
                    </select>
                    <button type="submit" style={{ maxWidth: "45%" }}
                        className="mx-auto text-center rounded-full bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 border border-blue-300 hover:bg-opacity-100"
                        onClick={() => { add_users_meeting_save() }}>Agregar</button>
                </div>
                <div className="campo">
                    <h3 className="text-center mb-3">Usuarios Agregados</h3>
                    <SelectGroup users={user_meetings_select} user_selecteds={(values) => remove_users_meeting(values)}></SelectGroup>
                    <button type="submit" style={{ maxWidth: "45%" }}
                        className="mx-auto text-center rounded-full bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 border border-blue-300 hover:bg-opacity-100"
                        onClick={() => { remover_users_meeting_save() }}>Quitar</button>
                </div>
            </div>
        </div>
    );
}
const SelectGroup = ({ users, user_selecteds }) => {
    return (
        <select name="addselect[]" multiple="multiple" size="20" id="delselectusers"
            className="form-control no-overflow w-full" style={{ color: "black", background: "white" }}
            onChange={(values) => { user_selecteds(values) }}>
            <optgroup label="">
                {
                    users.map(user => (<option id={user.id} value={user.id}>{user.firstname} {user.lastname} ({user.email})</option>))
                }
            </optgroup>
        </select>
    );
}
export default AddusersMeeting;
