import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';

import { notifications_constants } from '../constants/notifications';


export const notifications_services = {
    get_user_notifications,
    set_notification_as_read
};

async function get_user_notifications() {
    const request_notifications = api_iac.get("webservice/rest/server.php?wstoken=" + user.token + "&wsfunction=local_targettnotify_get_notifications&moodlewsrestformat=json");
    
    return { request_notifications };
}

async function set_notification_as_read(notiid) {

    let formData = new FormData();

    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'core_message_mark_notification_read');
    formData.append('notificationid', notiid);

    const mark_notification = await api_iac.post("webservice/rest/server.php");

    return { mark_notification };
}