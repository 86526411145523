import React, { useState } from 'react';
import Categorias from './Estructura/Categorias';
import Cohortes from './Estructura/Cohortes';
import Header from './Header';



const Estructura = ({onClose}) => {
    const [active, setActive] = useState(true);
    return ( 
        <div className="bg-gray-100 w-1/4 rounded-xl">
            <Header title="Estructura curricular" onClose={onClose}/>

            <div>
                <div className="flex justify-around text-mb mb-8">
                    <div 
                        onClick={ () => setActive(true)} 
                        className={`border-white cursor-pointer rounded-full text-white py-2 mr-1 ${ active ? 'border-b-4 bg-iacblack' : 'border-b-2 opacity-20' } w-1/2 text-center mb-1`} style={{border: '1px solid #797974'}}> { active ? <p className="flex items-center justify-center"><span className="dot mr-2"></span> Categoría</p> : <p className="flex items-center justify-center text-black"><span className="dot-black mr-2"></span> Categoría</p>}
                    </div>
                    <div onClick={ () => setActive(false)} 
                        className={`border-white cursor-pointer rounded-full text-white py-2 ml-1 ${ !active ? 'border-b-4 bg-iacblack' : 'border-b-2 opacity-20' } w-1/2 text-center mb-1`} style={{border: '1px solid #797974'}}> { !active ? <p className="flex items-center justify-center"><span className="dot mr-2"></span> Grupos</p> : <p className="flex items-center justify-center text-black"><span className="dot-black mr-2"></span> Grupos</p>}
                    </div>
                </div>
                
                { active ? <Categorias onClose={onClose}/> : <Cohortes onClose={onClose}/>}

            </div>
        </div>
        
    );
}
 
export default Estructura;