import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { cohorts_actions } from '../../../../../store/actions/cohorts';

const AgregarCohorte = ({ onCloseSubMenu }) => {
    const dispatch = useDispatch();

    const categories = useSelector(state => state.categories);
    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        cohorte: {}
    });
    var categoria = new Array();

    const [categoriesInDropdown, setCategoriesInDropdown] = useState({isLoading: true, list: []});
    let user = JSON.parse(localStorage.getItem('user'));
    
    const orderCategori = function (categoriasuperior, path) {

        categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        
        if ( (categoriasuperior.hasOwnProperty('children')) && (categoriasuperior.children.length > 0) ) {
            categoriasuperior.children.map(categoriachildren => {
                orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
            });
        }


    }

    useEffect(() => {
        //setCategoriesInDropdown({isLoading: true, list: []});
        if (!categories.loading_categories) {
            console.log('En agregar categoria: ', categories);
            categoria = new Array();
            categories.categories.forEach(categoriesraiz => {
                //if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children')) {
                    orderCategori(categoriesraiz, `${categoriesraiz.name}`);
                //}
            });

            console.log('categoria filled ', categoria);
            setCategoriesInDropdown({isLoading: false, list: categoria});
        }
    }, [categories]);

    const validate_add = Yup.object().shape({
        name: Yup.string('Escribe el nombre de tu curso')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            descriptionformat: 1,
            theme: '',
            categorytype: 'id',
            categoryvalue: 0

        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async (cohort) => {
        await cohorts_actions.cohorts_create(cohort);
        onCloseSubMenu(false);
        dispatch(cohorts_actions.get_all_cohorts());
    }
    const clickSubMenu = (type, open_param, cohorte) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            cohorte: cohorte
        })
    }
    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <div className="campo">
                <label className="text-md" htmlFor="">Nombre del Grupo</label>
                <input onChange={formik.handleChange} type="text" name="name" placeholder="Nombre del cohorte" value={formik.values.name} />
                <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="text-md" htmlFor="">Descripción del grupo</label>
                <textarea onChange={formik.handleChange} type="text" name="description" placeholder="Descripción del grupo" rows="4" value={formik.values.description} />
                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="mb-2" htmlFor="">Categorias</label>
                <select onChange={formik.handleChange} name="categoryvalue" value={formik.values.categoryvalue}>
                    {((user) && (user.isadmin))
                        ?<option className="bg-blue-600" value={0} selected >Sistemas</option>
                        :null
                    }
                    {categoriesInDropdown.isLoading ? 'Cargando...' :
                        categoriesInDropdown.list.length > 0
                            ? categoriesInDropdown.list.map((item) => {return <option value={item.id}>{item.nombre}</option>})
                            : null
                        
                    }
                </select>
                <p className="text-red-600 text-sm">{formik.errors.categoryvalue ? <div>{formik.errors.categoryvalue}</div> : null}</p>
            </div>
            <div className="block-inline text-center ">
                <button type="submit" style={{ maxWidth: "45%" }}
                    className="mx-auto text-center rounded-md bg-blue-500 bg-opacity-90 px-4 py-2 w-48 text-white border border-blue-300 hover:bg-opacity-100">Guardar</button>
            </div>

        </form>
    );
}

export default AgregarCohorte;