import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import URL from './Url';
import Resource from './Resource';
import Scorm from './Scorm';
import Exam from './Exam';
import Dctres from './Dctres';
import CreateForum from './Forum';
import CreateCommunication from './Communication';




const Activity = ({ course, onCloseSubMenu, type }) => {
    const dispatch = useDispatch();
    if (type === 'URL') {
        return <URL course={course} onCloseSubMenu={onCloseSubMenu} />
    }

    if (type === 'VIDEO' || type === 'PDF') {
        return <Resource course={course} onCloseSubMenu={onCloseSubMenu} type={type} />
    }

    if (type === 'SCORM') {
        return <Scorm course={course} onCloseSubMenu={onCloseSubMenu} />
    }

    if (type === 'EXAMEN') {
        return <Exam course={course} onCloseSubMenu={onCloseSubMenu} />
    }

    if (type === 'DCTRES') {
        return <Dctres course={course} onCloseSubMenu={onCloseSubMenu} />
    }

    if (type === 'FORO') {
        return <CreateForum course={course} onCloseSubMenu={onCloseSubMenu} />
    }

    if (type === 'CANAL DE COMUNICACIONES') {
        return <CreateCommunication course={course} onCloseSubMenu={onCloseSubMenu} />
    }

    return <p>Elemento no encontrado</p>
}



export default Activity;