import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2'

//import agredada_masiva from '../../../../assets/img/user/agregada_masiva.png'
import { users_actions } from "../../../../store/actions/users";

import { numerosfideicomisos } from '../../../../store/constants/fideicomisos.js';
import { comitenums } from '../../../../store/constants/comitenums.js';
import { nationalocupations } from '../../../../store/constants/nationalocupations.js';
import { adscritos } from "../../../../store/constants/adscritos";

import { categories_actions } from '../../../../store/actions/categories';
import { getCohortName } from "../../../../helpers/estructura_curricular";
// import { array } from "yup";

export default function UserCsv({ showModal, dataCsv, setShowModal, onCloseSubMenu }) {

  // const curpregex = /^[a-zA-Z]{4}[0-9]{6}[a-zA-Z]{6}[0-9]{2}$/;

  const [data, setData] = useState([]);
  const [result, setResult] = useState([]);
  const [tableHead, setTableHead] = useState({});
  const [parseErrors, setParseErrors] = useState([]);

  const users_cvs = useSelector(state => state.users.error_users_create);
  const man_categories = useSelector(state => state.categories);
  const dispatch = useDispatch();

  useEffect(() => { dispatch(categories_actions.get_all_categories()); }, []);

  useEffect(() => {
    var csvcounter = 0;
    var errorFound = [];
    var fideicomisoencontrado = false;


    if (dataCsv.length > 0) {

      setData(dataCsv);
      setTableHead(Object.keys(dataCsv[0]));

      var user = JSON.parse(localStorage.getItem('user'));

      if (user.ismanager) {
        console.debug('User uploading is manager --------');
        console.log(man_categories);
      }

      //console.debug('*************************');
      //console.debug(dataCsv);
      //console.debug('***********************');

      for (; csvcounter < dataCsv.length; csvcounter++) {
        //console.debug(dataCsv[csvcounter]);
        //console.log('Fecha inicio: ' + dataCsv[csvcounter].fechainicio);
        //console.log('Fecha vigencia: ' + dataCsv[csvcounter].fechavigencia);
        //console.log('Fecha admisión: ' + dataCsv[csvcounter].dateadmission);
        if (!dataCsv[csvcounter].firstname) {
          errorFound.push({ message: 'Falta nombre', row: (csvcounter + 1), column: 'firstname' });
        }
        if (!dataCsv[csvcounter].lastname) {
          errorFound.push({ message: 'Falta apellidos', row: (csvcounter + 1), column: 'lastname' });
        }
        if (!dataCsv[csvcounter].username) {
          errorFound.push({ message: 'Falta el correo del empleado', row: (csvcounter + 1), column: 'username' });
        }

        if (!dataCsv[csvcounter].idnumber) {
          errorFound.push({ message: 'Falta el numero de empleado', row: (csvcounter + 1), column: 'idnumber' });
        }

        //Employments

        if (!dataCsv[csvcounter].employment) {
          errorFound.push({ message: 'Falta información de puesto', row: (csvcounter + 1), column: 'employment' });
        }

        //if(typeof(adscritos[dataCsv[csvcounter].employment]) != "number") {
        //  errorFound.push({message: 'Dato no valido', row: (csvcounter + 1), column: 'employment'});
        //}


        //if (!dataCsv[csvcounter].area) {
        //errorFound.push({message: 'Falta información de puesto', row: (csvcounter + 1), column: 'area'});
        //}

        if (!(dataCsv[csvcounter].area) || (typeof (dataCsv[csvcounter].area) != 'string')) {
          console.log("Dato:", adscritos[dataCsv[csvcounter].area])
          errorFound.push({ message: 'Dato de usuario no encontrado', row: (csvcounter + 1), column: 'area' });
        }

        if (!dataCsv[csvcounter].workplace) {
          errorFound.push({ message: 'Falta información de puesto', row: (csvcounter + 1), column: 'workplace' });
        }

        /*if (!(dataCsv[csvcounter].fideicomisonum)) {
          errorFound.push({ message: 'fideicomiso no encontrado', row: (csvcounter + 1), row: (csvcounter + 1), column: 'fideicomisonum' });
        } else if (user.ismanager) {
          //console.log('fideicomiso encontrado, y es manager.......');
          //console.log(man_categories.categories);
          man_categories.categories.forEach(lllelement => {
            console.log("Comparamos: ")
            if (lllelement.name === dataCsv[csvcounter].fideicomisonum) {
              fideicomisoencontrado = true;
            }
          });
          console.log('fideicomiso encontrado: ', man_categories.categories);
          if (!fideicomisoencontrado) {
            errorFound.push({ message: `fideicomiso ${dataCsv[csvcounter].fideicomisonum} fuera del area`, row: (csvcounter + 1), column: 'fideicomisonum' });
          }
        }

        if ((typeof (numerosfideicomisos[dataCsv[csvcounter].fideicomisonum]) != 'number')) {
          errorFound.push({ message: 'Dato no valido ', row: (csvcounter + 1), column: 'fideicomisonum' });
        }*/

        if (!dataCsv[csvcounter].fideicomisonum) {
          errorFound.push({ message: 'Falta información de puesto', row: (csvcounter + 1), column: 'fideicomisonum' });
        }

        if (!dataCsv[csvcounter].tipofideicomiso) {
          errorFound.push({ message: 'Falta información de puesto', row: (csvcounter + 1), column: 'tipofideicomiso' });
        } /*else if ((dataCsv[csvcounter].fideicomisonum != '') && (typeof (numerosfideicomisos[dataCsv[csvcounter].fideicomisonum]) !== 'undefined')) {
          if (dataCsv[csvcounter].fideicomisonum.toLowerCase().indexOf(dataCsv[csvcounter].tipofideicomiso.toLowerCase()) === -1) {
            errorFound.push({ message: 'El fideicomiso ' + numerosfideicomisos[dataCsv[csvcounter].fideicomisonum] + ' no coincide con el tipo ' + dataCsv[csvcounter].tipofideicomiso, row: (csvcounter + 1), column: 'tipofideicomiso' });
          }
        }*/

        //if ((dataCsv[csvcounter].comitenum == '') || (typeof (comitenums[dataCsv[csvcounter].comitenum]) === 'undefined')) {
          //errorFound.push({ message: 'dato de usuario no encontrado', row: (csvcounter + 1), column: 'comitenum' });
        //}

        if ( (dataCsv[csvcounter].comitenum == '') ) {
          errorFound.push({ message: 'Falta información de puesto', row: (csvcounter + 1), column: 'comitenum' });
        }


        if (!dataCsv[csvcounter].workplace) {
          errorFound.push({ message: 'Falta información de puesto', row: (csvcounter + 1), column: 'workplace' });
        }

        if (!dataCsv[csvcounter].age) {
          errorFound.push({ message: 'Falta dato de edad', row: (csvcounter + 1), column: 'age' });
        }
        if (!dataCsv[csvcounter].gender.length) {
          errorFound.push({ message: 'Datos de usuario incompleto', row: (csvcounter + 1), column: 'gender' });
        }

        if (!dataCsv[csvcounter].password) {
          errorFound.push({ message: 'Falta contraseña del usuario', row: (csvcounter + 1), column: 'password' });
        }

        // if (!curpregex.test(dataCsv[csvcounter].curp)) {
        //   errorFound.push({message: 'CURP no válido', row: (csvcounter + 1), column: 'curp'});
        // }

        if (!dataCsv[csvcounter].niveldesarrollo) {
          errorFound.push({ message: 'Falta información de puesto', row: (csvcounter + 1), column: 'niveldesarrollo' });
        }
        //var lll_tmpocupation = dataCsv[csvcounter].nationalocupation.replace(/^0+/, '');
        //console.debug(nationalocupations);
        //console.debug(dataCsv[csvcounter]);
        //console.debug(dataCsv[csvcounter].nationalocupation);

//console.debug(lll_tmpocupation);
        if ((dataCsv[csvcounter].nationalocupation == '') || (typeof (nationalocupations[dataCsv[csvcounter].nationalocupation]) === 'undefined')) {
          errorFound.push({ message: 'Ocupación no encontrada', row: (csvcounter + 1), column: 'nationalocupation' });
        }

      }

      setParseErrors(errorFound);
    }

  }, [dataCsv]);

  const handleChanger = (index, oneKey, newValue) => {
    const updatedArray = [...data];
    updatedArray[index][oneKey] = newValue;
    setData(updatedArray);
  }

  const onSubmit = async () => {

    Swal.fire({
      title: 'Cargando usuarios...',
      timerProgressBar: true,
      onOpen: () => Swal.showLoading()
    });

    data.map((item, index) => {
      if (typeof (nationalocupations[item.nationalocupation]) !== 'undefined') {
        item.nationalocupation = nationalocupations[item.nationalocupation].num;
      }

      if (comitenums[item.comitenum]) {
        item.adscritonum = comitenums[item.comitenum];
        let temp = item.area
        //item.area = item.employment
        item.comitenum = adscritos[temp];
        //item.employment = temp
        //item.cohortsid = 

        // asignación del grupo
        item.grupoCoh = getCohortName(item.fideicomisonum, item.area)
      }
    });

    // console.log(data);
    //setData([]);
    //setResult({message: 'Testing only...'});
    //setShowModal(false);

    let result = await users_actions.users_add_csv(data);

    console.log("Resultado: ", result)

    if ((typeof (result.aggreages) == 'undefined') && (typeof (result.message) != 'undefined')) {
      Swal.fire(
        'Ocurrió un error al subir los usuarios',
        result.message,
        'error'
      );
    } else {

      let added = result.aggregates > 0 ? `<h4>Usuario Agregados: ${result.aggregates}</h4>` : ``;
      let update = result.updated > 0 ? `<h4>Usuario Actualizados: ${result.updated}</h4>` : ``;
      let warinings = result.warinings > 0 ? `<h4 style="display: none">Usuario con errores: ${result.warinings}</h4>` : ``;
      let datealles = '';
      result.warining.map(item => {
        datealles += `${item[0].mensaje}<br>`;
      });
      setShowModal(false)
      Swal.fire(
        'Carga Masiva de Usuarios',
        `${added}<br>
            ${update}<br>
            ${warinings}<br><br>
            ${datealles}`,
        'info',
      )
      setData([])

      setResult(result)
    }

  }

  return (
    <>
      {showModal ? (
        <>
          <div className="modal-csv fixed z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="justify-center items-center flex">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {result > 0 ? `Usuarios en total ${result.aggregates}` : `Total de usuarios ${dataCsv.length}`}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                    </button>
                  </div>
                  {/*body*/}
                  <form className="relative p-6 flex-auto max-h-72">
                    {data.length > 0 ?
                      <div className="h-36 scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-300 overflow-auto">
                        <table className="table-auto border-collapse">
                          <thead><tr className="bg-blue-500 bg-opacity-10" >{tableHead.map(head => head != 'error' ? <th className="border border-blue-500" key={head}> {head.toUpperCase()} </th> : '')}</tr></thead>
                          <tbody>
                            {data.map((item, index) => (
                              <tr className="bg-blue-500 bg-opacity-5" key={index + 2}>
                                {Object.keys((item)).map((oneKey, i) => (
                                  oneKey != 'error' ?
                                    <td className="border border-blue-500" key={i}>
                                      { /* <input type={ tableHead[i] == 'email' ? 'email' : 'text' } name={tableHead[i]} onChange={ e => handleChanger(index, tableHead[i], e.target.value ) } value={item[tableHead[i]]}/> */}
                                      <span className="bg-white border-2 border-gray-400 px-2 py-1 inline-block w-full h-full whitespace-nowrap overflow-x-hidden text-clip">
                                        {
                                          (() => {
                                            var l_dateObject;
                                            if ((tableHead[i] == 'fechainicio') || (tableHead[i] == 'fechavigencia') || (tableHead[i] == 'dateadmission')) {
                                              console.log(item[tableHead[i]]);
                                              l_dateObject = new Date(item[tableHead[i]] * 1000);
                                              console.log(l_dateObject);
                                              return (l_dateObject.toLocaleDateString());
                                              //} else if ( (tableHead[i]=='fideicomisonum') && (typeof(numerosfideicomisos[item[tableHead[i]]])!=='undefined') ) {
                                              //return numerosfideicomisos[item[tableHead[i]]];
                                              //} else if ( (tableHead[i]=='comitenum') && (typeof(comitenums[item[tableHead[i]]])!=='undefined') ) {
                                              //return comitenums[item[tableHead[i]]];
                                            } else if ((tableHead[i] == 'nationalocupation') && (typeof (nationalocupations[item[tableHead[i]]]) !== 'undefined')) {
                                              return nationalocupations[item[tableHead[i]]].name.substring(0, 20);
                                              //} else if ((tableHead[i]=='employment') && (typeof(adscritos[item[tableHead[i]]])!=='undefined') ) {
                                              //  return adscritos[item[tableHead[i]]];
                                            } else {
                                              return item[tableHead[i]];
                                            }
                                          })()
                                        }
                                      </span>
                                    </td>
                                    : ''
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      :
                      <div>
                        {users_cvs.map(item => (
                          <ul>
                            <li>{item.firstname} {item.lastname}, {item.email}</li>
                            <p className="text-red-500">{item.error}</p>
                            <br />
                          </ul>
                        ))}
                      </div>
                    }
                  </form>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    {((data.length > 0) && (parseErrors.length == 0)) ?
                      <div>
                        <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>
                          Cerrar
                        </button>
                        <button className="bg-emerald-500 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => onSubmit(false)}>
                          Agregar
                        </button>
                      </div>
                      : <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => setShowModal(false)}>
                        Cerrar
                      </button>}
                  </div>
                  {parseErrors.length == 0 ? <></> :
                    <div className="overflow-y-auto max-h-24 scrollbar-thin scrollbar-thumb-blue-500">
                      <p className="text-center text-lg font-bold">Se encontraron los siguientes errores en el archivo proporcionado: </p>
                      <ul className="text-center w-1/2 mx-auto list-disc">
                        {parseErrors.map(curError => (
                          <li>{curError.message} en la propiedad {curError.column} de la fila {curError.row}</li>
                        ))}
                      </ul>
                    </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 w-full fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
