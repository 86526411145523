import { courses_constants } from '../constants/courses';


const initialState = {
    courses: [],
    loading_courses: null,
    error_courses: null,

    courses_create: null,
    loading_courses_create: null,
    error_courses_create: null,

    courses_edit: null,
    loading_courses_edit: null,
    error_courses_edit: null,

    courses_modules: null,
    loading_courses_modules: null,
    error_courses_modules: null,

    courses_method_enrol: null,
    loading_courses_method_enrol: null,
    error_courses_method_enrol: null,

    courses_groups: null,
    loading_courses_groups: null,
    error_courses_groups: null,
};

export function courses_reducers(state = initialState, action) {
  switch (action.type) {

    //------------GET COUSERS--------------//
    case courses_constants.COURSES_REQUEST:
        return {
            ...state,
            loading_courses: action.payload,
        };
    case courses_constants.COURSES_SUCCESS:
        return {
            ...state,
            loading_courses: false,
            courses: action.payload,
            error_courses: null
        };
    case courses_constants.COURSES_FAILURE:
        return {
            ...state,
            courses: [],
            loading_courses: false,
            error_courses: action.payload
        };

     //------------CREATE COURSE--------------//   
    case courses_constants.COURSE_CREATE_REQUEST:
        return {
            ...state,
            loading_courses_create: action.payload,
        };
    case courses_constants.COURSE_CREATE_SUCCESS:
        return {
            ...state,
            loading_courses_create: false,
            courses_create: action.payload,
            error_courses_create: null
        };
    case courses_constants.COURSE_CREATE_FAILURE:
        return {
            ...state,
            courses_create: 0,
            loading_courses_create: false,
            error_courses_create: action.payload
        };

     //------------EDIT COURSE--------------//   
    case courses_constants.COURSE_EDIT_REQUEST:
        return {
            ...state,
            loading_courses_edit: action.payload,
        };
    case courses_constants.COURSE_EDIT_SUCCESS:
        return {
            ...state,
            loading_courses_edit: false,
            courses_edit: action.payload,
            error_courses_edit: null
        };
    case courses_constants.COURSE_EDIT_FAILURE:
        return {
            ...state,
            courses_edit: 0,
            loading_courses_edit: false,
            error_courses_edit: action.payload
        };
     //------------MODULES COURSE--------------//   
     case courses_constants.COURSE_CONTENTS_REQUEST:
        return {
            ...state,
            loading_courses_modules: action.payload,
        };
    case courses_constants.COURSE_CONTENTS_SUCCESS:
        return {
            ...state,
            loading_courses_modules: false,
            courses_modules: action.payload,
            error_courses_modules: null
        };
    case courses_constants.COURSE_CONTENTS_FAILURE:
        return {
            ...state,
            courses_modules: 0,
            loading_courses_modules: false,
            error_courses_modules: action.payload
        };
    
    //------------GROUP ACCESS COURSE--------------//   
     case courses_constants.COURSE_METHOD_EROL_REQUEST:
        return {
            ...state,
            loading_courses_method_enrol: action.payload,
        };
    case courses_constants.COURSE_METHOD_EROL_SUCCESS:
        return {
            ...state,
            loading_courses_method_enrol: false,
            courses_method_enrol: action.payload,
            error_courses_method_enrol: null
        };
    case courses_constants.COURSE_METHOD_EROL_FAILURE:
        return {
            ...state,
            courses_method_enrol: 0,
            loading_courses_method_enrol: false,
            error_courses_method_enrol: action.payload
        };
    
    //------------GROUP COURSE--------------//   
    case courses_constants.COURSE_GROUP_REQUEST:
        return {
            ...state,
            loading_courses_groups: action.payload,
        };
    case courses_constants.COURSE_GROUP_SUCCESS:
        return {
            ...state,
            loading_courses_groups: false,
            courses_groups: action.payload,
            error_courses_groups: null
        };
    case courses_constants.COURSE_GROUP_FAILURE:
        return {
            ...state,
            courses_groups: 0,
            loading_courses_groups: false,
            error_courses_groups: action.payload
        };

    default:
      return state
  }
}