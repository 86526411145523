import { communications_constants } from '../constants/communications';


const initialState = {
    communications: [],
    loading_communications: null,
    error_communications: null,

    loading_communications_create: null,
    communications_create: null,
    error_communications_create: null,

    loading_communications_edit: null,
    communications_edit: null,
    error_communications_edit: null,

    loading_communications_delete: false,
    communications_delete: false,
    error_communications_delete: false,
};

export function communications_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET COMMUNICATIONS--------------//
        case communications_constants.COMMUNICATIONS_REQUEST:
            return {
                ...state,
                loading_communications: action.payload,
            };
        case communications_constants.COMMUNICATIONS_SUCCESS:
            return {
                ...state,
                loading_communications: false,
                communications: action.payload,
                error_communications: null
            };
        case communications_constants.COMMUNICATIONS_FAILURE:
            return {
                ...state,
                loading_communications: false,
                error_communications: action.payload
            };

        //-------------CREATE COMMUNICATIONS-----------------
        case communications_constants.COMMUNICATIONS_CREATE_REQUEST:
            return {
                ...state,
                loading_communications_create: action.payload,
            };
        case communications_constants.COMMUNICATIONS_CREATE_SUCCESS:
            return {
                ...state,
                loading_communications_create: false,
                communications_create: action.payload,
                error_communications_create: null
            };
        case communications_constants.COMMUNICATIONS_CREATE_FAILURE:
            return {
                ...state,
                loading_communications_create: false,
                error_communications_create: action.payload
            };

        // ------------- EDIT COMMUNICATIONS ----------
        case communications_constants.COMMUNICATIONS_EDIT_REQUEST:
            return {
                ...state,
                loading_communications_edit: action.payload,
            };
        case communications_constants.COMMUNICATIONS_EDIT_SUCCESS:
            return {
                ...state,
                loading_communications_edit: false,
                communications_edit: action.payload,
                error_communications_edit: null
            };
        case communications_constants.COMMUNICATIONS_EDIT_FAILURE:
            return {
                ...state,
                communications_edit: 0,
                loading_communications_edit: false,
                error_communications_edit: action.payload
            };

        //------------DELETE COMMUNICATIONS--------------//
        case communications_constants.COMMUNICATIONS_DELETE_REQUEST:
            return {
                ...state,
                loading_communications_delete: action.payload,
                communications_delete: null,
                error_communications_delete: null
            };
        case communications_constants.COMMUNICATIONS_DELETE_SUCCESS:
            return {
                ...state,
                loading_communications_delete: false,
                communications_delete: action.payload,
                error_communications_delete: false
            };
        case communications_constants.COMMUNICATIONS_DELETE_FAILURE:
            return {
                ...state,
                loading_communications_delete: false,
                communications_delete: 0,
                error_communications_delete: action.payload
            };
        default:
            return state
    }
}