export const courses_constants = {
    COURSES_REQUEST: 'COURSES_REQUEST',
    COURSES_SUCCESS: 'COURSES_SUCCESS',
    COURSES_FAILURE: 'COURSES_FAILURE',

    COURSE_EDIT_REQUEST: 'COURSE_EDIT_REQUEST',
    COURSE_EDIT_SUCCESS: 'COURSE_EDIT_SUCCESS',
    COURSE_EDIT_FAILURE: 'COURSE_EDIT_FAILURE',

    COURSE_CREATE_REQUEST: 'COURSE_CREATE_REQUEST',
    COURSE_CREATE_SUCCESS: 'COURSE_CREATE_SUCCESS',
    COURSE_CREATE_FAILURE: 'COURSE_CREATE_FAILURE',

    COURSE_DELETE_REQUEST: 'COURSE_DELETE_REQUEST',
    COURSE_DELETE_SUCCESS: 'COURSE_DELETE_SUCCESS',
    COURSE_DELETE_FAILURE: 'COURSE_DELETE_FAILURE',

    COURSE_CONTENTS_REQUEST: 'COURSE_CONTENTS_REQUEST',
    COURSE_CONTENTS_SUCCESS: 'COURSE_CONTENTS_SUCCESS',
    COURSE_CONTENTS_FAILURE: 'COURSE_CONTENTS_FAILURE',

    COURSE_CONTENTS_DELETE_REQUEST: 'COURSE_CONTENTS_DELETE_REQUEST',
    COURSE_CONTENTS_DELETE_SUCCESS: 'COURSE_CONTENTS_DELETE_SUCCESS',
    COURSE_CONTENTS_DELETE_FAILURE: 'COURSE_CONTENTS_DELETE_FAILURE',

    COURSE_CONTENTS_ADD_REQUEST: 'COURSE_CONTENTS_ADD_REQUEST',
    COURSE_CONTENTS_ADD_SUCCESS: 'COURSE_CONTENTS_ADD_SUCCESS',
    COURSE_CONTENTS_ADD_FAILURE: 'COURSE_CONTENTS_ADD_FAILURE',

    COURSE_METHOD_EROL_REQUEST: 'COURSE_METHOD_EROL_REQUEST',
    COURSE_METHOD_EROL_SUCCESS: 'COURSE_METHOD_EROL_SUCCESS',
    COURSE_METHOD_EROL_FAILURE: 'COURSE_METHOD_EROL_FAILURE',

    COURSE_GROUP_REQUEST: 'COURSE_GROUP_REQUEST',
    COURSE_GROUP_SUCCESS: 'COURSE_GROUP_SUCCESS',
    COURSE_GROUP_FAILURE: 'COURSE_GROUP_FAILURE',

    COURSE_ADD_GROUP_REQUEST: 'COURSE_ADD_GROUP_REQUEST',
    COURSE_ADD_GROUP_SUCCESS: 'COURSE_ADD_GROUP_SUCCESS',
    COURSE_ADD_GROUP_FAILURE: 'COURSE_ADD_GROUP_FAILURE',
}
