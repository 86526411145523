import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { meetings_actions } from '../../../../../store/actions/meetings';
// import TimezoneSelect from 'react-timezone-select'
import momentTZ from 'moment-timezone';

const MyTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timeZonesList = momentTZ.tz.names();

const AgregarMeeting = ({ onCloseSubMenu }) => {
    const dispatch = useDispatch();

    const categories = useSelector(state => state.categories);
    const [hoursDuration, sethoursDuration] = useState(0);
    const [minutesDuration, setminutesDuration] = useState(0);

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        meeting: {}
    });

    let hours = 0;
    let minutes = 0;
    //let formatTime = 0;
    useEffect(() => {
        sethoursDuration(hours);
        setminutesDuration(minutes);
        //setformatStarttime(formatTime);
    }, []);

    let categoria = new Array();

    const orderCategori = function (categoriasuperior, path) {

        if (categoriasuperior.children.length > 0) {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
            categoriasuperior.children.map(categoriachildren => {
                orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
            });
        } else {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        }
    }

    categories.categories.map(categoriesraiz => {
        if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children')) {

            orderCategori(categoriesraiz, `${categoriesraiz.name}`);
        }
    });

    const validate_add = Yup.object().shape({
        topic: Yup.string('Escribe el nombre para la conferencia')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(40, 'Debe ser menor a 40 caracteres')
            .required('El nombre es requerido'),
        start_time: Yup.string('Selecciona una fecha para la conferencia')
            .required('La fecha es requerida'),
        timezone: Yup.string('Escribe una contraseña de acceso para la conferencia')
            .required('La zona horaria es requerida'),
        password: Yup.string('Escribe una contraseña de acceso para la conferencia')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(16, 'Debe ser menor a 16 caracteres')
            .required('La contraseña es requerida')
    });

    const validateData = values => {
        let errors = {};

        if (parseInt(values.hour) === 0 && parseInt(values.minute) === 0) {
            errors.minute = 'La duracion de la conferencia es requerida';
        }
        if (values.timezone === '') {
            errors.timezone = 'La zona horaria es requerida';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            topic: ' ',
            start_time: '',
            hour: hours,
            minute: minutes,
            timezone: MyTimeZone,
            password: 'dafi'
        },
        validationSchema: validate_add,
        validate: validateData,

        onSubmit: values => {
            const duration = convertHoursToMinutes(values.hour, values.minute);
            values.duration = duration;
            // values.timezone = values.timezone.value;

            /* delete values.hour;
            delete values.minute; */
            handleSubmit(values)
        },
    });

    const handleSubmit = async (meeting) => {
        await meetings_actions.meetings_create(meeting);
        onCloseSubMenu(false);
        dispatch(meetings_actions.get_all_meetings());
    }
    const clickSubMenu = (type, open_param, meeting) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            meeting: meeting
        })
    }

    function convertHoursToMinutes(hour, minute) {
        const hours = hour;
        const minutes = + minute;

        let total = hours * 60 + minutes;
        return total;
    }

    /* useEffect(() => {
        settimeZonesList(momentTZ.tz.names())

    }, []
    ); */
    //const timezones = timeZonesList.map(timezone => ({ label: timezone, value: timezone }));
    return (
        <form className="formularios" onSubmit={formik.handleSubmit} autocomplete="off" autoCorrect='off'>
            <div className="campo">
                <label className="text-md" htmlFor="topic">Nombre de la conferencias</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" id="topic" name="topic" placeholder="" autoComplete="off" value={formik.values.topic} />
                <p className="text-red-600 text-sm">{formik.errors.topic ? <div>{formik.errors.topic}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="start_time">Fecha de la conferencias</label>
                <input className="rounded-md" autoComplete="off" onChange={formik.handleChange} type="datetime-local" id="start_time" name="start_time" placeholder="" value={formik.values.start_time} />
                <p className="text-red-600 text-sm">{formik.errors.start_time ? <div>{formik.errors.start_time}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Duracion de la conferencias</label>
                <div className="inline-flex items-baseline">
                    <select className="w-full rounded-md" onChange={formik.handleChange} name="hour">
                        <option value={0} selected={hoursDuration === 0 ? true : false}>{0}</option>
                        <option value={1} selected={hoursDuration === 1 ? true : false}>{1}</option>
                        <option value={2} selected={hoursDuration === 2 ? true : false}>{2}</option>
                        <option value={3} selected={hoursDuration === 3 ? true : false}>{3}</option>
                        <option value={4} selected={hoursDuration === 4 ? true : false}>{4}</option>
                        <option value={5} selected={hoursDuration === 5 ? true : false}>{5}</option>
                        <option value={6} selected={hoursDuration === 6 ? true : false}>{6}</option>
                        <option value={7} selected={hoursDuration === 7 ? true : false}>{7}</option>
                        <option value={8} selected={hoursDuration === 8 ? true : false}>{8}</option>
                        <option value={9} selected={hoursDuration === 9 ? true : false}>{9}</option>
                        <option value={10} selected={hoursDuration === 10 ? true : false}>{10}</option>
                        <option value={11} selected={hoursDuration === 11 ? true : false}>{11}</option>
                        <option value={12} selected={hoursDuration === 12 ? true : false}>{12}</option>
                        <option value={13} selected={hoursDuration === 13 ? true : false}>{13}</option>
                        <option value={14} selected={hoursDuration === 14 ? true : false}>{14}</option>
                        <option value={15} selected={hoursDuration === 15 ? true : false}>{15}</option>
                        <option value={16} selected={hoursDuration === 16 ? true : false}>{16}</option>
                        <option value={17} selected={hoursDuration === 17 ? true : false}>{17}</option>
                        <option value={18} selected={hoursDuration === 18 ? true : false}>{18}</option>
                        <option value={19} selected={hoursDuration === 19 ? true : false}>{19}</option>
                        <option value={20} selected={hoursDuration === 20 ? true : false}>{20}</option>
                        <option value={21} selected={hoursDuration === 21 ? true : false}>{21}</option>
                        <option value={22} selected={hoursDuration === 22 ? true : false}>{22}</option>
                        <option value={23} selected={hoursDuration === 23 ? true : false}>{23}</option>
                        <option value={24} selected={hoursDuration === 24 ? true : false}>{24}</option>
                    </select>
                    <span className=" pl-2 pr-2">h</span>
                    <select className="w-full rounded-md" onChange={formik.handleChange} name="minute">
                        <option value={0} selected={minutesDuration === 0 ? true : false}>{0}</option>
                        <option value={15} selected={minutesDuration === 15 ? true : false}>{15}</option>
                        <option value={30} selected={minutesDuration === 30 ? true : false}>{30}</option>
                        <option value={45} selected={minutesDuration === 45 ? true : false}>{45}</option>
                    </select>
                    <span className=" pl-2">min.</span>
                </div>
                <span className="text-red-600 text-sm">{formik.errors.hour ? <div>{formik.errors.hour}</div> : null}</span>
                <span className="text-red-600 text-sm">{formik.errors.minute ? <div>{formik.errors.minute}</div> : null}</span>
            </div>
            <div className="campo">
                <label className="text-md">Zona horaria de la conferencias</label>
                {/* <TimezoneSelect
                    name="timezone"
                    value={formik.values.timezone}
                    onChange={option => formik.setFieldValue("timezone", option)}
                    className="rounded-md timezone"
                /> */}
                <select value={formik.values.timezone} onChange={option => formik.setFieldValue("timezone", option.target.value)}>
                    {timeZonesList.map((item, index) => (<option key={index}>{item}</option>))}
                </select>
                <p className="text-red-600 text-sm">{formik.errors.timezone ? <div>{formik.errors.timezone}</div> : null}</p>
            </div>
            <div className="hidden"><input type="text" id="hidin" name="hidin" /></div>
            <div className="campo">
                <label className="text-md" htmlFor="">Contraseña de la conferencias</label>
                <input className="rounded-md" autoComplete="off" onChange={formik.handleChange} type="password" id="password" name="password" placeholder="" value={formik.values.password} />
                <p className="text-red-600 text-sm">{formik.errors.password ? <div>{formik.errors.password}</div> : null}</p>
            </div>

            <div className="block-inline text-center ">
                <button type="submit" style={{ maxWidth: "45%" }}
                    className="mx-auto text-center rounded-full bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  border border-blue-300 hover:bg-opacity-100">Guardar</button>
            </div>
        </form>
    );
}

export default AgregarMeeting;

