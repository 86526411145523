import { useState } from "react";
import st from "./detalleCurso.module.css";

const DetalleCurso = ({ titulo, accesos, finalizado, califi, avance, fechaInicio, fechaFin }) => {
    const [height, setHeight] = useState(0);

    const handleClick = () => setHeight(!height);

    return (
        <div className={st.faq__question}>
            <dt>
                <button
                    // aria-expanded={ariaExpanded}
                    // aria-controls={`faq${index + 1}_desc`}
                    data-qa="faq__question_button"
                    className={st.faq__question_button}
                    onClick={handleClick}
                >
                    <span>{titulo}</span>
                </button>
            </dt>
            <dd>
                <div
                    // id={`faq${index + 1}_desc`}
                    data-qa="faq__desc"
                    // ref={ref}
                    style={{
                        height: height ? "120px" : 0,
                        marginBottom: ".5rem",
                    }}
                    className={st.faq__desc}
                >
                    <div className={st.faq__desc_data}>
                        <div className={st.faq__desc_data_grid}>
                            <span>
                                Acecesos: <strong>{accesos}</strong>
                            </span>
                            <span>
                                Finalizado: <strong>{finalizado}</strong>
                            </span>
                            <span>
                                Calificación: <strong>{califi}</strong>
                            </span>
                            <div className={st.faq__desc_data_grid_avance}>
                                <span>Avance</span>
                                <span>{avance}</span>
                                <div>
                                    <div style={{ width: avance }}></div>
                                </div>
                            </div>
                        </div>
                        <span>Fecha de inicio: {fechaInicio}</span>
                        <span>
                            Fecha de finalización: {fechaFin}
                        </span>
                    </div>
                </div>
            </dd>
        </div>
    );
};

export default DetalleCurso;
