export const parserData = (inData, typeReport, orderBy, fideicomiso) => {
    if (!inData) return {labels: [], datasets: []};
    const data = {}
    const aux = []
    
    // ==========
    if (fideicomiso > 0) {
        data["labels"] = Object.keys(inData);

        for (let i in inData) {
            aux.push(inData[i].length)
        }
        data.datasets = [
            {
                label: 'Usuarios',
                data: aux,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            }
        ]

        if (typeReport === 'acceso') {
            const total = []
            let aux = 0;
            for (let i in inData) {
                for (let j in inData[i]) {
                    aux += parseInt(inData[i][j].accesos)
                }
                total.push(aux);
                aux = 0;
            }
    
            data.datasets.push({
                label: "Accesos Totales",
                data: total,
                backgroundColor: "#195ca9",
            })
        }
    
        if (typeReport === 'cursos' && orderBy !== 'curso') {
            const total = []
            let aux = 0;
            for (let i in inData) {
                for (let j in inData[i]) {
                    aux += parseInt(inData[i][j].finalizados)
                }
                total.push(aux);
                aux = 0;
            }
    
            data.datasets.push({
                label: "Cursos Totales",
                data: total,
                backgroundColor: "#138152",
            })
        }

    }

    if (fideicomiso < 0) {
        const fideAux = {
            "FIGLOSNTE 2": 0,
            "FIGLOSNTE 8": 0,
            "FIGLOSNTE 13": 0,
            "FIGLOSNTE 16": 0,
            "FIGLOSNTE 23": 0,
            "FIGLOSNTE 25": 0,
            "FIGLOSNTE 26": 0,
            "FIGLOSNTE 27": 0,
            "FIGLOSNTE 32": 0,
            "FIGLOSNTE 33": 0,
            "FIGLOSNTE 47": 0,
            "FIGLOSNTE 53": 0,
            "FIGLOSNTE 55": 0,
            "FIGLOSNTE 56": 0,
            "FIGLOSTASE": 0,
            "FIGLOSNTSA": 0,
            "JUBICOM 16": 0,
            "JUBICOM 27": 0,
        }

        // Establecemos los labels de la tabla
        data["labels"] = Object.keys(fideAux);

        let label = 'Fideicomiso'

        // arreglo de valores
        const valuesFide = []

        if (fideicomiso === -1) {
            // reemplazamos los valores por actuales por el resultado de la consulta.
            if (typeReport === 'calificaciones') {
                for (let i in inData) {
                    fideAux[i] = parseFloat(inData[i].porcentaje)
                }
                label = 'Promedio de calificación en %'
            } else if (typeReport === 'avance') {
                for (let i in inData) {
                    fideAux[i] = parseFloat(inData[i].porcentaje)
                }
            } else {
                for (let i in inData) {
                    fideAux[i] = parseFloat(inData[i].promedio)
                }
            }
    
            if (typeReport === 'cursos') label = 'Promedio de cursos'
            if (typeReport === 'acceso') label = 'Promedio de accesos'
    
           
        }

        if (fideicomiso === -2) {
            if (typeReport === 'acceso') {
                label = 'Total de accesos'
                for (let i in inData) {
                    for (let j in inData[i]) {
                        fideAux[i] += parseInt(inData[i][j].accesos)
                    }
                }
            }

            if (typeReport === 'avance') {
                label = 'Promedio de avance'
                let aux = 0;
                for (let i in inData) {
                    for (let j in inData[i]) {
                        aux += parseFloat(inData[i][j].porcentaje)
                    }
                    fideAux[i] = aux ? aux / inData[i].length : 0;
                    aux = 0;
                }
            }

            if (typeReport === 'cursos') {
                label = 'Cursos finalizados'
                for (let i in inData) {
                    for (let j in inData[i]) {
                        fideAux[i] += parseInt(inData[i][j].finalizados)
                    }
                }
            }

            if (typeReport === 'calificaciones') {
                label = 'Promedio de calificaciones'
                let aux = 0;
                for (let i in inData) {
                    for (let j in inData[i]) {
                        aux += parseFloat(inData[i][j].calificacion)
                    }
                    fideAux[i] = aux ? aux / inData[i].length : 0;
                    aux = 0;
                }
            }
        }

        // creamos el arreglo de valores
        for (let i in fideAux) {
            valuesFide.push(fideAux[i]);
        }

        data.datasets = [
            {
                label,
                data: valuesFide,
                backgroundColor: "#457588",
            }
        ]
    }
    
    return data;
}

export const parseUserCursos = (data) => {
    // verificar si es un objeto con llaves
    // if (!Object.keys(userData).length) return {}
    // obtenemos la primera llave
    let aux = Object.keys(data)[0]

    aux = {...data[aux]}; // asignamos el valor de objetos
    let cursosKeys = aux.cursos ? Object.keys(aux.cursos) : [];
    const cursosAux = []

    if (cursosKeys.length) {
        for (let key of cursosKeys) {
          cursosAux.push({ ...aux.cursos[key], title: '' + key})
        }
    }

    aux.finalizados = cursosAux.filter((curso) => curso.finalizado).length;

    aux.cursos = cursosAux;
    return aux;
}
