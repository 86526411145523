
import { forums_constants } from '../constants/forums';
import { forums_service } from '../services/forums';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const forums_actions = {
    get_all_forums,
    discussion_create,
    discussion_edit,
    discussion_delete,
    respond_discussion,
    respond_forum_post,
    remove_comment_forum
};

function get_all_forums(idForum) {

    return dispatch => {
        dispatch(request());

        forums_service.get_all_forums(idForum)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.forums));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: forums_constants.FORUMS_REQUEST, payload: true } }
    function success(param) { return { type: forums_constants.FORUMS_SUCCESS, payload: param } }
    function failure(error) { return { type: forums_constants.FORUMS_FAILURE, payload: error } }
}

async function discussion_create(discussion) {

        await forums_service.discussion_create(discussion)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        failure(res.error.message.toString());
                        return Swal.fire(
                            'Foro',
                            `${res.error.message}`,
                            'error',
                        )
                    } else {
                        success(res);
                        return Swal.fire(
                            'Foro',
                            'Fue agregado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    failure(error.toString());
                    alert_actions.error(error.toString());
                    return Swal.fire(
                        'Error',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => failure(e.toString()))

    function request() { return { type: forums_constants.FORUMS_CREATE_REQUEST, payload: true } }
    function success(param) { return { type: forums_constants.FORUMS_CREATE_SUCCESS, payload: param } }
    function failure(error) { return { type: forums_constants.FORUMS_CREATE_FAILURE, payload: error } }
}

async function discussion_edit(discussion) {

    await forums_service.discussion_edit(discussion)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Foro',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Foro',
                        'Actualizado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: forums_constants.FORUMS_EDIT_REQUEST, payload: true } }
    function success(param) { return { type: forums_constants.FORUMS_EDIT_REQUEST, payload: param } }
    function failure(error) { return { type: forums_constants.FORUMS_EDIT_REQUEST, payload: error } }
}

async function discussion_delete(idDiscussion) {

    await forums_service.discussion_delete(idDiscussion)
        .then(
            res => {
                if (res == 0) {
                    failure(res);
                    return Swal.fire(
                        'Foro',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Foro',
                        'Fue borrado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: forums_constants.FORUMS_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: forums_constants.FORUMS_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: forums_constants.FORUMS_DELETE_FAILURE, payload: error } }
}

async function respond_discussion(discussion) {
    await forums_service.respond_discussion(discussion)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Foro',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Foro',
                        'Su comentario fue agregado con exito.',
                        'success'
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return e.toString();
        })
    return dispatch => {
        dispatch(request());

    }
    function request() { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_REQUEST, payload: true } }
    function success(param) { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_SUCCESS, payload: param } }
    function failure(error) { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_FAILURE, payload: error } }
}

async function respond_forum_post(post) {
    await forums_service.respond_forum_post(post)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Foro',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Foro',
                        'Su comentario fue agregado con exito.',
                        'success'
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return e.toString();
        })
    return dispatch => {
        dispatch(request());

    }
    function request() { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_REQUEST, payload: true } }
    function success(param) { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_SUCCESS, payload: param } }
    function failure(error) { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_FAILURE, payload: error } }
}

async function remove_comment_forum(forum) {
    await forums_service.remove_comment_forum(forum)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Foro',
                        //`${res.error.message}`,
                        'No es posible eliminar el comentario debido a que cuenta con una o mas replicas.',
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Foro',
                        'El comentario fue eliminado con exito.',
                        'success'
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return e.toString();
        })
    return dispatch => {
        dispatch(request());

    }
    function request() { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_REQUEST, payload: true } }
    function success(param) { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_SUCCESS, payload: param } }
    function failure(error) { return { type: forums_constants.REMOVE_COMMENTS_FORUMS_FAILURE, payload: error } }
}
