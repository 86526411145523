import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { categories_actions } from '../../../../../store/actions/categories';
import { cohorts_actions } from '../../../../../store/actions/cohorts';

import Select from 'react-dropdown-select';

const AgregarCategoria = ({ onCloseSubMenu }) => {
    const dispatch = useDispatch();

    const categories = useSelector(state => state.categories);
    const cohorts = useSelector(state => state.cohorts);
    const options_cohorts = cohorts.cohorts.map((option) => ({
        ...option,
        label: option.name,
        value: option.id,
        cohortid: option.idnumber
    }));
    var categoria = new Array();

    const [categoriesInDropdown, setCategoriesInDropdown] = useState({isLoading: true, list: []});
    let user = JSON.parse(localStorage.getItem('user'));
    
    const orderCategori = function (categoriasuperior, path) {

        categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });

        if ( (categoriasuperior.hasOwnProperty('children')) && (categoriasuperior.children.length > 0) ) {
            categoriasuperior.children.map(categoriachildren => {
                orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
            });
        }


    }

    useEffect(() => {
        //setCategoriesInDropdown({isLoading: true, list: []});
        if (!categories.loading_categories) {
            console.log('En agregar categoria: ', categories);
            categoria = new Array();
            categories.categories.forEach(categoriesraiz => {
                //if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children')) {
                    orderCategori(categoriesraiz, `${categoriesraiz.name}`);
                //}
            });

            console.log('categoria filled ', categoria);
            setCategoriesInDropdown({isLoading: false, list: categoria});
        }
    }, [categories]);

    const [cohorts_select, set_cohorts_select] = useState([]);
    

    const validate_add = Yup.object().shape({
        name: Yup.string('Escribe el nombre de tu curso')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            parent: 0,
            description: '',
            descriptionformat: 1,
            theme: '',
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });
    const opciones = []
    options_cohorts.map(cohort => {
        if (cohort.id !== 1 || cohort.name !== "webservice" || cohort.idnumber !== "webservice") {
            opciones.push(cohort);
        }
    });
    const handleSubmit = async (category) => {
        await categories_actions.category_create(category, cohorts_select);
        onCloseSubMenu(false);
        dispatch(categories_actions.get_all_categories());
        dispatch(cohorts_actions.get_all_cohorts());    
    }

    const onChange = (values) => set_cohorts_select(values);
    
    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <div className="campo">
                <label className="" htmlFor="">Nombre de la categoria</label>
                <input onChange={formik.handleChange} type="text" name="name" placeholder="Nombre de la categoria" value={formik.values.name} />
                <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="" htmlFor="">Descripción de la categoria</label>
                <textarea onChange={formik.handleChange} type="text" name="description" placeholder="Descripción del curso" value={formik.values.description} rows="4" cols="50" />
                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
            </div>

            <div className="campo">
                <label className="mb-2" htmlFor="">Categorias</label>
                <select onChange={formik.handleChange} name="parent" value={formik.values.parent}>
                    {((user) && (user.isadmin))
                        ?<option className="bg-blue-600" value={0} selected >Superior</option>
                        : null
                    }
                    {categoriesInDropdown.isLoading ? 'Cargando...' :
                        categoriesInDropdown.list.length > 0
                            ? categoriesInDropdown.list.map((item) => {return <option value={item.id}>{item.nombre}</option>})
                            : null
                        
                    }
                </select>
                <p className="text-red-600 text-sm">{formik.errors.parent ? <div>{formik.errors.parent}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="mb-2" htmlFor="">Agregar grupo</label>
                <Select
                    multi
                    options={opciones}
                    name="select"
                    onChange={(value) => onChange(value)}
                />
            </div>
            <button className="block mx-auto text-center rounded-md bg-blue-500 bg-opacity-90 px-4 py-2 w-48 text-white border border-blue-300 hover:bg-opacity-100">Guardar</button>

        </form>
    );
}

export default AgregarCategoria;