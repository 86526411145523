
import { attempts_quiz_constants } from '../constants/attemptQuiz';
import { attempts_quiz_service } from '../services/attemptQuiz';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const attempts_quiz_actions = {
    get_all_attempts_quiz,
    delete_attempt_quiz
};

function get_all_attempts_quiz(idScorm) {

    return dispatch => {
        dispatch(request());

        attempts_quiz_service.get_all_attempts_quiz(idScorm)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.attempts_quiz));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: attempts_quiz_constants.ATTEMPTS_QUIZ_REQUEST, payload: true } }
    function success(param) { return { type: attempts_quiz_constants.ATTEMPTS_QUIZ_SUCCESS, payload: param } }
    function failure(error) { return { type: attempts_quiz_constants.ATTEMPTS_QUIZ_FAILURE, payload: error } }
}

async function delete_attempt_quiz(row, idScorm) {

    await attempts_quiz_service.delete_attempt_quiz(row, idScorm)
        .then(
            res => {
                if (res == 0) {
                    failure(res);
                    return Swal.fire(
                        'Intento del examen',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Intento del examen',
                        'Intento borrado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: attempts_quiz_constants.ATTEMPTS_QUIZ_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: attempts_quiz_constants.ATTEMPTS_QUIZ_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: attempts_quiz_constants.ATTEMPTS_QUIZ_DELETE_FAILURE, payload: error } }
}