import { useState } from 'react'
import iconUser from '../../../../assets/icons/Icono_usuario_azulDAFI.svg'
import iconCalendar from '../../../../assets/icons/Icono_calendario_DAFI-03.svg'
import iconLupa from '../../../../assets/icons/Icono_buscar_DAFI_.svg'
import st from './searchBar.module.css'
import { useDispatch } from 'react-redux'
import { reporter_actions, resetGlobalTable, resetValues } from '../../../../store/actions/reporter'


const validate = (form) => {
    const errors = {}

    if (!form.search) {
        errors.search = "Campo requerido"
    }

    if (!form.fechaInicio || !form.fechaFin) {
        errors.fecha = "Seleccione una fecha de inicio y fin"
    }
    return errors;
}

const SearchBar = ({ fideicomisos }) => {
    const [form, setForm] = useState({});
    const [error, setError] = useState({});
    const dispatch = useDispatch();

    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

    const handleSubmit = () => {
        const aux = validate(form);
        setError(aux);

        if(!Object.keys(aux).length) {
            dispatch(reporter_actions.userDetails({...form, fideicomisos }))
            dispatch(resetGlobalTable())
            dispatch(resetValues())
            setForm({ search: '', fechaInicio: '', fechaFin: '' })
        }
    }

    return (
        <div className={st.searchbar}>
            <div className={st.searchbar_input}>
                <img src={iconUser} alt="user search" />
                <input
                    id='username'
                    type='search'
                    name='search'
                    value={form.search}
                    onChange={handleChange}
                    placeholder='No. de empleado'
                />
                {error.search && <span className={st.error_msg}>{error.search}</span>}
            </div>
            <div className={st.searchbar_input}>
                <img src={iconCalendar} alt="calendario" />
                <input
                    type="date"
                    id="fechaInicio"
                    name="fechaInicio"
                    onChange={handleChange}
                    value={form.fechaInicio}
                />
                <span>-</span>
                <input
                    type="date"
                    id="fechaFin"
                    name="fechaFin"
                    value={form.fechaFin}
                    onChange={handleChange}
                />
                {error.fecha && <span className={st.error_msg}>{error.fecha}</span>}
            </div>
            <button className={st.searchbar_input_btn} onClick={handleSubmit}>
                <img src={iconLupa} alt="Lupa" />
            </button>
        </div>
    )
}

export default SearchBar