import Swal from 'sweetalert2';
import api_iac from "../../config/instance_axios";
import { user } from "../../config/user_token";
import { formatNameExcel } from '../../helpers/estructura_curricular';
import { reporter_constants } from "../constants/reporter";
import { reporter_service } from "../services/reporter";
import { alert_actions } from "./alert";

function get_all_reporter() {
    return (dispatch) => {
        dispatch(request());

        reporter_service
            .get_all_reporter()
            .then(
                (res) => {
                    if (res.hasOwnProperty("error")) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.reporter));
                    }
                },
                (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            )
            .catch((e) => dispatch(failure(e.toString())));
    };

    function request() {
        return { type: reporter_constants.REPORTER_REQUEST, payload: true };
    }
    function success(param) {
        return { type: reporter_constants.REPORTER_SUCCESS, payload: param };
    }
    function failure(error) {
        return { type: reporter_constants.REPORTER_FAILURE, payload: error };
    }
}

async function get_all_reporter_filter(filters) {
    return await reporter_service
        .get_all_reporter_filter(filters)
        .then(
            (res) => {
                if (res.hasOwnProperty("error")) {
                    failure(res.error.message.toString());
                } else {
                    //success(res);
                    return res;
                }
            },
            (error) => {
                failure(error.toString());
                alert_actions.error(error.toString());
            }
        )
        .catch((e) => failure(e.toString()));

    function request() {
        return {
            type: reporter_constants.REPORTER_FILTER_REQUEST,
            payload: true,
        };
    }
    function success(param) {
        return {
            type: reporter_constants.REPORTER_FILTER_SUCCESS,
            payload: param,
        };
    }
    function failure(error) {
        return {
            type: reporter_constants.REPORTER_FILTER_FAILURE,
            payload: error,
        };
    }
}

const get_all_cursos = (data, global) => async (dispatch) => {

    try {
        global && dispatch(request());
        Swal.fire({
            title: global ? 'buscando datos' : 'Generando reporte...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                let timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                            b.textContent = Swal.getTimerLeft()
                        }
                    }
                }, 100)
            },
        });
        const response = await api_iac.post(
            'reportador/api.php?q=getCursosFinalizadosComiteAndEmployment',
            {
                ...data,
                token: user.token,
                orderBy: data.fideicomiso === -1 && !global ? 'fideicomiso' : data.orderBy
            }
        );

        if (
            response.status !== 200 ||
            response.data.hasOwnProperty("error")
        ){
            throw new Error("error al hacer la consulta");
        }

        if (Array.isArray(response.data)) {
            throw new Error("No encontramos datos para mostrar");
        }
        
        Swal.fire('Reporte', 'Reporte generado con exito', 'success');

        return global ? dispatch(successGTableData(response.data)) : dispatch(success(response.data));
    } catch (error) {
        Swal.fire(
            'Reporte',
            error.toString(),
            'info',
        );
        global && dispatch(failure(error.toString()));
    }

    // Petición del los datos más en concreto. (Solo aplica para la tabla)
    function request() {
        return {
            type: reporter_constants.REPORTE_GLOBAL_REQUEST,
            payload: true,
        };
    }
    function failure(error) {
        return {
            type: reporter_constants.REPORTE_GLOBAL_FAILURE,
            payload: error,
        };
    }

    function successGTableData (data) {
        return {
            type: reporter_constants.REPORTE_GLOBAL_SUCCESS,
            payload: data
        }
    }

    // Petición por fideicomiso individual y particular
    function success(param) {
        return {
            type: reporter_constants.REPORTE_FILTER_COURSE_GEN_SUCCESS,
            payload: param,
        };
    }
    
};

const get_all_acceso =
    (data, global) => async (dispatch) => {
        try {
            global && dispatch(request());
            Swal.fire({
                title: global ? 'buscando datos' : 'Generando reporte...',
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                },
            });
            const response = await api_iac.post(
                `reportador/api.php?q=getAccesos${data.reportBy}`,
                {
                    ...data,
                    token: user.token,
                    orderBy: (data.fideicomiso === -1 || data.fideicomiso === -2) && !global ? 'fideicomiso' : data.orderBy
                }
            );

            if (
                response.status !== 200 ||
                response.data.hasOwnProperty("error")
            ){
                throw new Error("Ocurrio un error, comprueba los datos o intenta de nuevo");
            }

            if (Array.isArray(response.data)) {
                throw new Error("No encontramos datos para mostrar");
            }
            
            Swal.fire('Reporte', global ? 'Datos consultados' : 'Reporte generado con exito', 'success');

            return global ? dispatch(successGTableData(response.data)) : dispatch(success(response.data));
        } catch (error) {
            Swal.fire(
                'Reporte',
                error.toString(),
                'info',
            );
            global && dispatch(failure(error.toString()));
        }

        function request() {
            return {
                type: reporter_constants.REPORTE_GLOBAL_REQUEST,
                payload: true,
            };
        }
        
        function failure(error) {
            return {
                type: reporter_constants.REPORTE_GLOBAL_FAILURE,
                payload: error,
            };
        }

        function successGTableData(param) {
            return {
                type: reporter_constants.REPORTE_GLOBAL_SUCCESS,
                payload: param,
            };
        }

        // Reporte para la tabla
        function success(param) {
            return {
                type: reporter_constants.REPORTE_FILTER_COURSE_GEN_SUCCESS,
                payload: param,
            };
        }
    };

const get_all_avance = (data, global) => async (dispatch) => {
    try {
        global && dispatch(request());
        Swal.fire({
            title: global ? 'buscando datos' : 'Generando reporte...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                let timerInterval = setInterval(() => {
                    const content = Swal.getHtmlContainer()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                            b.textContent = Swal.getTimerLeft()
                        }
                    }
                }, 100)
            },
        });
        const response = await api_iac.post(
            "reportador/api.php?q=getAvances",
            {
                ...data,
                token: user.token,
                orderBy: data.fideicomiso === -1 && !global ? 'fideicomiso' : data.orderBy
            }
        );

        if (
            response.status !== 200 ||
            response.data.hasOwnProperty("error")
        ){throw new Error("error al hacer la consulta");}

        if (Array.isArray(response.data)) {
            throw new Error("No encontramos datos para mostrar");
        }
        
        Swal.fire('Reporte', global ? 'Datos consultados' : 'Reporte generado con exito', 'success');
        return global ? dispatch(successGTableData(response.data)) : dispatch(success(response.data));
    } catch (error) {
        Swal.fire(
            'Reporte',
            error.toString(),
            'info',
        );
        global && dispatch(failure(error.toString()));
    }

    function successGTableData(param) { return { type: reporter_constants.REPORTE_GLOBAL_SUCCESS, payload: param,}}
    function request() { return {type: reporter_constants.REPORTE_GLOBAL_REQUEST, payload: true,}}
    function failure(error) { return { type: reporter_constants.REPORTE_GLOBAL_FAILURE, payload: error,}}
    
    function success(param) { return {type: reporter_constants.REPORTE_FILTER_COURSE_GEN_SUCCESS, payload: param,}}
};

const get_all_calificaciones = (data, global) => async (dispatch) => {
        try {
            Swal.fire({
                title: global ? 'buscando datos' : 'Generando reporte...',
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    let timerInterval = setInterval(() => {
                        const content = Swal.getHtmlContainer()
                        if (content) {
                            const b = content.querySelector('b')
                            if (b) {
                                b.textContent = Swal.getTimerLeft()
                            }
                        }
                    }, 100)
                },
            });
            dispatch(request());
            const response = await api_iac.post(
                "reportador/api.php?q=getCalificacionesCursos",
                {
                    ...data,
                    token: user.token,
                    orderBy: data.fideicomiso === -1 && !global ? 'fideicomiso' : data.orderBy
                }
            );

            if (
                response.status !== 200 ||
                response.data.hasOwnProperty("error")
            ){throw new Error("error al hacer la consulta");}

            if (Array.isArray(response.data)) {
                throw new Error("No encontramos datos para mostrar");
            }
            
            Swal.fire('Reporte', 'Reporte generado con exito', 'success');
            return global ? dispatch(successGTableData(response.data)) : dispatch(success(response.data));
        } catch (error) {
            Swal.fire(
                'Reporte',
                error.toString(),
                'info',
            );
            dispatch(failure(error.toString()));
        }

        function request() {
            return {
                type: reporter_constants.REPORTE_GLOBAL_REQUEST,
                payload: true,
            };
        }
        
        function failure(error) {
            return {
                type: reporter_constants.REPORTE_GLOBAL_FAILURE,
                payload: error,
            };
        }
        function successGTableData(param) {
            return {
                type: reporter_constants.REPORTE_GLOBAL_SUCCESS,
                payload: param,
            };
        }
        
        function success(param) {
            return {
                type: reporter_constants.REPORTE_FILTER_COURSE_GEN_SUCCESS,
                payload: param,
            };
        }
    };

const userDetails = (userInfo) => async (dispatch) => {
    try {
        Swal.fire({
            title: 'Buscando usuario',
            timerProgressBar: true,
            didOpen: () =>  Swal.showLoading()
        });

        const response = await api_iac.post(
            "reportador/api.php?q=search",
            {
                ...userInfo,
                token: user.token,
            }
        );

        if (
            response.status !== 200 ||
            response.data.hasOwnProperty("errror")
        ){ throw new Error("error al hacer la consulta") }

        if (response.data.hasOwnProperty("error")) {
            throw new Error(response.data.error)
        }
        
        Swal.fire('Reporte', 'Usuario encontrado', 'success');
        dispatch(success(response.data));
    } catch (error) {
        Swal.fire(
            'Reporte',
            error.toString(),
            'info',
        );
        dispatch(failure(error.toString()));
    }

    function success (data) { return { type: reporter_constants.REPORTE_USER_SUCCESS, payload: data } }
    function failure (error) { return { type: reporter_constants.REPORTE_USER_FAILURE, payload: error } }
}

// === reset
export const resetValues = () => {
    return {
        type: reporter_constants.REPORTE_FILTER_COURSE_RESET,
        payload: true,
    }
}

export const resetGlobalTable = () => {
    return {
        type: reporter_constants.REPORTE_GLOBAL_RESET,
        payload: true,
    }
}

export const resetUserDatails = () => {
    return {
        type: reporter_constants.REPORTE_USER_DETAIL_RESET,
        payload: true
    }
}

// ======================
// Esta función no hace uso de redux pero se encuentra en este archivo
// para mantener una relación en las funciones

export const downloadExcelReport = async (fideicomiso, method, orderBy, data, fechaInicio, fechaFin) => {
    try {
        Swal.fire({
            title: 'Generando reporte',
            text: "esto puede tardar unos minutos, por favor no cierre esta ventana ni recargue la página",
            timerProgressBar: true,
            allowOutsideClick: false,
            icon: "info",
            didOpen: () =>  Swal.showLoading()
        });

        // Formamos la fecha actual
        const currentDate = new Date();
        const currentDayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
        const currentYear = currentDate.getFullYear();

        const dateString = currentDayOfMonth + "-" + (currentMonth + 1) + "-" + currentYear;

        const response = await api_iac.post("reportador/api.php?q=export_excel", {
            fideicomiso,
            method,
            orderBy: orderBy === 0 ? "fideicomiso" : orderBy,
            data,
            token: user.token,
            fechaInicio,
            fechaFin,
            hora: new Date().toLocaleTimeString(),
            fecha: dateString
        }, { responseType: "blob"});

        if (
            response.status !== 200
        ){throw new Error("error al hacer la consulta");}
        
        // formamos el nombre del arcivo
        var nameHidden = formatNameExcel(fideicomiso, method);
        // creamos el link de descarga del archivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nameHidden);
        link.click();

        Swal.fire('Reporte', 'Reporte generado con éxito.', 'success');
    } catch (error) {
        console.log(error, '>>>>>')
        Swal.fire(
            'Error al descargar el reporte',
            error.toString(),
            'info',
        );
    }
}


// ======================


export const reporter_actions = {
    get_all_reporter,
    get_all_reporter_filter,

    // cursos
    get_all_cursos,

    // accesos
    get_all_acceso,

    // avances
    get_all_avance,

    // calificaciones
    get_all_calificaciones,

    // buscador
    userDetails,
};
