import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import { array } from 'yup/lib/locale';
import api_iac from '../../config/instance_axios';
import { user as userToken } from '../../config/user_token';
import { getNameFromId } from '../../helpers/estructura_curricular';
import { cohorts_service } from '../services/cohorts';


//&wsfunction=tool_dataprivacy_get_users&moodlewsrestformat=json&query=0
export const users_service = {
    get_all_users,
    users_edit,
    users_add,
    users_add_csv,
    users_status,
    users_delete,
    users_seach,
    get_user,
    get_user_field
};

async function get_all_users() {
    const request_users = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_get_users&moodlewsrestformat=json&criteria[0][key]=deleted&criteria[0][value]=0`);

    if (Array.isArray(request_users.data.users)) {
        const users = request_users.data.users
        return { users };
    }

    const error = request_users.data;
    return { error };

}

async function users_edit(user, selected_cohortes) {
    let id_photo = 0;
    let existentes = [];
    let cohortids = '';
    const area = getNameFromId(parseInt(user.comitenum))

    let cohortDelete = user.cohortsid.filter(cohortid => {
        let existe = selected_cohortes.find(id => id.id === cohortid.id);
        if (!existe) {
            return cohortid.id
        } else {
            existentes.push(existe);
        }
    });

    let newsCohorts = selected_cohortes.filter(item => {
        let x = existentes.find(id => id.id === item.id);
        if (!x) {
            return item;
        }
    });

    if (cohortDelete.length > 0) {
        cohortDelete.map(async cohort => {
            await cohorts_service.remove_members_cohort(cohort, [user.id]);
        })
    }
    if (newsCohorts.length > 0) {
        newsCohorts.map(async cohort => {
            await cohorts_service.add_members_cohort(cohort, [user.id]);
        })
    }
    if (user.file !== '' && user.file !== undefined) {
        let formData = new FormData();

        formData.append('token', userToken.token);
        formData.append('newfile', user.file);
        const request_user_photo = await api_iac.post(`webservice/upload.php`, formData);

        let formDataPicture = new FormData();

        formDataPicture.append('wstoken', userToken.token);
        formDataPicture.append('wsfunction', 'core_user_update_picture');
        formDataPicture.append('moodlewsrestformat', 'json');
        formDataPicture.append('userid', user.id);
        formDataPicture.append('draftitemid', request_user_photo.data[0].itemid);

        const request_user_photo_update = await api_iac.post('webservice/rest/server.php', formDataPicture);

        id_photo = request_user_photo.data[0].itemid;
    }

    let request_user_edit;
    let useridpicture = '';
    let password = '';
    if (id_photo !== 0) {
        useridpicture = `&users[0][userpicture]=${id_photo}`;
    }

    if (user.password !== undefined) {
        password = `&users[0][password]=${user.password}`;
    }
    request_user_edit = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_update_users&moodlewsrestformat=json&
    users[0][id]=${user.id}&
    users[0][firstname]=${user.firstname}&
    users[0][lastname]=${user.lastname}&
    users[0][age]=${user.age}&
    users[0][fechainicio]=${user.fechainicio}&
    users[0][fechavigencia]=${user.fechavigencia}&
    users[0][dateadmission]=${user.dateadmission}&
    users[0][employment]=${user.employment}&
    users[0][area]=${area}&
    users[0][workplace]=${user.workplace}&
    users[0][gender]=${user.gender}${password}${useridpicture}&
    users[0][curp]=${user.curp}&
    users[0][niveldesarrollo]=${user.niveldesarrollo}&
    users[0][nationalocupation]=${user.nationalocupation}&
    users[0][fideicomisonum]=${user.fideicomisonum}&
    users[0][adscritonum]=${user.adscritonum}&
    users[0][comitenum]=${user.comitenum}&
    users[0][tipofideicomiso]=${user.tipofideicomiso}`);
    
    if (request_user_edit.data === null) {
        let success = 'success';
        return { success };
    }
    const error = request_user_edit.data;
    return { error };

}

async function users_add(user) {
    const area = getNameFromId(parseInt(user.comite))
    let cohortids = '';
    user.cohortid.map((cohortid, index) => {
        cohortids += `users[0][cohorts][${index}][id]=${cohortid.id}&`;
    });
    const request_user_add = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_create_users&moodlewsrestformat=json&
    users[0][username]=${user.email}&
    users[0][firstname]=${user.firstname}&
    users[0][lastname]=${user.lastname}&
    users[0][email]=${user.email}&
    users[0][password]=${user.password}&
    users[0][idnumber]=${user.username}&
    ${cohortids}
    users[0][age]=${user.edad}&
    users[0][fechainicio]=${user.fechainicio}&
    users[0][fechavigencia]=${user.fechavigencia}&
    users[0][dateadmission]=${user.fechaingreso}&
    users[0][employment]=${user.cargo}&
    users[0][area]=${area}&
    users[0][workplace]=${user.centrotrabajo}&
    users[0][gender]=${user.sexo}&
    users[0][curp]=${user.curp}&
    users[0][niveldesarrollo]=${user.niveldesarrollo}&
    users[0][nationalocupation]=${user.nationalocupation}&
    users[0][fideicomisonum]=${user.fideicomiso}&
    users[0][adscritonum]=${user.adscritonum}&
    users[0][comitenum]=${user.comite}&
    users[0][tipofideicomiso]=${user.tipofideicomiso}`);

    if (Array.isArray(request_user_add.data)) {

        if (user.file !== '' && user.file !== undefined) {
            let formData = new FormData();

            formData.append('token', userToken.token);
            formData.append('newfile', user.file);
            const request_user_photo = await api_iac.post(`webservice/upload.php`, formData);

            let formDataPicture = new FormData();

            formDataPicture.append('wstoken', userToken.token);
            formDataPicture.append('wsfunction', 'core_user_update_picture');
            formDataPicture.append('moodlewsrestformat', 'json');
            formDataPicture.append('userid', request_user_add.data[0].id);
            formDataPicture.append('draftitemid', request_user_photo.data[0].itemid);

            const request_user_photo_update = await api_iac.post('webservice/rest/server.php', formDataPicture);

            let id_photo = request_user_photo.data[0].itemid;

            let request_user_edit = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_update_users&moodlewsrestformat=json&users[0][username]=${user.username}&users[0][firstname]=${user.firstname}&users[0][lastname]=${user.lastname}&users[0][email]=${user.email}&users[0][id]=${request_user_add.data[0].id}&users[0][userpicture]=${id_photo}`);
        }
        return { user };
    }

    const error = request_user_add.data;
    return { error };
}

async function users_add_csv(users) {
    let formData = new FormData();

    formData.append('wstoken', userToken.token);
    formData.append('wsfunction', 'core_user_bulk_load_users');
    formData.append('moodlewsrestformat', 'json');
    formData.append('csv', users);

    const request_user_add = await api_iac.post(`webservice/rest/server.php`, formData);
    // console.log(request_user_add);
    //debugger
    if (request_user_add.data.aggregates !== undefined) {
        const data = request_user_add.data;
        return data;
    }

    const error = request_user_add.data;
    return { error };
}


async function users_delete(user) {
    const request_user_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_delete_users&moodlewsrestformat=json&userids[0]=${user.id}`);
    if (request_user_delete.data == null) {
        return 1;
    } else {
        return 0
    }
}

async function users_status(user) {
    const suspended = user.suspended ? 1 : 0;
    const request_user_edit = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_update_users&moodlewsrestformat=json&users[0][suspended]=${suspended}&users[0][id]=${user.id}`);

    if (Array.isArray(request_user_edit.data) || request_user_edit.data == null) {
        return { user };
    }

    const error = request_user_edit.data;
    return { error };
}

async function users_seach(userArray) {
    const request_users = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=tool_dataprivacy_get_users&moodlewsrestformat=json&query=${userArray}`);
    if (Array.isArray(request_users.data)) {
        const users = request_users.data
        return { users };
    }

    const error = request_users.data;
    return { error };
}

async function get_user(id) {
    const request_users = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_get_users&moodlewsrestformat=json&criteria[0][key]=id&criteria[0][value]=${id}`);

    if (Array.isArray(request_users.data.users)) {
        const users = request_users.data.users
        return { users };
    }

    const error = request_users.data;
    return { error };

}

async function get_user_field(search, field) {

    const request_users = await api_iac.get(`webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_get_users_field&moodlewsrestformat=json&field=${field}&values=${search}`);
    if (Array.isArray(request_users.data.users)) {
        const users = request_users.data.users
        return { users };
    }

    const error = request_users.data;
    return { error };
    //webservice/rest/server.php?wstoken=${userToken.token}&wsfunction=core_user_get_users_field&moodlewsrestformat=json&field=username&values=32
}
