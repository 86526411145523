import React from 'react';

const Notificacion = ({subject, fullhtml}) => {
    return ( 
        <div className="mb-4">
            <div className="hidden flex mb-4 h-20">
                <div className="ml-4 flex justify-center items-center p-2"></div>
                <div className="flex w-0.5 h-auto bg-white mx-8"> </div>
                <div className="text-white">
                    <h3 className="text-2xl">Lorem ipsum dolor</h3>
                    <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                </div>
            </div>
            <div className="flex mb-4 h-20 oveflow-hidden">
                <div className="text-white">
                    <h3 className="text-2xl">{subject}</h3>
                    <p className="truncate">{fullhtml}</p>
                </div>
            </div>
            <div className="w-full bg-white h-0.5"></div>
        </div>
    );
}
 
export default Notificacion;