import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Aside from '../Aside/Aside';
import Nav from '../Nav/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { events_actions } from '../../store/actions/calendar';


export const PrivateRoute = ({ component: Component, ...rest }) => {
    let user = JSON.parse(localStorage.getItem('user'));
    //let user = true;

    const dispatch = useDispatch();
    dispatch(events_actions.get_all_events());

    return(
            <Route {...rest} render={props => (
                user ? 
                        <div>
                            <Nav/>
                            <div className="flex">
                                <Aside/>
                                <Component {...props} />
                            </div>
                        </div>
                    : 
                        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )} />
    );
}
