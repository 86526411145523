
import api_iac from "../../config/instance_axios";
import { user } from '../../config/user_token';


export const tutors_service = {
    get_tutor_courses,
    assing_tutor_courses,
    unassing_tutor_courses
};


async function get_tutor_courses(idTutor) {
    const request_tutors = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_admin_get_tutor_courses&
    &moodlewsrestformat=json&
    tutorid=${idTutor}`);

    if (Array.isArray(request_tutors.data.courses)) {
        const tutors = request_tutors.data.courses;
        return { tutors };
    }

    const error = request_tutors.data;
    return { error };
}

async function assing_tutor_courses (coursesList, idUser) {
    //Agrega los cursos
    console.log(coursesList);
    let courseadd = false;
    let coursedelete = false;
    if(coursesList.courseid.length > 0){
        let courses = "";
        coursesList.courseid.map((course, index) => {
            courses += `courses[${index}]=${course}&`;
        });
        const request_assing_course = await api_iac.get(`webservice/rest/server.php?
        wstoken=${user.token}&
        wsfunction=targett_assign_tutor_to_courses&
        moodlewsrestformat=json&
        userid=${idUser}&
        ${courses}`);
        if (typeof request_assing_course.data === 'object') {
            courseadd = true;
        }
    }

    if(coursesList.coursesdelete.length > 0){
        let courses = "";
        coursesList.coursesdelete.map((course, index) => {
            courses += `courses[${index}]=${course}&`;
        });
        const request_assing_course = await api_iac.get(`webservice/rest/server.php?
        wstoken=${user.token}&
        wsfunction=targett_unassign_tutor_to_courses&
        moodlewsrestformat=json&
        userid=${idUser}&
        ${courses}`);
        if (typeof request_assing_course.data === 'object') {
            coursedelete = true;
        }
    }
    if(coursedelete && courseadd){
        let mensaje =  "Cursos Actualizados"
        return {mensaje}
    }

    if(courseadd){
        let mensaje =  "Cursos Agregado"
        return {mensaje}
    }

    if(coursedelete){
        let mensaje =  "Cursos Eliminados"
        return {mensaje}
    }
    
    const error = "Error al intentar Actualizar";
    return { error };
}

async function unassing_tutor_courses(coursesList, idUser) {
    let courses = "";
    coursesList.courseid.map((course, index) => {
        courses += `courses[${index}]=${course}&`;
    });

    const request_unassing_course = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_unassign_tutor_to_courses&
    moodlewsrestformat=json&
    userid=${idUser}&
    ${courses}`);

    if (typeof request_unassing_course.data === 'object') {
        return 1;
    }
    const error = request_unassing_course.data.forums;
    return { error };
}