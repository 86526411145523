import { useRef, useState } from "react"
import st from "./detalleCurso.module.css";


export const TableAccesos = ({ accesOf, orderBy, data, title }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0)
    const ref = useRef()

    const handleClick = () => {
        setHeight(ref.current.scrollHeight)
        setOpen(!open)
    }

    if (accesOf === 'Plataforma') return (
        <div>
            <dt>
                <button
                    data-qa="faq__question_button"
                    className={`${st.faq__question_button} ${open ? st.faq__question_button_active : ''} `}
                    onClick={handleClick}
                >
                    <span>{title}</span>
                </button>
            </dt>
            <dd>
                <div
                    data-qa="faq__desc"
                    ref={ref}
                    style={{
                        height: open ? height : 0,
                        marginBottom: ".5rem",
                    }}
                    className={st.faq__desc}
                >
                    <div className={st.faq__desc_data}>
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">username</th>
                                    <th scope="col">nombre completo</th>
                                    <th scope="col">fideicomiso</th>
                                    <th scope="col">{orderBy === 'comite' ? 'Cargo' : 'Comite'}</th>
                                    <th scope="col">Accesos</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length && data.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.username}</td>
                                        <td>{item.nombre} {item.apellido}</td>
                                        <td>{item.fideicomiso}</td>
                                        <td>{orderBy === 'comite' ? (item.cargo ?? "-") : (item.comite ?? '-')}</td>
                                        <td>{item.accesos}</td>
                                        <td>{item.fecha}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </dd>
        
        </div>

    )

    return (
        <div>
            <dt>
                <button
                    data-qa="faq__question_button"
                    className={`${st.faq__question_button} ${open ? st.faq__question_button_active : ''} `}
                    onClick={handleClick}
                >
                    <span>{title}</span>
                </button>
            </dt>
            <dd>
                <div
                    data-qa="faq__desc"
                    ref={ref}
                    style={{
                        height: open ? height : 0,
                        marginBottom: ".5rem",
                    }}
                    className={st.faq__desc}
                >
                    <div className={st.faq__desc_data}>
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">username</th>
                                    <th scope="col">nombre completo</th>
                                    <th scope="col">fideicomiso</th>
                                    {['comite', 'curso'].includes(orderBy) && <th scope="col">Cargo</th>}
                                    {['cargo', 'curso'].includes(orderBy) && <th scope="col">Comite</th>}
                                    {orderBy !== 'curso' && <th scope="col">Curso</th>}
                                    <th scope="col">Accesos</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length && data.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.username}</td>
                                        <td>{item.nombre} {item.apellido}</td>
                                        <td>{item.fideicomiso ?? "-"}</td>
                                        {['comite', 'curso'].includes(orderBy) && <td>{item.cargo ?? "-"}</td>}
                                        {['cargo', 'curso'].includes(orderBy) && <td>{item.comite ?? "-"}</td>}
                                        {orderBy !== 'curso' && <td>{item.curso}</td>}
                                        <td>{item.accesos}</td>
                                        <td>{item.fecha}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </dd>
        </div>
        
    )
}

export const TableAvances = ({ orderBy, data, title }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0)
    const ref = useRef()

    const handleClick = () => {
        setHeight(ref.current.scrollHeight)
        setOpen(!open);
    }
    return (
        <div>
            <dt>
                <button
                    data-qa="faq__question_button"
                    className={`${st.faq__question_button} ${open ? st.faq__question_button_active : ''} `}
                    onClick={handleClick}
                >
                    <span>{title}</span>
                </button>
            </dt>
            <dd>
                <div
                    data-qa="faq__desc"
                    ref={ref}
                    style={{
                        height: open ? height : 0,
                        marginBottom: ".5rem",
                    }}
                    className={st.faq__desc}
                >
                    <div className={st.faq__desc_data}>
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">username</th>
                                    <th scope="col">nombre completo</th>
                                    <th scope="col">fideicomiso</th>
                                    {['comite', 'curso'].includes(orderBy) && <th scope="col">Cargo</th>}
                                    {['cargo', 'curso'].includes(orderBy) && <th scope="col">Comite</th>}
                                    {orderBy !== 'curso' && <th scope="col">Curso</th>}
                                    <th scope="col">Avance</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length && data.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.username}</td>
                                        <td>{item.nombre} {item.apellido}</td>
                                        <td>{item.fideicomiso}</td>
                                        {['comite', 'curso'].includes(orderBy) && <td>{item.cargo}</td>}
                                        {['cargo', 'curso'].includes(orderBy) && <td>{item.comite}</td>}
                                        {orderBy !== 'curso' && <td>{item.curso}</td>}
                                        <td>{item.porcentaje}</td>
                                        <td>{item.fecha}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </dd>
        </div>
    )
}

export const TableFinalizados = ({ orderBy, data, title }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0)
    const ref = useRef()

    const handleClick = () => {
        setHeight(ref.current.scrollHeight)
        setOpen(!open);
    }

    return (
        <div>
            <dt>
                <button
                    // aria-expanded={ariaExpanded}
                    // aria-controls={`faq${index + 1}_desc`}
                    data-qa="faq__question_button"
                    className={`${st.faq__question_button} ${open ? st.faq__question_button_active : ''} `}
                    onClick={handleClick}
                >
                    <span>{title}</span>
                </button>
            </dt>
            <dd>
                <div
                    data-qa="faq__desc"
                    ref={ref}
                    style={{
                        height: open ? height : 0,
                        marginBottom: ".5rem",
                    }}
                    className={st.faq__desc}
                >
                    <div className={st.faq__desc_data}>
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">username</th>
                                    <th scope="col">nombre completo</th>
                                    <th scope="col">fideicomiso</th>
                                    {['comite', 'curso'].includes(orderBy) && <th scope="col">Cargo</th>}
                                    {['cargo', 'curso'].includes(orderBy) && <th scope="col">Comite</th>}
                                    {/* {orderBy !== 'curso' && <th scope="col">Curso</th>} */}
                                    {orderBy !== 'curso' && <th scope="col">Finalizados</th>}
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length && data.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.username}</td>
                                        <td>{item.nombre} {item.apellido}</td>
                                        <td>{item.fideicomiso}</td>
                                        {['comite', 'curso'].includes(orderBy) && <td>{item.cargo}</td>}
                                        {['cargo', 'curso'].includes(orderBy) && <td>{item.comite}</td>}
                                        {/* {orderBy !== 'curso' && <td>{item.curso}</td>} */}
                                        {orderBy !== 'curso' && <td>{item.finalizados}</td>}
                                        <td>{item.fecha}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </dd>
        </div>
        
    )
}

export const TableCalifi = ({ orderBy, data, title }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0)
    const ref = useRef()

    const handleClick = () => {
        setHeight(ref.current.scrollHeight)
        setOpen(!open);
    }

    return (
        <div>
            <dt>
                <button
                    // aria-expanded={ariaExpanded}
                    // aria-controls={`faq${index + 1}_desc`}
                    data-qa="faq__question_button"
                    className={`${st.faq__question_button} ${open ? st.faq__question_button_active : ''} `}
                    onClick={handleClick}
                >
                    <span>{title}</span>
                </button>
            </dt>
            <dd>
                <div
                    data-qa="faq__desc"
                    ref={ref}
                    style={{
                        height: open ? height : 0,
                        marginBottom: ".5rem",
                    }}
                    className={st.faq__desc}
                >
                    <div className={st.faq__desc_data}>
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">username</th>
                                    <th scope="col">nombre completo</th>
                                    <th scope="col">fideicomiso</th>
                                    {['comite', 'curso'].includes(orderBy) && <th scope="col">Cargo</th>}
                                    {['cargo', 'curso'].includes(orderBy) && <th scope="col">Comite</th>}
                                    {orderBy !== 'curso' && <th scope="col">Curso</th>}
                                    <th scope="col">Calificación</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length && data.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.username}</td>
                                        <td>{item.nombre} {item.apellido}</td>
                                        <td>{item.fideicomiso}</td>
                                        {['comite', 'curso'].includes(orderBy) && <td>{item.cargo}</td>}
                                        {['cargo', 'curso'].includes(orderBy) && <td>{item.comite}</td>}
                                        {orderBy !== 'curso' && <td>{item.curso}</td>}
                                        <td>{parseFloat(item.calificacion || 0)}</td>
                                        <td>{item.fecha}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </dd>
        </div>
        
    )
}


export const TableTodos = ({ title, data, filtro, dataAux }) => {
    const [open, setOpen] = useState(false);
    const [height, setHeight] = useState(0)
    const ref = useRef()

    const handleClick = () => {
        setHeight(ref.current.scrollHeight)
        setOpen(!open);
    }

    return (
        <div>
            <dt>
                <button
                    data-qa="faq__question_button"
                    className={`${st.faq__question_button} ${open ? st.faq__question_button_active : ''} `}
                    onClick={handleClick}
                >
                    <span>{title}</span>
                </button>
            </dt>
            <dd>
                <div
                    data-qa="faq__desc"
                    ref={ref}
                    style={{
                        height: open ? height : 0,
                        marginBottom: ".5rem",
                    }}
                    className={st.faq__desc}
                >
                    <div className={st.faq__desc_data}>
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{filtro}</th>
                                    <th scope="col">porcentaje</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length && data.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item}</td>
                                        <td>{dataAux[item]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </dd>
        </div>
        
    )
}