import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faArchive, faComment, faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../../store/actions/courses';
/*import evaluacion from '../../../../assets/img/cursos/evaluacion.png'
import defaultImg from '../../../../assets/img/dafi.png';
import SubMenu from '../SubMenu/SubMenu';
 */


const URL = ({ course, onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const modulos = useSelector(state => state.courses.courses_modules)

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: true,
        course: {}
    });

    useEffect(() => {

    }, []);


    const validate_add = Yup.object().shape({
        fullname: Yup.string('Escribe el nombre de la actividad')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        url: Yup.string('Agrega una url valida')
            .url()
            .required('Agrega una url valida'),
    });

    const formik = useFormik({
        initialValues: {
            fullname: '',
            url: ''
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async values => {
        values.courseid = course.id;
        let result = await courses_actions.add_activity_url_course(values);
        dispatch(courses_actions.get_contents_course(course.id));
        onCloseSubMenu(false);
    }
    const clickSubMenu = (type, open_param, cohorte) => {
        setOpenSubMenu({
            type: document.querySelector('#typeresource').selectedOptions[0].value.toUpperCase(),
            open: open_param,
            cohorte: cohorte
        })
    }

    return (
        <div>
            <div>
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label htmlFor="">Nombre de la actividad</label>
                        <input onChange={formik.handleChange} type="text" name="fullname" placeholder="Nombre de la actividad" />
                        <p className="text-red-600 text-sm">{formik.errors.fullname ? <div>{formik.errors.fullname}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">URL de la Actividad</label>
                        <input onChange={formik.handleChange} type="text" name="url" placeholder="Url de la actividad" />
                        <p className="text-red-600 text-sm">{formik.errors.url ? <div>{formik.errors.url}</div> : null}</p>

                    </div>
                    <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-36 hover:bg-opacity-100`}>Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default URL;
