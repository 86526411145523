import { badges_constants } from '../constants/badges';


const initialState = {
    badges: [],
    loading_badges: null,
    error_badges: null,

    loading_badges_create: null,
    badges_create: null,
    error_badges_create: null,
};

export function badges_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET badges--------------//
        case badges_constants.BADGES_REQUEST:
            return {
                ...state,
                loading_badges: action.payload,
            };
        case badges_constants.BADGES_SUCCESS:
            return {
                ...state,
                loading_badges: false,
                badges: action.payload,
                error_badges: null
            };
        case badges_constants.BADGES_FAILURE:
            return {
                ...state,
                loading_badges: false,
                error_badges: action.payload
            };
        //-------------CREATE badges-----------------
        case badges_constants.BADGES_CREATE_REQUEST:
            return {
                ...state,
                loading_badges_create: action.payload,
            };
        case badges_constants.BADGES_CREATE_SUCCESS:
            return {
                ...state,
                loading_badges_create: false,
                badges_create: action.payload,
                error_badges_create: null
            };
        case badges_constants.BADGES_CREATE_FAILURE:
            return {
                ...state,
                loading_badges_create: false,
                error_badges_create: action.payload
            };
        // ------------- EDIT COHORTE ----------
        case badges_constants.BADGES_EDIT_REQUEST:
            return {
                ...state,
                loading_badges_edit: action.payload,
            };
        case badges_constants.BADGES_EDIT_SUCCESS:
            return {
                ...state,
                loading_badges_edit: false,
                badges_edit: action.payload,
                error_badges_edit: null
            };
        case badges_constants.BADGES_EDIT_FAILURE:
            return {
                ...state,
                badges_edit: 0,
                loading_badges_edit: false,
                error_badges_edit: action.payload
            };

        //------------DELETE badges--------------//
        case badges_constants.BADGES_DELETE_REQUEST:
            return {
                ...state,
                loading_badges_delete: action.payload,
                badge_delete: null,
                error_badge_delete: null
            };
        case badges_constants.BADGES_DELETE_SUCCESS:
            return {
                ...state,
                loading_badges_delete: false,
                badge_delete: action.payload,
                error_badge_delete: false
            };
        case badges_constants.BADGES_DELETE_FAILURE:
            return {
                ...state,
                loading_badges_delete: false,
                badge_delete: 0,
                error_badge_delete: action.payload
            };
        default:
            return state
    }
}