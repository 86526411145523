
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';
import { filterCertifi } from '../../helpers/filter_certi';

export const attempts_scorm_service = {
    get_all_attempts_scorm,
    delete_attempt_scorm
};

//let user = JSON.parse(localStorage.getItem('user'));


async function get_all_attempts_scorm (idScorm) {
    const request_attempts_scorm = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_scorm_attempts_list&
    &moodlewsrestformat=json&
    scormid=${idScorm}`);

    if (Array.isArray(request_attempts_scorm.data)) {
        const attempts_scorm = request_attempts_scorm.data;
        return { attempts_scorm };
    }

    const error = request_attempts_scorm.data;
    return { error };
}


async function delete_attempt_scorm (row, idScorm) {
    try {
        // 1. elimina los intentos realizados
        const request_attempt_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
        wsfunction=targett_delete_scorm_attempts&
        moodlewsrestformat=json&
        scormattempts[0][userid]=${row.userid}&
        scormattempts[0][scormid]=${idScorm}&
        scormattempts[0][attempt]=${row.numberatt}`);

        if (typeof request_attempt_delete.data === 'object') {

            if (row.reportedstatus=='completed') {

                // 2. Obtiene el certificado del curso (se obtienen todos los del usuario - ver la doc)
                const certifi = await api_iac.post(`webservice/rest/server.php?wstoken=${user.token}&
                wsfunction=mod_customcert_get_user_certificates_custom&
                moodlewsrestformat=json&
                userid=${row.userid}`)

                // 3. obtenemos solo el certificado del curso
                const courseid = filterCertifi(
                    parseInt(localStorage.getItem('course')), 
                    certifi.data.issues
                )

                if (courseid) {
                    // 3.1 Eliminamos el certificado
                    await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
                    wsfunction=mod_customcert_delete_issue&
                    moodlewsrestformat=json&
                    certificateid=${courseid.certificateid}&
                    issueid=${courseid.issueid}
                    `)
                }

                // 4. Eliminamos los registros de la tabla de log
                await api_iac.post('reportador/api.php?q=deleteLogAvance', { userid: parseInt(row.userid), courseid: parseInt(localStorage.getItem('course')) })
                await api_iac.post('reportador/api.php?q=deleteLogViewed', { userid: parseInt(row.userid), courseid: parseInt(localStorage.getItem('course')) })
                await api_iac.post('reportador/api.php?q=deleteLogGradeHistory', { userid: parseInt(row.userid), courseid: parseInt(localStorage.getItem('course')) })
            }

            return 1;

        } else { return 0; }
    } catch (error) {
        console.log(error)
        return 0
    }
}
