import { attempts_quiz_constants } from '../constants/attemptQuiz';


const initialState = {
    attempts_quiz: [],
    loading_attempts_quiz: null,
    error_attempts_quiz: null,

    loading_attempts_quiz_delete: false,
    attempts_quiz_delete: false,
    error_attempts_quiz_delete: false,
};

export function attempts_quiz_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET ATTEMPTS_QUIZ--------------//
        case attempts_quiz_constants.ATTEMPTS_QUIZ_REQUEST:
            return {
                ...state,
                loading_attempts_quiz: action.payload,
            };
        case attempts_quiz_constants.ATTEMPTS_QUIZ_SUCCESS:
            return {
                ...state,
                loading_attempts_quiz: false,
                attempts_quiz: action.payload,
                error_attempts_quiz: null
            };
        case attempts_quiz_constants.ATTEMPTS_QUIZ_FAILURE:
            return {
                ...state,
                loading_attempts_quiz: false,
                error_attempts_quiz: action.payload
            };

        //------------DELETE ATTEMPTS_QUIZ--------------//
        case attempts_quiz_constants.ATTEMPTS_QUIZ_DELETE_REQUEST:
            return {
                ...state,
                loading_attempts_quiz_delete: action.payload,
                attempts_quiz_delete: null,
                error_attempts_quiz_delete: null
            };
        case attempts_quiz_constants.ATTEMPTS_QUIZ_DELETE_SUCCESS:
            return {
                ...state,
                loading_attempts_quiz_delete: false,
                attempts_quiz_delete: action.payload,
                error_attempts_quiz_delete: false
            };
        case attempts_quiz_constants.ATTEMPTS_QUIZ_DELETE_FAILURE:
            return {
                ...state,
                loading_attempts_quiz_delete: false,
                attempts_quiz_delete: 0,
                error_attempts_quiz_delete: action.payload
            };
        default:
            return state
    }
}