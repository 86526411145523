import { events_constants } from '../constants/calendar';


const initialState = {
    events: [],
    loading_events: null,
    error_events: null,

    loading_events_create: null,
    events_create: null,
    error_events_create: null,

    loading_events_edit: null,
    events_edit: null,
    error_events_edit: null,

    loading_events_delete: false,
    events_delete: false,
    error_events_delete: false
};

export function calendar_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET events--------------//
        case events_constants.EVENTS_REQUEST:
            return {
                ...state,
                loading_events: action.payload,
            };
        case events_constants.EVENTS_SUCCESS:
            return {
                ...state,
                loading_events: false,
                events: action.payload,
                error_events: null
            };
        case events_constants.EVENTS_FAILURE:
            return {
                ...state,
                loading_events: false,
                error_events: action.payload
            };

        //-------------CREATE COHORTE-----------------
        case events_constants.EVENTS_CREATE_REQUEST:
            return {
                ...state,
                loading_events_create: action.payload,
            };
        case events_constants.EVENTS_CREATE_SUCCESS:
            return {
                ...state,
                loading_events_create: false,
                events_create: action.payload,
                error_events_create: null
            };
        case events_constants.EVENTS_CREATE_FAILURE:
            return {
                ...state,
                loading_events_create: false,
                error_events_create: action.payload
            };

        // ------------- EDIT COHORTE ----------
        case events_constants.EVENTS_EDIT_REQUEST:
            return {
                ...state,
                loading_events_edit: action.payload,
            };
        case events_constants.EVENTS_EDIT_SUCCESS:
            return {
                ...state,
                loading_events_edit: false,
                events_edit: action.payload,
                error_events_edit: null
            };
        case events_constants.EVENTS_EDIT_FAILURE:
            return {
                ...state,
                events_edit: 0,
                loading_events_edit: false,
                error_events_edit: action.payload
            };

        //------------DELETE COHORTE--------------//
        case events_constants.EVENTS_DELETE_REQUEST:
            return {
                ...state,
                loading_events_delete: action.payload,
                events_delete: null,
                error_events_delete: null
            };
        case events_constants.EVENTS_DELETE_SUCCESS:
            return {
                ...state,
                loading_events_delete: false,
                events_delete: action.payload,
                error_events_delete: false
            };
        case events_constants.EVENTS_DELETE_FAILURE:
            return {
                ...state,
                loading_events_delete: false,
                events_delete: 0,
                error_events_delete: action.payload
            };
        default:
            return state
    }
}