import React from 'react';
import Login from '../Pages/Auth/Login';
// Enrutamiento
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { PrivateRoute } from '../Components/PrivateRoute/PrivateRoute';
import { history } from '../store/helpers/history';
import Inicio from '../Pages/Dashboard/Inicio';
import Forum from '../Components/Aside/DetallesMenu/Curso/Forum';
import Communication from '../Components/Aside/DetallesMenu/Curso/Communication';

import AttemptScorm from '../Components/Aside/DetallesMenu/Curso/AttemptScorm';
import AttemptQuiz from '../Components/Aside/DetallesMenu/Curso/AttemptQuiz';




const RouterReact = () => {
    return (
        <Router basename="/" history={history}>
            <main style={{ minHeight: '50vh' }}>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <PrivateRoute exact path="/" component={Inicio} />
                    <PrivateRoute exact path="/dashboard" component={Inicio} />
                    <PrivateRoute exact path="/forum/:forumid" component={Forum} />
                    <PrivateRoute exact path="/communication/:communicationid" component={Communication} />

                    <PrivateRoute exact path="/attemptscorm/:scormid" component={AttemptScorm} />
                    <PrivateRoute exact path="/attemptquiz/:quizid" component={AttemptQuiz} />
                    
                    <Redirect from="*" to="/" />
                </Switch>
            </main>
        </Router>
    );
}

export default RouterReact;