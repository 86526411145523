export const users_constants = {
    USERS_REQUEST: 'USERS_REQUEST',
    USERS_SUCCESS: 'USERS_SUCCESS',
    USERS_FAILURE: 'USERS_FAILURE',

    USER_EDIT_REQUEST: 'USER_EDIT_REQUEST',
    USER_EDIT_SUCCESS: 'USER_EDIT_SUCCESS',
    USER_EDIT_FAILURE: 'USER_EDIT_FAILURE',

    USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
    USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',

    USERS_CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    USERS_CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    USERS_CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
    USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
    
    USERS_SEARCH_REQUEST: 'USERS_SEARCH_REQUEST',
    USERS_SEARCH_SUCCESS: 'USERS_SEARCH_SUCCESS',
    USERS_SEARCH_FAILURE: 'USERS_SEARCH_FAILURE',
}