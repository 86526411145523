export const adscritos = {
    "REPRESENTANTE DE SOCIO":1,
    "ASISTENTE REGIONAL":2,
    "COMITE TECNICO":3,
    "PATRONATO" : 4,
    "CONSEJO DE VIGILANCIA" : 5,
    "COMISION DE AUDITORIA" : 6,
    "COMISION DE ESTILO Y REDACCION" : 7,
    "COMITÉ TÉCNICO":3,
    "REPRESENTANTES DE SOCIOS":1,
}
