import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faArchive, faComment, faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../../store/actions/courses';

const Resource = ({ course, onCloseSubMenu, type }) => {
    const dispatch = useDispatch();

    const [fecha, setFecha] = useState('');
    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: true,
        course: {}
    });

    useEffect(() => {
        let toDay = new Date();
        let year = toDay.getFullYear();
        let month = (toDay.getMonth() + 1) < 10 ? `0${(toDay.getMonth() + 1)}` : toDay.getMonth();
        let day = toDay.getDate();

        let hour = toDay.getHours();
        let minut = toDay.getMinutes() < 10 ? `0${toDay.getMinutes()}` : toDay.getMinutes();
        setFecha(`${year}-${month}-${day}T${hour}:${minut}`);

    }, []);


    const validate_add = Yup.object().shape({
        fullname: Yup.string('Escribe el nombre de la actividad')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            fullname: '',
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async actividad => {
        actividad.courseid = course.id
        console.log('actividad', actividad);
        console.log('scorm', type)
        if (type === 'SCORM') {
            await courses_actions.add_activity_scorm_course(actividad);
        } else {
            await courses_actions.add_activity_resource_course(actividad);
        }
        dispatch(courses_actions.get_contents_course(course.id));
        onCloseSubMenu(false);
    }
    const clickSubMenu = (type, open_param, cohorte) => {
        console.log(document.querySelector('#typeresource').selectedOptions[0].value);
        setOpenSubMenu({
            type: document.querySelector('#typeresource').selectedOptions[0].value.toUpperCase(),
            open: open_param,
            cohorte: cohorte
        })
    }

    return (
        <div>
            <div>
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label htmlFor="">Nombre de la actividad</label>
                        <input onChange={formik.handleChange} type="text" name="fullname" placeholder="Nombre de la actividad" value={formik.values.fullname} />
                        <p className="text-red-600 text-sm">{formik.errors.fullname ? <div>{formik.errors.fullname}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Recurso</label>
                        <input type="file" name="actividad"
                            onChange={e => {
                                formik.setFieldValue('file', e.target.files[0])
                            }}
                        />
                    </div>
                    {
                        type === 'SCORM1' ? <>
                            <div className="campo">
                                <label htmlFor="">Fecha de inicio</label>
                                <input onChange={formik.handleChange} type="datetime-local" name="startdate" value={fecha} />
                            </div>
                            <div className="campo">
                                <label htmlFor="">Fecha de cierre</label>
                                <input onChange={formik.handleChange} type="datetime-local" name="startdate" />
                            </div>
                        </> : null

                    }
                    <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-36 hover:bg-opacity-100`}>Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default Resource;