import React from 'react';

const SubMenu = (props) => {
    return (
        <div className="w-screen absolute top-0 right-6 z-10 rounded-r-xl">
            {props.children}
        </div>
    );
}

export default SubMenu;