import React from 'react';
import editar_usuario from '../../../../assets/img/user/user_edit.png'

const User = ({ user, clickSubMenu }) => {
    return (
        <div className="bg-iacblack text-white uppercase mb-4 border border-blue-300 py-3 px-2 rounded-md">
            <div className="w-full flex items-center">
            <div className="w-1/4 mr-5"> <img className="rounded-md overflow-hidden" src={user.profileimageurlsmall} alt="" style={{ width: '100%', height: '1000%', objectFit: 'cover' }}/> </div>
                <div className="w-3/4">
                    <p className="mb-1 text-xs">{user.idnumber}</p>
                    <p className="mb-1 text-md">{user.fullname}</p>
                    <p className="mb-1 text-xs">{user.employment}</p>
                    {/* <p className="mb-1 text-xs">{user.workplace}</p> */}
                    <hr className="mb-1"/>
                    <p className="mb-1 text-xs">{user.area}</p>
                </div>
            </div>
            <div onClick={() => clickSubMenu()} className="flex items-center justify-end cursor-pointer">
                <p className="mr-2 text-xs">Editar</p>
            </div>
        </div>
    );
}



export default User;