import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
// import TimezoneSelect from 'react-timezone-select'
import momentTZ from 'moment-timezone';

import { meetings_actions } from '../../../../../store/actions/meetings';
import SubMenuAddUserMeeting from '../../SubMenu/SubMenuAddUserMeeting';
import api_iac from '../../../../../config/instance_axios';
import { user } from '../../../../../config/user_token';

import AddusersMeeting from './AddUsersMeeting';
/* import Select from 'react-select'; */
const timeZonesList = momentTZ.tz.names();

const EditarMeeting = ({ meeting, onCloseSubMenu, hideShadow }) => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories);

    const [hoursDuration, sethoursDuration] = useState(0);
    const [minutesDuration, setminutesDuration] = useState(0);
    const [formatStarttime, setformatStarttime] = useState(0);
    const [timezone, setTimezone] = useState('');

    const id_zoom = meeting.id_zoom;

    let hours = 0;
    let minutes = 0;
    let formatTime = 0;

    hours = Math.floor(meeting.duration / 60);
    minutes = meeting.duration % 60;
    formatTime = moment(meeting.start_time).format("YYYY-MM-DD[T]HH:mm:ss");

    useEffect(() => {
        sethoursDuration(hours);
        setminutesDuration(minutes);
        formik.setFieldValue('start_time', formatTime)
        setformatStarttime(formatTime);
        formik.setFieldValue('timezone', meeting.timezone)
        setTimezone(meeting.timezone);
    }, []
    );
    let categoria = new Array();

    const orderCategori = function (categoriasuperior, path) {

        if (categoriasuperior.children.length > 0) {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
            categoriasuperior.children.map(categoriachildren => {
                if (categoriachildren.id != meeting.id_category) {
                    orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
                }

            });
        } else {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        }
    }

    categories.categories.map(categoriesraiz => {

        if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children') && meeting.id_category != categoriesraiz.id) {

            orderCategori(categoriesraiz, `${categoriesraiz.name}`);
        }

    });
    const users = useSelector(state => state.users);
    const user_meetings = users.users.map((option) => ({
        ...option,
        label: option.fullname,
        value: option.id,
        meetingid: option.meetingid
    }));

    const [user_meetings_select, set_user_meetings_select] = useState([]);
    // const [user_meetings_members, set_user_meetings_select] = useState([]);
    const meetings_members = async () => {
        const request_meetings_members = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_meeting_get_meeting_members&moodlewsrestformat=json&meetingids[0]=${meeting.id}`);

        const temp = [];

        request_meetings_members.data[0].userids.forEach((item) => {

            const idx = user_meetings.find(user => user.id === item);
            temp.push(idx);
        });

        set_user_meetings_select(temp);
    }

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        meeting: {}
    });

    const clickSubMenu = (type, open_param, meeting) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            meeting: meeting
        })
    }

    const validate_edit = Yup.object().shape({
        topic: Yup.string('Escribe el nombre para la conferencia')
            .min(1, 'Debe ser mayor a 1 caracter')
            //.max(40, 'Debe ser menor a 40 caracteres')
            .required('El nombre es requerido'),
        start_time: Yup.string('Selecciona una fecha para la conferencia')
            .required('La fecha es requerida'),
        password: Yup.string('Escribe una contraseña de acceso para la conferencia')
            .required('La contraseña es requerida')
    });

    const validateData = values => {
        let errors = {};

        if (parseInt(values.hour) === 0 && parseInt(values.minute) === 0) {
            errors.minute = 'La duracion de la conferencia es requerida';
        }
        if (values.timezone === '') {
            errors.timezone = 'La zona horaria es requerida';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: { ...meeting, hour: hours, minute: minutes },
        validationSchema: validate_edit,
        validate: validateData,

        onSubmit: values => {
            const duration = convertHoursToMinutes(values.hour, values.minute);
            values.duration = duration;
            if (values.timezone === '') {
                values.timezone = values.timezone;
            }

            handleSubmit(values)
        },
    });

    const handleSubmit = async (meeting) => {
        await meetings_actions.meetings_edit(meeting, id_zoom);
        onCloseSubMenu(false);
        dispatch(meetings_actions.get_all_meetings());
    }

    function convertHoursToMinutes(hour, minute) {
        const hours = hour;
        const minutes = + minute;

        let total = hours * 60 + minutes;
        return total;
    }

    // Eliminar videoconferencia
    const handleClickDelete = async () => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar la conferencia?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed == true) {
            await meetings_actions.meetings_delete(meeting);
            onCloseSubMenu(false);
            dispatch(meetings_actions.get_all_meetings());
        }
    }


    return (
        <div>
            <div>
                {openSubMenu.open && !hideShadow ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null}
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label className="text-md" htmlFor="topic">Nombre de la conferencias</label>
                        <input className="rounded-md" onChange={formik.handleChange} type="text" id="topic" name="topic" placeholder="" value={formik.values.topic} />
                        <p className="text-red-600 text-sm">{formik.errors.topic ? <div>{formik.errors.topic}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="text-md" htmlFor="start_time">Fecha de la conferencias</label>
                        <input className="rounded-md" onChange={
                            (e) => {
                                setformatStarttime(e.target.value);
                                formik.setFieldValue('start_time', e.target.value);
                            }
                        } type="datetime-local" id="start_time" name="start_time" placeholder="" value={formatStarttime} />
                        <p className="text-red-600 text-sm">{formik.errors.start_time ? <div>{formik.errors.start_time}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="text-md" htmlFor="">Duracion de la conferencias</label>
                        <div className="inline-flex items-baseline">
                            <select className="w-full rounded-md" onChange={formik.handleChange} name="hour">
                                <option value={0} selected={hoursDuration === 0 ? true : false}>{0}</option>
                                <option value={1} selected={hoursDuration === 1 ? true : false}>{1}</option>
                                <option value={2} selected={hoursDuration === 2 ? true : false}>{2}</option>
                                <option value={3} selected={hoursDuration === 3 ? true : false}>{3}</option>
                                <option value={4} selected={hoursDuration === 4 ? true : false}>{4}</option>
                                <option value={5} selected={hoursDuration === 5 ? true : false}>{5}</option>
                                <option value={6} selected={hoursDuration === 6 ? true : false}>{6}</option>
                                <option value={7} selected={hoursDuration === 7 ? true : false}>{7}</option>
                                <option value={8} selected={hoursDuration === 8 ? true : false}>{8}</option>
                                <option value={9} selected={hoursDuration === 9 ? true : false}>{9}</option>
                                <option value={10} selected={hoursDuration === 10 ? true : false}>{10}</option>
                                <option value={11} selected={hoursDuration === 11 ? true : false}>{11}</option>
                                <option value={12} selected={hoursDuration === 12 ? true : false}>{12}</option>
                                <option value={13} selected={hoursDuration === 13 ? true : false}>{13}</option>
                                <option value={14} selected={hoursDuration === 14 ? true : false}>{14}</option>
                                <option value={15} selected={hoursDuration === 15 ? true : false}>{15}</option>
                                <option value={16} selected={hoursDuration === 16 ? true : false}>{16}</option>
                                <option value={17} selected={hoursDuration === 17 ? true : false}>{17}</option>
                                <option value={18} selected={hoursDuration === 18 ? true : false}>{18}</option>
                                <option value={19} selected={hoursDuration === 19 ? true : false}>{19}</option>
                                <option value={20} selected={hoursDuration === 20 ? true : false}>{20}</option>
                                <option value={21} selected={hoursDuration === 21 ? true : false}>{21}</option>
                                <option value={22} selected={hoursDuration === 22 ? true : false}>{22}</option>
                                <option value={23} selected={hoursDuration === 23 ? true : false}>{23}</option>
                                <option value={24} selected={hoursDuration === 24 ? true : false}>{24}</option>
                            </select>
                            <span className="pl-2 pr-2">h</span>
                            <select className="w-full rounded-md" onChange={formik.handleChange} name="minute">
                                <option value={0} selected={minutesDuration === 0 ? true : false}>{0}</option>
                                <option value={15} selected={minutesDuration === 15 ? true : false}>{15}</option>
                                <option value={30} selected={minutesDuration === 30 ? true : false}>{30}</option>
                                <option value={45} selected={minutesDuration === 45 ? true : false}>{45}</option>
                            </select>
                            <span className="pl-2">min.</span>
                        </div>
                        <span className="text-red-600 text-sm">{formik.errors.hour ? <div>{formik.errors.hour}</div> : null}</span>
                        <span className="text-red-600 text-sm">{formik.errors.minute ? <div>{formik.errors.minute}</div> : null}</span>
                    </div>
                    <div className="campo">
                        <label className="text-md" htmlFor="">Zona horaria de la conferencias</label>
                        {/* <TimezoneSelect
                            value={timezone}
                            onChange={
                                (option) => {
                                    formik.setFieldValue('timezone', option);
                                    setTimezone(option);
                                }
                            }
                            className="rounded-md border-black"
                            style={{border: '1px solid #000'}}
                        /> */}
                        <select value={timezone} onChange={
                                (option) => {
                                    formik.setFieldValue('timezone', option.target.value);
                                    setTimezone(option.target.value);
                                }
                            }>
                            {timeZonesList.map((item, index) => (<option key={index}>{item}</option>))}
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.timezone ? <div>{formik.errors.timezone}</div> : null}</p>
                    </div>
                    <div className="hidden"><input type="text" id="hidin" name="hidin" /></div>
                    <div className="campo">
                        <label className="text-md" htmlFor="">Contraseña de la conferencias</label>
                        <input className="rounded-md" onChange={formik.handleChange} type="password" id="password" name="password" placeholder="" value={formik.values.password} />
                        <p className="text-red-600 text-sm">{formik.errors.password ? <div>{formik.errors.password}</div> : null}</p>
                    </div>

                    <div className="block-inline text-center ">
                        <button type="button" style={{ maxWidth: "45%" }}
                            className="mr-2 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-2 py-2 w-48 border border-blue-300 hover:bg-opacity-100"
                            onClick={() => clickSubMenu('Agregar Usuarios', !openSubMenu.open)}>
                            Agregar Usuarios
                        </button>
                        <button type="submit" style={{ maxWidth: "45%" }}
                            className="mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-40 border border-blue-300 hover:bg-opacity-100">Guardar</button>
                        
                        <button type="button" name='eliminar' onClick={handleClickDelete} style={{ maxWidth: "45%" }}
                            className="mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-40 border border-blue-300 hover:bg-opacity-100 ml-2 mt-2">Eliminar</button>
                    </div>
                </form>
            </div>
            {openSubMenu.open ?
                <SubMenuAddUserMeeting width={hideShadow ? '100%' : ''}>
                    <div>
                        <div className="ml-2 flex justify-between items-center text-2xl p-4">
                            <p >{openSubMenu.type}</p>
                            <div className="flex"
                                onClick={() => setOpenSubMenu(false)}>
                                <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute top-0" />
                            </div>
                        </div>
                    </div>
                    <AddusersMeeting meeting={meeting}></AddusersMeeting>
                </SubMenuAddUserMeeting>
                : null}
        </div>
    );
}

export default EditarMeeting;
