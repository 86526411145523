
export const numerosfideicomisos = {
    '1': 'FIGLOSNTE 2',
    '2': 'FIGLOSNTE 8',
    '3': 'FIGLOSNTE 13',
    '4': 'FIGLOSNTE 16',
    '5': 'FIGLOSNTE 23',
    '6': 'FIGLOSNTE 25',
    '7': 'FIGLOSNTE 26',
    '8': 'FIGLOSNTE 32',
    '9': 'FIGLOSNTE 33',
    '10': 'FIGLOSNTE 47',
    '11': 'FIGLOSNTE 53',
    '12': 'FIGLOSNTE 55',
    '13': 'FIGLOSNTE 56',
    '14': 'FIGLOSTASE',
    '15': 'FIGLOSNTSA',
    '16': 'JUBICOM 16',
    '17': 'JUBICOM 27',
    '18': 'FIGLOSNTE 27'
}