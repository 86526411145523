import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import CSVReader from 'react-csv-reader'
import { CSVLink, CSVDownload } from "react-csv";

//import agregar from '../../../assets/img/user/agregar.png'
//import buscar_usuario from '../../../assets/img/user/buscar_usuario.svg'
//import agredada_masiva from '../../../assets/img/user/agregada_masiva.png'
import { users_actions } from '../../../store/actions/users';
import Header from './Header';
import SubMenu from './SubMenu/SubMenu';
import AgregarUser from './Usuario/AgregarUser';
// import EditarUser from './Usuario/EditarUser';
import EditUser from './Usuario/EditUser';
import User from './Usuario/User';
import UserCsv from './Usuario/UserCsv';

 import { numerosfideicomisos } from '../../../store/constants/fideicomisos.js';
 import { comitenums } from '../../../store/constants/comitenums.js';
import { nationalocupations } from '../../../store/constants/nationalocupations.js';
import api_iac from '../../../config/instance_axios';
// import { adscritos } from '../../../store/constants/adscritos';

import { user as userToken } from '../../../config/user_token';

const Usuarios = ({ onClose }) => {
    const dispatch = useDispatch();

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        user: {}
    });

    let user = {
        firstname: '',
        lastname: '',
        username: '',
        employment: '',
        area: '',
        workplace: '',
        fideicomisonum: '',
        comitenum: '',
        tipofideicomiso: '',
        fechainicio: '',
        fechavigencia: '',
        dateadmission: '',
        age: '',
        gender: '',
        password: '',
        email: '',
        curp: '',
        niveldesarrollo: '',
        nationalocupation: '',
    }

    const [dataCsv, setDataCsv] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const closeModalCsv = async () => {
        await dispatch(users_actions.users_add_csv_reset());
        setShowModal(false)
        document.getElementById('csvReader').value = '';
        //onClose();
    }

    const users = useSelector(state => state.users);
    const [busquedaRes, guardarBusquedadRes] = useState([]);
    const [busquedaShow, guardarBusquedadShow] = useState(false);

    //useEffect(() => {
        //console.log(users.users.length);
        //guardarBusquedadRes(users.users)
    //}, [users]);

    const get_user_to_edit = async (userid) => {
        var ll_user = await users_actions.get_user(userid);
        if (!ll_user) return null;
        console.log(ll_user);
        if ( (Array.isArray(ll_user)) && (ll_user.length) ) {
            ll_user = ll_user[0];
        }
        
        if (ll_user.id===userid) {
            return ll_user;
        }

        return null;

    }

    const clickSubMenu = (type, open_param, user) => {
        if ( (type==='Editar') && (open_param) ) {
            console.log(user);
            get_user_to_edit(user.id).then((l_user) => {
                //console.log(l_user);

                setOpenSubMenu({
                    type: type,
                    open: open_param,
                    user: user
                });

            });
        } else {

        
            setOpenSubMenu({
                type: type,
                open: open_param,
                user: user
            });
        }
    }

    const handleChange = async e => {
        if (e.target.value.length >= 2) {
            let typeseach = document.querySelector("#typeseach").selectedOptions[0].value;
            let searchuser = e.target.value;
            await searchUser(searchuser, typeseach);
        }
    }

    const searchUser = async (search, typeseach) => {
        //Esperar un segundo antes de realizar la busqueda
        if (search !== '') {

            //Si ya hay una busqueda en proceso, cancelarla
            let result = await users_actions.get_user_field(search, typeseach);


            guardarBusquedadRes(result);
            guardarBusquedadShow(true);

        } else {
            guardarBusquedadRes([])
            guardarBusquedadShow(false)
        }


    }


    const getKeyByValue = function( l_obj, l_value ) {
        for( var prop in l_obj ) {
            if( l_obj.hasOwnProperty( prop ) ) {
                 if( l_obj[ prop ] === l_value )
                     return prop;
            }
        }

        return -1;
    }

    var accent_map = {'á':'a', 'é':'e', 'è':'e', 'í':'i','ó':'o','ú':'u','Á':'a', 'É':'e', 'è':'e', 'Í':'i','Ó':'o','Ú':'u'};
    function accent_fold (s) {
    if (!s) { return ''; }
    var ret = '';
    for (var i = 0; i < s.length; i++) {
        ret += accent_map[s.charAt(i)] || s.charAt(i);
    }
    return ret;
    };

    const AllUsersBtn = function() {

        //const [currentList, setCurrentList] = useState(null);
        const [aubLoading, setAUBLoading] = useState(false);

        async function userlistfromserver() {

            if (aubLoading) {
                return;
            }

            setAUBLoading(true);

            let formData = new FormData();

            formData.append('wstoken', userToken.token);
            formData.append('wsfunction', 'targett_get_user_list');
            formData.append('moodlewsrestformat', 'json');


            api_iac.post('webservice/rest/server.php', formData).then((httpresponse) => {

                console.debug(httpresponse.data);
                console.debug(httpresponse.data.users);
                console.debug(httpresponse.data.length);
                setAUBLoading(false);
                if ((httpresponse.data) && (httpresponse.data.users) && (httpresponse.data.users.length) ) {
                    //let llcsvData = [['firstname', 'lastname', 'username', 'employment', 'area', 'workplace', 'fideicomiso', 'comite', 'tipofideicomiso', 'fechainicio', 'fechavigencia', 'dateadmission', 'age', 'gender', 'email', 'curp', 'niveldesarrollo', 'nationalocupation']];

                    //httpresponse.data.forEach((llluser) => {llcsvData.push([llluser.firstname, llluser.lastname, llluser.username, llluser.employment, llluser.area, llluser.workplace, llluser.workplace, numerosfideicomisos[llluser.fideicomisonum], comitenums[llluser.comitenum], llluser.tipofideicomiso, (new Date(llluser.fechainicio * 1000)).toLocaleDateString(), (new Date(llluser.fechavigencia * 1000)).toLocaleDateString(), (new Date(llluser.dateadmission * 1000)).toLocaleDateString(), llluser.age, llluser.gender, llluser.email, llluser.curp, llluser.niveldesarrollo, nationalocupations[llluser.nationalocupation]]);});

                    //setCurrentList(llcsvData);

                    let lllcsvstring = '"firstname", "lastname", "username", "employment", "area", "workplace", "fideicomiso", "comite", "tipofideicomiso", "fechainicio", "fechavigencia", "dateadmission", "age", "gender", "email", "curp", "niveldesarrollo", "nationalocupation"';

                    httpresponse.data.users.forEach((llluser) => {lllcsvstring += "\r\n" + '"' + llluser.firstname + '","' + llluser.lastname + '","' + llluser.username + '","' + llluser.employment + '","' + ((llluser.area)?(llluser.area):("No especificado")) + '","' + ((llluser.workplace)?(llluser.workplace):("No especificado")) + '","' + llluser.fideicomiso + '","' + llluser.comite + '","' + llluser.tipofideicomiso + '","' + (new Date(llluser.fechainicio * 1000)).toLocaleDateString() + '","' + (new Date(llluser.fechavigencia * 1000)).toLocaleDateString() + '","' + (new Date(llluser.dateadmission * 1000)).toLocaleDateString() + '","' + llluser.age + '","' + ((llluser.gender)?(llluser.gender):("No especificado")) + '","' + llluser.email + '","' + ((llluser.curp)?(llluser.curp):("No especificado")) + '","' + ((llluser.niveldesarrollo)?(llluser.niveldesarrollo):("No especificado")) + '","' + llluser.nationalocupation + '"';});

                    //window.open("data:text/csv;charset=utf-8," + encodeURIComponent(lllcsvstring).replace("\r\n", "%0D%0A"), '_blank');

                    var downloadLink = document.createElement("a");
                    downloadLink.href = "data:text/csv;charset=utf-8," + encodeURIComponent(lllcsvstring).replace("\r\n", "%0D%0A");
                    downloadLink.target = "_blank";
                    downloadLink.download = "usuariosuniversidaddafi.csv";

                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);

                } else {
                    alert("Ocurrió un error al descargar el listado de usuarios");
                }
            });
        }

        return (<div onClick={userlistfromserver} className="m-2 flex items-center cursor-pointer">{aubLoading?"Descargando...":"Descargar listado completo de usuarios"}</div>);

    };

    const csvData = [
        ['firstname', 'lastname', 'username', 'employment', 'area', 'workplace', 'fideicomisonum', 'comitenum', 'tipofideicomiso', 'fechainicio', 'fechavigencia', 'dateadmission', 'age', 'gender', 'password', 'email', 'curp', 'niveldesarrollo', 'nationalocupation'],
        //["user", 'demo', 'demouser', 'COMITE TECNICO', 'VICEPRESIDENTE', 'Guadalajara', 'FIGLOSTASE', 'ORGANO ADMINISTRATIVO', 'FIGLO', '01/01/2022', '01/05/2022', '01/01/2022', 27, 'M', "12345pass", "userdemo@userdemo.com", "DEDU970901HVZMMS09", 'Lider', '02']
        //["Antonio", 'Pinto Bautista', '12345antonio', '2do. VOCAL', '2do. VOCAL', 'Guadalajara', 12, 7, 'FIGLO', '2022-01-01', '2022-04-01', '2022-01-01', 27, 'M', "12345", "12345antonio@figlosnte12.com", "DEDU970901HVZMMS09", 'Lider', 2]
        ["JOSE OTONIEL", "PONCE GARCIA", "21218", "REPRESENTANTE", "REPRESENTANTES DE SOCIOS", "PARRAL", "FIGLOSNTE 8", "ORGANO ADMINISTRATIVO", "FIGLO", "04/11/2019", "04/11/2023", "01/06/2022", 35, "M", "21218", "21218@figlosnte8.mx", "POGO870619HCHNRT07", "LIDER", "11.2"]
    ];
    return (
        <div>
            <UserCsv showModal={showModal} dataCsv={dataCsv} setShowModal={closeModalCsv} onCloseSubMenu={onClose} />

            <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll">
                {openSubMenu.open ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null}
                <Header title="Usuarios" onClose={onClose} />
                <div className="flex flex-row flex-wrap justify-end flex-col items-end">
                    <div onClick={() => clickSubMenu('Agregar', !openSubMenu.open)} className=" m-2 flex items-center cursor-pointer">
                        <p className="mr-1">Agregar Usuarios <span className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</span></p>
                    </div>
                    <div className=" m-2 flex items-center cursor-pointer relative">
                        <p className="mr-1">Agregar carga masiva <span className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</span></p>
                        <CSVReader
                            inputId="csvReader"
                            cssClass="absolute top-0 left-0 opacity-0"
                            parserOptions={{ header: true }}
                            onFileLoaded={(data, fileInfo) => {
                                
                                const temp = [];
                                data.forEach(element => {
                                    // var auxprop = -1;
                                    if (element.firstname !== "" && element.lastname !== "" && element.email !== ""
                                        && element.password !== "" && element.username !== "") {
                                        user.username = element.email.trim();
                                        user.email = element.email.trim();
                                        user.firstname = element.firstname;
                                        user.lastname = element.lastname;
                                        user.employment = element.employment;
                                        user.workplace = element.workplace;

                                        user.idnumber = element.username;
                                        
                                        //adscritos
                                        /*
                                        if ( (!Array.isArray( element.area )) && ((element.area - parseFloat( element.area ) + 1) >= 0) ) {
                                            user.area = element.area;
                                        } else {
                                            element.area = accent_fold(element.area);
                                            element.area = element.area.toUpperCase();
                                            auxprop = getKeyByValue(adscritos, element.area);
                                            if (auxprop !==-1) {
                                                //user.area = adscritos.indexOf(element.area);
                                                user.area = auxprop;
                                            } else {
                                                user.area = '';
                                            }
                                        }
                                        
                                        
                                        
                                        if ( (!Array.isArray( element.fideicomisonum )) && ((element.fideicomisonum - parseFloat( element.fideicomisonum ) + 1) >= 0) ) {
                                            user.fideicomisonum = element.fideicomisonum;
                                        } else {
                                            auxprop = getKeyByValue(numerosfideicomisos, element.fideicomisonum);
                                            if (auxprop !==-1) {
                                                //user.fideicomisonum = numerosfideicomisos.indexOf(element.fideicomisonum);
                                                user.fideicomisonum = auxprop;
                                            } else {
                                                user.fideicomisonum = '';
                                            }
                                        }*/
                                        user.area = element.area;
                                        user.fideicomisonum = element.fideicomisonum;


                                        /*if ( (!Array.isArray( element.comitenum )) && ((element.comitenum - parseFloat( element.comitenum ) + 1) >= 0) ) {
                                            user.comitenum = element.comitenum;
                                        } else {
                                            //console.log(element.comitenum);
                                            element.comitenum = accent_fold(element.comitenum);
                                            element.comitenum = element.comitenum.toUpperCase();
                                            //console.log(element.comitenum);
                                            auxprop = getKeyByValue(comitenums, element.comitenum);
                                            //console.log(auxprop);
                                            if (auxprop !== -1) {
                                                user.comitenum = auxprop;
                                            } else {
                                                user.comitenum = '';
                                            }
                                        }*/
                                        
                                        user.comitenum = element.comitenum;

                                        user.tipofideicomiso = element.tipofideicomiso;
                                        
                                        user.fechainicio = users_actions.date_string_to_epoch(element.fechainicio);
                                        user.fechavigencia = users_actions.date_string_to_epoch(element.fechavigencia);
                                        user.dateadmission = users_actions.date_string_to_epoch(element.dateadmission);

                                        user.age = element.age;
                                        user.gender = element.gender;
                                        user.password = element.password;
                                        user.curp = element.curp;
                                        user.niveldesarrollo = element.niveldesarrollo;

                                        //var lll_tmpocupation = element.nationalocupation.replace(/^0+/, '');
                                        //console.debug('*************');
                                        //console.debug(element);
                                        //console.debug(element.nationalocupation);
                                        //console.debug(lll_tmpocupation);
                                        //console.debug('*************');

                                        if (typeof(nationalocupations[element.nationalocupation])!=='undefined') {
                                            user.nationalocupation = element.nationalocupation;
                                        } else if (typeof(nationalocupations[element.nationalocupation.replace(/^0+/, '')])!=='undefined') {
                                            user.nationalocupation = element.nationalocupation.replace(/^0+/, '');
                                        } else if (typeof(nationalocupations[element.nationalocupation.replace(/^0+/, '').replace(/0+$/, '')])!=='undefined') {
                                            user.nationalocupation = element.nationalocupation.replace(/^0+/, '').replace(/0+$/, '');
                                        } else {
                                            user.nationalocupation = '';
                                        }
                                        // console.log('step 1');
                                        // console.log(user);

                                        temp.push(user);
                                        user = {};
                                    }
                                });

                                setDataCsv(temp);
                                setShowModal(true);
                            }}
                        />
                    </div>
                    <div className="m-2 flex items-center cursor-pointer relative p-1 rounded-md" style={{border: '1px solid #797974'}}>
                        <CSVLink data={csvData} filename={"demousers.csv"}>Descargar demo CSV</CSVLink>
                    </div>
                    <div className="m-2 flex items-center cursor-pointer relative p-1 rounded-md" style={{border: '1px solid #797974'}}>
                        <AllUsersBtn />
                    </div>
                </div>
                {
                    <div>
                        <label for="search" className="block  text-sm">Buscar usuario</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-600 sm:text-sm">
                                    <FontAwesomeIcon icon={faSearch} className="cursor-pointer opacity-80 hover:opacity-100 " />
                                </span>
                            </div>
                            <input onChange={handleChange} type="text" name="search" id="search" className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-md" style={{border: '1px solid #797974'}}/>
                            <div className="absolute inset-y-0 right-0 flex items-center" >
                                <label for="typeseach" className="sr-only">typeseach</label>
                                <select id="typeseach" name="typeseach" className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-600 sm:text-sm rounded-md">
                                    <option value="idnumber">Numero Empleado</option>
                                </select>
                            </div>
                        </div>
                    </div>
                }

                {busquedaShow ?
                    <div>
                        {busquedaRes.length >= 0 ?
                            <div className=" mt-5">
                                <p className="text-xs mb-1">Resultados: {busquedaRes.length}</p>
                                {busquedaRes.map(user => (
                                    <User key={user.id} user={user} clickSubMenu={() => clickSubMenu('Editar', !openSubMenu.open, user)} />
                                ))
                                }
                            </div>
                            : <p className="">No tienes usuarios agregados</p>}
                    </div>
                    : null}
                {openSubMenu.open ?
                    <SubMenu>
                        <div>
                            <div className="min-w-full  submenu">
                                <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                    <div className=" flex justify-between items-center text-2xl p-4">

                                        <p className="mr-1">{openSubMenu.type}</p>
                                        <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0.3rem'}} />
                                        </div>
                                    </div>
                                    {openSubMenu.type === 'Agregar' ?
                                        <AgregarUser onCloseSubMenu={setOpenSubMenu} /> :
                                        <EditUser onCloseSubMenu={setOpenSubMenu} user={openSubMenu.user} searchUser={searchUser} handleClose={onClose} />
                                    }
                                </div>
                            </div>
                        </div>
                    </SubMenu>
                    : null}
            </div>
        </div>

    );
}

export default Usuarios;
