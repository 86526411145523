let token = localStorage.getItem('token');
let userid;

if(localStorage.getItem('user') !== null ){
    userid = JSON.parse(localStorage.getItem('user'));
}else{
    userid = {
        userid:3,
        istutor:false,
        firstname: '',
        lastname: ''
    }
}

export const user = {
    token,
    userid: userid.userid,
    istutor: userid.istutor,
    firstname: userid.firstname,
    lastname: userid.lastname,
    isAdmin: userid.isadmin,
    //token : '01d00c50b7b8bf5cfb2a9df2f26a1598'
}