import Swal from 'sweetalert2'
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';



export const badges_service = {
    get_all_badges,
    create_badge,
    edit_badge,
    delete_badge,
    action_badge,
    setting_badge
};


async function get_all_badges() {

    const request_badges = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_badges_get_all_badges&moodlewsrestformat=json`);

    if (request_badges.data.hasOwnProperty('badges')) {
        const badges = request_badges.data.badges;
        return { badges }
    }

    const error = request_badges.data;
    return { error };

}



async function create_badge(badge) {

    let formData = new FormData()
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'targett_create_badges');
    formData.append('moodlewsrestformat', 'json');
    formData.append('badge[badge][name]', badge.name);
    formData.append('badge[badge][image]', badge.file);
    formData.append('badge[badge][description]', badge.description);
    formData.append('badge[badge][usercreated]', badge.usercreated);

    const request_badges = await api_iac.post(`webservice/rest/server.php`, formData);

    //console.log(request_badges.data)
    
    if (typeof request_badges.data === 'object') {
        return 1;
    }

    const error = request_badges.data;
    return { error };

}


async function edit_badge(badge) {
    var formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", 'targett_edit_badge');
    formData.append("moodlewsrestformat", "json");
    formData.append("badge[badge][id]", badge.id);
    formData.append("badge[badge][name]", badge.name);
    formData.append("badge[badge][description]", badge.description);
    if( badge.file !== undefined){
        formData.append("badge[badge][image]", badge.file);
    }
    formData.append("badge[badge][usercreated]", badge.usercreated);

    const request_badges = await api_iac.post(`webservice/rest/server.php`, formData);

    console.log(request_badges.data)
    if (typeof request_badges.data === 'object') {
        return 1;
    }

    const error = request_badges.data;
    return { error };

}


async function delete_badge(badge) {

    var formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", 'targett_delete_badge');
    formData.append("moodlewsrestformat", "json");
    formData.append("badge[badge][id]", badge.id);
    formData.append("badge[badge][action]", badge.action);

    const request = await api_iac.post(`webservice/rest/server.php`, formData);
    console.log(request);
    if (request.data == null) {
        return 1;
    }

    let error =  request.data;
    return {error};
}

async function action_badge(action) {
    const request = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=targett_action_badge&moodlewsrestformat=json&action[action][id]=${action.badgeid}&action[action][status]=${action.status}`);
    console.log(request.data);
    if(request.data === null){
        let success = true;
        return { success }
    }
    let error =  request.data;
    return { error }
}

async function  setting_badge(courses) {

    let formData = new FormData()
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'targett_create_badges_criteria');
    formData.append('moodlewsrestformat', 'json');
    formData.append('badge[badge][idBadge]', courses.badgeid);
    formData.append('badge[badge][agg]', 1);
    formData.append('badge[badge][typeCriteria]', 5);
    formData.append('badge[badge][description]', '');
    
    let index = 0;
    courses.grade.map((course)=>{
        formData.append(`badge[badge][courses][${index}][id]`, course.courseid);
        formData.append(`badge[badge][courses][${index}][grade]`, course.grade);
        console.log(index);
        index++;
    });
    
    const request = await api_iac.post(`webservice/rest/server.php`,formData);
    if(request.data.status === 'success'){
        let success = request.data.status
        return {success}
    }
    let error = request.data;
    return {error}
}