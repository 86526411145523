import React, { useCallback, useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import user_edit from '../../../../assets/img/user/user_edit.png'
import thumbCurso from '../../../../assets/img/cursos/thumb_dafi.png'
import { courses_actions } from '../../../../store/actions/courses';
import { user } from '../../../../config/user_token';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'



const Curso = ({ course, clickSubMenu }) => {
    const [OpenDropdown, setOpenDropdown] = useState(false);
    const ref = useRef(null);

    const dispatch = useDispatch();
    const handleClickDelete = async () => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar el curso?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed === true) {
            await dispatch(courses_actions.courses_delete(course));
        }
    }

    const onClickOutsideListener = (e) => {
        document.removeEventListener("click", onClickOutsideListener);
        setOpenDropdown(false);
    }

    return (
        <div className="flex justify-center rounded-md mb-3" style={{ border: '1px solid #797974' }}>
            <div className="w-1/3">
                {course.overviewfiles.length > 0
                    ? <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${course.overviewfiles[0].fileurl}?token=${user.token}`} loading="lazy" alt="" />
                    : <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${thumbCurso}`} alt="" />
                }
            </div>

            <div className="ml-1 w-4/5 p-2 relative bg-indigo-100">
                <div>

                    <div className="absolute top-0 right-2 z-10">
                        <span 
                        onMouseLeave={() => {document.addEventListener("click", onClickOutsideListener)}} 
                        onClick={() => setOpenDropdown(!OpenDropdown)}>
                            {/* <img className="" src={three_dots} style={{ width: '1.5rem' }} /> */}
                            <FontAwesomeIcon icon={faEllipsisH} className="text-black cursor-pointer" />
                        </span>
                        {
                            OpenDropdown
                                ?
                                <div className="bg-white absolute top-5 right-2 p-2">
                                    <p className="text-xs mb-2 hover:bg-gray-200 p-1 cursor-pointer" onClick={() => clickSubMenu()}>Editar</p>
                                    {user.istutor ? <></> : <p className="text-xs hover:bg-gray-200 p-1 cursor-pointer" onClick={handleClickDelete}>Eliminar</p>}
                                </div>
                                :
                                ''
                        }
                    </div>

                    <h4 className="text-xl mt-4">{course.fullname}</h4>
                    <div className="text-sm" dangerouslySetInnerHTML={{ __html: course.summary }} />
                </div>
            </div>
        </div>
    );
}

export default Curso;
