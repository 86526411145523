import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
const Header = ({ title, onClose }) => {
    return (
        <div className="text-3xl flex justify-between mb-5">
            <h3>{title}</h3>
            <div className=""
                onClick={() => onClose()}>
                <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0.3rem'}}/>
            </div>
        </div>
    );
}

export default Header;