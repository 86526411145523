import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { badges_actions } from '../../../../store/actions/badges';
import Swal from 'sweetalert2';
import { user } from '../../../../config/user_token';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


const Insignia = ({ onCloseSubMenu, badge, clickSubMenu }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [OpenDropdown, setOpenDropdown] = useState(false);
    const dispatch = useDispatch();

    const onClickEdit = () => {
        setShowMenu(true)
        clickSubMenu()
    }

    const onClickAddCriteria = () => {
        setShowMenu(true)
        clickSubMenu()
    }

    const onClickDelete = async () => {

        await Swal.fire({
            title: '¿Cual es la acción que desea realizar?',
            icon: 'question',
            text: `Al elegir el Archivar las insignias otorgadas se mantienen.`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Archivar`,
            denyButtonText: `Eliminar`,
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                badge.action = 1;
                await badges_actions.delete_badge(badge);
            } else if (result.isDenied) {
                badge.action = 0;
                await badges_actions.delete_badge(badge);
            }
        })
        dispatch(badges_actions.get_all_badges());
    }

    const onClickOutsideListener = (e) => {
        document.removeEventListener("click", onClickOutsideListener);
        setOpenDropdown(false);
    }

    return (
        <div className="flex justify-center border rounded-md mb-2 relative bg-indigo-100" style={{ border: '1px solid #797974' }}>
            <div className="absolute top-0 right-2 z-10">
                <span onMouseLeave={() => {document.addEventListener("click", onClickOutsideListener)}} 
                    onClick={() => setOpenDropdown(!OpenDropdown)}>
                    {/* <img className="" src={three_dots} style={{ width: '1.5rem' }} /> */}
                    <FontAwesomeIcon icon={faEllipsisH} className="text-black cursor-pointer" />
                </span>
                {
                    OpenDropdown
                        ?
                        <div className="bg-white absolute top-5 right-2 p-2">
                            <p className="text-xs mb-2 hover:bg-gray-200 p-1 cursor-pointer" onClick={() => onClickEdit()}>Editar</p>
                            <p className="text-xs hover:bg-gray-200 p-1 cursor-pointer" onClick={() => onClickDelete()}>Eliminar</p>

                        </div>
                        :
                        ''
                }
            </div>
            <div className="w-1/3"> <img src={`${badge.badgeurl}?token=${user.token}`} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> </div>
            <div className="ml-1 w-4/5 p-2">
                <div>
                    <h4 className="text-xl">{badge.name}</h4>
                    <p className="text-sm">{badge.description}</p>
                </div>
            </div>
        </div>
    );
}

export default Insignia;