import api_iac from "../../config/instance_axios";
import { user } from "../../config/user_token";

export const reporter_service = {
    get_all_reporter,
    get_all_reporter_filter,
};

async function get_all_reporter() {
    let formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", "targett_get_graphic_rep_data");
    formData.append("moodlewsrestformat", "json");

    const request_reporter = await api_iac.post(
        `webservice/rest/server.php`,
        formData
    );
    //console.log(request_reporter);

    if (typeof request_reporter.data === "object") {
        const reporter = request_reporter.data;
        return { reporter };
    }

    const error = request_reporter.data;
    return { error };
}

async function get_all_reporter_filter(filters) {
    let start = 0;
    let end = 0;
    let fideicomiso = 0;
    let comite = 0;
    let programa = 0;
    let course = 0;

    start = filters[0].fechaInicio !== undefined ? filters[0].fechaInicio : 0;
    end = filters[0].fechaFinal !== undefined ? filters[0].fechaFinal : 0;
    fideicomiso =
        filters[0].fideicomiso !== undefined ? filters[0].fideicomiso : 0;
    comite = filters[0].comite !== undefined ? filters[0].comite : 0;
    programa = filters[0].programa !== undefined ? filters[0].programa : 0;
    course = filters[0].curso !== undefined ? filters[0].curso : 0;

    let formData = new FormData();
    formData.append("wstoken", user.token);
    formData.append("wsfunction", "targett_get_graphic_rep_data");
    formData.append("moodlewsrestformat", "json");
    formData.append("start", start);
    formData.append("end", end);
    formData.append("fideicomiso", fideicomiso);
    formData.append("comite", comite);
    formData.append("category", programa);
    formData.append("course", course);

    const request_reporter = await api_iac.post(
        `webservice/rest/server.php`,
        formData
    );
    console.log("Se consumio WS", request_reporter);

    if (typeof request_reporter.data === "object") {
        const reporter = request_reporter.data;
        return { reporter };
    }

    const error = request_reporter.data;
    return { error };
}

/**
 * orderby: [Plataforma, Cursos]
 */
// const get_all_access_by = async (orderby, fideicomiso, fInicio, fFin) => {
//     try {
//         const rest = await api_iac.post(`reportador/api.php?q=getAccesos${orderby}`, {
//             fideicomiso: fideicomiso,
//             token: user.token,
//             orderBy: "curso",
//             fechaInicio: "2022-09-07 16:10:54",
//             fechaFin: "2022-09-08 09:00:22"
//         })
//     } catch (error) {

//     }
// }

// async function get_all_course (fideicomiso, fInicio, fFin) {
//     try {
//         const response = await api_iac.post(
//             "reportador/api.php?q=getCursosFinalizados",
//             {
//                 fideicomiso: fideicomiso,
//                 token: user.token,
//                 fechaInicio: fInicio,
//                 fechaFin: fFin,
//             }
//         );

//         if (response.status !== 200 || Array.isArray(response.data))
//             throw new Error("error al hacer la consulta");

//         return response.data;
//     } catch (error) {
//         console.warn(error);
//         return { error: error.toString()};
//     }
// };


// async function get_accesos_by (reportBy, orderby, fideicomiso, fInicio, fFin) {
//     try {
//         const response = await api_iac.post(
//             `reportador/api.php?q=getAccesos${reportBy}`,
//             {
//                 fideicomiso: fideicomiso,
//                 token: user.token,
//                 orderBy: orderby,
//                 fechaInicio: fInicio,
//                 fechaFin: fFin,
//             }
//         );

//         if (response.status !== 200 || Array.isArray(response.data) || response.data.hasOwnProperty('error'))
//             throw new Error("error al hacer la consulta");

//         return response.data;
//     } catch (error) {
//         console.warn(error);
//         return { error: error.toString()};
//     }
// }