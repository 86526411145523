import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { rewards_actions } from '../../../../store/actions/rewards';
import defaultrecompensa from '../../../../assets/img/recompensas/recompensas.png';

// const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png"
];

const AgregarRecompensa = ({ onCloseSubMenu }) => {
    const [fileUrl, setFileUrl] = useState(defaultrecompensa);

    const dispatch = useDispatch();

    const validate_add = Yup.object().shape({
        title: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(20, 'Debe ser menor a 20 caracteres')
            .required('El campo es requerido'),
        description: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .required('El campo es requerido'),
        file: Yup.mixed()
            .required("El campo es requerido")
            .test(
                "fileSize",
                `El archivo es demasiado grande`,
                value => {
                    return value && value.size <= 2621440
                }
            ),
        price: Yup.number('Escribe la duracion del curso')
            .integer('Debe ser un numero entero')
            .positive('Debe ser un numero positivo')
            .required("El campo es requerido"),
        discount: Yup.number('Escribe la duracion del curso')
            .integer('Debe ser un numero entero')
    });
    const validateData = values => {
        let errors = {};
        
        const day = new Date(Date.now());
        let formatTime;
        formatTime = moment(day).format("YYYY-MM-DD[T]HH:mm");

        if (formatTime > values.promotionend) {
            errors.promotionend = 'Debe ser mayor a fecha actual';
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            file: '',
            price: 0,
            discount: 0,
            promotionend: ''
        },
        validationSchema: validate_add,
        validate: validateData,

        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async (values) => {

        const imageBase64 = await convertBase64(values.file);
        delete values.file;
        values.image = imageBase64;

        let unixstartdate = convertDatetoUnix(values.promotionend);
        values.promotionend = unixstartdate;

        await rewards_actions.create_reward(values);
        onCloseSubMenu(false);
        dispatch(rewards_actions.get_all_rewards());
    }

    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    const convertDatetoUnix = (datetime) => {
        let utctime = datetime;
        let date = moment(utctime);
        console.log(date);
        let dateComponent = date.format('YYYY-MM-DD HH:mm:ss');

        let unixtime = moment(dateComponent, 'YYYY.MM.DD HH:mm:ss').unix()
        return unixtime;
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <form onSubmit={formik.handleSubmit} className="formularios">
            <div className="flex cursor-pointer items-center text-md mb-4 relative group">
                <div className="w-3/5">
                    <img className="h-20 w-20 rounded-full overflow-hidden" src={fileUrl} alt=""></img>
                </div>
                <div className="flex items-center text-md relative group ml-1">
                    <label className="mr-1  group-hover:text-opacity-50" htmlFor="">Imagen para la recompensa</label>
                    <p className="text-3xl  group-hover:text-opacity-50">+</p>
                    <input onChange={e => {
                        processImage(e);
                        formik.setFieldValue('file', e.target.files[0])
                    }} name="file" type="file" className="absolute top-0 left-0 opacity-0" />
                    {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                </div>
            </div>
            <p className="text-red-600 text-sm mb-6">{formik.errors.file ? <div>{formik.errors.file}</div> : null}</p>
            <div className="campo">
                <label className="text-md" htmlFor="">Titulo de la recompensa</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="title" value={formik.values.title} placeholder="" />
                <p className="text-red-600 text-sm">{formik.errors.title ? <div>{formik.errors.title}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Descripción de la recompensa</label>
                <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="description" placeholder="" value={formik.values.description} />
                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Precio de la recompensa</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="price" value={formik.values.price} placeholder="" />
                <p className="text-red-600 text-sm">{formik.errors.price ? <div>{formik.errors.price}</div> : null}</p>
            </div>
            <div className="campo">
                <label className="text-md" htmlFor="">Descuento de la recompensa</label>
                <input className="rounded-md" onChange={formik.handleChange} type="text" name="discount" value={formik.values.discount} placeholder="" />
                <p className="text-red-600 text-sm">{formik.errors.discount ? <div>{formik.errors.discount}</div> : null}</p>
            </div>
            <div className="campo">
                <label htmlFor="">Fecha de expiracion</label>
                <input className="rounded-md" onChange={formik.handleChange} type="datetime-local" name="promotionend" value={formik.values.promotionend} />
                <p className="text-red-600 text-sm">{formik.errors.promotionend ? <div>{formik.errors.promotionend}</div> : null}</p>
            </div>
            <button type="submit" className="block mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-36 hover:bg-opacity-100">Guardar</button>
        </form>
    );
}

export default AgregarRecompensa;