import { rankings_constants } from '../constants/rankings';

const initialState = {
    rankings: [],
    loading_rankings: null,
    error_rankings: null
};

export function rankings_reducers(state = initialState, action) {
  switch (action.type) {
    //------------GET rankings--------------//
    case rankings_constants.RANKINGS_REQUEST:
        return {
            ...state,
            loading_rankings: action.payload,
        };
    case rankings_constants.RANKINGS_SUCCESS:
        return {
            ...state,
            loading_rankings: false,
            rankings: action.payload,
            error_rankings: null
        };
    case rankings_constants.RANKINGS_FAILURE:
        return {
            ...state,
            loading_rankings: false,
            error_rankings: action.payload
        };
    default:
      return state
  }
}