import { rankings_constants } from '../constants/rankings';
import { rankings_service } from '../services/rankings';
import { alert_actions } from './alert';
import Swal from 'sweetalert2';


export const rankings_actions = {
    get_all_rankings,
    add_ranking_points,
    remove_ranking_points,
    ranking_get_exchanges,
    get_ranking_page
};

function get_all_rankings() {
    return dispatch => {
        dispatch( request() );

        rankings_service.get_all_rankings()
            .then(
                res => {
                    console.log(res)
                    if(res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message));
                    }else{
                        dispatch(success(res.rankings));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: rankings_constants.RANKINGS_REQUEST , payload: true } }
    function success(param) { return { type: rankings_constants.RANKINGS_SUCCESS , payload: param } }
    function failure(error) { return { type: rankings_constants.RANKINGS_FAILURE , payload: error } }
}

async function add_ranking_points(user, points) {
    await rankings_service.add_ranking_points(user,points)
        .then(
            res => {
                console.log(res)
                if ( (res.hasOwnProperty('error')) && (res.error.length) ) {
                    return Swal.fire(
                        'Ranking',
                        `${res.error.message}`,
                        'error',
                    )
                }else{
                    return Swal.fire(
                        'Ranking',
                        'Los puntos fueron agregados',
                        'success'
                    );
                }
            },
            error => {
                return Swal.fire('Ranking', 'Ocurrió un error, intenta más tarde', 'error');
            }
        ).catch(e => {
            console.debug(e);
            return Swal.fire(
                'Ranking',
                'Ocurrio un error, intenta más tarde',
                'error',
            );
        });

}

async function remove_ranking_points(user, points) {
    await rankings_service.remove_ranking_points(user, points)
    .then(
        res => {
            console.log(res)
            if ( (res.hasOwnProperty('error')) && (res.error.length) ) {
                return Swal.fire(
                    'Ranking',
                    `${res.error.message}`,
                    'error',
                )
            } else {
                return Swal.fire(
                    'Ranking',
                    'Los puntos fueron retirados',
                    'success'
                );
            }
        },
        error => {
            return Swal.fire('Ranking', 'Ocurrió un error, intenta más tarde', 'error');
        }
    ).catch(e => {
        console.debug(e);
        return Swal.fire(
            'Ranking',
            'Ocurrio un error, intenta más tarde',
            'error',
        );
    });

}

function ranking_get_exchanges(user) {
    return dispatch => {
        dispatch( request() );

        rankings_service.ranking_get_exchanges(user,0)
            .then(
                res => {
                    console.log(res)
                    if(res.hasOwnProperty('error')){
                        dispatch(failure(res.error.message));
                    }else{
                        dispatch(success(res.rankings));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: rankings_constants.RANKINGS_REQUEST , payload: true } }
    function success(param) { return { type: rankings_constants.RANKINGS_SUCCESS , payload: param } }
    function failure(error) { return { type: rankings_constants.RANKINGS_FAILURE , payload: error } }
}

function get_ranking_page(size, offset) {
    return rankings_service.get_ranking_page(size, offset);
}