export const cohorts_constants = {
     COHORTS_REQUEST: ' COHORTS_REQUEST',
     COHORTS_SUCCESS: ' COHORTS_SUCCESS',
     COHORTS_FAILURE: ' COHORTS_FAILURE',

    COHORTS_EDIT_REQUEST: 'COHORTS_EDIT_REQUEST',
    COHORTS_EDIT_SUCCESS: 'COHORTS_EDIT_SUCCESS',
    COHORTS_EDIT_FAILURE: 'COHORTS_EDIT_FAILURE',

    COHORTS_DELETE_REQUEST: 'COHORTS_DELETE_REQUEST',
    COHORTS_DELETE_SUCCESS: 'COHORTS_DELETE_SUCCESS',
    COHORTS_DELETE_FAILURE: 'COHORTS_DELETE_FAILURE',

    COHORTS_CREATE_REQUEST: 'COHORTS_CREATE_REQUEST',
    COHORTS_CREATE_SUCCESS: 'COHORTS_CREATE_SUCCESS',
    COHORTS_CREATE_FAILURE: 'COHORTS_CREATE_FAILURE',
    
    ADD_MEMBERS_COHORTS_REQUEST: 'ADD_MEMBERS_COHORTS_REQUEST',
    ADD_MEMBERS_COHORTS_SUCCESS: 'ADD_MEMBERS_COHORTS_SUCCESS',
    ADD_MEMBERS_COHORTS_FAILURE: 'ADD_MEMBERS_COHORTS_FAILURE',

    REMOVE_MEMBERS_COHORTS_REQUEST: 'REMOVE_MEMBERS_COHORTS_REQUEST',
    REMOVE_MEMBERS_COHORTS_SUCCESS: 'REMOVE_MEMBERS_COHORTS_SUCCESS',
    REMOVE_MEMBERS_COHORTS_FAILURE: 'REMOVE_MEMBERS_COHORTS_FAILURE',

    COHORTS_MEMBERS_REQUEST: 'COHORTS_MEMBERS_REQUEST',
    COHORTS_MEMBERS_SUCCESS: 'COHORTS_MEMBERS_SUCCESS',
    COHORTS_MEMBERS_FAILURE: 'COHORTS_MEMBERS_FAILURE',
}
