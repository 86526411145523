
import { tutors_constants } from '../constants/tutors';
import { tutors_service } from '../services/tutors';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const tutors_actions = {
    get_tutor_courses,
    assing_tutor_courses,
    unassing_tutor_courses
};

function get_tutor_courses(idTutor) {

    return dispatch => {
        dispatch(request());

        tutors_service.get_tutor_courses(idTutor)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.tutors));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: tutors_constants.TUTORS_REQUEST, payload: true } }
    function success(param) { return { type: tutors_constants.TUTORS_SUCCESS, payload: param } }
    function failure(error) { return { type: tutors_constants.TUTORS_FAILURE, payload: error } }
}

/* 
async function get_tutor_courses(idTutor) {

    return await tutors_service.get_tutor_courses(idTutor)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString())
                } else {

                    return res.tutors
                }
            },
            error => {
                failure(error.toString())
                alert_actions.error(error.toString())
            }
        ).catch(e => {
            failure(e.toString())
        })

    function request() { return { type: tutors_constants.TUTORS_REQUEST, payload: true } }
    function success(param) { return { type: tutors_constants.TUTORS_SUCCESS, payload: param } }
    function failure(error) { return { type: tutors_constants.TUTORS_FAILURE, payload: error } }
} */



async function assing_tutor_courses (coursesList, idUser) {
    await tutors_service.assing_tutor_courses(coursesList, idUser)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Agregar cursos',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Agregar cursos',
                        `${res.mensaje}`,
                        'success'
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return e.toString();
        })
    return dispatch => {
        dispatch(request());

    }
    function request() { return { type: tutors_constants.TUTORS_REQUEST, payload: true } }
    function success(param) { return { type: tutors_constants.TUTORS_SUCCESS, payload: param } }
    function failure(error) { return { type: tutors_constants.TUTORS_FAILURE, payload: error } }
}

async function unassing_tutor_courses(coursesList, idUser) {
    await tutors_service.unassing_tutor_courses(coursesList, idUser)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Quitar cursos',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Quitar cursos',
                        'Se quitaron los cursos asignados correctamente.',
                        'success'
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return e.toString();
        })
    return dispatch => {
        dispatch(request());

    }
    function request() { return { type: tutors_constants.TUTORS_REQUEST, payload: true } }
    function success(param) { return { type: tutors_constants.TUTORS_SUCCESS, payload: param } }
    function failure(error) { return { type: tutors_constants.TUTORS_FAILURE, payload: error } }
}

