import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faArchive, faComment, faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../../store/actions/courses';



const CreateCommunication = ({ course, onCloseSubMenu }) => {
    const dispatch = useDispatch();

    const validate_add = Yup.object().shape({
        name: Yup.string('Escribe el nombre del tema')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido')
    });

    const formik = useFormik({
        initialValues: {
            courseid: course.id,
            name: '',        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async discussion => {
        await courses_actions.add_activity_communication_course(discussion);
        dispatch(courses_actions.get_contents_course(course.id));
        onCloseSubMenu(false);
    }

    return (
        <div>
            <div>
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label htmlFor="">Titulo del canal</label>
                        <input onChange={formik.handleChange} type="text" name="name" placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
                    </div>
                    <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-28  
                            border border-blue-300 hover:bg-opacity-100`}>Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    );

}


export default CreateCommunication;