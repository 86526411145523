import api_iac from "../../config/instance_axios";
import { user } from '../../config/user_token';


export const cohorts_service = {
    get_all_cohorts,
    cohorts_edit,
    cohorts_delete,
    cohorts_create,
    add_members_cohort,
    remove_members_cohort,
    cohorts_members_users
};

async function get_all_cohorts() {
    const request_cohorts = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_get_cohorts&moodlewsrestformat=json`);

    if (Array.isArray(request_cohorts.data)) {
        const categories = request_cohorts.data
        return { categories };
    }

    const error = request_cohorts.data;
    return { error };

}

async function cohorts_edit(cohort) {
    console.log(cohort);

    if (cohort.id_category == 0) {
        cohort.categorytype = "system";
    } else {
        cohort.categorytype = "id";
    }
    let formData = new FormData()
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'core_cohort_update_cohorts');
    formData.append('moodlewsrestformat', 'json');
    formData.append('cohorts[0][categorytype][type]', cohort.categorytype);
    formData.append('cohorts[0][categorytype][value]', cohort.id_category);
    formData.append('cohorts[0][id]', cohort.id);
    formData.append('cohorts[0][name]', cohort.name);
    formData.append('cohorts[0][idnumber]', cohort.name);
    formData.append('cohorts[0][description]', cohort.description);
    formData.append('cohorts[0][descriptionformat]', cohort.descriptionformat);
    formData.append('cohorts[0][theme]', cohort.theme);
    //formData.append('cohorts_users', JSON.stringify(user_cohorts_select) );
    //formData.append('cohort_id', cohort_id );

    const request_cohorts_edit = await api_iac.post(`webservice/rest/server.php`, formData);

    if (request_cohorts_edit.data == null) {
        return 1;
    }

    const error = request_cohorts_edit.data;
    return { error };

}

async function cohorts_delete(cohort) {
    const request_cohorts_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_delete_cohorts&moodlewsrestformat=json&cohortids[0]=${cohort.id}`);
    console.log(request_cohorts_delete);
    if (request_cohorts_delete.data == null) {
        return 1;
    } else {
        return 0
    }
}

async function cohorts_create(cohort) {

    if (cohort.categoryvalue == 0) {
        cohort.categorytype = "system";
    }
    const request_categories_create = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_create_cohorts&moodlewsrestformat=json&cohorts[0][categorytype][type]=${cohort.categorytype}&cohorts[0][categorytype][value]=${cohort.categoryvalue}&cohorts[0][name]=${cohort.name}&cohorts[0][description]=${cohort.description}&cohorts[0][idnumber]=${cohort.name}&cohorts[0][descriptionformat]=1`);

    console.log(request_categories_create.data)
    if (Array.isArray(request_categories_create.data)) {
        return 1;
    }

    const error = request_categories_create.data;
    return { error };
}

async function add_members_cohort(cohort, users) {
    let members = "";
    let index = 0;
    users.map((user) => {
        members += `members[${index}][cohorttype][type]=id&members[${index}][cohorttype][value]=${cohort.id}&members[${index}][usertype][type]=id&members[${index}][usertype][value]=${user}&`;
        index++;
    });
    const request_add_members_cohort = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_add_cohort_members&moodlewsrestformat=json&${members}`);
    if (request_add_members_cohort.data.warnings.length === 0) {

        return 1;
    }
    const error = request_add_members_cohort.data.warnings;
    return { error };

}

async function remove_members_cohort(cohort, users) {
    console.log(users)
    let members = "";
    let index = 0;
    users.map((user) => {
        members += `members[${index}][cohortid]=${cohort.id}&members[${index}][userid]=${user}&`;
        index++;
    });

    const request_remove_memberts_cohort = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_delete_cohort_members&moodlewsrestformat=json&${members}`);
    console.log(request_remove_memberts_cohort.data);
    if (request_remove_memberts_cohort.data == null) {
        console.log(request_remove_memberts_cohort.data)
        return 1;
    }
    const error = request_remove_memberts_cohort.data.warnings;
    return { error };
}

async function cohorts_members_users(cohortid) {
    const request_cohorts_members = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_get_cohort_members_users&moodlewsrestformat=json&cohortids[0]=${cohortid}`);
    if (Array.isArray(request_cohorts_members.data)) {
        const members_users = request_cohorts_members.data[0].userids;
        return { members_users };
    }

    const error = request_cohorts_members.data[0];
    return { error };
}