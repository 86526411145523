import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tree, { useTreeState } from 'react-hyper-tree'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import editar_usuario from '../../../../assets/img/user/user_edit.png'
import SubMenu from '../SubMenu/SubMenu';
import EditarMeeting from './Meetings/EditarMeeting';
import AgregarMeeting from './Meetings/AgregarMeeting';
import Header from '../../DetallesMenu/Header';
import { meetings_actions } from '../../../../store/actions/meetings';

function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    let newFormat = new Date(date).toLocaleDateString("es-ES", options);
    return newFormat;
}

let user = JSON.parse(localStorage.getItem('user'));
const Meetings = ({ onClose }) => {

    const meetings = useSelector(state => state.meetings);

    let [meetings_tree, setstate_meetings_tree] = useState([]);
    const dispatch = useDispatch();

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        meeting: {}
    });

    const clickSubMenu = (type, open_param, meeting) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            meeting: meeting
        })
    }

    const clicCloseSubMenu = () => {
        setOpenSubMenu(false)
        //document.querySelector(".detatlles.bg-blue-500.fixed.top-0.left-0.rounded-xl.m-4").style.width="400px";
    }

    //Elimina la categoria
    const handleClickDelete = async (meeting) => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar la conferencia?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed == true) {
            await meetings_actions.meetings_delete(meeting);
            dispatch(meetings_actions.get_all_meetings());
        }
    }

    return (
        <div>
            <div>
                <Header title="Conferencias" onClose={onClose} />

                {openSubMenu.open ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null}
                {
                    meetings.meetings.length > 0  || user.istutor?
                        ""
                        : 
                            <div className="mb-8">
                                <p className="text-sm">Crea tu primera videoconferencia</p>
                            </div>
                }
                {
                    user.istutor ?
                    null
                    :
                        <div className="flex flex-row flex-wrap justify-end" onClick={() => clickSubMenu('Crear Conferencia', !openSubMenu.open)}>
                            <div className="m-2 flex items-center cursor-pointer">Crear Videoconferencia <p className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</p></div>
                        </div>
                    
                }

                <div>
                    <p className="text-xs mb-1 mt-5">Resultados: {meetings.meetings.length}</p>
                    {meetings.loading_meetings == false ?
                        meetings.meetings.length > 0 ?
                            meetings.meetings.map(meeting => (
                                (meeting.name === "webservice" || meeting.id === 1 || meeting.idnumber === "webservice")
                                    ?
                                    meetings.meetings.length === 1 ? <p>No tienes Grupos Agregados</p> : ""
                                    :
                                    <Meeting key={meeting.id_zoom} meeting={meeting}
                                        clickSubMenu={() => clickSubMenu('Editar Conferencia', !openSubMenu.open, meeting)}
                                        clickDelete={() => { handleClickDelete(meeting) }} />

                            ))
                            : <p>No tienes conferencias creadas</p>
                        : <p>Cargando...</p>}


                </div>
            </div>
            {openSubMenu.open ?
                <div>
                    <SubMenu>
                        <div>
                            <div className="min-w-full submenu">
                                <div className="bg-gray-100 w-1/4 h-80 rounded-xl scroll">
                                    <div className="flex justify-between items-center text-2xl p-4">
                                        <p>{openSubMenu.type}</p>
                                        <div className="flex"
                                            onClick={() => clicCloseSubMenu()}>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 absolute" style={{top: '-1px', marginLeft: '0.3rem'}}/>
                                        </div>

                                    </div>
                                    {openSubMenu.type === 'Crear Conferencia' ?
                                        <AgregarMeeting onCloseSubMenu={setOpenSubMenu} /> :
                                        <EditarMeeting onCloseSubMenu={setOpenSubMenu} meeting={openSubMenu.meeting} />}
                                </div>
                            </div>
                        </div>

                    </SubMenu>
                </div>
                : null}
        </div>

    );
}



const Meeting = ({ meeting, onToggle, clickSubMenu, clickDelete }) => {
    const [OpenDropdown, setOpenDropdown] = useState(false);

    const onClickOutsideListener = (e) => {
        document.removeEventListener("click", onClickOutsideListener);
        setOpenDropdown(false);
    }

    return (
        <div className={` h-20 mb-4 border border-gray-600 p-4 rounded-md relative bg-indigo-100`}>
            { user.istutor ? null :
                <div className="absolute top-0 right-2 z-10">
                    <span 
                    onMouseLeave={() => {document.addEventListener("click", onClickOutsideListener)}} 
                    onClick={() => setOpenDropdown(!OpenDropdown)}>
                        {/* <img className="" src={three_dots} style={{ width: '1.5rem' }} /> */}
                        <FontAwesomeIcon icon={faEllipsisH} className="text-black cursor-pointer" />
                    </span>
                    {
                        OpenDropdown
                            ?
                            <div className="bg-white absolute top-5 right-2 p-2">
                                <p className="text-xs mb-2 hover:bg-gray-200 p-1 cursor-pointer" onClick={() => clickSubMenu()}>Editar</p>
                                <p className="text-xs hover:bg-gray-200 p-1 cursor-pointer" onClick={() => clickDelete()}>Eliminar</p>

                            </div>
                            :
                            ''
                    }
                </div>
            }
            <div className="w-full flex items-center">

                <div onClick={onToggle} className="w-3/4 cursor-pointer">
                    <p className="text-md sm">{meeting.topic}</p>
                    <div className="inline-flex items-baseline">
                        <p className="mr-3"><a className="text-xs text-black-500 hover:bg-blue-900 hover:text-white p-1" href={meeting.join_url} target="_blank" rel="noreferrer">Link</a> </p>
                        <p className="text-xs inline">{formatDate(meeting.start_time)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Meetings;