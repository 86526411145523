export const badges_constants = {
    BADGES_REQUEST: 'BADGES_REQUEST',
    BADGES_SUCCESS: 'BADGES_SUCCESS',
    BADGES_FAILURE: 'BADGES_FAILURE',

    CREATE_BADGES_REQUEST: 'CREATE_BADGES_REQUEST',
    CREATE_BADGES_SUCCESS: 'CREATE_BADGES_SUCCESS',
    CREATE_BADGES_FAILURE: 'CREATE_BADGES_FAILURE',

    EDIT_BADGES_REQUEST: 'EDIT_BADGES_REQUEST',
    EDIT_BADGES_SUCCESS: 'EDIT_BADGES_SUCCESS',
    EDIT_BADGES_FAILURE: 'EDIT_BADGES_FAILURE',

    DELETE_BADGES_REQUEST: 'DELETE_BADGES_REQUEST',
    DELETE_BADGES_SUCCESS: 'DELETE_BADGES_SUCCESS',
    DELETE_BADGES_FAILURE: 'DELETE_BADGES_FAILURE',
}