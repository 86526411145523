import api_iac from "../../config/instance_axios";
import { user } from '../../config/user_token';



export const meetings_service = {
    get_all_meetings,
    meetings_create,
    meetings_edit,
    meetings_delete,
    meetings_members_users,
    add_members_meeting,
    remove_members_meeting,
    get_meetings
};

async function get_all_meetings() {
    const request_meetings = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_all_meeting_zoom&
    moodlewsrestformat=json`);

    if (Array.isArray(request_meetings.data.meetings)) {
        const meetings = request_meetings.data.meetings
        return { meetings };
    }

    const error = request_meetings.data;
    return { error };
}

async function meetings_create(meeting) {

    const request_meeting_create = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_create_meeting_zoom&
    moodlewsrestformat=json&
    meeting[meeting][topic]=${meeting.topic}&
    meeting[meeting][start_time]=${meeting.start_time}&
    meeting[meeting][duration]=${meeting.duration}&
    meeting[meeting][timezone]=${meeting.timezone}&
    meeting[meeting][password]=${meeting.othername}`);

    if (typeof request_meeting_create.data === 'object') {
        return 1;
    }

    const error = request_meeting_create.data;
    return { error };
}

async function meetings_edit(meeting) {

    let formData = new FormData()
    formData.append('wstoken', user.token);
    formData.append('wsfunction', 'targett_update_meeting_zoom');
    formData.append('moodlewsrestformat', 'json');
    formData.append('meeting[meeting][idMeeting]', meeting.id_zoom);
    formData.append('meeting[meeting][topic]', meeting.topic);
    formData.append('meeting[meeting][start_time]', meeting.start_time);
    formData.append('meeting[meeting][duration]', meeting.duration);
    formData.append('meeting[meeting][timezone]', meeting.timezone);
    formData.append('meeting[meeting][password]', meeting.othername);
    //formData.append('meetingss_users', JSON.stringify(user_meetingss_select) );
    //formData.append('meeting_id', meeting_id );

    const request_meeting_edit = await api_iac.post(`webservice/rest/server.php`, formData);

    if  (typeof request_meeting_edit.data === 'object') {
        return 1;
    }


    const error = request_meeting_edit.data;
    return { error };
}

async function meetings_delete(meeting) {
    const request_meeting_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
    wsfunction=targett_delete_meeting_zoom&
    moodlewsrestformat=json&
    meeting[meeting][idMeeting]=${meeting.id_zoom}`);

    if (typeof request_meeting_delete.data === 'object') {
        return 1;
    } else {
        return 0
    }
}

async function meetings_members_users(meetingid) {
    const request_meetings_members = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_all_users_enrolled_meeting_zoom&
    moodlewsrestformat=json&
    meeting[meeting][meetingid]=${meetingid}`);
    if (typeof request_meetings_members.data === 'object') {
        const members_users = request_meetings_members.data.meetings;
        return { members_users };
    }

    const error = request_meetings_members.data.meetings;
    return { error };
}

async function add_members_meeting(meeting, users) {
    let members = "";
    let index = 0;
    users.map((user) => {
        members += `meeting[${index}][meetingid]=${meeting.id}&meeting[${index}][userid]=${user}&`;
        index++;
    });

    const request_add_members_meeting = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_add_users_meeting_zoom&
    moodlewsrestformat=json&
    ${members}`);
    if (typeof request_add_members_meeting.data === 'object') {
        return 1;
    }
    const error = request_add_members_meeting.data.meetings;
    return { error };
}

async function remove_members_meeting(meeting, users) {

    let members = "";
    let index = 0;
    users.map((user) => {
        members += `meeting[${index}][meetingid]=${meeting.id}&meeting[${index}][userid]=${user}&`;
        index++;
    });

    const request_remove_memberts_meeting = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_delete_users_meeting_zoom&
    moodlewsrestformat=json&
    ${members}`);

    if (typeof request_remove_memberts_meeting.data === 'object') {
        return 1;
    }
    const error = request_remove_memberts_meeting.data.meetings;
    return { error };
}

async function get_meetings() {
    const token = user.token;
    const id = user.userid;

    const consulta = await api_iac.post(`webservice/rest/server.php?
        wstoken=${token}&
        wsfunction=targett_get_meeting_zoom&
        userid=${id}&
        moodlewsrestformat=json`
    );

    return consulta.data.meetings
}
