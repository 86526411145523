import { kardex_constants } from '../constants/kardex';

const initialState = {
    //kardex data individual
    kardex_data_inidividual: null,

    //kardex data fideicomiso
    kardex_data_fideicomiso: null,

    //kardex general
    kardex_data_general: null,
};

export function kardex_reducers (state = initialState, action){
    switch(action.type){
        case kardex_constants.KARDEX_GENERAL_REQUEST:
            return {
                ...state,
                kardex_data_general: action.payload,
            };
        case kardex_constants.KARDEX_GENERAL_SUCCESS:
            return {
                ...state,
                kardex_data_general: action.payload,
            };
        case kardex_constants.KARDEX_GENERAL_FAILURE:
            return {
                ...state,
                kardex_data_general: action.payload,
            };
        case kardex_constants.KARDEX_FIDEICOMISO_REQUEST:
            return {
                ...state,
                kardex_data_fideicomiso: action.payload,
            };
        case kardex_constants.KARDEX_FIDEICOMISO_SUCCESS:
            return {
                ...state,
                kardex_data_fideicomiso: action.payload,
            };
        case kardex_constants.KARDEX_FIDEICOMISO_FAILURE:
            return {
                ...state,
                kardex_data_fideicomiso: action.payload,
            };
        case kardex_constants.KARDEX_INDIVIDUAL_REQUEST:
            return {
                ...state,
                kardex_data_inidividual: action.payload,
            };
        case kardex_constants.KARDEX_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                kardex_data_inidividual: action.payload,
            };
        case kardex_constants.KARDEX_INDIVIDUAL_FAILURE:
            return {
                ...state,
                kardex_data_inidividual: action.payload,
            };
        default:
            return state;
    }
}