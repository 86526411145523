export const events_constants = {
    EVENTS_REQUEST: 'EVENTS_REQUEST',
    EVENTS_SUCCESS: 'EVENTS_SUCCESS',
    EVENTS_FAILURE: 'EVENTS_FAILURE',

    EVENTS_CREATE_REQUEST: 'EVENTS_CREATE_REQUEST',
    EVENTS_CREATE_SUCCESS: 'EVENTS_CREATE_SUCCESS',
    EVENTS_CREATE_FAILURE: 'EVENTS_CREATE_FAILURE',

    EVENTS_DELETE_REQUEST: 'EVENTS_DELETE_REQUEST',
    EVENTS_DELETE_SUCCESS: 'EVENTS_DELETE_SUCCESS',
    EVENTS_DELETE_FAILURE: 'EVENTS_DELETE_FAILURE',

    EVENTS_EDIT_REQUEST: 'EVENTS_EDIT_REQUEST',
    EVENTS_EDIT_SUCCESS: 'EVENTS_EDIT_SUCCESS',
    EVENTS_EDIT_FAILURE: 'EVENTS_EDIT_FAILURE',
}
