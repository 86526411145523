import axios from "axios";

const api_iac = axios.create({
    //baseURL: 'http://dafi.test/corelms/',
    //baseURL: 'http://localhost/corelms/'
    //baseURL: 'https://universidadiac.com.mx/desarrollo/',
    //baseURL: 'https://www.regal.sescomm.com/corelms/',
    baseURL: 'https://universidaddafi.com.mx/corelms/',
    //baseURL: 'https://universidaddafi.com.mx/desarrollo/',
    //baseURL: 'http://localhost:8888/corelms/',
    //baseURL: 'http://localhost/moodle-hm/corelms/',
    timeout: 300000,
});

export default api_iac;
