import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-dropdown-select';
import { CompactPicker } from 'react-color'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment';
import { UnmountClosed } from 'react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { events_actions } from '../../store/actions/calendar';
import Header from '../../Components/Aside/DetallesMenu/Header';
import AgregarMeeting from '../../Components/Aside/DetallesMenu/Conferencias/Meetings/AgregarMeeting';


const AgregarEvento = ({ openMenuAdd }) => {
    const dispatch = useDispatch();
    const cohorts = useSelector(state => state.cohorts);
    const meetings = useSelector(state => state.meetings);
    const courses = useSelector(state => state.courses);

    const options_cohorts = cohorts.cohorts.map((option) => ({
        ...option,
        label: option.name,
        value: option.id,
        cohortid: option.id
    }));

    const opciones = []
    options_cohorts.map(cohort => {
        if (cohort.id !== 1 || cohort.name !== "webservice" || cohort.idnumber !== "webservice") {
            opciones.push(cohort);
        }
    });

    const options_meetings = meetings.meetings.map((optionMeeting) => ({
        ...optionMeeting,
        label: optionMeeting.topic,
        value: optionMeeting.id,
        meetingid: optionMeeting.id
    }));

    const opcionesMeeting = []
    options_meetings.map(meeting => {
        opcionesMeeting.push(meeting);

    });

    const options_courses = courses.courses.map((optionCourse) => ({
        ...optionCourse,
        label: optionCourse.fullname,
        value: optionCourse.id,
        meetingid: optionCourse.id
    }));

    const opcionesCourse = []
    options_courses.map(course => {
        opcionesCourse.push(course);

    });

    /*useEffect(() => {

    }, [])*/
    const [cohorts_select, set_cohorts_select] = useState([]);
    const [meetings_select, set_meetings_select] = useState([
        { id: '' }
    ]);
    const [courses_select, set_courses_select] = useState([
        { id: '' }
    ]);

    const [selectedColor, setSelectedColor] = useState('#367CF7');
    const [isOpenCourse, setIsOpenCourse] = useState(true);
    const [isOpenMeeting, setIsOpenMeeting] = useState(false);

    const validate_add = Yup.object().shape({
        //mode: Yup.string()
            //.required('El campo es requerido'),
        name: Yup.string('Escribe el nombre de tu evento')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        startdate: Yup.string('')
            .required('El campo es requerido'),
        enddate: Yup.string('')
            .required('El campo es requerido'),
        description: Yup.string('Escribe la descripcion de tu evento')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres'),
        /* area: Yup.string('')
            .required('El campo es requerido') */
    });

    const formik = useFormik({
        initialValues: {
            //mode: '',
            name: '',
            startdate: '',
            enddate: '',
            description: '',
            //area: ''
            bgcolor: ''
        },
        validationSchema: validate_add,
        onSubmit: values => {
            //console.log(values)
            handleSubmit(values)
        },
    });

    const handleSubmit = async (event) => {
        event.bgcolor = selectedColor;
        event.tipo = "Cursos";
        event.mode = "Presencial";
        // console.log(event, 'event')
        // console.log(cohorts_select, 'cohorts')
        // console.log( meetings_select, 'meetings')
        // console.log(courses_select, 'courses')

        await events_actions.events_create(event, cohorts_select, meetings_select, courses_select);
        openMenuAdd(false);
        dispatch(events_actions.get_all_events());
    }

    const onChange = (values) => set_cohorts_select(values);

    const onChangeMeeting = (values) => {
        setIsOpenMeeting(true);
        setIsOpenCourse(false);
        set_meetings_select({
            id: values.target.value,
        });
        set_courses_select([]);
    };

    const onChangeCourse = (values) => {
        //console.log(values)
        setIsOpenMeeting(false);
        setIsOpenCourse(true);
        set_meetings_select([]);
        set_courses_select({
            id: values.target.value,
        });
    };

    return (
        <>
            <div onClick={openMenuAdd} className="fixed h-screen z-10 min-w-full top-0 left-0 bg-black bg-opacity-60 overflow-hidden"></div>

            <div className="bg-gray-100 text-black absolute z-10 top-2/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-1 px-5 py-5 rounded-xl h-3/4 overflow-auto">

                <div className="text-3xl flex justify-between mb-5">
                    <h3>Agregar evento</h3>
                    <div className="flex"
                        onClick={() => openMenuAdd(false)}>
                        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '0px', marginLeft: '-0.3rem' }} />
                    </div>
                </div>

                <div className="flex flex-col justify-between">
                    <label className="" htmlFor="">Selecciona el tipo evento</label>
                    <div className="flex ">
                        <div className="radio-button flex items-center border-black border px-4 py-2 w-64 rounded-md">
                            <input type="radio" name="tipo" id="tipo1" onClick={() => { setIsOpenCourse(true); setIsOpenMeeting(false) }} value="Cursos" />
                            <label htmlFor="tipo1">Cursos</label>
                        </div>
                        <div className="radio-button flex items-center border-black border px-4 py-2 w-64 rounded-md">
                            <input type="radio" name="tipo" id="tipo2" onClick={() => { setIsOpenMeeting(true); setIsOpenCourse(false) }} value="Conferencias" />
                            <label htmlFor="tipo2">Conferencias</label>
                        </div>
                    </div>
                    {isOpenCourse && (
                        <form className="formularios" onSubmit={formik.handleSubmit}>
                            <div className="flex justify-between text-center mb-5  mt-1">
                                <div className="mr-4" style={{ width: '480px' }}> 
                                    <UnmountClosed isOpened={isOpenCourse}>
                                        <div className="campo text-black">
                                            <select onChange={(value) => onChangeCourse(value)} name="course" className="rounded-md">
                                                <option value={0} selected disabled>Selecciona un curso</option>
                                                {
                                                    opcionesCourse.map(item =>
                                                        <option value={item.value}>{item.label}</option>
                                                    )
                                                }
                                            </select>

                                            {/* <Select
                                                options={opcionesCourse}
                                                name="course"
                                                onChange={(value) => onChangeCourse(value[0].value)}
                                                value={""}
                                            /> */}
                                        </div>

                                    </UnmountClosed>
                                </div>
                                {/* <div style={{ width: '256px' }}>
                                    
                                    <UnmountClosed isOpened={isOpenMeeting}>
                                        <div className="campo text-black">
                                            <select onChange={(value) => onChangeMeeting(value)} name="meeting" className="rounded-md">
                                                <option value={0} selected disabled>Selecciona una conferencia</option>
                                                {opcionesMeeting.map(item =>
                                                    <option value={item.value}>{item.label}</option>
                                                )}
                                            </select> */}
                                            {/* <Select
                                                options={opcionesMeeting}
                                                name="meeting"
                                                onChange={(value) => onChangeMeeting(value)}
                                            />  */}
                                        {/* </div>
                                    </UnmountClosed>
                                </div> */}
                            </div>
                            {/* <div className="campo">
                                <label className="" htmlFor="">Modalidad del evento</label>
                                <div className="flex justify-around">
                                    <div class="mr-4">
                                        <input onChange={formik.handleChange} id="modalidad1" type="radio" name="mode" className="hidden" value="Presencial" />
                                        <label for="modalidad1" className="flex items-center cursor-pointer">
                                            <span class="w-4 h-4 inline-block mr-1 border border-black rounded"></span>
                                            Presencial</label>
                                    </div>
                                    <div class="mr-4">
                                        <input onChange={formik.handleChange} id="modalidad2" type="radio" name="mode" className="hidden" value="Virtual" />
                                        <label for="modalidad2" className="flex items-center cursor-pointer">
                                            <span class="w-4 h-4 inline-block mr-1 border border-black rounded"></span>
                                            Virtual</label>
                                    </div>
                                </div>
                                <p className="text-red-600 text-sm">{formik.errors.mode ? <div>{formik.errors.mode}</div> : null}</p>
                            </div> */}
                                        
                            <div className="campo text-black">
                                <label className="" htmlFor="">Nombre del evento</label>
                                <input className="rounded-md" onChange={formik.handleChange} type="text" name="name" placeholder="" value={formik.values.name} />
                                <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
                            </div>
                            <div className="campo text-black">
                                <div className="inline-flex items-baseline justify-between">
                                    <div>
                                        <label className="text-md" htmlFor="startdate">Fecha de inicio</label>
                                        <br />
                                        <input className="rounded-md" onChange={formik.handleChange} type="datetime-local" id="startdate" name="startdate" placeholder="" value={formik.values.startdate} />
                                        <p className="text-red-600 text-sm">{formik.errors.startdate ? <div>{formik.errors.startdate}</div> : null}</p>
                                    </div>
                                    <div className="m-3"></div>
                                    <div>
                                        <label className="text-md" htmlFor="enddate">Fecha de finalización</label>
                                        <br />
                                        <input className="rounded-md" onChange={formik.handleChange} type="datetime-local" id="enddate" name="enddate" placeholder="" value={formik.values.enddate} />
                                        <p className="text-red-600 text-sm">{formik.errors.enddate ? <div>{formik.errors.enddate}</div> : null}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="campo text-black">
                                <label className="" htmlFor="">Descripción del evento</label>
                                <textarea className="rounded-md" onChange={formik.handleChange} type="text" name="description" placeholder="" value={formik.values.description} rows="2" cols="50" />
                                <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
                            </div>
                            <div className="campo text-black">
                                <label className="mb-2" htmlFor="area">Seleccionar grupo</label>
                                <Select
                                    options={opciones}
                                    name="area"
                                    onChange={(value) => onChange(value)}
                                />
                                <p className="text-red-600 text-sm">{formik.errors.area ? <div>{formik.errors.area}</div> : null}</p>
                            </div>
                            <div className="flex items-end mb-5 ">
                                <Tippy interactive={true} placement={'bottom'} content={
                                    <CompactPicker
                                        color={selectedColor}
                                        onChangeComplete={color => setSelectedColor(color.hex)}
                                    />
                                }>
                                    <span class="text-center bg-iacblack px-4 py-2 w-12 h-12 rounded-md" style={{ backgroundColor: selectedColor }}></span>
                                </Tippy>
                                <label className="ml-5 mb-3" htmlFor="">Selecciona el color del evento</label>
                            </div>

                            <button type="submit" className="block ml-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 border border-blue-300 hover:bg-opacity-100">Guardar</button>
                        </form>
                    )}
                    {isOpenMeeting && (<AgregarMeeting onCloseSubMenu={openMenuAdd} />)}
                </div>
            </div>
        </>
    );
}

export default AgregarEvento;