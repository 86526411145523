import { attempts_scorm_constants } from '../constants/attemptScorm';


const initialState = {
    attempts_scorm: [],
    loading_attempts_scorm: null,
    error_attempts_scorm: null,

    loading_attempts_scorm_delete: false,
    attempts_scorm_delete: false,
    error_attempts_scorm_delete: false,
};

export function attempts_scorm_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET ATTEMPTS_SCORM--------------//
        case attempts_scorm_constants.ATTEMPTS_SCORM_REQUEST:
            return {
                ...state,
                loading_attempts_scorm: action.payload,
            };
        case attempts_scorm_constants.ATTEMPTS_SCORM_SUCCESS:
            return {
                ...state,
                loading_attempts_scorm: false,
                attempts_scorm: action.payload,
                error_attempts_scorm: null
            };
        case attempts_scorm_constants.ATTEMPTS_SCORM_FAILURE:
            return {
                ...state,
                loading_attempts_scorm: false,
                error_attempts_scorm: action.payload
            };

        //------------DELETE ATTEMPTS_SCORM--------------//
        case attempts_scorm_constants.ATTEMPTS_SCORM_DELETE_REQUEST:
            return {
                ...state,
                loading_attempts_scorm_delete: action.payload,
                attempts_scorm_delete: null,
                error_attempts_scorm_delete: null
            };
        case attempts_scorm_constants.ATTEMPTS_SCORM_DELETE_SUCCESS:
            return {
                ...state,
                loading_attempts_scorm_delete: false,
                attempts_scorm_delete: action.payload,
                error_attempts_scorm_delete: false
            };
        case attempts_scorm_constants.ATTEMPTS_SCORM_DELETE_FAILURE:
            return {
                ...state,
                loading_attempts_scorm_delete: false,
                attempts_scorm_delete: 0,
                error_attempts_scorm_delete: action.payload
            };
        default:
            return state
    }
}