import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import evaluacion from '../../../../../assets/img/cursos/evaluacion.png'
import { cohorts_actions } from '../../../../../store/actions/cohorts';
import SubMenuAddUserCohort from '../../SubMenu/SubMenuAddUserCohort';
import Select from 'react-dropdown-select';
import api_iac from '../../../../../config/instance_axios';
import { user } from '../../../../../config/user_token';
import AddusersCohort from './AddUsersCohort';
/* import Select from 'react-select'; */

const EditarCohorte = ({ cohorte, onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories);

    let categoria = new Array();
    useEffect(()=>{
        
    },[]);
    const orderCategori = function (categoriasuperior, path) {

        if (categoriasuperior.children.length > 0) {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
            categoriasuperior.children.map(categoriachildren => {
                orderCategori(categoriachildren, `${path} / ${categoriachildren.name}`);
                if (categoriachildren.id != cohorte.id_category) {
                }

            });
        } else {
            categoria.push({ "nombre": categoriasuperior.name, "id": categoriasuperior.id, "path": `${path}`, "parent": categoriasuperior.parent });
        }
    }

    categories.categories.map(categoriesraiz => {

        if (categoriesraiz.parent == 0 && categoriesraiz.hasOwnProperty('children')) {

            orderCategori(categoriesraiz, `${categoriesraiz.name}`);
        }

    });
    const users = useSelector(state => state.users);
    const user_cohorts = users.users.map((option) => ({
        ...option,
        label: option.fullname,
        value: option.id,
        cohortid: option.cohortid
    }));

    const [user_cohorts_select, set_user_cohorts_select] = useState([]);
    // const [user_cohorts_members, set_user_cohorts_select] = useState([]);
    const cohorts_members = async () => {
        const request_cohorts_members = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&wsfunction=core_cohort_get_cohort_members&moodlewsrestformat=json&cohortids[0]=${cohorte.id}`);

        const temp = [];

        request_cohorts_members.data[0].userids.forEach((item) => {

            const idx = user_cohorts.find(user => user.id === item);
            temp.push(idx);
        });

        set_user_cohorts_select(temp);
    }

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        cohorte: {}
    });

    const clickSubMenu = (type, open_param, cohorte) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            cohorte: cohorte
        })
    }

    const validate_edit = Yup.object().shape({
        id: Yup.string().required('El campo es requerido'),
        name: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: { ...cohorte },
        validationSchema: validate_edit,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async cohort => {
        let select = document.querySelector("#id_category");
        cohort.id_category = select.selectedOptions[0].value;
        await cohorts_actions.cohorts_edit(cohort);
        onCloseSubMenu(false); 
        dispatch(cohorts_actions.get_all_cohorts());
    }


    return (
        <div>
            <div>
                {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label className="mb-2" htmlFor="">Nombre del grupo</label>
                        <input type="text" onChange={formik.handleChange} name="name" placeholder="Nombre del cohorte" value={formik.values.name} />
                        <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="mb-2" htmlFor="">Categoria</label>
                        <select onChange={formik.handleChange} name="id_category" id="id_category" value={formik.values.id_category}>
                            <option className="bg-blue-600" value={0}>Sistemas</option>
                            {categories.loading_categories ? 'Cargando...' :
                                categories.categories.length > 0
                                    ? categoria.map(item =>
                                        item.id == cohorte.id_category ?
                                            <option className="bg-blue-600" selected value={item.id} >{item.path}</option>
                                            : <option className="bg-blue-600" value={item.id} >{item.path}</option>
                                    )
                                    : null
                            }
                        </select>
                    </div>
                    <div className="block-inline text-center ">
                        <button type="button" style={{ maxWidth: "45%" }}
                            className="mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  border border-blue-300 hover:bg-opacity-100"
                            onClick={() => clickSubMenu('Agregar Usuarios', !openSubMenu.open)}>
                            Agregar Usuarios
                        </button>
                        <button type="submit" style={{ maxWidth: "45%" }}
                            className="mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-40  border border-blue-300 hover:bg-opacity-100">Guardar</button>
                    </div>
                </form>
            </div>
            {openSubMenu.open ?
                <SubMenuAddUserCohort>
                    <div>
                        <div className=" ml-2 flex justify-between items-center text-2xl p-4">
                            <p >{openSubMenu.type}</p>
                            <div className="flex"
                                onClick={() => setOpenSubMenu(false)}>
                                <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0rem'}} />
                            </div>
                        </div>
                    </div>
                    <AddusersCohort cohorte={cohorte}></AddusersCohort>
                </SubMenuAddUserCohort>
                : null}
        </div>
    );
}

export default EditarCohorte;
