import { users_constants } from '../constants/users';


const initialState = {
    users: [],

    user_create: {},
    loading_users_create: null,
    error_user_create: null,

    users_create: [],
    error_users_create: [],

    user_edit: {},
    loading_users_edit: null,
    error_user_edit: null,

    loading_users_delete: null,
    user_delete: null,
    error_user_delete: null,
};

export function users_reducers(state = initialState, action) {
  switch (action.type) {
    //------------GET USERS--------------//
    case users_constants.USERS_REQUEST:
        return {
            ...state,
            loading_users: action.payload,
        };
    case users_constants.USERS_SUCCESS:
        return {
            ...state,
            loading_users: false,
            users: action.payload,
            error_users: ''
        };
    case users_constants.USERS_FAILURE:
        return {
            ...state,
            loading_users: false,
            error_users: action.payload
        };

    //------------CREATE USER--------------//
    case users_constants.USER_CREATE_REQUEST:
        return {
            ...state,
            loading_users_create: action.payload,
            user_create: {},
            error_user_create: null
        };
    case users_constants.USER_CREATE_SUCCESS:
        return {
            ...state,
            loading_users_create: false,
            user_create: action.payload,
            error_user_create: false
        };
    case users_constants.USER_CREATE_FAILURE:
        return {
            ...state,
            loading_users_create: false,
            user_create: { },
            error_user_create: action.payload
        };

     //------------CREATE USERS--------------//
    case users_constants.USERS_CREATE_REQUEST:
        return {
            ...state,
            loading_users_create: action.payload,
            users_create: [],
            error_users_create: []
        };
    case users_constants.USERS_CREATE_SUCCESS:
        return {
            ...state,
            loading_users_create: false,
            users_create: action.payload,
            error_users_create: []
        };
    case users_constants.USERS_CREATE_FAILURE:
        return {
            ...state,
            loading_users_create: false,
            users_create: [],
            error_users_create: action.payload,
        };
    
    //------------UPDATE USER--------------//
    case users_constants.USER_EDIT_REQUEST:
        return {
            ...state,
            loading_users_edit: action.payload,
            user_edit: {},
            error_user_edit: null
        };
    case users_constants.USER_EDIT_SUCCESS:
        return {
            ...state,
            loading_users_edit: false,
            user_edit: action.payload,
            error_user_edit: false
        };
    case users_constants.USER_EDIT_FAILURE:
        return {
            ...state,
            loading_users_edit: false,
            user_edit: { },
            error_user_edit: action.payload
        };

    //------------DELETE USER--------------//
    case users_constants.USER_DELETE_REQUEST:
        return {
            ...state,
            loading_users_delete: action.payload,
            user_delete: null,
            error_user_delete: null
        };
    case users_constants.USER_DELETE_SUCCESS:
        return {
            ...state,
            loading_users_delete: false,
            user_delete: action.payload,
            error_user_delete: false
        };
    case users_constants.USER_DELETE_FAILURE:
        return {
            ...state,
            loading_users_delete: false,
            user_delete: 0,
            error_user_delete: action.payload
        };

    default:
      return state
  }
}