import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import buscar_usuario from '../../../assets/img/user/buscar_usuario.svg'
import crear_insignia from '../../../assets/img/insignias/crear_insignia.png'
import Header from './Header';
import SubMenu from './SubMenu/SubMenu';

import AgregarInsignia from './Insignia/AgregarInsignia';
import Insignia from './Insignia/Insignia'
import EditarInsignia from './Insignia/EditarInsignia';

const Insignias = ({ onClose }) => {
    const [busquedaRes, guardarBusquedadRes] = useState([]);
    const badges = useSelector(state => state.badges);

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        badge: {}
    });

    const clickSubMenu = (type, open_param, badge) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            badge: badge
        })
    }

    const handleChange = e => {
        if (e.target.value != '' && e.target.value != undefined) {
            const resultado = badges.badges.filter(item => {
                if (item) {
                    return item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
                }
            });
            guardarBusquedadRes(resultado);
        } else {
            guardarBusquedadRes(badges.badges);
        }
    }

    useEffect(() => {
        guardarBusquedadRes(badges.badges)
    }, [badges])

    return (
        <div>
            <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll">
                {openSubMenu.open ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null}
                <Header title="Insignias" onClose={onClose} />

                <div className="flex flex-row flex-wrap justify-end mb-2">
                    <div onClick={() => clickSubMenu('Agregar', !openSubMenu.open)} className=" m-2 flex items-center cursor-pointer">Crear insignia <p className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</p> </div>
                </div>

                {/* <div className="mb-4">
                    <div className="bg-white h-12 flex p-1" style={{border: '1px solid #797974'}}>
                        <input autoComplete="off" onChange={handleChange} className="bg-white w-11/12 p-1 mr-2" type="text" />
                        <img className="" src={buscar_usuario} alt="buscar_usuario" />
                    </div>

                    <p className=" text-sm text-right">Filtros</p>
                </div>
 */}
                <div>
                    {busquedaRes.length > 0 ?
                        <div className=" mt-5">
                            <p className="text-xs mb-1">Resultados: {busquedaRes.length}</p>
                            {busquedaRes.map(badge => (
                                <Insignia onCloseSubMenu={setOpenSubMenu} key={badge.id} badge={badge}
                                    clickSubMenu={() => clickSubMenu('Editar', !openSubMenu.open, badge)} />))}
                        </div>
                        : <p className="">No tienes insignias creadas</p>}
                    {openSubMenu.open ?
                        <SubMenu>
                            <div>
                                <div className="min-w-full  submenu">
                                    <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                        <div className=" m-2 flex justify-between items-center text-2xl">
                                            <p className="mr-1">{openSubMenu.type}</p>
                                            <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                                <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '-0.1rem'}} />
                                            </div>
                                        </div>
                                        {openSubMenu.type === 'Agregar' ?
                                            <AgregarInsignia onCloseSubMenu={setOpenSubMenu} /> :
                                            <EditarInsignia onCloseSubMenu={setOpenSubMenu} badge={openSubMenu.badge} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </SubMenu>
                        : null}
                </div>
            </div>
        </div>

    );
}


export default Insignias;