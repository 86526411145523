
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';

export const forums_service = {
    get_all_forums,
    discussion_create,
    discussion_edit,
    discussion_delete,
    respond_discussion,
    respond_forum_post,
    remove_comment_forum
};

//let user = JSON.parse(localStorage.getItem('user'));


async function get_all_forums(idForum) {
    const request_forums = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_forum_and_posts&
    &moodlewsrestformat=json&
    id=${idForum}&
    sortby=${'timemodified'}&
    sortdirection=${'DESC'}&
    page=${-1}&
    perpage=${0}`);

    if (Array.isArray(request_forums.data.discussions)) {
        const forums = request_forums.data.discussions
        return { forums };
    }

    const error = request_forums.data;
    return { error };
}

async function discussion_create(discussion) {
    const request_discussion_create = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_start_forum_discussion&
    moodlewsrestformat=json&
    forumid=${discussion.id}&
    subject=${discussion.subject}&
    message=${discussion.description}`);

    if (typeof request_discussion_create.data === 'object') {
        return 1;
    }

    const error = request_discussion_create.data;
    return { error };
}


async function discussion_edit(discussion) {
    const request_discussion_edit = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_edit_forum_post&
    moodlewsrestformat=json&
    postid=${discussion.id}&
    subject=${discussion.subject}&
    message=${discussion.description}`);

    if (typeof request_discussion_edit.data === 'object') {
        return 1;
    }

    const error = request_discussion_edit.data;
    return { error };
}

async function discussion_delete(idDiscussion) {
    const request_discussion_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
    wsfunction=targett_remove_discussion&
    moodlewsrestformat=json&
    id=${idDiscussion}`);

    if (typeof request_discussion_delete.data === 'object') {
        return 1;
    }

    const error = request_discussion_delete.data;
    return { error };
}

async function respond_discussion (discussion) {
    const request_remove_comment_forum = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_respond_forum_discussion&
    moodlewsrestformat=json&
    discussionid=${discussion.id}&
    subject=${discussion.subject}&
    message=${discussion.description}`);

    if (typeof request_remove_comment_forum.data === 'object') {
        return 1;
    }

    const error = request_remove_comment_forum.data.forums;
    return { error };
}

async function respond_forum_post (post) {
    const request_remove_comment_forum_post = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_respond_forum_post&
    moodlewsrestformat=json&
    postid=${post.id}&
    subject=${post.subject}&
    message=${post.description}`);


    if (typeof request_remove_comment_forum_post.data === 'object') {
        return 1;
    }

    const error = request_remove_comment_forum_post.data.forums;
    return { error };
}

async function remove_comment_forum(forum) {
    const request_remove_comment_forum = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_remove_response&
    moodlewsrestformat=json&
    id=${forum}`);

    if (typeof request_remove_comment_forum.data === 'object') {
        return 1;
    }
    
    const error = request_remove_comment_forum.data.forums;
    return { error };
}