import { useRef, useState } from "react"
import st from "./styles.module.css"
import moment, { locale } from "moment/moment"
import { numerosfideicomisos } from "../../../../store/constants/fideicomisosinverso";

export const TableFideicomiso = ({ data, datados }) => {

    //Imprimir el tipo de dato que se recibe

    console.debug(data);


    let claves = Object.keys(data)

    let cursosAsignadosTotal = 0;
    let cursosRealizadosTotal = 0;
    let porcentaje_avanceTotal = 0;
    let promedio_calificacionesTotal = 0;
    let usuariosTotal = 0;

    console.debug(datados);
    console.debug(datados['data']);
    
    // Previene pantalla en blanco cuando no se recibe respuesta del servidor...
    //if (!datados) {
        //return <></>;
   // }

    claves.map((key, index) => {
        cursosAsignadosTotal += data[key].cantidad_cursos;
        cursosRealizadosTotal += data[key].cursos_terminados;
        porcentaje_avanceTotal += data[key].porcentaje_avance;
        promedio_calificacionesTotal += data[key].promedio_calificaciones;
        usuariosTotal += 1;
    })

    //Sacar total de
    return (
        <div className={st.container}>

            <div className={st.fecha__consultada_fideicomiso}>
                <div className={st.fecha_consultada_contenedor}>
                    <p className={st.fecha__consultada_fideicomiso_text}>Total de usuarios</p>
                    <p className={st.fecha_fideicomiso}>{claves.length}</p>
                </div>
                <div className={st.fecha_consultada_contenedor}>
                    <p className={st.fecha__consultada_fideicomiso_text}>Fecha de consulta</p>
                    <p className={st.fecha_fideicomiso}>{new Date().toLocaleDateString()}</p>
                </div>
            </div>

            <table className={st.table}>
                <thead className={st.thead__table}>
                    <tr>
                        <th>Órgano</th>
                        <th>Usuario</th>
                        <th>Nombre</th>
                        <th>Cursos asignados</th>
                        <th>Cursos realizados</th>
                        <th>% Avance</th>
                        <th>Promedio Calificaciones</th>
                    </tr>
                </thead>
                <tbody>

                    {claves.map((key, index) => {
                        return (
                            <tr key={index}>
                                <td>{data[key].area ? data[key].area : 'Sin area'}</td>
                                <td>{data[key].nombre_usuario}</td>
                                <td>{data[key].nombre}</td>
                                <td>{data[key].cantidad_cursos}</td>
                                <td>{data[key].cursos_terminados}</td>
                                <td>{data[key].porcentaje_avance}</td>
                                <td>{data[key].promedio_calificaciones}</td>
                            </tr>

                        )

                    })}


                </tbody>
            </table>

            <table className={st.table}>
                <thead className={st.thead__table}>
                    <tr>
                        <th>Cursos asignados</th>
                        <th>Cursos finalizados</th>
                        <th>Porcentaje avance</th>
                        <th>Promedio calificaciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{cursosAsignadosTotal}</td>
                        <td>{cursosRealizadosTotal}</td>
                        <td>{(porcentaje_avanceTotal / usuariosTotal).toFixed(2)}</td>
                        <td>{(promedio_calificacionesTotal / usuariosTotal).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>


        </div>
    )
}

export const TableIndividual = ({ data }) => {
    console.log(data)
    return (
        <div className={st.container}>

            <h1 className={st.title}>Reporte de resultados obtenidos en el proceso de capacitación e-learning</h1>

            <div className={st.fecha__consultada}>
                <p className={st.fecha__consultada_text}>Fecha de consulta: </p>
                <p className={st.fecha}>{
                    new Date().toLocaleDateString()
                }</p>
            </div>

            <div className={st.data__consultada}>
                <div className={st.data__consultada_item}>
                    <p className={st.data__consultada_text}>Usuario: </p>
                    <p className={st.data}>{data.username}</p>
                </div>

                <div className={st.data__consultada_item}>
                    <p className={st.data__consultada_text}>Nombre completo: </p>
                    <p className={st.data}>{data.nombre}</p>
                </div>

                <div className={st.data__consultada_item}>
                    <p className={st.data__consultada_text}>Organo: </p>
                    <p className={st.data}>{data.area}</p>
                </div>
            </div>

            <table className={st.table}>
                <thead className={st.thead__table}>
                    <tr>
                        <th>Nombre persona</th>
                        <th>Nombre curso</th>
                        <th>Calificación</th>
                        <th>Aprobatoria</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.datos.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.firstname} {item.lastname}</td>
                                    <td>{item.NombreCurso}</td>
                                    <td>{parseFloat(item.Calificacion).toFixed(2)}</td>
                                    <td>{parseFloat(item.Calificacion).toFixed(2) >= 80.00 ? 'Si' : 'No'}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            <div className={st.fecha__consultada}>
                <table className={st.table__container}>
                    {
                        //Promedio | 90
                        // Avance | 21% | 5/34
                    }
                    <thead className={st.thead__table}>
                        <tr>
                            <th>Promedio</th>
                            <th colSpan={2}>Avance</th>
                        </tr>
                    </thead>


                    <tbody>

                        <tr>
                            <td>{data.promedio}</td>
                            <td>{data.porcentaje_avance}</td>
                            <td>{data.cursos_terminados}/{data.cantidad_cursos}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export const TableGeneral = ({ data }) => {
    //data[0].usuarios

    console.log(data)

    let claves = Object.keys(data)

    let totalUsuariosFideicomisos = 0;

    console.log(claves)

    claves.map((key, index) => {
        if(key != 'cursos_terminados' && key != 'cursos_totales' && key != 'porcentaje_avance' && key != 'promedio_calificaciones' && key != 'total_usuarios' && key != 'total_areas' && key != 20 && key != 22 && key != 23){
            totalUsuariosFideicomisos += data[key].usuarios;
        }
    })

    //Recorrer los usuarios y sumarlos



    return (
        <div className={st.container}>

            <div className={st.div_container_general}>
                <div className={st.div_container_general_text}>
                    <p className={st.fecha__consultada_fideicomiso_text}>Usuarios inscritos</p>
                    <p className={st.fecha}>{data['total_usuarios']}</p>
                </div>
                <div className={st.div_container_general_text}>
                    <p className={st.fecha__consultada_fideicomiso_text}>Fecha consultada</p>
                    <p className={st.fecha}>{new Date().toLocaleDateString()}</p>
                </div>
            </div>

            <table className={st.table}>
                <thead className={st.thead__table}>
                    <tr>
                        <td rowSpan={3}>
                            Fideicomiso
                        </td>
                        <td colSpan={8}>
                            Miembros por órgano
                        </td>
                        <td rowSpan={3}>
                            Cursos totales
                        </td>
                        <td rowSpan={3}>
                            Cursos realizados
                        </td>
                        <td rowSpan={3}>
                            % Avance
                        </td>
                        <td rowSpan={3}>
                            Promedio calificaciones
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2}>RS</td>
                        <td rowSpan={2}>CT</td>
                        <td rowSpan={2}>CA</td>
                        <td rowSpan={2}>CV</td>
                        <td rowSpan={2}>P</td>
                        <td rowSpan={2}>AR</td>
                        <td rowSpan={2}>PI</td>
                        <td rowSpan={2}>TOTAL</td>
                    </tr>
                </thead>
                <tbody>
                    {data.hasOwnProperty(1) && <tr>
                        <td>
                            FIGLOSNTE 2
                        </td>
                        <td>
                            {(data[1]['areas']['REPRESENTANTE DE SOCIO'] ? data[1]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[1]['areas']['REPRESENTANTES DE SOCIOS'] ? data[1]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[1]['areas']['COMITÉ TÉCNICO'] ? data[1]['areas']['COMITÉ TÉCNICO'] : 0) + (data[1]['areas']['COMITE TECNICO'] ? data[1]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[1]['areas']['COMISION DE AUDITORIA'] ? data[1]['areas']['COMISION DE AUDITORIA'] : 0) + (data[1]['areas']['COMISIÓN DE AUDITORÍA'] ? data[1]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[1]['areas']['CONSEJO DE VIGILANCIA'] ? data[1]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[1]['areas']['PATRONATO'] ? data[1]['areas']['PATRONATO'] : 0) + (data[1]['areas']['PATRONATO DE OBRAS'] ? data[1]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[1]['areas']['ASISTENTE REGIONAL'] ? data[1]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[1]['areas']['CORPORATIVO'] ? data[1]['areas']['CORPORATIVO'] : 0) + (data[1]['areas']['PLAZA SINALOA'] ? data[1]['areas']['PLAZA SINALOA'] : 0) + (data[1]['areas']['FIGLOSNTE 66'] ? data[1]['areas']['FIGLOSNTE 66'] : 0) + (data[1]['areas']['PROMOTOR'] ? data[1]['areas']['PROMOTOR'] : 0) + (data[1]['areas']['Otro valor por defecto'] ? data[1]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[1]['areas']['REPRESENTANTE DE SOCIO'] ? data[1]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[1]['areas']['REPRESENTANTES DE SOCIOS'] ? data[1]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[1]['areas']['COMITÉ TÉCNICO'] ? data[1]['areas']['COMITÉ TÉCNICO'] : 0) + (data[1]['areas']['COMITE TECNICO'] ? data[1]['areas']['COMITE TECNICO'] : 0) + (data[1]['areas']['COMISIÓN DE AUDITORÍA'] ? data[1]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[1]['areas']['COMISION DE AUDITORIA'] ? data[1]['areas']['COMISION DE AUDITORIA'] : 0) + (data[1]['areas']['CONSEJO DE VIGILANCIA'] ? data[1]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[1]['areas']['PATRONATO'] ? data[1]['areas']['PATRONATO'] : 0) + (data[1]['areas']['PATRONATO DE OBRAS'] ? data[1]['areas']['PATRONATO DE OBRAS'] : 0) + (data[1]['areas']['ASISTENTE REGIONAL'] ? data[1]['areas']['ASISTENTE REGIONAL'] : 0) + (data[1]['areas']['CORPORATIVO'] ? data[1]['areas']['CORPORATIVO'] : 0) + (data[1]['areas']['PLAZA SINALOA'] ? data[1]['areas']['PLAZA SINALOA'] : 0) + (data[1]['areas']['FIGLOSNTE 66'] ? data[1]['areas']['FIGLOSNTE 66'] : 0) + (data[1]['areas']['PROMOTOR'] ? data[1]['areas']['PROMOTOR'] : 0) + (data[1]['areas']['Otro valor por defecto'] ? data[1]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[1]['cantidad_cursos']}
                        </td>
                        <td>
                            {data[1]['cantidad_cursos_terminados']}
                        </td>
                        <td>
                            {data[1]['porcentaje_avance']}
                        </td>
                        <td>
                            {data[1]['promedio_calificaciones']}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(2) && <tr>
                        <td>
                            FIGLOSNTE 8
                        </td>
                        <td>
                            {(data[2]['areas']['REPRESENTANTE DE SOCIO'] ? data[2]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[2]['areas']['REPRESENTANTES DE SOCIOS'] ? data[2]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[2]['areas']['COMITÉ TÉCNICO'] ? data[2]['areas']['COMITÉ TÉCNICO'] : 0) + (data[2]['areas']['COMITE TECNICO'] ? data[2]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[2]['areas']['COMISION DE AUDITORIA'] ? data[2]['areas']['COMISION DE AUDITORIA'] : 0) + (data[2]['areas']['COMISIÓN DE AUDITORÍA'] ? data[2]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[2]['areas']['CONSEJO DE VIGILANCIA'] ? data[2]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[2]['areas']['PATRONATO'] ? data[2]['areas']['PATRONATO'] : 0) + (data[2]['areas']['PATRONATO DE OBRAS'] ? data[2]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[2]['areas']['ASISTENTE REGIONAL'] ? data[2]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[2]['areas']['CORPORATIVO'] ? data[2]['areas']['CORPORATIVO'] : 0) + (data[2]['areas']['PLAZA SINALOA'] ? data[2]['areas']['PLAZA SINALOA'] : 0) + (data[2]['areas']['FIGLOSNTE 66'] ? data[2]['areas']['FIGLOSNTE 66'] : 0) + (data[2]['areas']['PROMOTOR'] ? data[2]['areas']['PROMOTOR'] : 0) + (data[2]['areas']['Otro valor por defecto'] ? data[2]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[2]['areas']['REPRESENTANTE DE SOCIO'] ? data[2]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[2]['areas']['REPRESENTANTES DE SOCIOS'] ? data[2]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[2]['areas']['COMITÉ TÉCNICO'] ? data[2]['areas']['COMITÉ TÉCNICO'] : 0) + (data[2]['areas']['COMITE TECNICO'] ? data[2]['areas']['COMITE TECNICO'] : 0) + (data[2]['areas']['COMISIÓN DE AUDITORÍA'] ? data[2]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[2]['areas']['COMISION DE AUDITORIA'] ? data[2]['areas']['COMISION DE AUDITORIA'] : 0) + (data[2]['areas']['CONSEJO DE VIGILANCIA'] ? data[2]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[2]['areas']['PATRONATO'] ? data[2]['areas']['PATRONATO'] : 0) + (data[2]['areas']['PATRONATO'] ? data[2]['areas']['PATRONATO'] : 0) + (data[2]['areas']['ASISTENTE REGIONAL'] ? data[2]['areas']['ASISTENTE REGIONAL'] : 0) + (data[2]['areas']['CORPORATIVO'] ? data[2]['areas']['CORPORATIVO'] : 0) + (data[2]['areas']['PLAZA SINALOA'] ? data[2]['areas']['PLAZA SINALOA'] : 0) + (data[2]['areas']['FIGLOSNTE 66'] ? data[2]['areas']['FIGLOSNTE 66'] : 0) + (data[2]['areas']['PROMOTOR'] ? data[2]['areas']['PROMOTOR'] : 0) + (data[2]['areas']['Otro valor por defecto'] ? data[2]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[2]['cantidad_cursos']}
                        </td>
                        <td>
                            {data[2]['cantidad_cursos_terminados']}
                        </td>
                        <td>
                            {data[2]['porcentaje_avance']}
                        </td>
                        <td>
                            {data[2]['promedio_calificaciones']}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(3) && <tr>
                        <td>
                            FIGLOSNTE 13
                        </td>
                        <td>
                            {(data[3]['areas']['REPRESENTANTE DE SOCIO'] ? data[3]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[3]['areas']['REPRESENTANTES DE SOCIOS'] ? data[3]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[3]['areas']['COMITÉ TÉCNICO'] ? data[3]['areas']['COMITÉ TÉCNICO'] : 0) + (data[3]['areas']['COMITE TECNICO'] ? data[3]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[3]['areas']['COMISION DE AUDITORIA'] ? data[3]['areas']['COMISION DE AUDITORIA'] : 0) + (data[3]['areas']['COMISIÓN DE AUDITORÍA'] ? data[3]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[3]['areas']['CONSEJO DE VIGILANCIA'] ? data[3]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[3]['areas']['PATRONATO'] ? data[3]['areas']['PATRONATO'] : 0) + (data[3]['areas']['PATRONATO DE OBRAS'] ? data[3]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[3]['areas']['ASISTENTE REGIONAL'] ? data[3]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[3]['areas']['CORPORATIVO'] ? data[3]['areas']['CORPORATIVO'] : 0) + (data[3]['areas']['PLAZA SINALOA'] ? data[3]['areas']['PLAZA SINALOA'] : 0) + (data[3]['areas']['FIGLOSNTE 66'] ? data[3]['areas']['FIGLOSNTE 66'] : 0) + (data[3]['areas']['PROMOTOR'] ? data[3]['areas']['PROMOTOR'] : 0) + (data[3]['areas']['Otro valor por defecto'] ? data[3]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[3]['areas']['REPRESENTANTE DE SOCIO'] ? data[3]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[3]['areas']['REPRESENTANTES DE SOCIOS'] ? data[3]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[3]['areas']['COMITÉ TÉCNICO'] ? data[3]['areas']['COMITÉ TÉCNICO'] : 0) + (data[3]['areas']['COMITE TECNICO'] ? data[3]['areas']['COMITE TECNICO'] : 0) + (data[3]['areas']['COMISIÓN DE AUDITORÍA'] ? data[3]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[3]['areas']['COMISION DE AUDITORIA'] ? data[3]['areas']['COMISION DE AUDITORIA'] : 0) + (data[3]['areas']['CONSEJO DE VIGILANCIA'] ? data[3]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[3]['areas']['PATRONATO'] ? data[3]['areas']['PATRONATO'] : 0) + (data[3]['areas']['PATRONATO'] ? data[3]['areas']['PATRONATO'] : 0) + (data[3]['areas']['ASISTENTE REGIONAL'] ? data[3]['areas']['ASISTENTE REGIONAL'] : 0) + (data[3]['areas']['CORPORATIVO'] ? data[3]['areas']['CORPORATIVO'] : 0) + (data[3]['areas']['PLAZA SINALOA'] ? data[3]['areas']['PLAZA SINALOA'] : 0) + (data[3]['areas']['FIGLOSNTE 66'] ? data[3]['areas']['FIGLOSNTE 66'] : 0) + (data[3]['areas']['PROMOTOR'] ? data[3]['areas']['PROMOTOR'] : 0) + (data[3]['areas']['Otro valor por defecto'] ? data[3]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[3]['cantidad_cursos']}
                        </td>
                        <td>
                            {data[3]['cantidad_cursos_terminados']}
                        </td>
                        <td>
                            {data[3]['porcentaje_avance']}
                        </td>
                        <td>
                            {data[3]['promedio_calificaciones']}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(4) && <tr>
                        <td>
                            FIGLOSNTE 16
                        </td>
                        <td>
                            {(data[4]['areas']['REPRESENTANTE DE SOCIO'] ? data[4]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[4]['areas']['REPRESENTANTES DE SOCIOS'] ? data[4]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[4]['areas']['COMITÉ TÉCNICO'] ? data[4]['areas']['COMITÉ TÉCNICO'] : 0) + (data[4]['areas']['COMITE TECNICO'] ? data[4]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[4]['areas']['COMISION DE AUDITORIA'] ? data[4]['areas']['COMISION DE AUDITORIA'] : 0) + (data[4]['areas']['COMISIÓN DE AUDITORÍA'] ? data[4]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[4]['areas']['CONSEJO DE VIGILANCIA'] ? data[4]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[4]['areas']['PATRONATO'] ? data[4]['areas']['PATRONATO'] : 0) + (data[4]['areas']['PATRONATO DE OBRAS'] ? data[4]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[4]['areas']['ASISTENTE REGIONAL'] ? data[4]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[4]['areas']['CORPORATIVO'] ? data[4]['areas']['CORPORATIVO'] : 0) + (data[4]['areas']['PLAZA SINALOA'] ? data[4]['areas']['PLAZA SINALOA'] : 0) + (data[4]['areas']['FIGLOSNTE 66'] ? data[4]['areas']['FIGLOSNTE 66'] : 0) + (data[4]['areas']['PROMOTOR'] ? data[4]['areas']['PROMOTOR'] : 0) + (data[4]['areas']['Otro valor por defecto'] ? data[4]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[4]['areas']['REPRESENTANTE DE SOCIO'] ? data[4]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[4]['areas']['REPRESENTANTES DE SOCIOS'] ? data[4]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[4]['areas']['COMITÉ TÉCNICO'] ? data[4]['areas']['COMITÉ TÉCNICO'] : 0) + (data[4]['areas']['COMITE TECNICO'] ? data[4]['areas']['COMITE TECNICO'] : 0) + (data[4]['areas']['COMISIÓN DE AUDITORÍA'] ? data[4]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[4]['areas']['COMISION DE AUDITORIA'] ? data[4]['areas']['COMISION DE AUDITORIA'] : 0) + (data[4]['areas']['CONSEJO DE VIGILANCIA'] ? data[4]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[4]['areas']['PATRONATO'] ? data[4]['areas']['PATRONATO'] : 0) + (data[4]['areas']['PATRONATO'] ? data[4]['areas']['PATRONATO'] : 0) + (data[4]['areas']['ASISTENTE REGIONAL'] ? data[4]['areas']['ASISTENTE REGIONAL'] : 0) + (data[4]['areas']['CORPORATIVO'] ? data[4]['areas']['CORPORATIVO'] : 0) + (data[4]['areas']['PLAZA SINALOA'] ? data[4]['areas']['PLAZA SINALOA'] : 0) + (data[4]['areas']['FIGLOSNTE 66'] ? data[4]['areas']['FIGLOSNTE 66'] : 0) + (data[4]['areas']['PROMOTOR'] ? data[4]['areas']['PROMOTOR'] : 0) + (data[4]['areas']['Otro valor por defecto'] ? data[4]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[4]['cantidad_cursos']}
                        </td>
                        <td>
                            {data[4]['cantidad_cursos_terminados']}
                        </td>
                        <td>
                            {data[4]['porcentaje_avance']}
                        </td>
                        <td>
                            {data[4]['promedio_calificaciones']}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(5) && <tr>
                        <td>
                            FIGLOSNTE 23
                        </td>
                        <td>
                            {(data[5]['areas']['REPRESENTANTE DE SOCIO'] ? data[5]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[5]['areas']['REPRESENTANTES DE SOCIOS'] ? data[5]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[5]['areas']['COMITÉ TÉCNICO'] ? data[5]['areas']['COMITÉ TÉCNICO'] : 0) + (data[5]['areas']['COMITE TECNICO'] ? data[5]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[5]['areas']['COMISION DE AUDITORIA'] ? data[5]['areas']['COMISION DE AUDITORIA'] : 0) + (data[5]['areas']['COMISIÓN DE AUDITORÍA'] ? data[5]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[5]['areas']['CONSEJO DE VIGILANCIA'] ? data[5]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[5]['areas']['CONSEJO DE VIGILANCIA'] ? data[5]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[5]['areas']['PATRONATO'] ? data[5]['areas']['PATRONATO'] : 0) + (data[5]['areas']['PATRONATO DE OBRAS'] ? data[5]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[5]['areas']['ASISTENTE REGIONAL'] ? data[5]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[5]['areas']['CORPORATIVO'] ? data[5]['areas']['CORPORATIVO'] : 0) + (data[5]['areas']['PLAZA SINALOA'] ? data[5]['areas']['PLAZA SINALOA'] : 0) + (data[5]['areas']['FIGLOSNTE 66'] ? data[5]['areas']['FIGLOSNTE 66'] : 0) + (data[5]['areas']['PROMOTOR'] ? data[5]['areas']['PROMOTOR'] : 0) + (data[5]['areas']['Otro valor por defecto'] ? data[5]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[5]['areas']['REPRESENTANTE DE SOCIO'] ? data[5]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[5]['areas']['REPRESENTANTES DE SOCIOS'] ? data[5]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[5]['areas']['COMITÉ TÉCNICO'] ? data[5]['areas']['COMITÉ TÉCNICO'] : 0) + (data[5]['areas']['COMITE TECNICO'] ? data[5]['areas']['COMITE TECNICO'] : 0) + (data[5]['areas']['COMISIÓN DE AUDITORÍA'] ? data[5]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[5]['areas']['COMISION DE AUDITORIA'] ? data[5]['areas']['COMISION DE AUDITORIA'] : 0) + (data[5]['areas']['CONSEJO DE VIGILANCIA'] ? data[5]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[5]['areas']['CONSEJO DE VIGILANCIA'] ? data[5]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[5]['areas']['PATRONATO'] ? data[5]['areas']['PATRONATO'] : 0) + (data[5]['areas']['PATRONATO'] ? data[5]['areas']['PATRONATO'] : 0) + (data[5]['areas']['ASISTENTE REGIONAL'] ? data[5]['areas']['ASISTENTE REGIONAL'] : 0) + (data[5]['areas']['CORPORATIVO'] ? data[5]['areas']['CORPORATIVO'] : 0) + (data[5]['areas']['PLAZA SINALOA'] ? data[5]['areas']['PLAZA SINALOA'] : 0) + (data[5]['areas']['FIGLOSNTE 66'] ? data[5]['areas']['FIGLOSNTE 66'] : 0) + (data[5]['areas']['PROMOTOR'] ? data[5]['areas']['PROMOTOR'] : 0) + (data[5]['areas']['Otro valor por defecto'] ? data[5]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[5]['cantidad_cursos'] ? data[5]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[5]['cantidad_cursos_terminados'] ? data[5]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[5]['porcentaje_avance'] ? data[5]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[5]['promedio_calificaciones'] ? data[5]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(6) && <tr>
                        <td>
                            FIGLOSNTE 25
                        </td>
                        <td>
                            {(data[6]['areas']['REPRESENTANTE DE SOCIO'] ? data[6]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[6]['areas']['REPRESENTANTES DE SOCIOS'] ? data[6]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[6]['areas']['COMITÉ TÉCNICO'] ? data[6]['areas']['COMITÉ TÉCNICO'] : 0) + (data[6]['areas']['COMITE TECNICO'] ? data[6]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[6]['areas']['COMISION DE AUDITORIA'] ? data[6]['areas']['COMISION DE AUDITORIA'] : 0) + (data[6]['areas']['COMISIÓN DE AUDITORÍA'] ? data[6]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[6]['areas']['CONSEJO DE VIGILANCIA'] ? data[6]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[6]['areas']['PATRONATO'] ? data[6]['areas']['PATRONATO'] : 0) + (data[6]['areas']['PATRONATO DE OBRAS'] ? data[6]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[6]['areas']['ASISTENTE REGIONAL'] ? data[6]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[6]['areas']['CORPORATIVO'] ? data[6]['areas']['CORPORATIVO'] : 0) + (data[6]['areas']['PLAZA SINALOA'] ? data[6]['areas']['PLAZA SINALOA'] : 0) + (data[6]['areas']['FIGLOSNTE 66'] ? data[6]['areas']['FIGLOSNTE 66'] : 0) + (data[6]['areas']['PROMOTOR'] ? data[6]['areas']['PROMOTOR'] : 0) + (data[6]['areas']['Otro valor por defecto'] ? data[6]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[6]['areas']['REPRESENTANTE DE SOCIO'] ? data[6]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[6]['areas']['REPRESENTANTES DE SOCIOS'] ? data[6]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[6]['areas']['COMITÉ TÉCNICO'] ? data[6]['areas']['COMITÉ TÉCNICO'] : 0) + (data[6]['areas']['COMITE TECNICO'] ? data[6]['areas']['COMITE TECNICO'] : 0) + (data[6]['areas']['COMISIÓN DE AUDITORÍA'] ? data[6]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[6]['areas']['COMISION DE AUDITORIA'] ? data[6]['areas']['COMISION DE AUDITORIA'] : 0) + (data[6]['areas']['CONSEJO DE VIGILANCIA'] ? data[6]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[6]['areas']['PATRONATO'] ? data[6]['areas']['PATRONATO'] : 0) + (data[6]['areas']['PATRONATO'] ? data[6]['areas']['PATRONATO'] : 0) + (data[6]['areas']['ASISTENTE REGIONAL'] ? data[6]['areas']['ASISTENTE REGIONAL'] : 0) + (data[6]['areas']['CORPORATIVO'] ? data[6]['areas']['CORPORATIVO'] : 0) + (data[6]['areas']['PLAZA SINALOA'] ? data[6]['areas']['PLAZA SINALOA'] : 0) + (data[6]['areas']['FIGLOSNTE 66'] ? data[6]['areas']['FIGLOSNTE 66'] : 0) + (data[6]['areas']['PROMOTOR'] ? data[6]['areas']['PROMOTOR'] : 0) + (data[6]['areas']['Otro valor por defecto'] ? data[6]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[6]['cantidad_cursos'] ? data[6]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[6]['cantidad_cursos_terminados'] ? data[6]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[6]['porcentaje_avance'] ? data[6]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[6]['promedio_calificaciones'] ? data[6]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(7) && <tr>
                        <td>
                            FIGLOSNTE 26
                        </td>
                        <td>
                            {(data[7]['areas']['REPRESENTANTE DE SOCIO'] ? data[7]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[7]['areas']['REPRESENTANTES DE SOCIOS'] ? data[7]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[7]['areas']['COMITÉ TÉCNICO'] ? data[7]['areas']['COMITÉ TÉCNICO'] : 0) + (data[7]['areas']['COMITE TECNICO'] ? data[7]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[7]['areas']['COMISION DE AUDITORIA'] ? data[7]['areas']['COMISION DE AUDITORIA'] : 0) + (data[7]['areas']['COMISIÓN DE AUDITORÍA'] ? data[7]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[7]['areas']['CONSEJO DE VIGILANCIA'] ? data[7]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[7]['areas']['PATRONATO'] ? data[7]['areas']['PATRONATO'] : 0) + (data[7]['areas']['PATRONATO DE OBRAS'] ? data[7]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[7]['areas']['ASISTENTE REGIONAL'] ? data[7]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[7]['areas']['CORPORATIVO'] ? data[7]['areas']['CORPORATIVO'] : 0) + (data[7]['areas']['PLAZA SINALOA'] ? data[7]['areas']['PLAZA SINALOA'] : 0) + (data[7]['areas']['FIGLOSNTE 66'] ? data[7]['areas']['FIGLOSNTE 66'] : 0) + (data[7]['areas']['PROMOTOR'] ? data[7]['areas']['PROMOTOR'] : 0) + (data[7]['areas']['Otro valor por defecto'] ? data[7]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[7]['areas']['REPRESENTANTE DE SOCIO'] ? data[7]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[7]['areas']['REPRESENTANTES DE SOCIOS'] ? data[7]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[7]['areas']['COMITÉ TÉCNICO'] ? data[7]['areas']['COMITÉ TÉCNICO'] : 0) + (data[7]['areas']['COMITE TECNICO'] ? data[7]['areas']['COMITE TECNICO'] : 0) + (data[7]['areas']['COMISIÓN DE AUDITORÍA'] ? data[7]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[7]['areas']['COMISION DE AUDITORIA'] ? data[7]['areas']['COMISION DE AUDITORIA'] : 0) + (data[7]['areas']['CONSEJO DE VIGILANCIA'] ? data[7]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[7]['areas']['PATRONATO'] ? data[7]['areas']['PATRONATO'] : 0) + (data[7]['areas']['PATRONATO'] ? data[7]['areas']['PATRONATO'] : 0) + (data[7]['areas']['ASISTENTE REGIONAL'] ? data[7]['areas']['ASISTENTE REGIONAL'] : 0) + (data[7]['areas']['CORPORATIVO'] ? data[7]['areas']['CORPORATIVO'] : 0) + (data[7]['areas']['PLAZA SINALOA'] ? data[7]['areas']['PLAZA SINALOA'] : 0) + (data[7]['areas']['FIGLOSNTE 66'] ? data[7]['areas']['FIGLOSNTE 66'] : 0) + (data[7]['areas']['PROMOTOR'] ? data[7]['areas']['PROMOTOR'] : 0) + (data[7]['areas']['Otro valor por defecto'] ? data[7]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[7]['cantidad_cursos'] ? data[7]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[7]['cantidad_cursos_terminados'] ? data[7]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[7]['porcentaje_avance'] ? data[7]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[7]['promedio_calificaciones'] ? data[7]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(8) && <tr>
                        <td>
                            FIGLOSNTE 32
                        </td>
                        <td>
                            {(data[8]['areas']['REPRESENTANTE DE SOCIO'] ? data[8]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[8]['areas']['REPRESENTANTES DE SOCIOS'] ? data[8]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[8]['areas']['COMITÉ TÉCNICO'] ? data[8]['areas']['COMITÉ TÉCNICO'] : 0) + (data[8]['areas']['COMITE TECNICO'] ? data[8]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[8]['areas']['COMISION DE AUDITORIA'] ? data[8]['areas']['COMISION DE AUDITORIA'] : 0) + (data[8]['areas']['COMISIÓN DE AUDITORÍA'] ? data[8]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[8]['areas']['CONSEJO DE VIGILANCIA'] ? data[8]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[8]['areas']['PATRONATO'] ? data[8]['areas']['PATRONATO'] : 0) + (data[8]['areas']['PATRONATO DE OBRAS'] ? data[8]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[8]['areas']['ASISTENTE REGIONAL'] ? data[8]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[8]['areas']['CORPORATIVO'] ? data[8]['areas']['CORPORATIVO'] : 0) + (data[8]['areas']['PLAZA SINALOA'] ? data[8]['areas']['PLAZA SINALOA'] : 0) + (data[8]['areas']['FIGLOSNTE 66'] ? data[8]['areas']['FIGLOSNTE 66'] : 0) + (data[8]['areas']['PROMOTOR'] ? data[8]['areas']['PROMOTOR'] : 0) + (data[8]['areas']['Otro valor por defecto'] ? data[8]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[8]['areas']['REPRESENTANTE DE SOCIO'] ? data[8]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[8]['areas']['REPRESENTANTES DE SOCIOS'] ? data[8]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[8]['areas']['COMITÉ TÉCNICO'] ? data[8]['areas']['COMITÉ TÉCNICO'] : 0) + (data[8]['areas']['COMITE TECNICO'] ? data[8]['areas']['COMITE TECNICO'] : 0) + (data[8]['areas']['COMISIÓN DE AUDITORÍA'] ? data[8]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[8]['areas']['COMISION DE AUDITORIA'] ? data[8]['areas']['COMISION DE AUDITORIA'] : 0) + (data[8]['areas']['CONSEJO DE VIGILANCIA'] ? data[8]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[8]['areas']['PATRONATO'] ? data[8]['areas']['PATRONATO'] : 0) + (data[8]['areas']['PATRONATO'] ? data[8]['areas']['PATRONATO'] : 0) + (data[8]['areas']['ASISTENTE REGIONAL'] ? data[8]['areas']['ASISTENTE REGIONAL'] : 0) + (data[8]['areas']['CORPORATIVO'] ? data[8]['areas']['CORPORATIVO'] : 0) + (data[8]['areas']['PLAZA SINALOA'] ? data[8]['areas']['PLAZA SINALOA'] : 0) + (data[8]['areas']['FIGLOSNTE 66'] ? data[8]['areas']['FIGLOSNTE 66'] : 0) + (data[8]['areas']['PROMOTOR'] ? data[8]['areas']['PROMOTOR'] : 0) + (data[8]['areas']['Otro valor por defecto'] ? data[8]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[8]['cantidad_cursos'] ? data[8]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[8]['cantidad_cursos_terminados'] ? data[8]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[8]['porcentaje_avance'] ? data[8]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[8]['promedio_calificaciones'] ? data[8]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(9) && <tr>
                        <td>
                            FIGLOSNTE 33
                        </td>
                        <td>
                            {(data[9]['areas']['REPRESENTANTE DE SOCIO'] ? data[9]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[9]['areas']['REPRESENTANTES DE SOCIOS'] ? data[9]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[9]['areas']['COMITÉ TÉCNICO'] ? data[9]['areas']['COMITÉ TÉCNICO'] : 0) + (data[9]['areas']['COMITE TECNICO'] ? data[9]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[9]['areas']['COMISION DE AUDITORIA'] ? data[9]['areas']['COMISION DE AUDITORIA'] : 0) + (data[9]['areas']['COMISIÓN DE AUDITORÍA'] ? data[9]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[9]['areas']['CONSEJO DE VIGILANCIA'] ? data[9]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[9]['areas']['PATRONATO'] ? data[9]['areas']['PATRONATO'] : 0) + (data[9]['areas']['PATRONATO DE OBRAS'] ? data[9]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[9]['areas']['ASISTENTE REGIONAL'] ? data[9]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[9]['areas']['CORPORATIVO'] ? data[9]['areas']['CORPORATIVO'] : 0) + (data[9]['areas']['PLAZA SINALOA'] ? data[9]['areas']['PLAZA SINALOA'] : 0) + (data[9]['areas']['FIGLOSNTE 66'] ? data[9]['areas']['FIGLOSNTE 66'] : 0) + (data[9]['areas']['PROMOTOR'] ? data[9]['areas']['PROMOTOR'] : 0) + (data[9]['areas']['Otro valor por defecto'] ? data[9]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[9]['areas']['REPRESENTANTE DE SOCIO'] ? data[9]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[9]['areas']['REPRESENTANTES DE SOCIOS'] ? data[9]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[9]['areas']['COMITÉ TÉCNICO'] ? data[9]['areas']['COMITÉ TÉCNICO'] : 0) + (data[9]['areas']['COMITE TECNICO'] ? data[9]['areas']['COMITE TECNICO'] : 0) + (data[9]['areas']['COMISIÓN DE AUDITORÍA'] ? data[9]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[9]['areas']['COMISION DE AUDITORIA'] ? data[9]['areas']['COMISION DE AUDITORIA'] : 0) + (data[9]['areas']['CONSEJO DE VIGILANCIA'] ? data[9]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[9]['areas']['PATRONATO'] ? data[9]['areas']['PATRONATO'] : 0) + (data[9]['areas']['PATRONATO'] ? data[9]['areas']['PATRONATO'] : 0) + (data[9]['areas']['ASISTENTE REGIONAL'] ? data[9]['areas']['ASISTENTE REGIONAL'] : 0) + (data[9]['areas']['CORPORATIVO'] ? data[9]['areas']['CORPORATIVO'] : 0) + (data[9]['areas']['PLAZA SINALOA'] ? data[9]['areas']['PLAZA SINALOA'] : 0) + (data[9]['areas']['FIGLOSNTE 66'] ? data[9]['areas']['FIGLOSNTE 66'] : 0) + (data[9]['areas']['PROMOTOR'] ? data[9]['areas']['PROMOTOR'] : 0) + (data[9]['areas']['Otro valor por defecto'] ? data[9]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[9]['cantidad_cursos'] ? data[9]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[9]['cantidad_cursos_terminados'] ? data[9]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[9]['porcentaje_avance'] ? data[9]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[9]['promedio_calificaciones'] ? data[9]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(10) && <tr>
                        <td>
                            FIGLOSNTE 47
                        </td>
                        <td>
                            {(data[10]['areas']['REPRESENTANTE DE SOCIO'] ? data[10]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[10]['areas']['REPRESENTANTES DE SOCIOS'] ? data[10]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[10]['areas']['COMITÉ TÉCNICO'] ? data[10]['areas']['COMITÉ TÉCNICO'] : 0) + (data[10]['areas']['COMITE TECNICO'] ? data[10]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[10]['areas']['COMISION DE AUDITORIA'] ? data[10]['areas']['COMISION DE AUDITORIA'] : 0) + (data[10]['areas']['COMISIÓN DE AUDITORÍA'] ? data[10]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[10]['areas']['CONSEJO DE VIGILANCIA'] ? data[10]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[10]['areas']['PATRONATO'] ? data[10]['areas']['PATRONATO'] : 0) + (data[10]['areas']['PATRONATO DE OBRAS'] ? data[10]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[10]['areas']['ASISTENTE REGIONAL'] ? data[10]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[10]['areas']['CORPORATIVO'] ? data[10]['areas']['CORPORATIVO'] : 0) + (data[10]['areas']['PLAZA SINALOA'] ? data[10]['areas']['PLAZA SINALOA'] : 0) + (data[10]['areas']['FIGLOSNTE 66'] ? data[10]['areas']['FIGLOSNTE 66'] : 0) + (data[10]['areas']['PROMOTOR'] ? data[10]['areas']['PROMOTOR'] : 0) + (data[10]['areas']['Otro valor por defecto'] ? data[10]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[10]['areas']['REPRESENTANTE DE SOCIO'] ? data[10]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[10]['areas']['REPRESENTANTES DE SOCIOS'] ? data[10]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[10]['areas']['COMITÉ TÉCNICO'] ? data[10]['areas']['COMITÉ TÉCNICO'] : 0) + (data[10]['areas']['COMITE TECNICO'] ? data[10]['areas']['COMITE TECNICO'] : 0) + (data[10]['areas']['COMISIÓN DE AUDITORÍA'] ? data[10]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[10]['areas']['COMISION DE AUDITORIA'] ? data[10]['areas']['COMISION DE AUDITORIA'] : 0) + (data[10]['areas']['CONSEJO DE VIGILANCIA'] ? data[10]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[10]['areas']['PATRONATO'] ? data[10]['areas']['PATRONATO'] : 0) + (data[10]['areas']['PATRONATO'] ? data[10]['areas']['PATRONATO'] : 0) + (data[10]['areas']['ASISTENTE REGIONAL'] ? data[10]['areas']['ASISTENTE REGIONAL'] : 0) + (data[10]['areas']['CORPORATIVO'] ? data[10]['areas']['CORPORATIVO'] : 0) + (data[10]['areas']['PLAZA SINALOA'] ? data[10]['areas']['PLAZA SINALOA'] : 0) + (data[10]['areas']['FIGLOSNTE 66'] ? data[10]['areas']['FIGLOSNTE 66'] : 0) + (data[10]['areas']['PROMOTOR'] ? data[10]['areas']['PROMOTOR'] : 0) + (data[10]['areas']['Otro valor por defecto'] ? data[10]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[10]['cantidad_cursos'] ? data[10]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[10]['cantidad_cursos_terminados'] ? data[10]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[10]['porcentaje_avance'] ? data[10]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[10]['promedio_calificaciones'] ? data[10]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(11) && <tr>
                        <td>
                            FIGLOSNTE 53
                        </td>
                        <td>
                            {(data[11]['areas']['REPRESENTANTE DE SOCIO'] ? data[11]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[11]['areas']['REPRESENTANTES DE SOCIOS'] ? data[11]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[11]['areas']['COMITÉ TÉCNICO'] ? data[11]['areas']['COMITÉ TÉCNICO'] : 0) + (data[11]['areas']['COMITE TECNICO'] ? data[11]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[11]['areas']['COMISION DE AUDITORIA'] ? data[11]['areas']['COMISION DE AUDITORIA'] : 0) + (data[11]['areas']['COMISIÓN DE AUDITORÍA'] ? data[11]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[11]['areas']['CONSEJO DE VIGILANCIA'] ? data[11]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[11]['areas']['PATRONATO'] ? data[11]['areas']['PATRONATO'] : 0) + (data[11]['areas']['PATRONATO DE OBRAS'] ? data[11]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[11]['areas']['ASISTENTE REGIONAL'] ? data[11]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[11]['areas']['CORPORATIVO'] ? data[11]['areas']['CORPORATIVO'] : 0) + (data[11]['areas']['PLAZA SINALOA'] ? data[11]['areas']['PLAZA SINALOA'] : 0) + (data[11]['areas']['FIGLOSNTE 66'] ? data[11]['areas']['FIGLOSNTE 66'] : 0) + (data[11]['areas']['PROMOTOR'] ? data[11]['areas']['PROMOTOR'] : 0) + (data[11]['areas']['Otro valor por defecto'] ? data[11]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[11]['areas']['REPRESENTANTE DE SOCIO'] ? data[11]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[11]['areas']['REPRESENTANTES DE SOCIOS'] ? data[11]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[11]['areas']['COMITÉ TÉCNICO'] ? data[11]['areas']['COMITÉ TÉCNICO'] : 0) + (data[11]['areas']['COMITE TECNICO'] ? data[11]['areas']['COMITE TECNICO'] : 0) + (data[11]['areas']['COMISIÓN DE AUDITORÍA'] ? data[11]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[11]['areas']['COMISION DE AUDITORIA'] ? data[11]['areas']['COMISION DE AUDITORIA'] : 0) + (data[11]['areas']['CONSEJO DE VIGILANCIA'] ? data[11]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[11]['areas']['PATRONATO'] ? data[11]['areas']['PATRONATO'] : 0) + (data[11]['areas']['PATRONATO'] ? data[11]['areas']['PATRONATO'] : 0) + (data[11]['areas']['ASISTENTE REGIONAL'] ? data[11]['areas']['ASISTENTE REGIONAL'] : 0) + (data[11]['areas']['CORPORATIVO'] ? data[11]['areas']['CORPORATIVO'] : 0) + (data[11]['areas']['PLAZA SINALOA'] ? data[11]['areas']['PLAZA SINALOA'] : 0) + (data[11]['areas']['FIGLOSNTE 66'] ? data[11]['areas']['FIGLOSNTE 66'] : 0) + (data[11]['areas']['PROMOTOR'] ? data[11]['areas']['PROMOTOR'] : 0) + (data[11]['areas']['Otro valor por defecto'] ? data[11]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[11]['cantidad_cursos'] ? data[11]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[11]['cantidad_cursos_terminados'] ? data[11]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[11]['porcentaje_avance'] ? data[11]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[11]['promedio_calificaciones'] ? data[11]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(12) && <tr>
                        <td>
                            FIGLOSNTE 55
                        </td>
                        <td>
                            {(data[12]['areas']['REPRESENTANTE DE SOCIO'] ? data[12]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[12]['areas']['REPRESENTANTES DE SOCIOS'] ? data[12]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[12]['areas']['COMITÉ TÉCNICO'] ? data[12]['areas']['COMITÉ TÉCNICO'] : 0) + (data[12]['areas']['COMITE TECNICO'] ? data[12]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[12]['areas']['COMISION DE AUDITORIA'] ? data[12]['areas']['COMISION DE AUDITORIA'] : 0) + (data[12]['areas']['COMISIÓN DE AUDITORÍA'] ? data[12]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[12]['areas']['CONSEJO DE VIGILANCIA'] ? data[12]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[12]['areas']['PATRONATO'] ? data[12]['areas']['PATRONATO'] : 0) + (data[12]['areas']['PATRONATO DE OBRAS'] ? data[12]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[12]['areas']['ASISTENTE REGIONAL'] ? data[12]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[12]['areas']['CORPORATIVO'] ? data[12]['areas']['CORPORATIVO'] : 0) + (data[12]['areas']['PLAZA SINALOA'] ? data[12]['areas']['PLAZA SINALOA'] : 0) + (data[12]['areas']['FIGLOSNTE 66'] ? data[12]['areas']['FIGLOSNTE 66'] : 0) + (data[12]['areas']['PROMOTOR'] ? data[12]['areas']['PROMOTOR'] : 0) + (data[12]['areas']['Otro valor por defecto'] ? data[12]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[12]['areas']['REPRESENTANTE DE SOCIO'] ? data[12]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[12]['areas']['REPRESENTANTES DE SOCIOS'] ? data[12]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[12]['areas']['COMITÉ TÉCNICO'] ? data[12]['areas']['COMITÉ TÉCNICO'] : 0) + (data[12]['areas']['COMITE TECNICO'] ? data[12]['areas']['COMITE TECNICO'] : 0) + (data[12]['areas']['COMISIÓN DE AUDITORÍA'] ? data[12]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[12]['areas']['COMISION DE AUDITORIA'] ? data[12]['areas']['COMISION DE AUDITORIA'] : 0) + (data[12]['areas']['CONSEJO DE VIGILANCIA'] ? data[12]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[12]['areas']['PATRONATO'] ? data[12]['areas']['PATRONATO'] : 0) + (data[12]['areas']['PATRONATO'] ? data[12]['areas']['PATRONATO'] : 0) + (data[12]['areas']['ASISTENTE REGIONAL'] ? data[12]['areas']['ASISTENTE REGIONAL'] : 0) + (data[12]['areas']['CORPORATIVO'] ? data[12]['areas']['CORPORATIVO'] : 0) + (data[12]['areas']['PLAZA SINALOA'] ? data[12]['areas']['PLAZA SINALOA'] : 0) + (data[12]['areas']['FIGLOSNTE 66'] ? data[12]['areas']['FIGLOSNTE 66'] : 0) + (data[12]['areas']['PROMOTOR'] ? data[12]['areas']['PROMOTOR'] : 0) + (data[12]['areas']['Otro valor por defecto'] ? data[12]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[12]['cantidad_cursos'] ? data[12]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[12]['cantidad_cursos_terminados'] ? data[12]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[12]['porcentaje_avance'] ? data[12]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[12]['promedio_calificaciones'] ? data[12]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(13) && <tr>
                        <td>
                            FIGLOSNTE 56
                        </td>
                        <td>
                            {(data[13]['areas']['REPRESENTANTE DE SOCIO'] ? data[13]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[13]['areas']['REPRESENTANTES DE SOCIOS'] ? data[13]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[13]['areas']['COMITÉ TÉCNICO'] ? data[13]['areas']['COMITÉ TÉCNICO'] : 0) + (data[13]['areas']['COMITE TECNICO'] ? data[13]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[13]['areas']['COMISION DE AUDITORIA'] ? data[13]['areas']['COMISION DE AUDITORIA'] : 0) + (data[13]['areas']['COMISIÓN DE AUDITORÍA'] ? data[13]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[13]['areas']['CONSEJO DE VIGILANCIA'] ? data[13]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[13]['areas']['PATRONATO'] ? data[13]['areas']['PATRONATO'] : 0) + (data[13]['areas']['PATRONATO DE OBRAS'] ? data[13]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[13]['areas']['ASISTENTE REGIONAL'] ? data[13]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[13]['areas']['CORPORATIVO'] ? data[13]['areas']['CORPORATIVO'] : 0) + (data[13]['areas']['PLAZA SINALOA'] ? data[13]['areas']['PLAZA SINALOA'] : 0) + (data[13]['areas']['FIGLOSNTE 66'] ? data[13]['areas']['FIGLOSNTE 66'] : 0) + (data[13]['areas']['PROMOTOR'] ? data[13]['areas']['PROMOTOR'] : 0) + (data[13]['areas']['Otro valor por defecto'] ? data[13]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[13]['areas']['REPRESENTANTE DE SOCIO'] ? data[13]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[13]['areas']['REPRESENTANTES DE SOCIOS'] ? data[13]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[13]['areas']['COMITÉ TÉCNICO'] ? data[13]['areas']['COMITÉ TÉCNICO'] : 0) + (data[13]['areas']['COMITE TECNICO'] ? data[13]['areas']['COMITE TECNICO'] : 0) + (data[13]['areas']['COMISIÓN DE AUDITORÍA'] ? data[13]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[13]['areas']['COMISION DE AUDITORIA'] ? data[13]['areas']['COMISION DE AUDITORIA'] : 0) + (data[13]['areas']['CONSEJO DE VIGILANCIA'] ? data[13]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[13]['areas']['PATRONATO'] ? data[13]['areas']['PATRONATO'] : 0) + (data[13]['areas']['PATRONATO'] ? data[13]['areas']['PATRONATO'] : 0) + (data[13]['areas']['ASISTENTE REGIONAL'] ? data[13]['areas']['ASISTENTE REGIONAL'] : 0) + (data[13]['areas']['CORPORATIVO'] ? data[13]['areas']['CORPORATIVO'] : 0) + (data[13]['areas']['PLAZA SINALOA'] ? data[13]['areas']['PLAZA SINALOA'] : 0) + (data[13]['areas']['FIGLOSNTE 66'] ? data[13]['areas']['FIGLOSNTE 66'] : 0) + (data[13]['areas']['PROMOTOR'] ? data[13]['areas']['PROMOTOR'] : 0) + (data[13]['areas']['Otro valor por defecto'] ? data[13]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[13]['cantidad_cursos'] ? data[13]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[13]['cantidad_cursos_terminados'] ? data[13]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[13]['porcentaje_avance'] ? data[13]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[13]['promedio_calificaciones'] ? data[13]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(14) && <tr>
                        <td>
                            FIGLOSTASE
                        </td>
                        <td>
                            {(data[14]['areas']['REPRESENTANTE DE SOCIO'] ? data[14]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[14]['areas']['REPRESENTANTES DE SOCIOS'] ? data[14]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[14]['areas']['COMITÉ TÉCNICO'] ? data[14]['areas']['COMITÉ TÉCNICO'] : 0) + (data[14]['areas']['COMITE TECNICO'] ? data[14]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[14]['areas']['COMISION DE AUDITORIA'] ? data[14]['areas']['COMISION DE AUDITORIA'] : 0) + (data[14]['areas']['COMISIÓN DE AUDITORÍA'] ? data[14]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[14]['areas']['CONSEJO DE VIGILANCIA'] ? data[14]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[14]['areas']['PATRONATO'] ? data[14]['areas']['PATRONATO'] : 0) + (data[14]['areas']['PATRONATO DE OBRAS'] ? data[14]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[14]['areas']['ASISTENTE REGIONAL'] ? data[14]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[14]['areas']['CORPORATIVO'] ? data[14]['areas']['CORPORATIVO'] : 0) + (data[14]['areas']['PLAZA SINALOA'] ? data[14]['areas']['PLAZA SINALOA'] : 0) + (data[14]['areas']['FIGLOSNTE 66'] ? data[14]['areas']['FIGLOSNTE 66'] : 0) + (data[14]['areas']['PROMOTOR'] ? data[14]['areas']['PROMOTOR'] : 0) + (data[14]['areas']['Otro valor por defecto'] ? data[14]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[14]['areas']['REPRESENTANTE DE SOCIO'] ? data[14]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[14]['areas']['REPRESENTANTES DE SOCIOS'] ? data[14]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[14]['areas']['COMITÉ TÉCNICO'] ? data[14]['areas']['COMITÉ TÉCNICO'] : 0) + (data[14]['areas']['COMITE TECNICO'] ? data[14]['areas']['COMITE TECNICO'] : 0) + (data[14]['areas']['COMISIÓN DE AUDITORÍA'] ? data[14]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[14]['areas']['COMISION DE AUDITORIA'] ? data[14]['areas']['COMISION DE AUDITORIA'] : 0) + (data[14]['areas']['CONSEJO DE VIGILANCIA'] ? data[14]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[14]['areas']['PATRONATO'] ? data[14]['areas']['PATRONATO'] : 0) + (data[14]['areas']['PATRONATO'] ? data[14]['areas']['PATRONATO'] : 0) + (data[14]['areas']['ASISTENTE REGIONAL'] ? data[14]['areas']['ASISTENTE REGIONAL'] : 0) + (data[14]['areas']['CORPORATIVO'] ? data[14]['areas']['CORPORATIVO'] : 0) + (data[14]['areas']['PLAZA SINALOA'] ? data[14]['areas']['PLAZA SINALOA'] : 0) + (data[14]['areas']['FIGLOSNTE 66'] ? data[14]['areas']['FIGLOSNTE 66'] : 0) + (data[14]['areas']['PROMOTOR'] ? data[14]['areas']['PROMOTOR'] : 0) + (data[14]['areas']['Otro valor por defecto'] ? data[14]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[14]['cantidad_cursos'] ? data[14]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[14]['cantidad_cursos_terminados'] ? data[14]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[14]['porcentaje_avance'] ? data[14]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[14]['promedio_calificaciones'] ? data[14]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(15) && <tr>
                        <td>
                            FIGLOSNTSA
                        </td>
                        <td>
                            {(data[15]['areas']['REPRESENTANTE DE SOCIO'] ? data[15]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[15]['areas']['REPRESENTANTES DE SOCIOS'] ? data[15]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[15]['areas']['COMITÉ TÉCNICO'] ? data[15]['areas']['COMITÉ TÉCNICO'] : 0) + (data[15]['areas']['COMITE TECNICO'] ? data[15]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[15]['areas']['COMISION DE AUDITORIA'] ? data[15]['areas']['COMISION DE AUDITORIA'] : 0) + (data[15]['areas']['COMISIÓN DE AUDITORÍA'] ? data[15]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[15]['areas']['CONSEJO DE VIGILANCIA'] ? data[15]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[15]['areas']['PATRONATO'] ? data[15]['areas']['PATRONATO'] : 0) + (data[15]['areas']['PATRONATO DE OBRAS'] ? data[15]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[15]['areas']['ASISTENTE REGIONAL'] ? data[15]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[15]['areas']['CORPORATIVO'] ? data[15]['areas']['CORPORATIVO'] : 0) + (data[15]['areas']['PLAZA SINALOA'] ? data[15]['areas']['PLAZA SINALOA'] : 0) + (data[15]['areas']['FIGLOSNTE 66'] ? data[15]['areas']['FIGLOSNTE 66'] : 0) + (data[15]['areas']['PROMOTOR'] ? data[15]['areas']['PROMOTOR'] : 0) + (data[15]['areas']['Otro valor por defecto'] ? data[15]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[15]['areas']['REPRESENTANTE DE SOCIO'] ? data[15]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[15]['areas']['REPRESENTANTES DE SOCIOS'] ? data[15]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[15]['areas']['COMITÉ TÉCNICO'] ? data[15]['areas']['COMITÉ TÉCNICO'] : 0) + (data[15]['areas']['COMITE TECNICO'] ? data[15]['areas']['COMITE TECNICO'] : 0) + (data[15]['areas']['COMISIÓN DE AUDITORÍA'] ? data[15]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[15]['areas']['COMISION DE AUDITORIA'] ? data[15]['areas']['COMISION DE AUDITORIA'] : 0) + (data[15]['areas']['CONSEJO DE VIGILANCIA'] ? data[15]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[15]['areas']['PATRONATO'] ? data[15]['areas']['PATRONATO'] : 0) + (data[15]['areas']['PATRONATO'] ? data[15]['areas']['PATRONATO'] : 0) + (data[15]['areas']['ASISTENTE REGIONAL'] ? data[15]['areas']['ASISTENTE REGIONAL'] : 0) + (data[15]['areas']['CORPORATIVO'] ? data[15]['areas']['CORPORATIVO'] : 0) + (data[15]['areas']['PLAZA SINALOA'] ? data[15]['areas']['PLAZA SINALOA'] : 0) + (data[15]['areas']['FIGLOSNTE 66'] ? data[15]['areas']['FIGLOSNTE 66'] : 0) + (data[15]['areas']['PROMOTOR'] ? data[15]['areas']['PROMOTOR'] : 0) + (data[15]['areas']['Otro valor por defecto'] ? data[15]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[15]['cantidad_cursos'] ? data[15]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[15]['cantidad_cursos_terminados'] ? data[15]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[15]['porcentaje_avance'] ? data[15]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[15]['promedio_calificaciones'] ? data[15]['promedio_calificaciones'] : 0}
                        </td>

                    </tr>}
                    {data.hasOwnProperty(16) && <tr>
                        <td>
                            JUBICOM 16
                        </td>
                        <td>
                            {(data[16]['areas']['REPRESENTANTE DE SOCIO'] ? data[16]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[16]['areas']['REPRESENTANTES DE SOCIOS'] ? data[16]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[16]['areas']['COMITÉ TÉCNICO'] ? data[16]['areas']['COMITÉ TÉCNICO'] : 0) + (data[16]['areas']['COMITE TECNICO'] ? data[16]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[16]['areas']['COMISION DE AUDITORIA'] ? data[16]['areas']['COMISION DE AUDITORIA'] : 0) + (data[16]['areas']['COMISIÓN DE AUDITORÍA'] ? data[16]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[16]['areas']['CONSEJO DE VIGILANCIA'] ? data[16]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[16]['areas']['PATRONATO'] ? data[16]['areas']['PATRONATO'] : 0) + (data[16]['areas']['PATRONATO DE OBRAS'] ? data[16]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[16]['areas']['ASISTENTE REGIONAL'] ? data[16]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[16]['areas']['CORPORATIVO'] ? data[16]['areas']['CORPORATIVO'] : 0) + (data[16]['areas']['PLAZA SINALOA'] ? data[16]['areas']['PLAZA SINALOA'] : 0) + (data[16]['areas']['FIGLOSNTE 66'] ? data[16]['areas']['FIGLOSNTE 66'] : 0) + (data[16]['areas']['PROMOTOR'] ? data[16]['areas']['PROMOTOR'] : 0) + (data[16]['areas']['Otro valor por defecto'] ? data[16]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[16]['areas']['REPRESENTANTE DE SOCIO'] ? data[16]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[16]['areas']['REPRESENTANTES DE SOCIOS'] ? data[16]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[16]['areas']['COMITÉ TÉCNICO'] ? data[16]['areas']['COMITÉ TÉCNICO'] : 0) + (data[16]['areas']['COMITE TECNICO'] ? data[16]['areas']['COMITE TECNICO'] : 0) + (data[16]['areas']['COMISIÓN DE AUDITORÍA'] ? data[16]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[16]['areas']['COMISION DE AUDITORIA'] ? data[16]['areas']['COMISION DE AUDITORIA'] : 0) + (data[16]['areas']['CONSEJO DE VIGILANCIA'] ? data[16]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[16]['areas']['PATRONATO'] ? data[16]['areas']['PATRONATO'] : 0) + (data[16]['areas']['PATRONATO'] ? data[16]['areas']['PATRONATO'] : 0) + (data[16]['areas']['ASISTENTE REGIONAL'] ? data[16]['areas']['ASISTENTE REGIONAL'] : 0) + (data[16]['areas']['CORPORATIVO'] ? data[16]['areas']['CORPORATIVO'] : 0) + (data[16]['areas']['PLAZA SINALOA'] ? data[16]['areas']['PLAZA SINALOA'] : 0) + (data[16]['areas']['FIGLOSNTE 66'] ? data[16]['areas']['FIGLOSNTE 66'] : 0) + (data[16]['areas']['PROMOTOR'] ? data[16]['areas']['PROMOTOR'] : 0) + (data[16]['areas']['Otro valor por defecto'] ? data[16]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[16]['cantidad_cursos'] ? data[16]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[16]['cantidad_cursos_terminados'] ? data[16]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[16]['porcentaje_avance'] ? data[16]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[16]['promedio_calificaciones'] ? data[16]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(17) && <tr>
                        <td>
                            JUBICOM 27
                        </td>
                        <td>
                            {(data[17]['areas']['REPRESENTANTE DE SOCIO'] ? data[17]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[17]['areas']['REPRESENTANTES DE SOCIOS'] ? data[17]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[17]['areas']['COMITÉ TÉCNICO'] ? data[17]['areas']['COMITÉ TÉCNICO'] : 0) + (data[17]['areas']['COMITE TECNICO'] ? data[17]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[17]['areas']['COMISION DE AUDITORIA'] ? data[17]['areas']['COMISION DE AUDITORIA'] : 0) + (data[17]['areas']['COMISIÓN DE AUDITORÍA'] ? data[17]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[17]['areas']['CONSEJO DE VIGILANCIA'] ? data[17]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[17]['areas']['PATRONATO'] ? data[17]['areas']['PATRONATO'] : 0) + (data[17]['areas']['PATRONATO DE OBRAS'] ? data[17]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[17]['areas']['ASISTENTE REGIONAL'] ? data[17]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[17]['areas']['CORPORATIVO'] ? data[17]['areas']['CORPORATIVO'] : 0) + (data[17]['areas']['PLAZA SINALOA'] ? data[17]['areas']['PLAZA SINALOA'] : 0) + (data[17]['areas']['FIGLOSNTE 66'] ? data[17]['areas']['FIGLOSNTE 66'] : 0) + (data[17]['areas']['PROMOTOR'] ? data[17]['areas']['PROMOTOR'] : 0) + (data[17]['areas']['Otro valor por defecto'] ? data[17]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[17]['areas']['REPRESENTANTE DE SOCIO'] ? data[17]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[17]['areas']['REPRESENTANTES DE SOCIOS'] ? data[17]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[17]['areas']['COMITÉ TÉCNICO'] ? data[17]['areas']['COMITÉ TÉCNICO'] : 0) + (data[17]['areas']['COMITE TECNICO'] ? data[17]['areas']['COMITE TECNICO'] : 0) + (data[17]['areas']['COMISIÓN DE AUDITORÍA'] ? data[17]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[17]['areas']['COMISION DE AUDITORIA'] ? data[17]['areas']['COMISION DE AUDITORIA'] : 0) + (data[17]['areas']['CONSEJO DE VIGILANCIA'] ? data[17]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[17]['areas']['PATRONATO'] ? data[17]['areas']['PATRONATO'] : 0) + (data[17]['areas']['PATRONATO DE OBRAS'] ? data[17]['areas']['PATRONATO DE OBRAS'] : 0) + (data[17]['areas']['ASISTENTE REGIONAL'] ? data[17]['areas']['ASISTENTE REGIONAL'] : 0) + (data[17]['areas']['CORPORATIVO'] ? data[17]['areas']['CORPORATIVO'] : 0) + (data[17]['areas']['PLAZA SINALOA'] ? data[17]['areas']['PLAZA SINALOA'] : 0) + (data[17]['areas']['FIGLOSNTE 66'] ? data[17]['areas']['FIGLOSNTE 66'] : 0) + (data[17]['areas']['PROMOTOR'] ? data[17]['areas']['PROMOTOR'] : 0) + (data[17]['areas']['Otro valor por defecto'] ? data[17]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[17]['cantidad_cursos'] ? data[17]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[17]['cantidad_cursos_terminados'] ? data[17]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[17]['porcentaje_avance'] ? data[17]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[17]['promedio_calificaciones'] ? data[17]['promedio_calificaciones'] : 0}
                        </td>
                    </tr>}
                    {data.hasOwnProperty(18) && <tr>
                        <td>
                            FIGLOSNTE 27
                        </td>
                        <td>
                            {(data[18]['areas']['REPRESENTANTE DE SOCIO'] ? data[18]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[18]['areas']['REPRESENTANTES DE SOCIOS'] ? data[18]['areas']['REPRESENTANTES DE SOCIOS'] : 0)}
                        </td>
                        <td>
                            {(data[18]['areas']['COMITÉ TÉCNICO'] ? data[18]['areas']['COMITÉ TÉCNICO'] : 0) + (data[18]['areas']['COMITE TECNICO'] ? data[18]['areas']['COMITE TECNICO'] : 0)}
                        </td>
                        <td>
                            {(data[18]['areas']['COMISION DE AUDITORIA'] ? data[18]['areas']['COMISION DE AUDITORIA'] : 0) + (data[18]['areas']['COMISIÓN DE AUDITORÍA'] ? data[18]['areas']['COMISIÓN DE AUDITORÍA'] : 0)}
                        </td>
                        <td>
                            {(data[18]['areas']['CONSEJO DE VIGILANCIA'] ? data[18]['areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td>
                            {(data[18]['areas']['PATRONATO'] ? data[18]['areas']['PATRONATO'] : 0) + (data[18]['areas']['PATRONATO DE OBRAS'] ? data[18]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td>
                            {(data[18]['areas']['ASISTENTE REGIONAL'] ? data[18]['areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td>
                            {(data[18]['areas']['CORPORATIVO'] ? data[18]['areas']['CORPORATIVO'] : 0) + (data[18]['areas']['PLAZA SINALOA'] ? data[18]['areas']['PLAZA SINALOA'] : 0) + (data[18]['areas']['FIGLOSNTE 66'] ? data[18]['areas']['FIGLOSNTE 66'] : 0) + (data[18]['areas']['PROMOTOR'] ? data[18]['areas']['PROMOTOR'] : 0) + (data[18]['areas']['Otro valor por defecto'] ? data[18]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {(data[18]['areas']['REPRESENTANTE DE SOCIO'] ? data[18]['areas']['REPRESENTANTE DE SOCIO'] : 0) + (data[18]['areas']['REPRESENTANTES DE SOCIOS'] ? data[18]['areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data[18]['areas']['COMITÉ TÉCNICO'] ? data[18]['areas']['COMITÉ TÉCNICO'] : 0) + (data[18]['areas']['COMITE TECNICO'] ? data[18]['areas']['COMITE TECNICO'] : 0) + (data[18]['areas']['COMISIÓN DE AUDITORÍA'] ? data[18]['areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data[18]['areas']['COMISION DE AUDITORIA'] ? data[18]['areas']['COMISION DE AUDITORIA'] : 0) + (data[18]['areas']['CONSEJO DE VIGILANCIA'] ? data[18]['areas']['CONSEJO DE VIGILANCIA'] : 0) + (data[18]['areas']['PATRONATO'] ? data[18]['areas']['PATRONATO'] : 0) + (data[18]['areas']['ASISTENTE REGIONAL'] ? data[18]['areas']['ASISTENTE REGIONAL'] : 0) + (data[18]['areas']['CORPORATIVO'] ? data[18]['areas']['CORPORATIVO'] : 0) + (data[18]['areas']['PLAZA SINALOA'] ? data[18]['areas']['PLAZA SINALOA'] : 0) + (data[18]['areas']['FIGLOSNTE 66'] ? data[18]['areas']['FIGLOSNTE 66'] : 0) + (data[18]['areas']['PROMOTOR'] ? data[18]['areas']['PROMOTOR'] : 0) + (data[18]['areas']['Otro valor por defecto'] ? data[18]['areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {data[18]['cantidad_cursos'] ? data[18]['cantidad_cursos'] : 0}
                        </td>
                        <td>
                            {data[18]['cantidad_cursos_terminados'] ? data[18]['cantidad_cursos_terminados'] : 0}
                        </td>
                        <td>
                            {data[18]['porcentaje_avance'] ? data[18]['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {data[18]['promedio_calificaciones'] ? data[18]['promedio_calificaciones'] : 0}
                        </td>

                    </tr>}
                </tbody>
            </table>

            <table className={st.table}>

                <tbody>
                    <tr>
                        <th className={st.th_total}>Total</th>
                        <td className={st.th_table}>{(data['total_areas']['REPRESENTANTES DE SOCIOS'] ? data['total_areas']['REPRESENTANTES DE SOCIOS'] : 0) + (data['total_areas']['REPRESENTANTE DE SOCIO'] ? data['total_areas']['REPRESENTANTE DE SOCIO'] : 0)}</td>
                        <td className={st.th_table}>{(data['total_areas']['COMITÉ TÉCNICO'] ? data['total_areas']['COMITÉ TÉCNICO'] : 0) + (data['total_areas']['COMITE TECNICO'] ? data['total_areas']['COMITE TECNICO'] : 0)}</td>
                        <td className={st.th_table}>
                            {(data['total_areas']['COMISIÓN DE AUDITORÍA'] ? data['total_areas']['COMISIÓN DE AUDITORÍA'] : 0) + (data['total_areas']['COMISION DE AUDITORIA'] ? data['total_areas']['COMISION DE AUDITORIA'] : 0)}
                        </td >
                        <td className={st.th_table}>
                            {(data['total_areas']['CONSEJO DE VIGILANCIA'] ? data['total_areas']['CONSEJO DE VIGILANCIA'] : 0)}
                        </td>
                        <td className={st.th_table}>
                            {(data['total_areas']['PATRONATO'] ? data['total_areas']['PATRONATO'] : 0) + (data[18]['areas']['PATRONATO DE OBRAS'] ? data[18]['areas']['PATRONATO DE OBRAS'] : 0)}
                        </td>
                        <td className={st.th_table}>
                            {(data['total_areas']['ASISTENTE REGIONAL'] ? data['total_areas']['ASISTENTE REGIONAL'] : 0)}
                        </td>
                        <td className={st.th_table}>
                            {(data['total_areas']['CORPORATIVO'] ? data['total_areas']['CORPORATIVO'] : 0) + (data['total_areas']['PLAZA SINALOA'] ? data['total_areas']['PLAZA SINALOA'] : 0) + (data['total_areas']['FIGLOSNTE 66'] ? data['total_areas']['FIGLOSNTE 66'] : 0) + (data['total_areas']['PROMOTOR'] ? data['total_areas']['PROMOTOR'] : 0) + (data['total_areas']['Otro valor por defecto'] ? data['total_areas']['Otro valor por defecto'] : 0)}
                        </td>
                        <td>
                            {totalUsuariosFideicomisos}
                        </td>
                        <td>
                            {(data['cursos_totales']) ? data['cursos_totales'] : 0}
                        </td>
                        <td>
                            {(data['cursos_terminados']) ? data['cursos_terminados'] : 0}
                        </td>
                        <td>
                            {(data['porcentaje_avance']) ? data['porcentaje_avance'] : 0}
                        </td>
                        <td>
                            {(data['promedio_calificaciones']) ? data['promedio_calificaciones'] : 0}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}