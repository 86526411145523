import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { notifications_actions } from '../../store/actions/notifications';
import Notificacion from './Notificacion';

const Notificaciones = ({ onclose, notis }) => {
    const [fullnoti, setFullnoti] = useState(-1);

    const openNotiInIndex = (position, dbindex) => {
        //console.log('Marca aquí la notificación con ID ' + dbindex + ' como leido');
        notifications_actions.set_notification_as_read(dbindex);
        setFullnoti(position);
    }

    const closeNotiInIndex = () => {
        //console.log('Vuelve a leer las notificaciones');
        setFullnoti(-1);
    }

    return (
        <>
            <div className="fixed h-screen z-10 min-w-full top-0 left-0 bg-black bg-opacity-60 overflow-hidden"></div>
            <div className="w-full max-w-2xl bg-gray-100 text-black absolute z-10 left-1/2 transform -translate-x-1/2 mt-1 px-10 py-6 rounded-xl">
                {fullnoti === -1 ?
                    <div className="notilistmod w-full">
                        <div className="flex w-full justify-end mb-1 text-3xl">
                            <div onClick={onclose} className="flex -mt-4 -mr-8 justify-center items-center p-1 w-6 h-6 cursor-pointer">
                                <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '-1px', marginLeft: '1rem' }} />
                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div className="text-2xl">
                                <h3>Notificaciones</h3>
                            </div>
                            {(notis.length) ? <div className="border-white border-2 text-2xl p-2 w-10 h-10 flex justify-center items-center">
                                <span>{notis.length}</span>
                            </div> : <div className="w-10 h-10 flex justify-center items-center"></div>}
                        </div>

                        <div className="listaNotificaciones">
                            {(notis.length) ? notis.map((noti, notipos) => {
                                if (notipos < 5) { return (<div onClick={() => { openNotiInIndex(notipos, noti.id); }} ><Notificacion subject={noti.subject} fullhtml={noti.fulltexthtml} /></div>); }
                            }) : <p>No hay notificaciones disponibles</p>}
                        </div>
                    </div>
                    :
                    <div className="notidetailmod w-full">
                        <div>
                            <div onClick={() => { closeNotiInIndex() }}>
                                <FontAwesomeIcon icon={faTimesCircle} className="cursor-pointer opacity-80 hover:opacity-100 " />
                            </div>
                        </div>
                        <h2>{notis[fullnoti].subject}</h2>
                        <hr />
                        <p>{notis[fullnoti].fulltexthtml}</p>
                    </div>
                }
            </div>
        </>
    );
}

export default Notificaciones;