
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';

export const attempts_quiz_service = {
    get_all_attempts_quiz,
    delete_attempt_quiz
};

//let user = JSON.parse(localStorage.getItem('user'));


async function get_all_attempts_quiz (idQuiz) {
    const request_attempts_quiz = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_get_quiz_attempts_list&
    &moodlewsrestformat=json&
    quizid=${idQuiz}&
    userid=${0}`);

    if (Array.isArray(request_attempts_quiz.data)) {
        const attempts_quiz = request_attempts_quiz.data;
        return { attempts_quiz };
    }

    const error = request_attempts_quiz.data;
    return { error };
}


async function delete_attempt_quiz (row, idQuiz) {
    const request_attempt_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
    wsfunction=targett_delete_exam_attempts&
    moodlewsrestformat=json&
    examattempts[0][userid]=${row.id}&
    examattempts[0][quizid]=${idQuiz}&
    examattempts[0][attempt]=${row.numberatt}`);

    if (typeof request_attempt_delete.data === 'object') {
        return 1;
    } else {
        return 0
    }
}