import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { rewards_actions } from '../../../../store/actions/rewards';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const Recompensa = ({ onCloseSubMenu, reward, clickSubMenu }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [OpenDropdown, setOpenDropdown] = useState(false);

    const dispatch = useDispatch();

    const onClickEdit = () => {
        setShowMenu(true)
        clickSubMenu()
    }

    const onClickDelete = async () => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que deseas eliminar la recompensa?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed === true) {
            await rewards_actions.delete_reward(reward);
            dispatch(rewards_actions.get_all_rewards());
        }
    }

    const onClickOutsideListener = (e) => {
        document.removeEventListener("click", onClickOutsideListener);
        setOpenDropdown(false);
    }

    return (
        <div className="flex justify-center border rounded-md mb-2 relative bg-indigo-100" style={{ border: '1px solid #797974' }}>
            <div className="absolute top-0 right-2 z-10">
                <span onMouseLeave={() => {document.addEventListener("click", onClickOutsideListener)}} 
                    onClick={() => setOpenDropdown(!OpenDropdown)}>
                    {/* <img className="" src={three_dots} style={{ width: '1.5rem' }} /> */}
                    <FontAwesomeIcon icon={faEllipsisH} className="text-black cursor-pointer" />
                </span>
                {
                    OpenDropdown
                        ?
                        <div className="bg-white absolute top-5 right-2 p-2">
                            <p className="text-xs mb-2 hover:bg-gray-200 p-1 cursor-pointer" onClick={() => onClickEdit()}>Editar</p>
                            <p className="text-xs hover:bg-gray-200 p-1 cursor-pointer" onClick={() => onClickDelete()}>Eliminar</p>

                        </div>
                        :
                        ''
                }
            </div>
            <div className="w-1/3"> <img src={`${reward.image}`} alt="" style={{ width: '90%', height: '90%', objectFit: 'cover' }} /> </div>
            <div className="ml-1 w-4/5 p-2">
                <div>
                    <h4 className="text-xl">{reward.title}</h4>
                    <p className="text-sm">{reward.description}</p>
                </div>
            </div>
        </div>
    );
}

export default Recompensa;