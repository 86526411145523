export const meetings_constants = {
    MEETINGS_REQUEST: 'MEETINGS_REQUEST',
    MEETINGS_SUCCESS: 'MEETINGS_SUCCESS',
    MEETINGS_FAILURE: 'MEETINGS_FAILURE',

    MEETINGS_CREATE_REQUEST: 'MEETINGS_CREATE_REQUEST',
    MEETINGS_CREATE_SUCCESS: 'MEETINGS_CREATE_SUCCESS',
    MEETINGS_CREATE_FAILURE: 'MEETINGS_CREATE_FAILURE',

    MEETINGS_DELETE_REQUEST: 'MEETINGS_DELETE_REQUEST',
    MEETINGS_DELETE_SUCCESS: 'MEETINGS_DELETE_SUCCESS',
    MEETINGS_DELETE_FAILURE: 'MEETINGS_DELETE_FAILURE',

    MEETINGS_EDIT_REQUEST: 'MEETINGS_EDIT_REQUEST',
    MEETINGS_EDIT_SUCCESS: 'MEETINGS_EDIT_SUCCESS',
    MEETINGS_EDIT_FAILURE: 'MEETINGS_EDIT_FAILURE',

    MEETINGS_MEMBERS_REQUEST: 'MEETINGS_MEMBERS_REQUEST',
    MEETINGS_MEMBERS_SUCCESS: 'MEETINGS_MEMBERS_SUCCESS',
    MEETINGS_MEMBERS_FAILURE: 'MEETINGS_MEMBERS_FAILURE',

    ADD_MEMBERS_MEETINGS_REQUEST: 'ADD_MEMBERS_MEETINGS_REQUEST',
    ADD_MEMBERS_MEETINGS_SUCCESS: 'ADD_MEMBERS_MEETINGS_SUCCESS',
    ADD_MEMBERS_MEETINGS_FAILURE: 'ADD_MEMBERS_MEETINGS_FAILURE',

    REMOVE_MEMBERS_MEETINGS_REQUEST: 'REMOVE_MEMBERS_MEETINGS_REQUEST',
    REMOVE_MEMBERS_MEETINGS_SUCCESS: 'REMOVE_MEMBERS_MEETINGS_SUCCESS',
    REMOVE_MEMBERS_MEETINGS_FAILURE: 'REMOVE_MEMBERS_MEETINGS_FAILURE',
}
