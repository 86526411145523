import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faArchive, faComment, faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../../store/actions/courses';

const Scorm = ({ course, onCloseSubMenu, type }) => {
    const dispatch = useDispatch();
    const modulos = useSelector(state => state.courses.courses_modules)
    const [fecha, setFecha] = useState('');
    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: true,
        course: {}
    });

    useEffect(() => {
        let toDay = new Date();
        let year = toDay.getFullYear();
        let month = (toDay.getMonth() + 1) < 10 ? `0${(toDay.getMonth() + 1)}` : toDay.getMonth();
        let day = toDay.getDate();

        let hour = toDay.getHours();
        let minut = toDay.getMinutes() < 10 ? `0${toDay.getMinutes()}` : toDay.getMinutes();
        setFecha(`${year}-${month}-${day}T${hour}:${minut}`);

    }, []);


    const validate_add = Yup.object().shape({
        fullname: Yup.string('Escribe el nombre de la actividad')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: {
            fullname: '',
            grademethod: 0,
            attempts: 0,
            completionview: 0,
            completionusegrade: 0,
            completionscorerequired: 0,
            completionscoredisabled: 0,
            completionapproved: 0,
            completionfinish: 0,
        },
        validationSchema: validate_add,
        onSubmit: values => {

            values.completionview = values.completionview[0] === 'on' ? 1 : 0;
            values.completionscoredisabled = values.completionscoredisabled[0] === 'on' ? 1 : 0;
            values.completionapproved = values.completionapproved[0] === '2' ? 2 : 0;
            values.completionfinish = values.completionfinish[0] === '4' ? 4 : 0;
            values.completionusegrade = values.completionusegrade[0] === 'on' ? 1 : 0;

            if (values.activity === undefined) {
                let jsonEmpty = {
                    op: '&',
                    c: [],
                    showc: []
                };
                let jsonAvailabilityEmpty = JSON.stringify(jsonEmpty);
                values.availability = jsonAvailabilityEmpty;
            } else {
                let jsonFull = {
                    op: '&',
                    c: [{ "type": "completion", "cm": values.activity, "e": 1 }],
                    showc: [true]
                };
    
                let jsonAvailabilityFull = JSON.stringify(jsonFull);
                values.availability = jsonAvailabilityFull;
            }
            //console.log(values);
            handleSubmit(values)
        },
    });

    const handleSubmit = async actividad => {
        actividad.courseid = course.id
        //console.log('scorm', actividad);
        await courses_actions.add_activity_scorm_course(actividad);
        dispatch(courses_actions.get_contents_course(course.id));
        onCloseSubMenu(false); 
    }
    const minScore = () => {
        let completation = document.querySelector("#id_completionscoredisabled");
        let completionscorerequired = document.querySelector("#id_completionscorerequired");
        completionscorerequired.disabled = completation.checked
    }
    return (
        <div>
            <div>
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label htmlFor="">Nombre de la actividad</label>
                        <input onChange={formik.handleChange} type="text" name="fullname" placeholder="Nombre de la actividad" value={formik.values.fullname} />
                        <p className="text-red-600 text-sm">{formik.errors.fullname ? <div>{formik.errors.fullname}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Recurso</label>
                        <input type="file" name="actividad"
                            onChange={e => {
                                formik.setFieldValue('file', e.target.files[0])
                            }}
                        />
                    </div>
                    <div className="campo">
                        <label htmlFor="">Método de Calificacion</label>
                        <select name="grademethod" id="id_grademethod" onChange={formik.handleChange} value={formik.values.grademethod}>
                            <option value="0" selected="">Objeto de aprendizaje</option>
                            <option value="1">Calificación más alta</option>
                            <option value="2">Promedio de calificaciones</option>
                            <option value="3">Primer intento</option>
                            <option value="4">Último intento</option>
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.grademethod ? <div>{formik.errors.grademethod}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Intentos permitidos</label>
                        <select name="attempts" id="id_attempts" onChange={formik.handleChange} value={formik.values.attempts}>
                            <option value="0">Sin límite</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.attempts ? <div>{formik.errors.attempts}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="mb-2" htmlFor="">Finalización de actividad</label>
                        <div style={{ backgroundColor: "#E7EFF6", padding: "1em" }}>
                            <div className="form-group row  fitem  " >
                                <div className="col-md-9 checkbox">
                                    <div className="flex items-center justify-between" >
                                        <label >
                                            <input type="checkbox" name="completionview" className="form-check-input " onChange={formik.handleChange} id="id_completionview" />
                                            Se debe de ver la actividad para completar la actividad
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row  fitem ">
                                <div className="col-md-9 checkbox" >
                                    <div className="flex items-center justify-between" >
                                        <label >
                                            <input type="checkbox" name="completionusegrade" className="form-check-input " onChange={formik.handleChange} id="id_completionusegrade" />
                                            Se debe de recibir una calificacion para completar la actividad
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="campo">
                        <div className="col-md-3 col-form-label d-flex pb-0 pr-md-0">
                            <p id="fgroup_id_completionscoregroup_label" className="mb-0 word-break" aria-hidden="true" style={{ cursor: "default" }}>
                                Requiere puntuación mínima
                            </p>
                        </div>
                        <div className="col-md-9 form-inline align-items-start felement" data-fieldtype="group" style={{ backgroundColor: "#E7EFF6", padding: "1em" }}>
                            <fieldset className="w-100 m-0 p-0 border-0">
                                <legend className="sr-only">Requiere puntuación mínima</legend>
                                <div className="d-flex flex-wrap align-items-center">
                                    <div className="form-group  fitem  " style={{ width: "70px", display: "inline-block" }}>
                                        <span data-fieldtype="text" >
                                            <input type="number" className="form-control " name="completionscorerequired" id="id_completionscorerequired"
                                                min={0} max={100} style={{ width: "70px" }} onChange={formik.handleChange} />
                                        </span>
                                    </div>
                                    <label className="form-check  fitem  ">
                                        <input type="checkbox" onClick={() => { minScore() }} name="completionscoredisabled" className="form-check-input ml-2"
                                            id="id_completionscoredisabled" onChange={formik.handleChange} />
                                        Deshabilitar
                                    </label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="campo">
                        <div className="col-md-3">
                            <label for="id_completionstatusrequired_2">
                                Requerir estatus
                            </label>
                        </div>
                        <div className="col-md-9 checkbox" style={{ backgroundColor: "#E7EFF6", padding: "1em" }}>
                            <div className="form-check d-flex">
                                <label>
                                    <input type="checkbox" name="completionapproved" className="form-check-input "
                                        id="id_completionapproved" value={2} onChange={formik.handleChange} />
                                    Pasado
                                </label>
                            </div>
                            <div className="form-check d-flex">
                                <label>
                                    <input type="checkbox" name="completionfinish" className="form-check-input "
                                        id="id_completionfinish" value={4} onChange={formik.handleChange} />
                                    Finalizado
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Retringir Acessos</label>
                        <select onChange={formik.handleChange} name="activity" value={formik.values.activity}>
                            <option value={0} selected disabled>Selecciona una actividad</option>
                            {
                                modulos !== null ?
                                    modulos.modules.map(item => (
                                        <option className="bg-blue-600" value={item.id}>{item.name}</option>
                                    )
                                    )
                                    : <p>Cargado...</p>
                            }
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.activity ? <div>{formik.errors.activity}</div> : null}</p>
                    </div>
                    <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-28  
                            border border-blue-300 hover:bg-opacity-100`}>Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default Scorm;