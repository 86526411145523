
import { users_constants } from '../constants/users';
import { users_service } from '../services/users';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';


export const users_actions = {
    get_all_users,
    users_edit,
    users_add,
    users_add_csv,
    users_status,
    users_delete,
    users_add_csv_reset,
    users_seach,
    get_user,
    get_user_field,
    date_string_to_epoch
};

function get_all_users() {
    return dispatch => {
        dispatch( request() );

        users_service.get_all_users()
            .then(
                res => {
                    if(res.hasOwnProperty('error')){
                        dispatch(failure(res.error.message.toString()));
                    }else{
                        dispatch(success(res.users));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: users_constants.USERS_REQUEST , payload: true } }
    function success(param) { return { type: users_constants.USERS_SUCCESS , payload: param } }
    function failure(error) { return { type: users_constants.USERS_FAILURE , payload: error } }
}

function users_add(user) {
    return dispatch => {
        dispatch( request() );

        users_service.users_add(user)
            .then(
                res => {
                    if(res.hasOwnProperty('error')){
                        dispatch(failure(res.error.debuginfo));
                        return Swal.fire(
                            'Usuario',
                            `${res.error.debuginfo}`,
                            'error',
                        )
                    }else{
                        dispatch(success(res.user));
                        return Swal.fire(
                            'Usuario',
                            'El usuario fue agregado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    console.log(error.toString())
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                    return Swal.fire(
                        'Usuario',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: users_constants.USER_CREATE_REQUEST , payload: true } }
    function success(param) { return { type: users_constants.USER_CREATE_SUCCESS , payload: param } }
    function failure(error) { return { type: users_constants.USER_CREATE_FAILURE , payload: error } }
}

async function users_add_csv(users) {
    return await users_service.users_add_csv(JSON.stringify(users))
    .then(
        res => {
            
            if(res.hasOwnProperty('error')){
                res.error = res.error.debuginfo
                //error_users_create.push(user)
                //dispatch(failure(error_users_create));
                console.log("Error:",res)
            }else{
                return res;
                //users_create.push(user)
                success(res);
            }
        },
        error => {
            failure(error.toString());
            alert_actions.error(error.toString());
            return Swal.fire(
                'Usuario',
                'Ocurrio un error, intenta más tarde',
                'error',
            )
        }
    ).catch(e => failure(e.toString())) 


    function request(param) { return { type: users_constants.USERS_CREATE_REQUEST , payload: param } }
    function success(param) { return { type: users_constants.USERS_CREATE_SUCCESS , payload: param } }
    function failure(error) { return { type: users_constants.USERS_CREATE_FAILURE , payload: error } }
}

function users_add_csv_reset() {
    return dispatch => {
        dispatch(request());
        dispatch(success());
        dispatch(failure());

        function request(param) { return { type: users_constants.USERS_CREATE_REQUEST , payload: param } }
        function success() { return { type: users_constants.USERS_CREATE_SUCCESS , payload: [] } }
        function failure() { return { type: users_constants.USERS_CREATE_FAILURE , payload: [] } }
    }
}

async function users_edit(user,selected_cohortes) {
    await users_service.users_edit(user,selected_cohortes)
            .then(
                res => {
                    console.log(res);
                    if(res.hasOwnProperty('error')){
                        failure(res.error.message.toString());
                        return Swal.fire(
                            'Usuario',
                            'Ocurrio un error, intenta más tarde',
                            'error',
                        )
                    }else{
                        
                        success(res.user);
                        return Swal.fire(
                            'Usuario',
                            'Fue actualizado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    failure(error.toString());
                    alert_actions.error(error.toString());
                    return Swal.fire(
                        'Usuario',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => failure(e.toString()))
    return dispatch => {
        dispatch( request() );

    };

    function request() { return { type: users_constants.USER_EDIT_REQUEST , payload: true } }
    function success(param) { return { type: users_constants.USER_EDIT_SUCCESS , payload: param } }
    function failure(error) { return { type: users_constants.USER_EDIT_FAILURE , payload: error } }
}

function users_status(user) {
    return dispatch => {
        dispatch( request() );

        users_service.users_status(user)
            .then(
                res => {
                    
                    if(res.hasOwnProperty('error')){
                        dispatch(failure(res.error.message.toString()));
                        return Swal.fire(
                            'Usuario',
                            'Ocurrio un error, intenta más tarde',
                            'error',
                        )
                    }else{
                        dispatch(success(res.user));
                        return Swal.fire(
                            'Usuario',
                            'Fue actualizado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                    return Swal.fire(
                        'Usuario',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: users_constants.USER_EDIT_REQUEST , payload: true } }
    function success(param) { return { type: users_constants.USER_EDIT_SUCCESS , payload: param } }
    function failure(error) { return { type: users_constants.USER_EDIT_FAILURE , payload: error } }
}

function users_delete(user) {
    return dispatch => {
        dispatch( request() );

        users_service.users_delete(user)
            .then(
                res => {
                    console.log(res)
                    if(res == 0){
                        dispatch(failure(res));
                        return Swal.fire(
                            'Usuario',
                            'Ocurrio un error, intenta más tarde',
                            'error',
                        )
                    }else{
                        dispatch(success(res));
                        return Swal.fire(
                            'Usuario',
                            'Fue borrado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                    return Swal.fire(
                        'Usuario',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: users_constants.USER_DELETE_REQUEST , payload: true } }
    function success(param) { return { type: users_constants.USER_DELETE_SUCCESS , payload: param } }
    function failure(error) { return { type: users_constants.USER_DELETE_FAILURE , payload: error } }
}

async function users_seach(user){
    let  result = await users_service.users_seach(user)
    return result;
}

async function get_user(id) {
   return await users_service.get_user(id)
        .then(
            res => {
                if(res.hasOwnProperty('error')){
                    failure(res.error.message.toString());
                }else{
                    return res.users[0];
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: users_constants.USERS_REQUEST , payload: true } }
    function success(param) { return { type: users_constants.USERS_SUCCESS , payload: param } }
    function failure(error) { return { type: users_constants.USERS_FAILURE , payload: error } }
}

async function get_user_field(search,field) {
    return await users_service.get_user_field(search,field)
         .then(
             res => {
                 if(res.hasOwnProperty('error')){
                     failure(res.error.message.toString());
                 }else{
                     
                     return res.users;
                 }
             },
             error => {
                 failure(error.toString());
                 alert_actions.error(error.toString());
             }
         ).catch(e => failure(e.toString()))
 
     function request() { return { type: users_constants.USERS_REQUEST , payload: true } }
     function success(param) { return { type: users_constants.USERS_SUCCESS , payload: param } }
     function failure(error) { return { type: users_constants.USERS_FAILURE , payload: error } }
 }

 function date_string_to_epoch (a_dateString) {
    //var dateMatch = /^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/;
    var dateMatch1 = /^([0-9]{4})[\-|\/]([0][0-9]|[1][0-2])[\-|\/]([0-2][0-9]|[3][0-1])$/;
    var dateMatch2 = /^([0-2][0-9]|[3][0-1])[\-|\/]([0][0-9]|[1][0-2])[\-|\/]([0-9]{4})$/;
    var datearr = a_dateString.match(dateMatch1);


    var dateobject;

    if ((datearr!=null) && (datearr.length>3)) {
        dateobject = new Date();

        dateobject.setFullYear(datearr[1]);
        dateobject.setMonth(parseInt(datearr[2]) - 1);
        dateobject.setDate(datearr[3]);

        if ( (!dateobject) || (isNaN(dateobject.getTime())) ) {
            return a_dateString;
        }

        return Math.floor(dateobject.getTime()/1000.0);
    } else {
        datearr = a_dateString.match(dateMatch2);

        if ((datearr!=null) && (datearr.length>3)) {
            dateobject = new Date();

            dateobject.setFullYear(datearr[3]);
            dateobject.setMonth(parseInt(datearr[2]) - 1);
            dateobject.setDate(datearr[1]);

            if ( (!dateobject) || (isNaN(dateobject.getTime())) ) {
                return a_dateString;
            }

            return Math.floor(dateobject.getTime()/1000.0);
        } else {
            return a_dateString;
        }
    }
}
