import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tree, { useTreeState } from 'react-hyper-tree'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';

import editar_usuario from '../../../../assets/img/user/user_edit.png'
import SubMenu from '../SubMenu/SubMenu';
import EditarCohorte from './Cohorte/EditarCohorte';
import AgregarCohorte from './Cohorte/AgregarCohorte';
import Header from '../../DetallesMenu/Header';
import { cohorts_actions } from '../../../../store/actions/cohorts';

const Cohortes = ({ onClose }) => {

    const cohorts = useSelector(state => state.cohorts);
    const categories = useSelector(state => state.categories);
    let [cohorts_tree, setstate_cohorts_tree] = useState([]);
    const dispatch = useDispatch();

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        cohorte: {}
    });

    const clickSubMenu = (type, open_param, cohorte) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            cohorte: cohorte
        })
    }

    const clicCloseSubMenu = () => {
        setOpenSubMenu(false)
        //document.querySelector(".detatlles.bg-blue-500.fixed.top-0.left-0.rounded-xl.m-4").style.width="400px";
    }

    //Elimina la categoria
    const handleClickDelete = async (cohorte) => {
        const alert_delet = await Swal.fire({
            title: "¿Seguro que desea eliminar el grupo?",
            text: "No hay vuelta atras",
            icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar'
        })

        if (alert_delet.isConfirmed == true) {
            await cohorts_actions.cohorts_delete(cohorte);
            dispatch(cohorts_actions.get_all_cohorts());
        }
    }

    console.log(cohorts, '>>> cohorts')

    return (
        <div>
            <div>
                {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
                {
                    cohorts.cohorts.length > 1 ?
                        ""
                        : <div className="mb-8">
                            <p className="text-sm ">Crea tu primer grupo</p>
                        </div>
                }
                <div className="flex flex-row flex-wrap justify-end" onClick={() => clickSubMenu('Agregar', !openSubMenu.open)}>
                    <div className=" m-2 flex items-center cursor-pointer"> Agregar Grupos<p className="ml-1 text-lg text-red-500 group-hover:text-opacity-50">+</p>
                    </div>
                </div>

                <div>
                    {cohorts.loading_cohorts == false ?
                        cohorts.cohorts.length > 0 ?
                            cohorts.cohorts.map(cohorte => (

                                (cohorte.name === "webservice" || cohorte.id === 1 || cohorte.idnumber === "webservice")
                                    ?
                                    cohorts.cohorts.length === 1 ? <p className="">No tienes Grupos Agregados</p> : ""
                                    :
                                    <Cohorte key={cohorte.id} cohorte={cohorte}
                                        clickSubMenu={() => clickSubMenu('Editar', !openSubMenu.open, cohorte)}
                                        clickDelete={() => { handleClickDelete(cohorte) }}
                                        categorias={categories.categories} />
                            ))
                            : <p className="">No tienes grupos agregados</p>
                        : <p className="">Cargando...</p>}
                </div>
            </div>
            {openSubMenu.open ?
                <div>
                    <SubMenu>
                        <div>
                            <div className="min-w-full  submenu">
                                <div className="w-1/4 bg-gray-100 h-80 rounded-xl">
                                    <div className=" flex justify-between items-center text-2xl p-4">
                                        <p>{openSubMenu.type}</p>
                                        <div className="flex"
                                            onClick={() => clicCloseSubMenu()}>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{ top: '-1px', marginLeft: '-0.4rem' }} />
                                        </div>

                                    </div>
                                    {openSubMenu.type === 'Agregar' ?
                                        <AgregarCohorte onCloseSubMenu={setOpenSubMenu} /> :
                                        <EditarCohorte onCloseSubMenu={setOpenSubMenu} cohorte={openSubMenu.cohorte} />}
                                </div>
                            </div>
                        </div>

                    </SubMenu>
                </div>
                : null}
        </div>

    );
}

const Cohorte = ({ cohorte, onToggle, clickSubMenu, clickDelete }) => {
    const [OpenDropdown, setOpenDropdown] = useState(false);

    const onClickOutsideListener = (e) => {
        document.removeEventListener("click", onClickOutsideListener);
        setOpenDropdown(false);
    }

    return (
        <div className={`h-20 mb-4 border border-blue-300 p-4 rounded-md bg-indigo-100 relative`} style={{ border: '1px solid #797974' }}>
            <div className="absolute top-0 right-2 z-10">
                <span onMouseLeave={() => {document.addEventListener("click", onClickOutsideListener)}} 
                    onClick={() => setOpenDropdown(!OpenDropdown)}>
                    {/* <img className="" src={three_dots} style={{ width: '1.5rem' }} /> */}
                    <FontAwesomeIcon icon={faEllipsisH} className="text-black cursor-pointer" />
                </span>
                {
                    OpenDropdown
                        ?
                        <div className="bg-white absolute top-5 right-2 p-2">
                            <p className="text-xs mb-2 hover:bg-gray-200 p-1 cursor-pointer" onClick={() => clickSubMenu()}>Editar</p>
                            <p className="text-xs hover:bg-gray-200 p-1 cursor-pointer" onClick={() => clickDelete()}>Eliminar</p>

                        </div>
                        :
                        ''
                }
            </div>

            <div className="w-full flex items-center">

                <div onClick={onToggle} className="w-3/4 cursor-pointer">
                    <p className="mb-1 text-md sm">{cohorte.name}</p>
                    <p className="mb-1 text-md"><span className="text-xs text-gray-500">{cohorte.name_category}</span> </p>
                </div>
            </div>
        </div>
    );
}

export default Cohortes;