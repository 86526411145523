import { meetings_constants } from '../constants/meetings';


const initialState = {
    meetings: [],
    loading_meetings: null,
    error_meetings: null,

    loading_meetings_create: null,
    meetings_create: null,
    error_meetings_create: null,

    loading_meetings_edit: null,
    meetings_edit: null,
    error_meetings_edit: null,

    loading_meetings_delete: false,
    meetings_delete: false,
    error_meetings_delete: false,
};

export function meetings_reducers(state = initialState, action) {
    switch (action.type) {
        //------------GET MEETINGS--------------//
        case meetings_constants.MEETINGS_REQUEST:
            return {
                ...state,
                loading_meetings: action.payload,
            };
        case meetings_constants.MEETINGS_SUCCESS:
            return {
                ...state,
                loading_meetings: false,
                meetings: action.payload,
                error_meetings: null
            };
        case meetings_constants.MEETINGS_FAILURE:
            return {
                ...state,
                loading_meetings: false,
                error_meetings: action.payload
            };

        //-------------CREATE MEETINGS-----------------
        case meetings_constants.MEETINGS_CREATE_REQUEST:
            return {
                ...state,
                loading_meetings_create: action.payload,
            };
        case meetings_constants.MEETINGS_CREATE_SUCCESS:
            return {
                ...state,
                loading_meetings_create: false,
                meetings_create: action.payload,
                error_meetings_create: null
            };
        case meetings_constants.MEETINGS_CREATE_FAILURE:
            return {
                ...state,
                loading_meetings_create: false,
                error_meetings_create: action.payload
            };

        // ------------- EDIT MEETINGS ----------
        case meetings_constants.MEETINGS_EDIT_REQUEST:
            return {
                ...state,
                loading_meetings_edit: action.payload,
            };
        case meetings_constants.MEETINGS_EDIT_SUCCESS:
            return {
                ...state,
                loading_meetings_edit: false,
                meetings_edit: action.payload,
                error_meetings_edit: null
            };
        case meetings_constants.MEETINGS_EDIT_FAILURE:
            return {
                ...state,
                meetings_edit: 0,
                loading_meetings_edit: false,
                error_meetings_edit: action.payload
            };

        //------------DELETE MEETINGS--------------//
        case meetings_constants.MEETINGS_DELETE_REQUEST:
            return {
                ...state,
                loading_meetings_delete: action.payload,
                meetings_delete: null,
                error_meetings_delete: null
            };
        case meetings_constants.MEETINGS_DELETE_SUCCESS:
            return {
                ...state,
                loading_meetings_delete: false,
                meetings_delete: action.payload,
                error_meetings_delete: false
            };
        case meetings_constants.MEETINGS_DELETE_FAILURE:
            return {
                ...state,
                loading_meetings_delete: false,
                meetings_delete: 0,
                error_meetings_delete: action.payload
            };
        default:
            return state
    }
}