import { user_login } from '../constants/user_auth';
import { userService } from '../services/user';
import { alert_actions } from './alert';
import { history } from '../helpers/history';

export const userAuthActions = {
    login,
    logout
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                res => {
					if(res.user != undefined){
                        res.user.password = password;
                        dispatch(success(res.user));
                        history.push('#/inicio');
                        window.location.reload();
                    }else{
                        dispatch(failure(res.error.toString()));
                        dispatch(alert_actions.error(res.error.toString()));
                    }

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: user_login.LOGIN_REQUEST, payload: true } }
    function success(user) { return { type: user_login.LOGIN_SUCCESS, payload: user } }
    function failure(error) { console.log('error in auth action'); console.log(error); return { type: user_login.LOGIN_FAILURE, payload: error } }
}
    

function logout() {
    userService.logout();
    window.location.reload("\#login");
}
