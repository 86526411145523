import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faArchive, faComment, faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../../store/actions/courses';

const Dctres = ({ course, onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const modulos = useSelector(state => state.courses.courses_modules)

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: true,
        course: {}
    });

    useEffect(() => {

    }, []);

    const validate_add = Yup.object().shape({
        name: Yup.string('Escribe el nombre del certificado DC3')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        intro: Yup.string('Escribe el nombre del certificado DC3')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(80, 'Debe ser menor a 80 caracteres'),
        companyname: Yup.string('Escribe el nombre o razon social de la empresa')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(50, 'Debe ser menor a 50 caracteres')
            .required('El campo es requerido'),
        companycurp: Yup.string('Escribe el RFC de la empresa')
            .min(12, 'Debe ser mayor a 12 caracter')
            .max(13, 'Debe ser menor a 13 caracteres')
            .required('El campo es requerido'),
        duration: Yup.number('Escribe la duracion del curso')
            .integer('Debe ser un numero entero')
            .positive('Debe ser un numero positivo'),
        agentstps: Yup.string('Escribe el agente capacitdor')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(50, 'Debe ser menor a 50 caracteres')
            .required('El campo es requerido')
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            intro: '',
            companyname: '',
            companycurp: '',
            duration: '',
            agentstps: ''
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async values => {
        let unixstartdate = convertDatetoUnix(values.startdate);
        let unixenddate = convertDatetoUnix(values.enddate)
        let json = {
            op: '&',
            c: [{ "type": "completion", "cm": values.activity, "e": 1 }],
            showc: [true]
        };

        let jsonAvailability = JSON.stringify(json);

        values.availability = jsonAvailability;
        values.startdate = unixstartdate;
        values.enddate = unixenddate;
        values.courseid = course.id

        //console.log(values);

        let result = await courses_actions.add_activity_dctres_course(values);
        dispatch(courses_actions.get_contents_course(course.id));
        onCloseSubMenu(false);
    }
    const clickSubMenu = (type, open_param, cohorte) => {
        console.log(document.querySelector('#typeresource').selectedOptions[0].value);
        setOpenSubMenu({
            type: document.querySelector('#typeresource').selectedOptions[0].value.toUpperCase(),
            open: open_param,
            cohorte: cohorte
        })
    }

    const convertDatetoUnix = (datetime) => {
        let utctime = datetime;
        let date = moment(utctime);
        let dateComponent = date.utc().format('YYYY-MM-DD HH:mm:ss');
        let unixtime = moment(dateComponent, 'YYYY.MM.DD HH:mm:ss').unix()
        return unixtime;
    }

    return (
        <div>
            <div>
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label htmlFor="">Nombre del certificado</label>
                        <input onChange={formik.handleChange} type="text" name="name" placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Escribe una introduccion (opcional)</label>
                        <input onChange={formik.handleChange} type="text" name="intro" placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.intro ? <div>{formik.errors.intro}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Razón social</label>
                        <input onChange={formik.handleChange} type="text" name="companyname" placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.companyname ? <div>{formik.errors.companyname}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">RFC</label>
                        <input onChange={formik.handleChange} type="text" name="companycurp" placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.companycurp ? <div>{formik.errors.companycurp}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Duracion en horas de la capacitacion </label>
                        <input onChange={formik.handleChange} type="text" name="duration" placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.duration ? <div>{formik.errors.duration}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Fecha de inicio</label>
                        <input onChange={formik.handleChange} type="datetime-local" name="startdate" value={formik.values.startdate} />
                        <p className="text-red-600 text-sm">{formik.errors.startdate ? <div>{formik.errors.startdate}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Fecha de termino</label>
                        <input onChange={formik.handleChange} type="datetime-local" name="enddate" value={formik.values.enddate} />
                        <p className="text-red-600 text-sm">{formik.errors.enddate ? <div>{formik.errors.enddate}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="mb-2" htmlFor="">Área temática del curso</label>
                        <select onChange={formik.handleChange} name="area" value={formik.values.area}>
                            <option value={0} selected disabled>Selecciona el area</option>
                            <option value="1">Producción general</option>
                            <option value="2">Servicios</option>
                            <option value="3">Administración, contabilidad y economía</option>
                            <option value="4">Comercialización</option>
                            <option value="5">Mantenimiento y recepción</option>
                            <option value="6">Seguridad</option>
                            <option value="7">Desarrollo personal y familiar</option>
                            <option value="8">Uso de la tecnologías de la información y comunicación</option>
                            <option value="9">Participación social</option>
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.area ? <div>{formik.errors.area}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Agente capacitador o STPS</label>
                        <input onChange={formik.handleChange} type="text" name="agentstps" placeholder="" />
                        <p className="text-red-600 text-sm">{formik.errors.agentstps ? <div>{formik.errors.agentstps}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label className="mb-2" htmlFor="">Finalización de actividad</label>
                        <select onChange={formik.handleChange} name="activity" value={formik.values.activity}>
                            <option value={0} selected disabled>Selecciona una actividad</option>
                            {
                                modulos !== null ?
                                    modulos.modules.map(item => (
                                        <option className="bg-blue-600" value={item.id}>{item.name}</option>
                                    )
                                    )
                                    : <p>Cargado...</p>
                            }
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.activity ? <div>{formik.errors.activity}</div> : null}</p>
                    </div>

                    <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-36 hover:bg-opacity-100`}>Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default Dctres;