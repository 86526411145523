export const nationalocupations = {
    "1": {name: "Cultivo, crianza y aprovechamiento 01", num: 1},
    "1.1": {name: "Agricultura y silvicultura 01.1", num: 2},
    "1.2": {name: "Ganadería 01.2", num: 3},
    "1.3": {name: "Pesca y acuacultura 01.3", num: 4},
    "2": {name: "Extracción y suministro 02", num: 5},
    "2.1": {name: "Exploración 02.1", num: 6},
    "2.2": {name: "Extracción 02.2", num: 7},
    "2.3": {name: "Refinación y beneficio 02.3", num: 8},
    "2.4": {name: "Provisión de energía 02.4", num: 9},
    "2.5": {name: "Provisión de agua 02.5", num: 10},
    "3": {name: "Construcción 03", num: 11},
    "3.1": {name: "Planeación y dirección de obras 03.1", num: 12},
    "3.2": {name: "Edificación y urbanización 03.2", num: 13},
    "3.3": {name: "Acabado 03.3", num: 14},
    "3.4": {name: "Instalación y mantenimiento 03.4", num: 15},
    "4": {name: "Tecnología 04", num: 16},
    "4.1": {name: "Mecánica 04.1", num: 17},
    "4.2": {name: "Electricidad 04.2", num: 18},
    "4.3": {name: "Electrónica 04.3", num: 19},
    "4.4": {name: "Informática 04.4", num: 20},
    "4.5": {name: "Telecomunicaciones 04.5", num: 21},
    "4.6": {name: "Procesos industriales 04.6", num: 22},
    "5": {name: "Procesamiento y fabricación 05", num: 23},
    "5.1": {name: "Minerales no metálicos 05.1", num: 24},
    "5.2": {name: "Metales 05.2", num: 25},
    "5.3": {name: "Alimentos y bebidas 05.3", num: 26},
    "5.4": {name: "Textiles y prendas de vestir 05.4", num: 27},
    "5.5": {name: "Materia orgánica 05.5", num: 28},
    "5.6": {name: "Productos químicos 05.6", num: 29},
    "5.7": {name: "Productos metálicos y de hule y plástico 05.7", num: 30},
    "5.8": {name: "Productos eléctricos y electrónicos 05.8", num: 31},
    "5.9": {name: "Productos impresos 05.9", num: 32},
    "6": {name: "Transporte 06", num: 33},
    "6.1": {name: "Ferroviario 06.1", num: 34},
    "6.2": {name: "Autotransporte 06.2", num: 35},
    "6.3": {name: "Aéreo 06.3", num: 36},
    "6.4": {name: "Marítimo y fluvial 06.4", num: 37},
    "6.5": {name: "Servicios de apoyo 06.5", num: 38},
    "7": {name: "Provisión de bienes y servicios 07", num: 39},
    "7.1": {name: "Comercio 07.1", num: 40},
    "7.2": {name: "Alimentación y hospedaje 07.2", num: 41},
    "7.3": {name: "Turismo 07.3", num: 42},
    "7.4": {name: "Deporte y esparcimiento 07.4", num: 43},
    "7.5": {name: "Servicios personales 07.5", num: 44},
    "7.6": {name: "Reparación de artículos de uso doméstico y personal 07.6", num: 45},
    "7.7": {name: "Limpieza 07.7", num: 46},
    "7.8": {name: "Servicio postal y mensajería 07.8", num: 47},
    "8": {name: "Gestión y soporte administrativo 08", num: 48},
    "8.1": {name: "Bolsa, banca y seguros 08.1", num: 49},
    "8.2": {name: "Administración 08.2", num: 50},
    "8.3": {name: "Servicios legales 08.3", num: 51},
    "9": {name: "Salud y protección social 09", num: 52},
    "9.1": {name: "Servicios médicos 09.1", num: 53},
    "9.2": {name: "Inscripción sanitaria y del medio ambiente 09.2", num: 54},
    "9.3": {name: "Seguridad social 09.3", num: 55},
    "9.4": {name: "Protección de bienes yo personas 09.4", num: 56},
    "10": {name: "Comunicación 10", num: 57},
    "10.1": {name: "Publicación 10.1", num: 58},
    "10.2": {name: "Radio, cine, televisión y teatro 10.2", num: 59},
    "10.3": {name: "Interpretación artística 10.3", num: 60},
    "10.4": {name: "Traducción e interpretación lingüística 10.4", num: 61},
    "10.5": {name: "Publicidad, propaganda y relaciones públicas 10.5", num: 62},
    "11": {name: "Desarrollo y extensión del conocimiento 11", num: 63},
    "11.1": {name: "Investigación  11.1", num: 64},
    "11.2": {name: "Enseñanza  11.2", num: 65},
    "11.3": {name: "Difusión cultural  11.3", num: 66}
}
