import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es'
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { attempts_quiz_actions } from '../../../../store/actions/attemptQuiz';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

const AttemptQuiz = (props) => {
    const dispatch = useDispatch();

    const { quizid } = props.match.params;
    const [datAttemptQuiz, setDatAttemptQuiz] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    //const user = JSON.parse(localStorage.getItem('user'));
    const user = useSelector(state => state.authentication);
    const data_attempts_quiz = useSelector(state => state.attempts_quiz.attempts_quiz);

    useEffect(() => {
        const getAttemptScorm = () => dispatch(attempts_quiz_actions.get_all_attempts_quiz(quizid));
        getAttemptScorm();
        courses_seleccionados();

    }, [data_attempts_quiz]);



    const courses_seleccionados = () => {
        let intentosuser = [];

        data_attempts_quiz.map(useridatt => {
            useridatt.attempts.map((attem) => {
                let userintento = {
                    id: useridatt.userid,
                    fullname: `${useridatt.userfirstname} ${useridatt.userlastname}`,
                    userusername: useridatt.userusername,
                    numberatt: attem.number,
                    reportedstatus: attem.reportedstatus,

                }
                intentosuser.push(userintento);
            });
        });
        setDatAttemptQuiz(intentosuser);
    }

    console.log(datAttemptQuiz);


    const handleButtonClick = async (evt, row) => {
        await attempts_quiz_actions.delete_attempt_quiz(row, quizid);
        dispatch(attempts_quiz_actions.get_all_attempts_quiz(quizid));
    };

    /* const handleChange = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []); */

    const columns = [
        {
            name: "Id",
            selector: "id",
            sortable: true
        },
        {
            name: "Nombre",
            selector: "fullname",
            sortable: true
        },
        {
            name: "Username",
            selector: "userusername",
            sortable: true,
        },
        {
            name: "Intentos",
            selector: "numberatt",
            sortable: true
        },
        {
            name: "Estatus",
            selector: "reportedstatus",
            sortable: true,
        },
        {
            cell: (row) => <button className='text-center rounded-full bg-blue-500 text-white text-xs p-1.5' onClick={evt => handleButtonClick(evt, row)}>Eliminar intento</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    const data = [
        ...datAttemptQuiz
    ];


    const tableData = {
        columns,
        data
    };



    return (
        <div className="container mx-auto px-4 pt-8 pb-32 h-screen overflow-y-scroll">
            <h3 className='text-4xl font-black text-blue-500 mr-5'>Quitar intentos Examen</h3>

            <DataTableExtensions
                export={false}
                print={false}
                {...tableData}>
                <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    highlightOnHover
                    paginationComponentOptions={paginationComponentOptions}
                    noDataComponent={<span>No se encontró ningún elemento</span>}
                />
            </DataTableExtensions>

        </div>
    );
}



export default AttemptQuiz;