import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faArchive, faComment, faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'

import { courses_actions } from '../../../../../store/actions/courses';

const Synchro = ({course,onCloseSubMenu,type}) => {
    const dispatch = useDispatch();
    const groups = useSelector(state=>state.courses);

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: true,
        course: {}
    });

    useEffect(() => {
        dispatch(courses_actions.get_group_category_course(course.contextid));
    },[]);


    const validate_add = Yup.object().shape({
        fullname: Yup.string('Escribe el nombre de la actividad')
          .min(1, 'Debe ser mayor a 1 caracter')
          //.max(30, 'Debe ser menor a 30 caracteres')
          .required('El campo es requerido'),
          statusActivo: Yup.string().required('Seleccionar un Status'),
        group: Yup.string().required('Seleccionar una categoria'),
    });

    const formik = useFormik({
        initialValues: {
            fullname: '',
            statusActivo: 0,
            group:'',
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async synchron => {
        console.log(synchron);
        synchron.courseid = course.id;
        synchron.contextid = course.contextid;
        onCloseSubMenu(false);
        await courses_actions.group_synchro(synchron);
        dispatch(courses_actions.get_method_enrol_course(course.id));
        /* actividad.courseid = course.id
        console.log('actividad',actividad);
        console.log('scorm',type)
        if(type === 'SCORM'){
        }else{
            await courses_actions.add_activity_resource_course(actividad);
        }
        dispatch(courses_actions.get_contents_course(course.id));
          */
    }
    const clickSubMenu = (type,open_param,cohorte) => {
        console.log(document.querySelector('#typeresource').selectedOptions[0].value);
        setOpenSubMenu({
            type: document.querySelector('#typeresource').selectedOptions[0].value.toUpperCase(),
            open: open_param,
            cohorte: cohorte
        })
    }

    return ( 
        <div>
            <div>
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="campo">
                        <label  htmlFor="">Nombre de la síncrononizacion</label>
                        <input onChange={formik.handleChange} type="text"  name="fullname" placeholder="Nombre de la actividad" value={formik.values.fullname}/>
                        <p className="text-red-600 text-sm">{formik.errors.fullname ? <div>{formik.errors.fullname}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label  htmlFor="">Estatus Activo</label>
                        <select onChange={formik.handleChange} name="statusActivo" value={formik.values.statusActivo}>
                            <option value={0}>Si</option>
                            <option value={1} >No</option>
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.statusActivo ? <div>{formik.errors.statusActivo}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label  htmlFor="">Grupo</label>
                        <select onChange={formik.handleChange} name="group" value={formik.values.group}>
                            <option className="bg-blue-600" value={''}>Seleccione un Grupo</option>
                            {
                                groups.loading_courses_groups === false ?
                                    groups.courses_groups.map(group=>(<option value={group.id}>{group.name}</option>)):
                                    null
                            }
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.group ? <div>{formik.errors.group}</div> : null}</p>
                    </div>
                    <div className="block-inline text-center ">
                        <button style={{maxWidth:"45%"}} type="submit" 
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 
                            border border-blue-300 hover:bg-opacity-100`}>Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default Synchro;