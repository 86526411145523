
import { badges_constants } from '../constants/badges';
import { badges_service } from '../services/badges';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const badges_actions = {
    get_all_badges,
    create_badge,
    edit_badge,
    delete_badge,
    action_badge,
    setting_badge
};

function get_all_badges() {
    return dispatch => {
        dispatch(request());

        badges_service.get_all_badges()
            .then(
                res => {

                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.badges));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: badges_constants.BADGES_REQUEST, payload: true } }
    function success(param) { return { type: badges_constants.BADGES_SUCCESS, payload: param } }
    function failure(error) { return { type: badges_constants.BADGES_FAILURE, payload: error } }
}


async function create_badge(badge) {
    await badges_service.create_badge(badge)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Insignia',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Insignia',
                        `Fue creada con exito`,
                        'success',
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return Swal.fire(
                'Error',
                'Ocurrio un error, intenta más tarde',
                'error',
            )
        })

    function request() { return { type: badges_constants.CREATE_BADGES_REQUEST, payload: true } }
    function success(param) { return { type: badges_constants.CREATE_BADGES_SUCCESS, payload: param } }
    function failure(error) { return { type: badges_constants.CREATE_BADGES_FAILURE, payload: error } }
}

async function edit_badge(badge) {
    await badges_service.edit_badge(badge)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {

                    return Swal.fire(
                        'Insignia',
                        `${res.error.message}`,
                        'error',
                    )
                } else {

                    return Swal.fire(
                        'Insignia',
                        `Fue actualizada con exito`,
                        'success',
                    )
                }
            },
            error => {

                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return Swal.fire(
                'Insignia',
                `${e}`,
                'error',
            )
        })
}

async function delete_badge(badge) {


    await badges_service.delete_badge(badge)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {

                    return Swal.fire(
                        'Insignia',
                        `${res.error.message}`,
                        'error',
                    )
                } else {

                    return Swal.fire(
                        'Insignia',
                        `Fue ${badge.action === 0 ? 'eliminado' : 'archivado'} con exito`,
                        'success',
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return Swal.fire(
                'Error',
                e,
                'error',
            )
        })
}

async function action_badge(action) {
    await badges_service.action_badge(action)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    return Swal.fire(
                        'Insignia',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Insignia',
                        `El estatus fue cambiado con exito`,
                        'success',
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Insignia',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        )
        .catch(e => {
            return Swal.fire(
                'Insignia',
                'Ocurrio un error, intenta más tarde',
                'error',
            )
        });
}

async function setting_badge(courses) {
    await badges_service.setting_badge(courses)
        .then(res => {
            if (res.hasOwnProperty('error')) {

                return Swal.fire(
                    'Insignia',
                    `${res.error.message}`,
                    'error',
                )
            } else {

                return Swal.fire(
                    'Insignia',
                    `Fue configurada la insignia`,
                    'success',
                )
            }
        },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            })
        .catch(e => {
            return Swal.fire(
                'Error',
                'Ocurrio un error, intenta más tarde',
                'error',
            )
        });

}