import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons'

import agregar from '../../../assets/img/user/agregar.png'
import buscar_usuario from '../../../assets/img/user/buscar_usuario.svg'
import agredada_masiva from '../../../assets/img/user/agregada_masiva.png'
import { users_actions } from '../../../store/actions/users';
import Header from './Header';
import SubMenu from './SubMenu/SubMenu';
import Tutor from './Tutor/Tutor';
import AsignarCursos from './Tutor/AsignarCursos';
import { tutors_actions } from '../../../store/actions/tutors';


const Tutores = ({ onClose }) => {
    const dispatch = useDispatch();

    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        user: {}
    });


    const users = useSelector(state => state.users);
    const [busquedaRes, guardarBusquedadRes] = useState([]);
    const [busquedaShow, guardarBusquedadShow] = useState(false);

    useEffect(() => {
        //console.log(users.users.length);
        //guardarBusquedadRes(users.users)
    }, [users]);

    const clickSubMenu = (type, open_param, user) => {
        dispatch(tutors_actions.get_tutor_courses(user.id));
        setOpenSubMenu({
            type: type,
            open: open_param,
            user: user
        })
    }

    const handleChange = async e => {
        if (e.target.value.length >= 4) {
            let typeseach = document.querySelector("#typeseach").selectedOptions[0].value;
            let searchuser = e.target.value;
            await searchUser(searchuser, typeseach);
        }
    }
    const searchUser = async (search, typeseach) => {
        if (search !== '') {
            let result = await users_actions.get_user_field(search, typeseach);
            /* const resultado = users.users.filter( item => {
                if(item){
                    return item.fullname.toLowerCase().search(search.toLowerCase()) !== -1;
                }
            }); */
            guardarBusquedadRes(result);
            guardarBusquedadShow(true)
        } else {
            guardarBusquedadRes([])
            guardarBusquedadShow(false)
        }
    }


    return (
        <div>

            <div className="bg-gray-100 w-1/4 rounded-xl h-80 scroll">
                {openSubMenu.open ? <div className="absolute bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl z-10"></div> : null}
                <Header title="Tutores" onClose={onClose} />
                {
                    <div>
                        <label for="search" className="block  text-sm">Buscar tutor</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-600 sm:text-sm">
                                    <FontAwesomeIcon icon={faSearch} className="cursor-pointer opacity-80 hover:opacity-100 " />
                                </span>
                            </div>
                            <input onChange={handleChange} type="text" name="search" id="search" className="h-9 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-md" style={{border: '1px solid #797974'}}/>
                            <div className="absolute inset-y-0 right-0 flex items-center" >
                                <label for="typeseach" className="sr-only">typeseach</label>
                                <select id="typeseach" name="typeseach" className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-600 sm:text-sm rounded-md">
                                    <option value="username">Numero Empleado</option>
                                </select>
                            </div>
                        </div>
                    </div>
                }

                {busquedaShow ?
                    <div>
                        {busquedaRes.length > 0 ?
                            <div className=" mt-5">
                                <p className="text-xs mb-1">Resultados: {busquedaRes.length}</p>
                                {busquedaRes.map(user => (
                                    <Tutor key={user.id} user={user} clickSubMenu={() => clickSubMenu('Asignar rol y cursos', !openSubMenu.open, user)} />
                                ))
                                }
                            </div>
                            : <p className="">No tienes usuarios agregados</p>}
                    </div>
                    : null}
                {openSubMenu.open ?
                    <SubMenu>
                        <div>
                            <div className="min-w-full  submenu">
                                <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                    <div className=" flex justify-between items-center text-2xl p-4">

                                        <p className="mr-1">{openSubMenu.type}</p>
                                        <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                            <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0.3rem'}} />
                                        </div>
                                    </div>
                                    {openSubMenu.type === 'Asignar rol y cursos' ?
                                        <AsignarCursos tutor={openSubMenu.user} searchUser={searchUser} onCloseSubMenu={setOpenSubMenu} /> 
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </SubMenu>
                    : null}
            </div>
        </div>

    );
}


export default Tutores;