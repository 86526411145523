import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { badges_actions } from '../../../../store/actions/badges';
import SubMenu from '../SubMenu/SubMenu';
import ConfigurationBadge from './ConfigurationBadge';
import { user } from '../../../../config/user_token';


const EditarInsignia = ({ badge, onCloseSubMenu }) => {
    const dispatch = useDispatch();
    const [fileUrl, setFileUrl] = useState(`${badge.badgeurl}?token=${user.token}`);
    const [openSubMenu, setOpenSubMenu] = useState({
        type: '',
        open: false,
        course: {}
    });
    const validate_edit = Yup.object().shape({
        id: Yup.string().required('El campo es requerido'),
        name: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        description: Yup.string()
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
    });

    const formik = useFormik({
        initialValues: { ...badge },
        validationSchema: validate_edit,
        onSubmit: values => {

            handleSubmit(values);
        },
    });

    const actionStatus = async () => {
        let action = {};
        action.badgeid = badge.id;
        action.status = badge.status === 0 ? 1 : 0;
        await badges_actions.action_badge(action);
        onCloseSubMenu(false)
        dispatch(badges_actions.get_all_badges());

    }

    const handleSubmit = async badge => {
        await badges_actions.edit_badge(badge);
        onCloseSubMenu(false);
        dispatch(badges_actions.get_all_badges());
    }

    function processImage(event) {
        const imageFile = event.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
    }

    const clickSubMenu = (type, open_param, cohorte) => {
        setOpenSubMenu({
            type: type,
            open: open_param,
            cohorte: cohorte
        })
    }
    
    return (
        <div>
            <div>
                {openSubMenu.open ? <div className="absolute z-10 bg-black bg-opacity-60 top-0 left-0 w-1/4 h-full rounded-xl"></div> : null}
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className="flex cursor-pointer items-center text-md mb-7 relative group">
                        <div className="w-2/5">
                            <img className="h-20 w-20 rounded-full overflow-hidden" src={fileUrl} alt=""></img>
                        </div>
                        <div className="flex items-center text-md relative group ml-1">
                            <label className="mr-1  group-hover:text-opacity-50" htmlFor="">Imagen para la insignia</label>
                            <p className="text-3xl  group-hover:text-opacity-50">+</p>
                            <input onChange={e => {
                                processImage(e);
                                formik.setFieldValue('file', e.target.files[0])
                            }} name="file" type="file" className="absolute top-0 left-0 opacity-0" accept="image/png, image/jpeg"
                                disabled={badge.status === 0 ? false : true}
                            />
                            {/* <p className="text-red-600 text-sm">{formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</p> */}
                        </div>
                    </div>
                    <div className="campo">
                        <label className="text-md" htmlFor="">Nombre de la Insignia</label>
                        <input className="rounded-md" onChange={formik.handleChange} type="text" name="name" value={formik.values.name} placeholder="Nombre de la Insignia" value={formik.values.name}
                            disabled={badge.status === 0 ? false : true} />
                        <p className="text-red-600 text-sm">{formik.errors.name ? <div>{formik.errors.name}</div> : null}</p>
                    </div>

                    <div className="campo">
                        <label className="text-md" htmlFor="">Descripción de la Insignia</label>
                        <textarea className="rounded-md" onChange={formik.handleChange} value={formik.values.description}
                            type="text" name="description" placeholder="Descripción de la Insignia"
                            disabled={badge.status === 0 ? false : true}
                        />
                        <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
                    </div>
                    {/* <div className="campo">
                        <label className="mb-2 text-md" htmlFor="">Seleccione Cursos</label>

                    </div>          
                    <div className="campo">
                        <label className="mb-2 text-md" htmlFor="">Criterios de asignación</label>
                        <select name="" id="">
                            <option value="" selected>Selecciona el criterio para la asignación</option>
                        </select>
                    </div> */}
                    <div className="block-inline text-center mb-2.5">
                        <button style={{ maxWidth: "45%" }} type="button"
                            onClick={() => actionStatus()}
                            className={`mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 
                            border border-blue-300 hover:bg-opacity-100 mr-1`}>{badge.status === 0 ? 'Habilitar' : 'Deshabilitar'}</button>
                        <button style={{ maxWidth: "45%" }} type="button"
                            className={`mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48  
                                border border-blue-300 hover:bg-opacity-100 ml-1`}
                            onClick={() => clickSubMenu('Configurar', !openSubMenu.open)}>Configurar</button>
                    </div>
                    <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            disabled={badge.status === 0 ? false : true}
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-48 
                            border border-blue-300 hover:bg-opacity-100`}>Guardar</button>
                    </div>
                </form>
            </div>
            {openSubMenu.open ?
                <SubMenu>
                    <div>
                        <div className="min-w-full  submenu">
                            <div className="w-1/4 bg-gray-100 h-80 rounded-xl scroll">
                                <div className=" flex justify-between items-center text-2xl p-4">
                                    <p className="mr-1">{openSubMenu.type}</p>
                                    <div className="flex" onClick={() => setOpenSubMenu(false)}>
                                        <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute" style={{top: '-1px', marginLeft: '0.3rem'}} />
                                    </div>
                                </div>
                                {
                                    openSubMenu.type === 'Configurar' ?
                                        <ConfigurationBadge badge={badge} onCloseSubMenu={onCloseSubMenu} /> :
                                        <></>

                                }
                            </div>
                        </div>
                    </div>

                </SubMenu>
                : null}
        </div>
    );
}

export default EditarInsignia;