import React from 'react';

const SubMenuAddUserMeeting = (props) => {
    return ( 
        <div style={{ width: props.width }} className="subsubmenu bg-gray-100 w-screen absolute p-2 top-0 left-0 z-11 rounded-xl">
            {props.children}
        </div>
     );
}
 
export default SubMenuAddUserMeeting;