
import api_iac from '../../config/instance_axios';
import { user } from '../../config/user_token';

export const communications_service = {
    get_all_communications,
    discussion_create,
    discussion_edit,
    discussion_delete
};

//let user = JSON.parse(localStorage.getItem('user'));


async function get_all_communications(idChannel) {
    const request_communications = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_read_channel&
    &moodlewsrestformat=json&
    channelid=${idChannel}`);

    if (Array.isArray(request_communications.data.announcements)) {
        const communications = request_communications.data.announcements;
        return { communications };
    }

    const error = request_communications.data;
    return { error };
}

async function discussion_create(discussion) {
    const request_discussion_create = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_send_announcement&
    moodlewsrestformat=json&
    channelid=${discussion.id}&
    subject=${discussion.subject}&
    message=${discussion.description}`);

    if (typeof request_discussion_create.data === 'object') {
        return 1;
    }

    const error = request_discussion_create.data;
    return { error };
}


async function discussion_edit(discussion) {
    const request_discussion_edit = await api_iac.get(`webservice/rest/server.php?
    wstoken=${user.token}&
    wsfunction=targett_edit_announcement&
    moodlewsrestformat=json&
    announcementid=${discussion.id}&
    subject=${discussion.subject}&
    message=${discussion.description}`);

    if (typeof request_discussion_edit.data === 'object') {
        return 1;
    }

    const error = request_discussion_edit.data;
    return { error };
}

async function discussion_delete(idDiscussion) {
    const request_discussion_delete = await api_iac.get(`webservice/rest/server.php?wstoken=${user.token}&
    wsfunction=targett_remove_announcement&
    moodlewsrestformat=json&
    announcementid=${idDiscussion}`);

    if (typeof request_discussion_delete.data === 'object') {
        return 1;
    } else {
        return 0
    }
}