import React from 'react';

const ReportFoundUser = ({user,clickSubMenu}) => {
    return( 
        <div className="bg-iacblack text-white uppercase mb-4 border border-blue-300 py-3 px-2 rounded-md">
            <div className="w-full flex items-center">
                <div className="w-1/4 mr-5"> <img className="rounded-md overflow-hidden" src={user.profileimageurlsmall} alt="" style={{ width: '100%', height: '1000%', objectFit: 'cover' }}/> </div>
                <div className="w-3/4">
                    <p className="mb-1 text-xs">{user.username}</p>
                    <p className="mb-1 text-md">{user.fullname}</p>
                    <p className="mb-1 text-xs">{user.workplace}</p>
                    <hr className="mb-1"/>
                    <p className="mb-1 text-xs">{user.area}</p>
                    <p className="mb-1 text-xs">{user.emplyment}</p>
                </div>
            </div>
            <div className="flex items-center justify-end">
                <p onClick={() => clickSubMenu(user)} className="mr-2 text-xs border border-white p-2 rounded-2xl bg-gray-800 cursor-pointer">Reporte</p>
            </div>
        </div>
    );
}


 
export default ReportFoundUser;