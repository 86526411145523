import React from 'react';

const SubMenuAddUserCohort = (props) => {
    return ( 
        <div className="subsubmenu bg-gray-100 w-screen absolute p-2 top-0 left-0 z-11 rounded-xl">
            {props.children}
        </div>
     );
}
 
export default SubMenuAddUserCohort;