
import { communications_constants } from '../constants/communications';
import { communications_service } from '../services/communications';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'


export const communications_actions = {
    get_all_communications,
    discussion_create,
    discussion_edit,
    discussion_delete
};

function get_all_communications(idChannel) {

    return dispatch => {
        dispatch(request());

        communications_service.get_all_communications(idChannel)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.communications));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: communications_constants.COMMUNICATIONS_REQUEST, payload: true } }
    function success(param) { return { type: communications_constants.COMMUNICATIONS_SUCCESS, payload: param } }
    function failure(error) { return { type: communications_constants.COMMUNICATIONS_FAILURE, payload: error } }
}

async function discussion_create(discussion) {

        await communications_service.discussion_create(discussion)
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        failure(res.error.message.toString());
                        return Swal.fire(
                            'Canal de comunicación',
                            `${res.error.message}`,
                            'error',
                        )
                    } else {
                        success(res);
                        return Swal.fire(
                            'Canal de comunicación',
                            'Mensaje agregado con exito',
                            'success'
                        )
                    }
                },
                error => {
                    failure(error.toString());
                    alert_actions.error(error.toString());
                    return Swal.fire(
                        'Error',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                }
            ).catch(e => failure(e.toString()))

    function request() { return { type: communications_constants.COMMUNICATIONS_CREATE_REQUEST, payload: true } }
    function success(param) { return { type: communications_constants.COMMUNICATIONS_CREATE_SUCCESS, payload: param } }
    function failure(error) { return { type: communications_constants.COMMUNICATIONS_CREATE_FAILURE, payload: error } }
}

async function discussion_edit(discussion) {

    await communications_service.discussion_edit(discussion)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Canal de comunicación',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Canal de comunicación',
                        'Mensaje actualizado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: communications_constants.COMMUNICATIONS_EDIT_REQUEST, payload: true } }
    function success(param) { return { type: communications_constants.COMMUNICATIONS_EDIT_REQUEST, payload: param } }
    function failure(error) { return { type: communications_constants.COMMUNICATIONS_EDIT_REQUEST, payload: error } }
}

async function discussion_delete(idDiscussion) {

    await communications_service.discussion_delete(idDiscussion)
        .then(
            res => {
                if (res == 0) {
                    failure(res);
                    return Swal.fire(
                        'Canal de comunicación',
                        'Ocurrio un error, intenta más tarde',
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Canal de comunicación',
                        'Mensaje borrado con exito',
                        'success'
                    )
                }
            },
            error => {
                failure(error.toString());
                alert_actions.error(error.toString());
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: communications_constants.COMMUNICATIONS_DELETE_REQUEST, payload: true } }
    function success(param) { return { type: communications_constants.COMMUNICATIONS_DELETE_SUCCESS, payload: param } }
    function failure(error) { return { type: communications_constants.COMMUNICATIONS_DELETE_FAILURE, payload: error } }
}