import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useRef, useState, useMemo, useCallback, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import { user } from '../../../../config/user_token'
import grafico from "../../../../assets/img/DAFI_original.jpeg";
import { getIdsFromFiglos, setStructure } from "../../../../helpers/estructura_curricular";
import { parserData } from "../../../../helpers/reporter";
import { downloadExcelReport, reporter_actions, resetGlobalTable, resetUserDatails, resetValues, } from "../../../../store/actions/reporter";
import { options } from "./constants";
import st from "./styles.module.css";
import { TableAccesos, TableAvances, TableCalifi, TableFinalizados, TableTodos } from "./Tables";
import { courses_service } from "../../../../store/services/courses";
import { reports_service } from "../../../../store/services/reports";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import UserDetail from "./UserDetail";
import SearchBar from "./SearchBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const validate = (obj, typeReport) => {
  const errors = {}

  if (!obj.fideicomiso || obj.fideicomiso === 0) {
    errors.fideicomiso = "Selecciona un fideicomiso"
  }

  if (typeReport === 'acceso') {
    if (!obj.reportBy) {
      errors.reportBy = "Selecciona un tipo de acceso"
    }
  }

  if (!obj.fechaInicio) {
    errors.fechaInicio = "Seleccione una fecha de inicio"
  }

  if (!obj.fechaFin) {
    errors.fechaFin = "Seleccione una fecha de fin"
  }

  if ((obj.fideicomiso !== -1 && obj.fideicomiso !== -2) && !obj.orderBy) {
    errors.orderBy = "Selecciona un filtro"
  }

  return errors;
}

const ReporteadorX = ({ onClose }) => {
  const [typeReport, setTypeReport] = useState('acceso')
  const [dataFideRep, setDataFideRep] = useState({})
  // const [globalFilter, setGlobalFilter] = useState("");
  const [error, setError] = useState({});

  const categories = useSelector(state => state.categories.categories);
  const reportData = useSelector((state) => state.reporter.course_gen);
  const userInfo = useSelector((state) => state.reporter.user_detail);
  const reportGlobalData = useSelector((state) => state.reporter.global_data); // -- usado por las tablas
  // const request = useSelector((state) => state.reporter.filter_request);
  const dispatch = useDispatch()
  // console.log(userInfo, '>>> userInfo')
  const chartRef = useRef();

  useEffect(() => {
    return () => {
      dispatch(resetValues())
      dispatch(resetGlobalTable())
      dispatch(resetUserDatails())
    }
  }, [dispatch])

  const handleTypeRep = (type) => {
    setTypeReport(type);
    setDataFideRep({ ...dataFideRep, fideicomiso: 0, reportBy: 0, orderBy: 0, })
    dispatch(resetGlobalTable())
    dispatch(resetValues())
  }

  const handleConsult = () => {
    // validar si no faltan datos de ingresar
    setError(validate(dataFideRep, typeReport))
    if (!Object.keys(validate(dataFideRep, typeReport)).length) {
      if (dataFideRep.fideicomiso === -1) dispatch(resetGlobalTable())
      dispatch(reporter_actions[`get_all_${typeReport}`](dataFideRep))

      dispatch(resetUserDatails())
    }
  }

  const handleDataFide = (e) => {
    const { name, value } = e.target;
    setDataFideRep({ ...dataFideRep, [name]: value })
  }

  const handleFideico = (e) => {
    //(e) => handleDataFide({ target: { name: "fideicomiso", value: parseInt(e.target.value) } })
    setDataFideRep({ ...dataFideRep, "fideicomiso": parseInt(e.target.value), orderBy: 0, })
  }

  const handleReportBy = (e) => setDataFideRep({ ...dataFideRep, [e.target.name]: e.target.value, orderBy: 0 })

  const handleOrderBy = (e) => {
    if (dataFideRep.fideicomiso === -1) {
      // hacemos las peticiones a la api
      // TODO: verificar que solo se obtenga la información si los datos del reporte global ya se cargaron.
      dataFideRep.orderBy = e.target.value;
      return dispatch(reporter_actions[`get_all_${typeReport}`](dataFideRep, true))
    }
    setDataFideRep({ ...dataFideRep, [e.target.name]: e.target.value })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const xdata = useMemo(() => parserData(reportData, typeReport, dataFideRep.orderBy, dataFideRep.fideicomiso), [reportData])

  const keys = useMemo(() => {
    if (!reportData) return [];
    console.log(reportGlobalData, '>>> global data')
    if (dataFideRep.fideicomiso === -1) {
      // necesitamos saber si reportGlobalData es válido 
      // return reportGlobalData ? Object.keys(reportGlobalData) : []
      if (!reportGlobalData) return []
      return Object.keys(reportGlobalData);
    }

    return Object.keys(reportData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, reportGlobalData]);

  // ====== Tablas
  const Table = useCallback(
    ({ users, title }) => {
      // console.log(dataFideRep.reportBy, '>>>')
      if (typeReport === 'acceso') return <TableAccesos data={users} accesOf={dataFideRep.reportBy} orderBy={dataFideRep.orderBy} title={title} />

      if (typeReport === 'avance') return <TableAvances orderBy={dataFideRep.orderBy} data={users} title={title} />

      if (typeReport === 'cursos') return <TableFinalizados orderBy={dataFideRep.orderBy} data={users} title={title} />

      if (typeReport === 'calificaciones') return <TableCalifi orderBy={dataFideRep.orderBy} data={users} title={title} />
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportData],
  )

  const ResultTables = useCallback(() => {
    if (dataFideRep.fideicomiso === -1) {
      let aux = []
      return keys.length ? keys.map((key, index) => {
        aux = Object.keys(reportGlobalData[key]);
        return (
          <TableTodos
            data={aux}
            title={key}
            key={index}
            filtro={dataFideRep.orderBy}
            dataAux={reportGlobalData[key]}
          />
        )
      }) : null
    }

    return keys.length
      ? keys.map((key, index) => (
        <div key={index}>
          {/* <h5>{key}</h5> */}
          <Table users={reportData[key]} title={key} />
        </div>
      ))
      : null

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys])

  // ====== Grafica
  const downloadImageChart = () => {
    html2canvas(document.querySelector('#chartBloque')).then(function (canvas) {
      saveImage(canvas.toDataURL(), 'grafica.png');
    });
  }

  const saveImage = (uri, filename) => {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;
      link.click();
    } else {
      window.open(uri);
    }
  }

  // ====== Reporte csv
  const getGlobalReport = () => {
    Swal.fire({
      title: 'Generando reporte...',
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        let timerInterval = setInterval(() => {
          const content = Swal.getHtmlContainer()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
    });

    courses_service.get_all_courses()
      .then(res => {
        let reportdata = [];
        let csvtext = 'Reporte del ' + (new Date()).toLocaleDateString() + ',\n';
        let startcourse = true;
        let startgroup = true;
        let comasstring = '';
        //let fieldsstring = 'nombre(s),apellido(s),código personal';
        let fieldsstring = '';
        var printFinishedCourses, askForNextCourseData;
        var llcounter = 0;

        if (res.hasOwnProperty('error')) {
          Swal.fire('Reporte', res.error.message.toString(), 'error');
        } else {
          printFinishedCourses = function () {
            reportdata.forEach((courseinfo) => {
              //console.log(courseinfo);
              if (!courseinfo.groups.length) {
                csvtext += 'Curso ' + courseinfo.coursename + ',\nNo se encontraron grupos enrolados...,\n';
              } else {
                courseinfo.groups.forEach((groupinfo) => {
                  groupinfo.students.forEach((studentinfo) => {
                    if (comasstring === '') {
                      comasstring = ',,,,,,,,';
                      if (typeof (studentinfo.fideicomiso) != 'undefined') {
                        comasstring += ',';
                        fieldsstring = 'fideicomiso,' + fieldsstring;
                      }

                      fieldsstring += 'nombre,apellido,código personal';
                      if (typeof (studentinfo.employment) != 'undefined') {
                        comasstring += ',';
                        fieldsstring += ',cargo';
                      }

                      fieldsstring += ',correo electrónico,progreso,calificacion,inicio,termino\n';
                      comasstring += '\n';
                    }

                    if (startcourse) {
                      csvtext += 'Curso ' + courseinfo.coursename + comasstring;
                      startcourse = false;
                    }

                    if (startgroup) {
                      csvtext += ' ' + comasstring;
                      if (groupinfo.name === '') {
                        csvtext += 'Estudiantes sin grupo';
                      } else {
                        csvtext += 'Grupo: ' + groupinfo.name;
                      }

                      csvtext += comasstring + fieldsstring;
                      startgroup = false;
                    }

                    if (typeof (studentinfo.fideicomiso) != 'undefined') {
                      csvtext += studentinfo.fideicomiso + ',';
                    }

                    csvtext += studentinfo.firstname + ',' + studentinfo.lastname + ',' + studentinfo.username;
                    if (typeof (studentinfo.employment) != 'undefined') {
                      csvtext += ',' + studentinfo.employment;
                    }
                    csvtext += ',' + studentinfo.email + ',' + studentinfo.progress + ',' + studentinfo.grade;

                    if (studentinfo.start) {
                      csvtext += ',' + (new Date(studentinfo.start * 1000)).toLocaleDateString();
                    } else {
                      csvtext += ',-';
                    }

                    if (((studentinfo.end) && (studentinfo.progress == 100))) {
                      csvtext += ',' + (new Date(studentinfo.end * 1000)).toLocaleDateString();
                    } else {
                      csvtext += ',-';
                    }

                    csvtext += '\n';

                  });

                  startgroup = true;
                });

                //courseinfo.groups[0].students.forEach((studentinfo) => {
                //csvtext += studentinfo.firstname + ',' + studentinfo.lastname + ',' + studentinfo.email + ',' + studentinfo.progress + ',' + studentinfo.grade + '\n';
                //});
              }

              startcourse = true;
            });
            //console.log(csvtext);
            // var encoding = "data:text/csv;charset=utf-8,%EF%BB%BF";  
            // var link = document.createElement("a");
            // link.setAttribute("href", encoding + escape(csvtext));
            // link.setAttribute("download", "FormData.csv");
            // link.click(); 

            //var encodedUri = encodeURI(csvtext);
            var hiddenElement = document.createElement('a');
            //hiddenElement.href = 'data:text/csv;charset=utf-8,' + escape(csvtext);
            hiddenElement.setAttribute('href', "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvtext))
            hiddenElement.target = '_blank';
            hiddenElement.download = 'Reporte_capacitacion.csv';
            hiddenElement.click();
            Swal.fire('Reporte', 'Reporte generado con exito, comenzara la descarga en su navegador.', 'success');
          }

          askForNextCourseData = function () {
            reports_service.get_course_data(res.courses[llcounter].id).then((row) => {
              return row.request_reportdata.then(rowresult => {
                reportdata.push(rowresult.data);
                llcounter++;
                if (llcounter < res.courses.length) {
                  window.setTimeout(askForNextCourseData, 100);
                } else {
                  window.setTimeout(printFinishedCourses, 500);
                }
              });
            });
          }

          askForNextCourseData();
        }
      },
        error => {
          Swal.fire('Reporte', error.toString(), 'error');
        })
      .catch(e => Swal.fire('Reporte', e.toString(), 'error'))
  }

  // reporte
  const hanndleDownload = () => {
    var methodAux = "export_";

    switch (typeReport) {
      case "acceso":
        methodAux += "acceso_"
        if (dataFideRep.reportBy === "Plataforma") {
          methodAux += "plataforma"
        }
        if (dataFideRep.reportBy === "Cursos") {
          methodAux += "curso"
        }
        break;
      case "avance":
        methodAux += "avances"
        break;
      case "cursos":
        methodAux += "cursos_finalizados"
        break;
      case "calificaciones":
        methodAux += "calificaciones"
        break;
      default:
        break;
    }

    downloadExcelReport(dataFideRep.fideicomiso, methodAux, dataFideRep.orderBy, reportData, dataFideRep.fechaInicio, dataFideRep.fechaFin)
  }

  const categoriasUser = useMemo(() => setStructure(categories, true), [categories])
  const idsFiglos = useMemo(() => getIdsFromFiglos(categoriasUser), [categoriasUser]);

  //console.log('se recargo el componente')
  return (
    <div id="reporteador" className={st.reporteador}>
      <div className={st.reporteador_sidebar}>
        <span className={st.reporteador_sidebar_title}>
          Tipo de reporte
        </span>
        <nav className={st.reporteador_sidebar_nav}>
          <button className={typeReport === 'acceso' ? st.active : ''} onClick={() => handleTypeRep('acceso')}>Acceso</button>
          <button className={typeReport === 'avance' ? st.active : ''} onClick={() => handleTypeRep('avance')}>Avance</button>
          <button className={typeReport === 'cursos' ? st.active : ''} onClick={() => handleTypeRep('cursos')}>Finalizados</button>
          <button className={typeReport === 'calificaciones' ? st.active : ''} onClick={() => handleTypeRep('calificaciones')}>Calificaciones</button>
        </nav>
        <hr
          className="my-5"
          style={{
            color: "#a9a9a9",
            backgroundColor: "#a9a9a9",
            height: 1,
            borderColor: "#a9a9a9",
          }}
        />
        <div className={st.reporteador_sidebar_dates}>
          <label htmlFor="managercategory" >Fideicomiso</label>
          <select
            className="w-full"
            name="fideicomiso"
            id="managercategory"
            onChange={handleFideico}
            value={dataFideRep.fideicomiso}
          >
            <option value={0} selected disabled>Fideicomisos</option>
            {user.isAdmin && <option value={-1}>Todos</option>}
            {user.isAdmin && <option value={-2}>Managers</option>}
            {categoriasUser.length
              ? categoriasUser.map(item =>
                <option key={item.id} value={item.id}>{item.name}</option>
              )
              : null}
          </select>
          {typeReport === 'acceso' && (
            <>
              <label>Accesos a:</label>
              <select
                name="reportBy"
                className="w-full"
                onChange={handleReportBy}
                value={dataFideRep.reportBy}
              >
                <option value={0} selected disabled>tipo de acceso</option>
                <option value="Plataforma">Plataforma</option>
                <option value="Cursos">Cursos</option>
              </select>
            </>
          )}
        </div>
        <hr
          className="my-5"
          style={{
            color: "#a9a9a9",
            backgroundColor: "#a9a9a9",
            height: 1,
            borderColor: "#a9a9a9",
          }}
        />
        <div className={st.reporteador_sidebar_dates}>
          <label className="mb-2" htmlFor="fechaInicio">Fecha Inicio</label>
          <input
            id="fechaInicio"
            className="w-full"
            name="fechaInicio"
            type="datetime-local"
            onChange={handleDataFide}
            defaultValue={dataFideRep.fechaInicio}
          />
          <label className="mb-2" htmlFor="fechaFinal">Fecha Final</label>
          <input
            id="fechaFinal"
            name="fechaFin"
            type="datetime-local"
            onChange={handleDataFide}
            className="rounded-md w-full"
            value={dataFideRep.fechaFin}
          />
        </div>
        <hr
          className="my-5"
          style={{
            color: "#a9a9a9",
            backgroundColor: "#a9a9a9",
            height: 1,
            borderColor: "#a9a9a9",
          }}
        />
        {(dataFideRep.fideicomiso !== -2 && dataFideRep.fideicomiso !== -1) ? <div className={st.reporteador_sidebar_dates}>
          <label>Filtrar por</label>
          <select
            name="orderBy"
            className="w-full"
            onChange={handleOrderBy}
            value={dataFideRep.orderBy}
          >
            <option value={0} selected disabled>Categorías</option>
            <option value="comite">Comités</option>
            <option value="cargo">Cargos</option>
            {dataFideRep.reportBy !== 'Plataforma' && <option value="curso">Cursos</option>}
          </select>
        </div> : null}
        <div className={st.reporteador_sidebar_nav}>
          <button className={`${st.btn_min} ${!dataFideRep && st.desabled}`} onClick={handleConsult}>Consultar</button>
        </div>
        <div className="grid">
          {error.fideicomiso && <span>{error.fideicomiso}</span>}
          {error.reportBy && <span>{error.reportBy}</span>}
          {error.fechaInicio && <span>{error.fechaInicio}</span>}
          {error.fechaFin && <span>{error.fechaFin}</span>}
          {error.orderBy && <span>{error.orderBy}</span>}
        </div>
        {reportData && <>
          <hr
            className="my-5"
            style={{
              color: "#a9a9a9",
              backgroundColor: "#a9a9a9",
              height: 1,
              borderColor: "#a9a9a9",
            }}
          />
          <div className={st.reporteador_sidebar_nav}>
            <button onClick={hanndleDownload} className={st.w_full_grid}>Descargar reporte</button>
          </div>
        </>}
      </div>
      <div id="reporteador_graphic" className={st.reporteador_graphic}>
        <div className={st.reporteador_graphic_header}>
          <img src={grafico} alt="reporteador" />
          <SearchBar fideicomisos={idsFiglos} />
          {reportData && <button onClick={downloadImageChart}>Descargar imagen</button>}
        </div>
        {userInfo && <UserDetail userData={userInfo} />}
        {reportData && (
          <div className={st.reporteador_graphic_sec}>
            <div id="chartBloque" className={st.reporteador_graphic_canv}>
              <Bar ref={chartRef} options={options} data={xdata} />
            </div>
            {dataFideRep.fideicomiso === -1 && reportData ? <div className={`${st.reporteador_sidebar_dates} ${st.reporte_filter_aux}`}>
              <label>Filtrar por</label>
              <select
                name="orderBy"
                onChange={handleOrderBy}
                value={dataFideRep.orderBy}
              >
                <option value={0} selected disabled>Categorías</option>
                <option value="comite">Comités</option>
                <option value="cargo">Cargos</option>
                {dataFideRep.reportBy !== 'Plataforma' && <option value="curso">Cursos</option>}
              </select>
            </div> : null}
            <div className={st.reporteador_table_detail}>
              <ResultTables />
            </div>
          </div>
        )}
            <div className="text-right" onClick={() => onClose()}>
              <FontAwesomeIcon icon={faTimesCircle} className="text-red-600 cursor-pointer opacity-80 hover:opacity-100 text-2xl absolute mt-2" style={{ top: '-1px', marginLeft: '0.3rem' }} />
          </div>
      </div>
    </div>
  );
};

export default ReporteadorX;
/**
 * 
 */