import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import CSVReader from 'react-csv-reader'
import { CSVLink, CSVDownload } from "react-csv";

import { courses_actions } from '../../../../../store/actions/courses';

import evaluacion from '../../../../../assets/img/cursos/evaluacion.png'


const Exam = ({ course, onCloseSubMenu, type }) => {
    const dispatch = useDispatch();

    const [fecha, setFecha] = useState('');
    const [dataExamen, setdataExamen] = useState('');
    useEffect(() => {
        let toDay = new Date();
        let year = toDay.getFullYear();
        let month = (toDay.getMonth() + 1) < 10 ? `0${(toDay.getMonth() + 1)}` : toDay.getMonth();
        let day = toDay.getDate();

        let hour = toDay.getHours();
        let minut = toDay.getMinutes() < 10 ? `0${toDay.getMinutes()}` : toDay.getMinutes();
        setFecha(`${year}-${month}-${day}T${hour}:${minut}`);

    }, []);


    const validate_add = Yup.object().shape({
        fullname: Yup.string('Escribe el nombre de la actividad')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(30, 'Debe ser menor a 30 caracteres')
            .required('El campo es requerido'),
        description: Yup.string('Escribe la descripcion de la actividad')
            .min(1, 'Debe ser mayor a 1 caracter')
            .max(90, 'Debe ser menor a 90 caracteres'),
        attempts: Yup.number('Se requiere un numero')
            .min(0, 'debe de seleccionar una opcion valida')
            .max(10, 'No debe de pasar de 10 intentos'),
        grademethod: Yup.number('Se requiere un numero')
            .min(1, 'debe de seleccionar una opcion valida')
            .max(4, 'No debe de pasar de '),
        shuffleanswers: Yup.number('Se requiere un numero')
            .min(0, 'debe de seleccionar una opcion valida')
            .max(4, 'No debe de pasar de '),
        gradepass: Yup.number('Se requiere un numero')
            .min(0, 'debe de seleccionar una opcion valida')
            .max(4, 'No debe de pasar de '),
        csv: Yup.string('Se requiere un numero')
            .min(1, 'debe de seleccionar una opcion valida')

    });

    const formik = useFormik({
        initialValues: {
            fullname: '',
            description: '',
            attempts: 0,
            grademethod: 1,
            shuffleanswers: 1,
            gradepass: '0.00',
            csv: ""
        },
        validationSchema: validate_add,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    const handleSubmit = async actividad => {
        actividad.courseid = course.id
        actividad.csv = dataExamen;
        console.log('actividad', actividad);
        await courses_actions.add_activity_exam_course(actividad);
        dispatch(courses_actions.get_contents_course(course.id));
        onCloseSubMenu(false);
    }

    const csvData = [
        ['category', 'parent', 'name', 'questiontext', 'questiontextformat', 'generalfeedback', 'generalfeedbackformat', 'defaultmark', 'penalty', 'qtype', 'question_answers'],
        [50, 0, 'name1', 'questiontext1', 0, 'ok', 0, 1, 1, 'multichoice', '[{"answer":"4", "fraction":1},{"answer":"8","fraction":0},{"answer":"9","fraction":0}]'],
        [50, 0, 'name2', 'questiontext2', 0, 'ok', 0, 1, 1, 'multichoice', '[{"answer":"4", "fraction":1},{"answer":"8","fraction":0},{"answer":"9","fraction":0}]'],
        [50, 0, 'name3', 'questiontext3', 0, 'ok', 0, 1, 1, 'multichoice', '[{"answer":"4", "fraction":1},{"answer":"8","fraction":0},{"answer":"9","fraction":0}]'],
        [50, 0, 'name4', 'questiontext4', 0, 'ok', 0, 1, 1, 'multichoice', '[{"answer":"4", "fraction":1},{"answer":"8","fraction":0},{"answer":"9","fraction":0}]'],
        [50, 0, 'true o false 1', 'true o false 1', 0, 'ok', 0, 1, 1, 'truefalse', '[{"answer":"Verdadero","fraction":1},{"answer":"Falso","fraction":0}]'],
        [50, 0, 'true o false 2', 'true o false 2', 0, 'ok', 0, 1, 1, 'truefalse', '[{"answer":"Verdadero","fraction":1},{"answer":"Falso","fraction":0}]'],
    ];

    return (
        <div>
            <div>
                <form className="formularios" onSubmit={formik.handleSubmit}>
                    <div className='flex items-center w-full mb-10'>
                        <p className='w-1/2'>Examen ejemplo: </p>
                        <CSVLink className='text-center cursor-pointer relative p-1 rounded-md w-1/2' data={csvData} filename={"examen-ejemplo.csv"} style={{ border: '1px solid #797974' }}>Descargar CSV</CSVLink>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Nombre de la actividad</label>
                        <input onChange={formik.handleChange} type="text" name="fullname" placeholder="Nombre de la actividad" value={formik.values.fullname} />
                        <p className="text-red-600 text-sm">{formik.errors.fullname ? <div>{formik.errors.fullname}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Descripcion de la actividad</label>
                        <textarea onChange={formik.handleChange} name="description" id="" cols="20" rows="3" placeholder="Nombre de la descripcion" value={formik.values.description}></textarea>
                        <p className="text-red-600 text-sm">{formik.errors.description ? <div>{formik.errors.description}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Método de Calificacion</label>
                        <select name="grademethod" id="id_grademethod" onChange={formik.handleChange} value={formik.values.grademethod}>
                            <option value="1" selected="">Calificación más alta</option>
                            <option value="2">Promedio de calificaciones</option>
                            <option value="3">Primer intento</option>
                            <option value="4">Último intento</option>
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.grademethod ? <div>{formik.errors.grademethod}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Intentos permitidos</label>
                        <select name="attempts" id="id_attempts" onChange={formik.handleChange} value={formik.values.attempts}>
                            <option value="0">Sin límite</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.attempts ? <div>{formik.errors.attempts}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <label htmlFor="">Ordenar al azar las respuestas</label>
                        <select name="shuffleanswers" id="id_attempts" onChange={formik.handleChange} value={formik.values.shuffleanswers}>
                            <option value="0">No</option>
                            <option value="1" selected>Sí</option>
                        </select>
                        <p className="text-red-600 text-sm">{formik.errors.shuffleanswers ? <div>{formik.errors.shuffleanswers}</div> : null}</p>
                    </div>
                    <div className="campo">
                        <div className="flex items-center mb-5 relative">
                            <p className="text-dark mr-3 text-lg">Cargar evaluación</p>
                            <img className='imgExam' src={evaluacion} alt="evaluación" />
                            <CSVReader
                                cssClass="absolute top-0 left-0 opacity-0"
                                parserOptions={{ header: true }}
                                onFileLoaded={async (data, fileInfo) => {
                                    console.log(data);
                                    formik.setFieldValue('csv', JSON.stringify(data))
                                    setdataExamen(JSON.stringify(data))
                                }}
                            />
                        </div>
                        <p className="text-red-600 text-sm">{formik.errors.csv ? <div>{formik.errors.csv}</div> : null}</p>
                    </div>
                    <div className="block-inline text-center ">
                        <button style={{ maxWidth: "45%" }} type="submit"
                            className={`mr-4 mx-auto text-center rounded-md bg-blue-500 text-white bg-opacity-90 px-4 py-2 w-36 hover:bg-opacity-100`}>Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}


export default Exam;