import { notifications_constants } from '../constants/notifications';
import { notifications_services } from '../services/notifications';


export const notifications_actions = {
    get_user_notifications,
    set_notification_as_read
};

function get_user_notifications() {
    return notifications_services.get_user_notifications();
}

async function set_notification_as_read(notiid) {
    await notifications_services.set_notification_as_read(notiid).then(
        res => {
            console.log(res);
        },
        error => {
            console.log(error);
        }
    ).catch (e => { console.log(e); });
}