export const kardex_constants = {
    KARDEX_GENERAL_REQUEST: 'KARDEX_GENERAL_REQUEST',
    KARDEX_GENERAL_SUCCESS: 'KARDEX_GENERAL_SUCCESS',
    KARDEX_GENERAL_FAILURE: 'KARDEX_GENERAL_FAILURE',

    KARDEX_FIDEICOMISO_REQUEST: 'KARDEX_FIDEICOMISO_REQUEST',
    KARDEX_FIDEICOMISO_SUCCESS: 'KARDEX_FIDEICOMISO_SUCCESS',
    KARDEX_FIDEICOMISO_FAILURE: 'KARDEX_FIDEICOMISO_FAILURE',

    KARDEX_INDIVIDUAL_REQUEST: 'KARDEX_INDIVIDUAL_REQUEST',
    KARDEX_INDIVIDUAL_SUCCESS: 'KARDEX_INDIVIDUAL_SUCCESS',
    KARDEX_INDIVIDUAL_FAILURE: 'KARDEX_INDIVIDUAL_FAILURE',

    KARDEX_GENERAL_RESET: 'KARDEX_GENERAL_RESET',
    KARDEX_FIDEICOMISO_RESET: 'KARDEX_FIDEICOMISO_RESET',
    KARDEX_INDIVIDUAL_RESET: 'KARDEX_INDIVIDUAL_RESET',
}