//import { types } from "../types/types";

import { events_constants } from '../constants/calendar';
import { events_service } from '../services/calendar';
import { alert_actions } from './alert';
import Swal from 'sweetalert2'

export const events_actions = {
    get_all_events,
    events_create,
    events_edit,
    events_delete
};

function get_all_events() {
    return dispatch => {
        dispatch(request());

        events_service.get_all_events()
            .then(
                res => {
                    if (res.hasOwnProperty('error')) {
                        dispatch(failure(res.error.message.toString()));
                    } else {
                        dispatch(success(res.events));
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alert_actions.error(error.toString()));
                }
            ).catch(e => dispatch(failure(e.toString())))
    };

    function request() { return { type: events_constants.EVENTS_REQUEST, payload: true } }
    function success(param) { return { type: events_constants.EVENTS_SUCCESS, payload: param } }
    function failure(error) { return { type: events_constants.EVENTS_FAILURE, payload: error } }
}

async function events_create(event, selected_cohort, meetings_select, courses_select) {
    await events_service.events_create(event, selected_cohort, meetings_select, courses_select)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {
                    failure(res.error.message.toString());
                    return Swal.fire(
                        'Evento',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    success(res);
                    return Swal.fire(
                        'Evento',
                        'Fue agregado con exito',
                        'success'
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => failure(e.toString()))

    function request() { return { type: events_constants.EVENTS_CREATE_REQUEST, payload: true } }
    function success(param) { return { type: events_constants.EVENTS_CREATE_SUCCESS, payload: param } }
    function failure(error) { return { type: events_constants.EVENTS_CREATE_FAILURE, payload: error } }
}

async function events_edit(event) {
    await events_service.events_edit(event)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {

                    return Swal.fire(
                        'Evento',
                        `${res.error.message}`,
                        'error',
                    )
                } else {

                    return Swal.fire(
                        'Evento',
                        `Fue actualizado con exito`,
                        'success',
                    )
                }
            },
            error => {

                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return Swal.fire(
                'Insignia',
                `${e}`,
                'error',
            )
        })
}

async function events_delete(event) {

    await events_service.events_delete(event)
        .then(
            res => {
                if (res.hasOwnProperty('error')) {

                    return Swal.fire(
                        'Evento',
                        `${res.error.message}`,
                        'error',
                    )
                } else {
                    return Swal.fire(
                        'Evento',
                        `Fue eliminado con exito`,
                        'success',
                    )
                }
            },
            error => {
                return Swal.fire(
                    'Error',
                    'Ocurrio un error, intenta más tarde',
                    'error',
                )
            }
        ).catch(e => {
            return Swal.fire(
                'Error',
                e,
                'error',
            )
        })
}
