import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-dropdown-select';
import Swal from 'sweetalert2';

import { courses_actions } from '../../../../store/actions/courses';
import { badges_actions } from '../../../../store/actions/badges';

const ConfigurationBadge = ({ badge, onCloseSubMenu }) => {

    const dispatch = useDispatch();
    const courses = useSelector(state => state.courses);
    const [opciones_courses, set_opciones_courses] = useState([]);
    const [selected_courses, set_selected_courses] = useState([]);
    const [added_course, set_added_course] = useState([]);
    const [opciones, set_opciones] = useState([]);
    const [grades_course, set_grades_course] = useState();
    const [courses_selected, set_course_selected] = useState([]);
    //pre-config
    const [options_selected_coursesids, set_options_selected_coursesids] = useState([]);
    const [options_selected_grades, set_options_selected_grades] = useState([]);
    //Courses Options
    const options_courses = courses.courses.map((option) => ({
        ...option,
        label: option.fullname,
        value: option.id,
        courseid: option.id
    }));
    const optionsCourses = async () => {
        let opcionesC = [];
        let addedGroup = [];
        let options_coursesids = [];
        let options_grades = [];
        options_courses.map(course => {
            let entryCurso = false;
            if (badge.criteria[0].params !== undefined) {
                badge.criteria[0].params.map(curso => {
                    if (curso.course === course.id) {
                        course.grade = curso.grade
                        addedGroup.push(course);
                        entryCurso = true;
                        options_coursesids.push(course.id)
                        options_grades[course.id] = {
                            courseid: course.id,
                            grade: curso.grade
                        }
                    }
                });
            }
            if (!entryCurso) {
                opcionesC.push(course);
            }
        });
        //set_added_course(addedGroup);
        set_course_selected(addedGroup);
        set_opciones(opcionesC);
        set_opciones_courses(opcionesC);
        formik.setValues({ "courseid": options_coursesids, "grade": options_grades, badgeid: badge.id });
        set_options_selected_coursesids(options_coursesids);
        set_grades_course(options_grades);

    }

    useEffect(() => {
        if (badge.hasbeenissued === true) {
            Swal.fire({
                title: 'Configurar Insignia',
                text: 'La insignia no puede ser configurada por que ya fue obtenida por al menos un usuario/a.',
                icon: 'warning',
                timer: 5000,
            }).then( () => {
                //onCloseSubMenu(false);
            })
        }
        optionsCourses();
    }, []);
    const validate = (values) => {
        console.log(values);
        let errors = {};
        if (values.courseid.length === 0) {
            errors.courseid = 'Debe de Elegir un Curso'
        } else {
            let clearGrades = values.grade.filter(grade => grade);
            if (clearGrades.length !== values.courseid.length) {
                errors.grade = clearGrades.length > 1 ? 'Falta configurar las calificaciones' : 'Falta una calificion por configurar'
            } else {
                clearGrades.map(grade => {
                    if (grade.grade === undefined) {

                        errors.grade = 'Falta una calificion por configurar';
                    }
                });
            }
        }
        return errors;
    }
    const formik = useFormik({
        initialValues: {
            courseid: [],
            grade: [],
            badgeid: badge.id
        },
        validate: validate,
        onSubmit: values => {
            console.log(values);
            handleSubmit(values)
        },
    });
    const handleSubmit = async courses => {

        await badges_actions.setting_badge(courses);
        onCloseSubMenu(false);
        dispatch(badges_actions.get_all_badges());

    }

    const onChange = async (values) => {
        console.log(values);
        let coursesids = formik.values.courseid;
        let coursesid = values.filter(value => !coursesids.includes(value.courseid));
        //let coursesid = formik.values.courseid.filter(id=> !coursesids.includes());
        coursesids.push(coursesid.courseid);
        formik.setFieldValue('courseid', coursesids);
        set_selected_courses(values);
        courses_seleccionados(values);
    };

    const courses_seleccionados = (courseid) => {
        const selectes_cohortes = opciones.filter(cohort => !courseid.includes(cohort));
        set_opciones_courses(selectes_cohortes);
    }

    const setGradeCourse = (courseid, grade) => {
        let gradesCourses = grades_course;
        console.log(formik.values.grade);
        selected_courses.map(course => {
            if (course.id === courseid) {
                gradesCourses[courseid] = {
                    courseid,
                    grade
                }
            }
        });

        formik.values.grade.map(course => {
            if (course.courseid === courseid) {
                gradesCourses[courseid] = {
                    courseid,
                    grade
                }
            }
        })
        courses_selected.map(course => {
            if (course.id === courseid) {
                course.grade = grade;
            }
        });
        //let gradesCourses = grades_course;
        formik.setFieldValue('grade', gradesCourses);
        set_grades_course(gradesCourses);
    }
    return (
        <form className="formularios" onSubmit={formik.handleSubmit}>
            <div className="campo">
                <label className="mb-2" htmlFor="">Seleccionar los cursos</label>

                <Select
                    multi
                    options={opciones_courses}
                    values={[]}
                    name="courseid"
                    onChange={(value) => {
                        onChange(value)
                    }}
                    disabled={badge.hasbeenissued === true ? true : false} 
                />
                <p className="text-red-600 text-sm">{formik.errors.courseid ? <div>{formik.errors.courseid}</div> : null}</p>
            </div>
            <div className='campo'>
                {
                    courses_selected.length === 0 ?
                        null :
                        courses_selected.map(course =>
                            <div className="flex justify-between align-center items-center text-center mb-2 rounded-xl border p-4 text">
                                <div className={`w-1/4 inline mr-3`}>
                                    <label className="mb-2" htmlFor="">{course.fullname}</label>
                                </div>
                                <div className={`w-1/2 inline`}>
                                    <label className="mr-1" htmlFor="">Calificación</label>
                                    <input onChange={(e) => {
                                        if (e.target.value !== "") {
                                            setGradeCourse(course.id, e.target.value);
                                        }
                                    }} type="number" name="grade[]" className={`w-1/2 rounded-xl`} placeholder="Nombre de la Insignia" min={0} max={100} value={course.grade} disabled={badge.hasbeenissued === true ? true : false} />
                                </div>

                            </div>
                        )
                }
                {
                    selected_courses.length === 0 ?
                        courses_selected.length === 0 ?
                            <p className="text-red-600 text-sm">
                                Seleccione un curso
                            </p> :
                            null
                        :
                        selected_courses.map(course =>
                            <div className="flex justify-between align-center items-center text-center mb-2 rounded-xl border p-4 text">
                                <div className={`w-1/4 inline mr-3`}>
                                    <label className="mb-2" htmlFor="">{course.fullname}</label>
                                </div>
                                <div className={`w-1/2 inline`}>
                                    <label className="mr-1" htmlFor="">Calificación</label>
                                    <input onChange={(e) => {
                                        if (e.target.value !== "") {
                                            setGradeCourse(course.id, e.target.value);
                                        }
                                    }} type="number" name="grade[]" className={`w-1/2 rounded-xl`} min={0} max={100} disabled={badge.hasbeenissued === true ? true : false} />
                                </div>

                            </div>
                        )
                }
                <p className="text-red-600 text-sm">{formik.errors.grade ? <div>{formik.errors.grade}</div> : null}</p>
            </div>
            <button className="mx-auto text-center rounded-full bg-iacblack bg-opacity-100 px-4 py-2 w-48 text-white
                            border border-blue-300 hover:text-blue-300 hover:bg-opacity-50 block" disabled={badge.hasbeenissued === true ? true : false} >Guardar</button>
        </form>
    );

}

export default ConfigurationBadge;