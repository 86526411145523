export const communications_constants = {
    COMMUNICATIONS_REQUEST: 'COMMUNICATIONS_REQUEST',
    COMMUNICATIONS_SUCCESS: 'COMMUNICATIONS_SUCCESS',
    COMMUNICATIONS_FAILURE: 'COMMUNICATIONS_FAILURE',

    COMMUNICATIONS_CREATE_REQUEST: 'COMMUNICATIONS_CREATE_REQUEST',
    COMMUNICATIONS_CREATE_SUCCESS: 'COMMUNICATIONS_CREATE_SUCCESS',
    COMMUNICATIONS_CREATE_FAILURE: 'COMMUNICATIONS_CREATE_FAILURE',

    COMMUNICATIONS_EDIT_REQUEST: 'COMMUNICATIONS_EDIT_REQUEST',
    COMMUNICATIONS_EDIT_SUCCESS: 'COMMUNICATIONS_EDIT_SUCCESS',
    COMMUNICATIONS_EDIT_FAILURE: 'COMMUNICATIONS_EDIT_FAILURE',

    COMMUNICATIONS_DELETE_REQUEST: 'COMMUNICATIONS_DELETE_REQUEST',
    COMMUNICATIONS_DELETE_SUCCESS: 'COMMUNICATIONS_DELETE_SUCCESS',
    COMMUNICATIONS_DELETE_FAILURE: 'COMMUNICATIONS_DELETE_FAILURE',

    REMOVE_COMMENTS_COMMUNICATIONS_REQUEST: 'REMOVE_COMMENTS_COMMUNICATIONS_REQUEST',
    REMOVE_COMMENTS_COMMUNICATIONS_SUCCESS: 'REMOVE_COMMENTS_COMMUNICATIONS_SUCCESS',
    REMOVE_COMMENTS_COMMUNICATIONS_FAILURE: 'REMOVE_COMMENTS_COMMUNICATIONS_FAILURE',
}