import Swal from 'sweetalert2';
import api_iac from "../../config/instance_axios";
import { user } from "../../config/user_token";
import { formatNameExcel } from '../../helpers/estructura_curricular';
import { kardex_constants } from "../constants/kardex";
import { alert_actions } from "./alert";

const get_kardex_general =
    (data) => async (dispatch) => {
        try {
            dispatch(request());
            Swal.fire({
                title: 'Generando reporte...',
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                },
            });
            const response = await api_iac.post(
                `kardex/api.php?q=getKardexGeneral`,
                {
                    ...data,
                    token: user.token,
                    isadmin: user.isAdmin,
                    userid: user.userid
                }
            );

            if (
                response.status !== 200 ||
                response.data.hasOwnProperty("error")
            ) {
                throw new Error("Ocurrio un error, comprueba los datos o intenta de nuevo");
            }

            if (Array.isArray(response.data)) {
                throw new Error("No encontramos datos para mostrar");
            }

            Swal.fire('Reporte', 'Reporte generado con exito', 'success');

            return dispatch(success(response.data));
        } catch (error) {
            Swal.fire(
                'Reporte',
                error.toString(),
                'info',
            );
            dispatch(failure(error.toString()));
        }

        function request() {
            return {
                type: kardex_constants.KARDEX_GENERAL_REQUEST,
                payload: true,
            };
        }

        function failure(error) {
            return {
                type: kardex_constants.KARDEX_GENERAL_FAILURE,
                payload: error,
            };
        }

        function success(param) {
            return {
                type: kardex_constants.KARDEX_GENERAL_SUCCESS,
                payload: param,
            };
        }
    };

const get_kardex_por_fideicomiso = (data) => async (dispatch) => {
    try {
        dispatch(request());
        Swal.fire({
            title: 'Generando reporte...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        });
        const response = await api_iac.post(
            `kardex/api.php?q=getKardexPorFideicomiso`,
            {
                ...data,
                token: user.token
            }
        );

        if (
            response.status !== 200 ||
            response.data.hasOwnProperty("error")
        ) {
            throw new Error("Ocurrio un error, comprueba los datos o intenta de nuevo");
        }

        if (Array.isArray(response.data)) {
            throw new Error("No encontramos datos para mostrar");
        }

        Swal.fire('Reporte', 'Reporte generado con exito', 'success');
        console.log(response.data)
        return dispatch(success(response.data));
    } catch (error) {
        Swal.fire(
            'Reporte',
            error.toString(),
            'info',
        );
        dispatch(failure(error.toString()));
    }

    function request() {
        return {
            type: kardex_constants.KARDEX_FIDEICOMISO_REQUEST,
            payload: true,
        };
    }

    function failure(error) {
        return {
            type: kardex_constants.KARDEX_FIDEICOMISO_FAILURE,
            payload: error,
        };
    }

    function success(param) {
        return {
            type: kardex_constants.KARDEX_FIDEICOMISO_SUCCESS,
            payload: param,
        };
    }
};

const get_kardex_individual = (data) => async (dispatch) =>{
    try {
        dispatch(request());
        Swal.fire({
            title: 'Generando reporte...',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        });
        const response = await api_iac.post(
            `kardex/api.php?q=getKardexIndividual`,
            {
                ...data,
                token: user.token
            }
        );

        if (
            response.status !== 200 ||
            response.data.hasOwnProperty("error")
        ) {
            throw new Error("Ocurrio un error: " + response.data.error.toString() + ". Comprueba los datos o intenta de nuevo");
            //return dispatch(failure(response.data.error.toString()));
        } else {

            if (Array.isArray(response.data)) {
                throw new Error("No encontramos datos para mostrar");
            }

            Swal.fire('Reporte', 'Reporte generado con exito', 'success');
            console.log(response.data)
            return dispatch(success(response.data));
        }
    } catch (error) {
        Swal.fire(
            'Reporte',
            error.toString(),
            'info',
        );
        dispatch(failure(error.toString()));
    }

    function request() {
        return {
            type: kardex_constants.KARDEX_FIDEICOMISO_REQUEST,
            payload: true,
        };
    }

    function failure(error) {
        return {
            type: kardex_constants.KARDEX_INDIVIDUAL_FAILURE,
            payload: error,
        };
    }

    function success(param) {
        return {
            type: kardex_constants.KARDEX_INDIVIDUAL_SUCCESS,
            payload: param,
        };
    }
}

// ======================
// Esta función no hace uso de redux pero se encuentra en este archivo
// para mantener una relación en las funciones

export const downloadExcelReport = async (data, name, type, method, fideicomiso) => {
    try {
        Swal.fire({
            title: 'Generando reporte',
            text: "Esto puede tardar unos minutos, por favor no cierre esta ventana ni recargue la página.",
            timerProgressBar: true,
            allowOutsideClick: false,
            icon: "info",
            didOpen: () => Swal.showLoading()
        });

        // Formamos la fecha actual
        const currentDate = new Date();
        const currentDayOfMonth = currentDate.getDate();
        const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
        const currentYear = currentDate.getFullYear();

        const dateString = currentDayOfMonth + "-" + (currentMonth + 1) + "-" + currentYear;

        const response = await api_iac.post("kardex/api.php?q=export_excel", {
            data,
            hora: new Date().toLocaleTimeString(),
            fecha: dateString,
            tipo: type,
            method: method,
            fideicomiso: fideicomiso,
        }, { responseType: "blob" });

        if (
            response.status !== 200
        ) { throw new Error("error al hacer la consulta"); }

        // formamos el nombre del arcivo
        var nameHidden = name + "_kardex_" + dateString + "_" + new Date().toLocaleTimeString() + ".xlsx"	;
        // creamos el link de descarga del archivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nameHidden);
        link.click();

        Swal.fire('Reporte', 'Reporte generado con éxito.', 'success');
    } catch (error) {
        console.log(error, '>>>>>')
        Swal.fire(
            'Error al descargar el reporte',
            error.toString(),
            'info',
        );
    }
}

export const resetKardexGeneral = () =>{
    return {
        type: kardex_constants.KARDEX_GENERAL_RESET,
        payload: true
    }
}

export const resetKardexFideicomiso = () =>{
    return {
        type: kardex_constants.KARDEX_FIDEICOMISO_RESET,
        payload: true
    }
}

export const resetKardexIndividual = () =>{
    return {
        type: kardex_constants.KARDEX_INDIVIDUAL_RESET,
        payload: true
    }
}

export const kardex_actions = {
    get_kardex_general,
    get_kardex_por_fideicomiso,
    get_kardex_individual,
    downloadExcelReport
};